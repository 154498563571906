import { StyleSheet } from "react-native";
import { MAIN_COLOR, TAB_BAR_COLOR } from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 22,
  },
  section: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: 14,
  },
  btn: {
    width: 170,
    height: 41,
    paddingHorizontal: 24,
    paddingVertical: 10,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  btnWrap: {
    display: "flex",
    flexDirection: "row",
    marginTop: 22,
    alignItems: "center",
    justifyContent: "center",
    gap: 22,
    marginTop: 22,
  },
  btnCancel: {
    borderColor: "#ADB9C7",
    borderWidth: 1.5,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
  },
  btnSave: {
    backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
  },
  btnText: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: MAIN_FONT.regular,
    color: TAB_BAR_COLOR.INACTIVE_COLOR,
  },
  box: {
    paddingHorizontal: 15,
    paddingVertical: 12,
    borderRadius: 8,
    backgroundColor: "#F0F1F8",
  },
});
