// open -n -a /Applications/Google\ Chrome.app/Contents/MacOS/Google\ Chrome --args --user-data-dir="/tmp/chrome_dev_test" --disable-web-security
// "homepage": "/client_portal",


import React from 'react';
import {
  BackHandler,
  Dimensions,
  Image,
  Keyboard,
  Linking,
  Platform,
  SafeAreaView,
  Text,
  TextInput,
  TouchableOpacity, View
} from 'react-native';
import { showMessage } from "react-native-flash-message";
import { MaterialIndicator } from 'react-native-indicators';
import { bindActionCreators } from 'redux';
// import { FirebaseRecaptchaVerifierModal, FirebaseRecaptchaBanner } from 'expo-firebase-recaptcha';
import ReCAPTCHA from "react-google-recaptcha";
import { RFValue } from "react-native-responsive-fontsize";
import { connect } from 'react-redux';
import { addToken, Login, signIn } from '../../Clickup/api';
import { reset, setClient } from '../../Reducer/tia';
import { MAIN_COLOR } from "../../Utility/Colors";
import LOADER from "../../Utility/ComponentCard/Loader";
//Component
import stylesCommonComponents from "../../Utility/ComponentStyle/style";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import styles from "./style";

const { width, height } = Dimensions.get("window");

class LoginScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      token: { data: "" },
      responseToken: null,
      showPopupMessageBox: false,
      showPassword: false,
      popupIconName: "", // iconName = {"MINUS"} // "MINUS", "INFO", "CHECK"
      popupMessageTitle: "", // "Invalid Access"
      popupMessageText: "", // "Your account has not been activated. Speak to contact the supplier"
      popupButtonText: "", // "GO BACK"
      popupTypeId: 0,
      signing: false,
      window: Dimensions.get('window'),
      notTab: Dimensions.get('window').width > 1000 ? true : false,
      isSmall: Dimensions.get('window').height < 900 ? true : false
    };

    // this.ReCaptchaRef = this.ReCaptchaRef.bind(this);
  }

  _onBlurr = () => {
    BackHandler.removeEventListener('hardwareBackPress',
      this._handleBackButtonClick);
  }

  _onFocus = () => {
    BackHandler.addEventListener('hardwareBackPress',
      this._handleBackButtonClick);
  }

  _handleBackButtonClick = () => {
    BackHandler.exitApp();
    return true;
  }

  onPressForgotPassword = () => {
    this.props.navigation.navigate("ForgotPasswordScreen");
  };


  componentDidMount() {

    Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        this.setState({ notTab: window.width > 1000 ? true : false, isSmall: window.height < 900 ? true : false })
      }
    );

    this.props.reset({});

    Linking.getInitialURL()
      .then((url) => {
        if (url.includes("https://theinvestorsagency.com.au/client_portal/?resetPassword?email=")) {
          url = url.replace("https://theinvestorsagency.com.au/client_portal/?resetPassword?email=", "");
          this.props.navigation.navigate("ResetPasswordScreen", { email: url });
        };
      })
      .catch(console.warn);
    
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (window.location.pathname === "/verify-email" && params.get("email")) {
      this.props.navigation.navigate("SignUpScreen");
    }
  }

  onResponseTokenReceived = async () => {
    
    const { email, password, token, responseToken } = this.state;

    this.setState({ signing: true });

    signIn(email, password, responseToken)
      .then((result) => {
        if (result.tasks?.length) {
          const r = result.tasks[0].custom_fields.filter(
            (item, index) => item.id === "08208191-53bd-4985-97f1-2dd9b9327797"
          );
          if (r[0].value !== password) {
            showMessage({
              message: "Email or Password is incorrect.",
              type: "info",
              color: "#FFF",
              backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
            });
            this.setState({ signing: false });
            return;
          }
          this.props.setClient({ ...result.tasks[0], email: email });
          this.props.navigation.navigate("MainScreen");
          addToken(result.tasks[0], token.data);
        } else {
          showMessage({
            message: "Something went wrong, please try again later.",
            type: "info",
            color: "#FFF",
            backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
          });
          this.setState({ signing: false });
        }
      })
      .catch((error) => {
        showMessage({
          message: error.message,
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
        });
        this.setState({ signing: false });
      });
  }


  onPressLogin = async () => {

    // if(this.state.signing) {
    //   return
    // }

    const { email, password } = this.state;
    //firebase.crashlytics().crash();
    //this.ffgfg();
    //Check for null,undefined,0,000,"",false
    !Boolean(email) ? this.setState({ emailRequired: true }) : null;
    !Boolean(password) ? this.setState({ passwordRequired: true }) : null;

    if (Boolean(email) && Boolean(password)) {
      //un-comment before push
      this.ReCaptchaRef.execute();
      // this.onResponseTokenReceived();

      signIn({ email, password })
        .then((response) => {
          let result = response.data;
          if (result) {
            const passwordId =
              result.role == "partner"
                ? "6712e7e6-5104-4223-8553-055a90d0b38f"
                : "08208191-53bd-4985-97f1-2dd9b9327797";
            const r = result.custom_fields.filter(
              (item, index) =>
                item.id === passwordId
            );
            if (r[0].value !== password) {
              showMessage({
                message: "Email or Password is incorrect.",
                type: "info",
                color: "#FFF",
                backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
              });
              this.setState({ signing: false });
              return;
            }

            this.props.setClient({ ...result, email: email });
            this.props.navigation.navigate("MainScreen");

            addToken(result, email);
          } else {
            showMessage({
              message: "Email or Password is incorrect.",
              type: "info",
              color: "#FFF",
              backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
            });
            this.setState({ signing: false });
          }
        })
        .catch((error) => {
          showMessage({
            message: error.message,
            type: "info",
            color: "#FFF",
            backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
          });
          this.setState({ signing: false });
        });

    }
    else {
      this.setState({signing: false});
      showMessage({
        message: "Username or Password can't be empty.",
        type: "info",
        color: '#FFF',
        backgroundColor: MAIN_COLOR.PRIMARY_COLOR
      });
    }
  };

  render() {
    const { notTab, isSmall, signing, responseToken } = this.state;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: MAIN_COLOR.BACKGROUND, flexDirection: notTab ? 'row' : 'column' }} keyboardShouldPersistTaps={true} behavior={Platform.OS === "ios" ? "padding" : "padding"} keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}>
        {/* <NavigationEvents
          onWillFocus={this._onFocus}
          onWillBlur={this._onBlurr}
        /> */}
        <View onPress={Keyboard.dismiss} accessible={false} style={{ padding: '5%', height: notTab ? '100%' : isSmall ? '100%' : '68%', width: notTab ? '50%' : '100%', backgroundColor: '#FFF' }}>
          <View style={[styles.topView]}>
            <Image
              style={styles.logoImage}
              resizeMode="contain"
              source={require("../../assets/header_logo.svg")}
            />
          </View>
          <View style={[styles.scrollViewContentView, { width: notTab ? '100%' : '68%', alignSelf: 'center' }]}>
            <Text style={{ fontFamily: MAIN_FONT.bold, color: '#272D37', fontSize: 18, marginBottom: 5 }}>
              Hey! Welcome back
            </Text>

            <Text style={{ fontFamily: MAIN_FONT.light, color: '#272D37', fontSize: 14, }}>
              Please sign-in to continue!
            </Text>


            <View style={styles.centerView}>


              {/* TextInput email address */}


              {/* <Text style={{ fontFamily: MAIN_FONT.regular, color: '#272D37', marginBottom: 5 }}>
                Email
              </Text>



                <TextInput
                  // ref={(input) => { this.emailTextInput = input; }}
                  // keyboardType="web-search"
                  // autoCorrect={false}
                  style={{ height: 50, width: '100%', fontFamily: MAIN_FONT.regular, backgroundColor: 'red' }}
                  // placeholderTextColor="#ADB9C7"
                  value={this.state.email}
                  autoFocus
                  focusable
                  editable
                  // underlineColorAndroid="transparent"
                  // onChangeText={email => this.setState({ email, emailRequired: false })}
                  // placeholder="Enter You Username"
                  // returnKeyType={"next"}
                  // onSubmitEditing={() => { this.passwordTextInput.focus(); }}
                  // blurOnSubmit={false}
                  // autoCapitalize="none"
                />
               */}



              <Text style={{ fontFamily: MAIN_FONT.regular, color: '#272D37', marginBottom: 5 }}>
                Email
              </Text>

              <View style={{ paddingHorizontal: 20, width: '90%', borderWidth: 0.5, borderRadius: 10, borderColor: '#ADB9C7', marginBottom: 20 }}>


                <TextInput
                  ref={(input) => { this.emailTextInput = input; }}
                  keyboardType="web-search"
                  autoCorrect={false}
                  outline='none'
                  style={{ height: 50, outline: 'none', width: '90%', fontFamily: MAIN_FONT.regular, }}
                  placeholderTextColor="#ADB9C7"
                  value={this.state.email}
                  underlineColorAndroid="transparent"
                  onChangeText={email => !signing && this.setState({ email, emailRequired: false })}
                  placeholder="Enter Your Email"
                  returnKeyType={"next"}
                  onSubmitEditing={() => { this.passwordTextInput.focus(); }}
                  blurOnSubmit={false}
                  autoCapitalize="none"
                //clearButtonMode="always"
                />
              </View>

              {/* Text password */}
              <Text style={{ fontFamily: MAIN_FONT.regular, color: '#272D37', marginBottom: 5 }}>
                Password
              </Text>
              <View style={{ flexDirection: 'row', width: '90%', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 20, borderWidth: 0.5, borderRadius: 10, borderColor: '#ADB9C7' }}>
                <TextInput
                  ref={(input) => { this.passwordTextInput = input; }}
                  secureTextEntry={!this.state.showPassword}
                  keyboardType="default"
                  autoCorrect={false}
                  style={{ height: 50, outline: 'none', width: '90%', fontFamily: MAIN_FONT.regular, }}
                  placeholderTextColor="#ADB9C7"
                  value={this.state.password}
                  underlineColorAndroid="transparent"
                  onChangeText={password =>  !signing && this.setState({ password, passwordRequired: false })}
                  placeholder="Enter Your Password"
                  returnKeyType={"done"}
                  onSubmitEditing={this.onPressLogin}
                //clearButtonMode="always"
                />
                <TouchableOpacity onPress={() => this.setState({ showPassword: !this.state.showPassword })}>
                  <Image source={require('../../assets/eye.png')} style={{ height: 30, width: 30, resizeMode: 'contain' }} />
                </TouchableOpacity>
              </View>

              <ReCAPTCHA
                ref={(ReCaptchaRef)=>this.ReCaptchaRef=ReCaptchaRef}
                sitekey="6LfOX_shAAAAAHN_GM6S1mUGcBhzcPJptDEoZW-n"
                size='invisible'
                onChange={(responseToken)=>this.setState({responseToken},()=>{
                  this.ReCaptchaRef.reset();
                  this.onResponseTokenReceived();
                })}
                onErrored={()=>{
                  this.setState({responseToken: null, signing: false}),()=>{
                  this.ReCaptchaRef.reset();
                }}}
                onExpired={()=>{
                  this.setState({responseToken: null, signing: false})
                }}
                // type={'image'}
                badge={'bottomright'}
              />

              {/* TouchableOpacity forgot password */}
              <View style={{ width: '90%', justifyContent: 'space-between', alignItems: 'flex-end', padding: 20, paddingRight: 0 }}>
                <TouchableOpacity
                  style={styles.forgotPassword}
                  onPress={this.onPressForgotPassword}
                >
                  <Text style={{ fontFamily: MAIN_FONT.regular, color: '#272D37', marginBottom: 5 }}>Forgot password?</Text>
                </TouchableOpacity>
              </View>

              {/* TouchableOpacity Login */}
              <TouchableOpacity
                style={[stylesCommonComponents.button, { height: 50, width: '60%', alignSelf: 'center', marginTop: 50 }]}
                onPress={()=>this.onPressLogin()}
                activeOpacity={0.8}

              >
                {
                  signing && responseToken ?
                    <MaterialIndicator size={RFValue(16.75)} color={MAIN_COLOR.BACKGROUND_COLOR} />
                    :
                    <Text
                      style={{
                        fontFamily: MAIN_FONT.semi,
                        fontSize: 16,
                        color: "#FFFFFF",
                      }}
                      numberOfLines={1} >Sign In</Text>
                }
              </TouchableOpacity>
            </View>
          </View>

        </View>

        <View style={{
          justifyContent: 'center',
          flexDirection: notTab ? 'column' : 'row',
          alignItems: 'center', overflow: 'hidden',
          height: notTab ? '100%' : isSmall ? 0 : '32%',
          width: notTab ? '50%' : '100%'
        }}>
          <Image
            style={{ height: 200, width: "50%" }}
            resizeMode="contain"
            source={require("../../assets/content.png")}
          />

          <View style={{ flexDirection: 'column', justifyContent: 'flex-start', width: notTab ? '100%' : '50%' }}>
            <Text style={{ fontSize: 21, color: '#1A3F49', fontFamily: MAIN_FONT.bold, textAlign: notTab ? 'center' : 'left', marginTop: notTab ? 25 : 0 }}>
              The Investors Agency
            </Text>

            <Text style={{ fontSize: 16, color: '#A2ADB9', fontFamily: MAIN_FONT.regular, textAlign: notTab ? 'center' : 'left', marginTop: 10 }}>
              You’re on your way to better property management.
            </Text>
          </View>
        </View>
        {this.state.isLoading ? <LOADER /> : null}
      </SafeAreaView>
    );
  }
};


function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART
  }
}

// function mapDispatchToProps(dispatch) {
//   return {
//     setClient:
//     // setUserInfo: (state, action) => dispatch(setUser(state, action)),
//     // setUserToken: (state, action) => dispatch(setUser(state, action)),
//     // setActiveSupplier: (id, host) => dispatch(setActiveSupplier(id, host)),
//     // setColorsToRedux: (data) => dispatch(setColors(data)),
//     // setActiveSupplierInfo: (data) => dispatch(setActiveSupplierInfo(data)),
//     // setSupplierList: (list) => dispatch(setSupplierList(list)),
//     // setCartInfo: (id, total) => dispatch(setCartInfo(id, total))
//   }
// }

function mapDispatchToProps(dispatch) {


  return bindActionCreators(
    {
      setClient,
      reset,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);