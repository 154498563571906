import React from 'react';
import {
  StyleSheet,
} from 'react-native';

import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { MAIN_HEADER, MAIN_COLOR } from "../Colors";
import { MAIN_FONT } from "./font";

var FONT_STYLE;

export default FONT_STYLE = StyleSheet.create({

  homeTabTitle: {
    fontFamily: MAIN_FONT.regular,
    fontSize: 16,
    color: 'red'
  },
});
