import { isEmpty, sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { Platform, SafeAreaView, View } from "react-native";
import Toast from "react-native-toast-message";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetailClient,
  getDetailsByScenarioId,
  getListRoadmapPartner,
  getListScenarios,
  getProperty,
} from "../../Clickup/api";
import {
  setLoading,
  setPurchasedProperties,
  setScenarioSelected,
} from "../../Reducer/portfolio-roadmap";
import { setClient } from "../../Reducer/tia";
import { MAIN_COLOR } from "../../Utility/Colors";
import Loader from "../../Utility/ComponentCard/Loader";
import { monthDiff } from "../../Utility/common";
import { toastConfig } from "../../configs/toastConfig";
import { ROLES, usePermission } from "../../hooks/usePermission";
import Header from "./Header";
import PurchaseOverview from "./PurchaseOverview";
import AddPartner from "./PurchaseOverview/AddPartner";
import SaveScenario from "./PurchaseOverview/SaveScenario";
import ButtonExpand from "./components/ButtonExpand";
import EmptyState from "./components/EmptyState";
import AccessDenied from "./components/AccessDenied";
import styles from "./style";

const PortfolioRoadmap = () => {
  const dispatch = useDispatch();
  const { opportunities, client, role } = useSelector((state) => state.TIA);
  const { loading, scenarioSelected } = useSelector(
    (state) => state.PORTFOLIO_ROADMAP
  );
  const { isView: viewAddPartner } = usePermission("addPartner");
  const { isView: viewSaveScenario } = usePermission("saveScenario");
  const [accessDenied, setAccessDenied] = useState(false);
  const [disabledClickOutside, setDisabledClickOutside] = useState(false);
  const [listScenarios, setListScenarios] = useState([]);
  const [listPurchases, setListPurchases] = useState([]);
  const [listRoadmapPartner, setListRoadmapPartner] = useState([]);
  const [showPopupAddScenario, setShowPopupAddScenario] = useState(false);
  const [showPopupAddPartner, setShowPopupAddPartner] = useState(false);
  const [isEmptyScreen, setIsEmptyScreen] = useState(scenarioSelected);

  const fetchPropertyData = async (id) => {
    try {
      const propertyData = await getProperty(id);
      if (propertyData?.err) return null;
      return {
        ...propertyData?.custom_fields?.reduce(
          (fields, field) => ({
            ...fields,
            [field.name]: field.value,
          }),
          {}
        ),
        id: propertyData.id,
      };
    } catch (error) {
      return null;
    }
  };

  const fetchListScenario = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const scenarioId = params.get("scenario_id");

      dispatch(setLoading(true));
      const response = await getListScenarios(client.id, role);
      setListScenarios(response.data);
      if (!isEmpty(response.data)) {
        const scenario = await getDetailsByScenarioId(
          scenarioId
            ? scenarioId
            : role?.toLowerCase() === ROLES.CLIENT
            ? response.data.scenarios?.[0].id
            : response.data.shared_scenarios?.[0].id,
          client.id
        );
        if (scenario.status === false) {
          throw new Error("You do not have permission to view this scenario");
        }
        dispatch(setScenarioSelected(scenario));
      } else {
        dispatch(setScenarioSelected(null));
        setIsEmptyScreen(true);
      }
    } catch (error) {
      dispatch(setScenarioSelected(null));
      setAccessDenied(true);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const fetchListRoadmapPartner = async () => {
    try {
      dispatch(setLoading(true));
      const response = await getListRoadmapPartner(client.id, role);
      setListRoadmapPartner(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    const getInfoClient = async () => {
      const result = await getDetailClient(client.id);
      dispatch(setClient({ ...result, email: client.email }));
    };
    getInfoClient();
  }, []);

  useEffect(() => {
    const opportunityIds = opportunities
      .filter((opportunity) => {
        const { status, "Proposed Property": proposedProperty } = opportunity;
        return (
          status?.status?.toUpperCase() === "MARKET UPDATES" &&
          !!proposedProperty?.[0]?.id
        );
      })
      .map((item) => item["Proposed Property"][0].id);

    const propertyIds = client.custom_fields
      ?.find((one) => one.name === "Properties")
      ?.value?.map((item) => item.id);

    const propertyDataPromises = [
      ...opportunityIds,
      ...(propertyIds || []),
    ].map(fetchPropertyData);

    Promise.all(propertyDataPromises)
      .then((propertyData) => {
        const validPropertyData = propertyData.filter(Boolean);
        const listPurchase = sortBy(
          validPropertyData
            .map((one) => {
              if (
                !!one?.["Purchase date "] &&
                !!one?.["CURRENT RENTAL RATE P/W $"]
              ) {
                const isNonTIA = !!one["Purchased By"];
                return {
                  rentPW: Number(one["CURRENT RENTAL RATE P/W $"]),
                  interestPerMonth: one["Interest Only Loan At"],
                  propertyPrice: Number(one["Purchase Price"]),
                  date: Number(one["Purchase date "]),
                  recommendedBorrowing: Number(
                    (isNonTIA ? one["Debt remaining"] : one["Loan Amount"]) || 0
                  ),
                  availableEquity: 0,
                  isNonTIA,
                  deposit: one["Deposit Amount"],
                  interestRate: Number(one["Interest Only Loan Percent"]),
                };
              }
            })
            .filter(Boolean),
          "date"
        );
        if (listPurchase) {
          const newListPurchase = listPurchase.map((item, index) => {
            return {
              ...item,
              noOfMonth: monthDiff(
                new Date(item.date),
                new Date(
                  index !== listPurchase.length - 1
                    ? listPurchase[index + 1].date
                    : new Date()
                )
              ),
            };
          });
          setListPurchases(newListPurchase);
        }
        dispatch(setPurchasedProperties(validPropertyData));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [opportunities, client]);

  useEffect(() => {
    fetchListScenario();
    if (viewAddPartner) {
      fetchListRoadmapPartner();
    }
    return () => {
      window.history.replaceState({}, "", window.location.origin);
    };
  }, []);

  useEffect(() => {
    scenarioSelected && setIsEmptyScreen(false);
  }, [scenarioSelected]);

  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: MAIN_COLOR.BACKGROUND }}
      keyboardShouldPersistTaps={true}
      behavior="padding"
      keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}
    >
      {loading && <Loader />}
      <View style={styles.header}>
        <View
          style={{
            position: "fixed",
            right: 120,
            top: 0,
            width: 150,
            zIndex: 9999,
          }}
        >
          <Toast config={toastConfig} />
        </View>
        {viewAddPartner && (
          <View style={{ position: "absolute", top: 20, right: 362 }}>
            <ButtonExpand
              showPopup={showPopupAddPartner}
              setShowPopup={setShowPopupAddPartner}
              title="Add partners to view"
              showRightIc
              disabledClickOutside={disabledClickOutside}
              leftIcon={require("../../assets/portfolio-roadmap/user-cirlce-add.png")}
            >
              <AddPartner
                setDisabledClickOutside={setDisabledClickOutside}
                setShowPopup={setShowPopupAddPartner}
                listRoadmapPartner={listRoadmapPartner}
                setListRoadmapPartner={setListRoadmapPartner}
              />
            </ButtonExpand>
          </View>
        )}
        {viewSaveScenario && (
          <View style={{ position: "absolute", top: 20 }}>
            <ButtonExpand
              title="Save Scenario"
              showPopup={showPopupAddScenario}
              setShowPopup={setShowPopupAddScenario}
              disabledClickOutside={disabledClickOutside}
              leftIcon={require("../../assets/portfolio-roadmap/archive-add.png")}
            >
              <SaveScenario
                setShowPopup={setShowPopupAddScenario}
                userId={client.id}
                listScenarios={listScenarios}
                fetchData={fetchListScenario}
                setDisabledClickOutside={setDisabledClickOutside}
                setAccessDenied={setAccessDenied}
              />
            </ButtonExpand>
          </View>
        )}
      </View>
      {!accessDenied && (
        <View
          style={{
            overflow: "auto",
            padding: 25,
            zIndex: -1,
            flex: 1,
          }}
        >
          <Header listPurchases={listPurchases} />
          {isEmptyScreen ? (
            <EmptyState />
          ) : (
            <PurchaseOverview listPurchases={listPurchases} />
          )}
        </View>
      )}
      {accessDenied && (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <AccessDenied />
        </View>
      )}
    </SafeAreaView>
  );
};

export default PortfolioRoadmap;
