export const PW_REACTIONS = {
  240000: 346,
  250000: 361,
  260000: 375,
  270000: 389,
  280000: 404,
  290000: 418,
  300000: 433,
  310000: 441,
  320000: 449,
  330000: 457,
  340000: 464,
  350000: 471,
  360000: 478,
  370000: 484,
  380000: 490,
  390000: 495,
  400000: 500,
  410000: 505,
  420000: 509,
  430000: 513,
  440000: 516,
  450000: 519,
  460000: 526,
  470000: 533,
  480000: 540,
  490000: 547,
  500000: 553,
  510000: 559,
  520000: 565,
  530000: 571,
  540000: 576,
  550000: 582,
  560000: 587,
  570000: 592,
  580000: 597,
  590000: 601,
  600000: 606,
  610000: 615,
  620000: 624,
  630000: 632,
  640000: 641,
  650000: 650,
  660000: 655,
  670000: 660,
  680000: 664,
  690000: 669,
  700000: 673,
  710000: 676,
  720000: 678,
  730000: 681,
  740000: 683,
  750000: 685,
  760000: 687,
  770000: 689,
  780000: 690,
  790000: 691,
  800000: 692,
  810000: 693,
  820000: 694,
  830000: 694,
  840000: 695,
  850000: 695,
  860000: 695,
  870000: 694,
  880000: 694,
  890000: 693,
  900000: 692,
  910000: 691,
  920000: 690,
  930000: 689,
  940000: 687,
  950000: 731,
  960000: 738,
  970000: 746,
  980000: 754,
  990000: 762,
  1000000: 769,
  1010000: 767,
  1020000: 765,
  1030000: 763,
  1040000: 760,
  1050000: 757,
  1060000: 754,
  1070000: 751,
  1080000: 748,
  1090000: 744,
  1100000: 740,
  1110000: 736,
  1120000: 732,
  1130000: 728,
  1140000: 723,
  1150000: 719,
  1160000: 725,
  1170000: 731,
  1180000: 738,
  1190000: 744,
  1200000: 750,
  1210000: 756,
  1220000: 763,
  1230000: 769,
  1240000: 775,
  1250000: 781,
  1260000: 775,
  1270000: 769,
  1280000: 763,
  1290000: 757,
  1300000: 750,
  1310000: 756,
  1320000: 762,
  1330000: 767,
  1340000: 773,
  1350000: 779,
  1360000: 785,
  1370000: 790,
  1380000: 796,
  1390000: 802,
  1400000: 808,
  1410000: 813,
  1420000: 819,
  1430000: 825,
  1440000: 831,
  1450000: 837,
  1460000: 842,
  1470000: 848,
  1480000: 854,
  1490000: 860,
  1500000: 865,
};
