import {
  ErrorToast,
  InfoToast,
  SuccessToast,
} from "react-native-toast-message";
import { MAIN_COLOR } from "../Utility/Colors";
import { MAIN_FONT } from "../Utility/Fonts/font";

export const toastConfig = {
  info: (props) => (
    <InfoToast
      {...props}
      style={{ borderLeftWidth: 0 }}
      contentContainerStyle={{
        backgroundColor: "#E9E9EB",
        borderRadius: 6,
      }}
      text1Style={{
        fontSize: 12,
        lineHeight: 22,
        color: MAIN_COLOR.darkGray,
        fontFamily: MAIN_FONT.regular,
      }}
    />
  ),
  success: (props) => (
    <SuccessToast
      {...props}
      style={{
        borderLeftWidth: 0,
        width: 250,
      }}
      contentContainerStyle={{
        borderRadius: 6,
        backgroundColor: MAIN_COLOR.green,
      }}
      text1Style={{
        fontSize: 12,
        lineHeight: 22,
        color: MAIN_COLOR.BACKGROUND_COLOR,
        fontFamily: MAIN_FONT.regular,
      }}
    />
  ),
  error: (props) => (
    <ErrorToast
      {...props}
      style={{
        borderLeftWidth: 0,
        width: 300,
      }}
      contentContainerStyle={{
        borderRadius: 6,
        backgroundColor: "#F76F6F",
      }}
      text1Style={{
        fontSize: 12,
        lineHeight: 22,
        color: MAIN_COLOR.BACKGROUND_COLOR,
        fontFamily: MAIN_FONT.regular,
      }}
    />
  ),
};
