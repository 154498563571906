import { StyleSheet } from "react-native";
import { MAIN_COLOR, TAB_BAR_COLOR } from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    padding: 16,
    borderWidth: 1,
    borderColor: "rgba(173, 185, 199, 0.3)",
    width: 345,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    borderRadius: 15,
    height: 276,
    flexShrink: 1,
    gap: 8,
  },
  flatListContainer: {
    alignItems: "center",
  },
  yearItem: {
    marginHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textYear: {
    fontSize: 10,
    fontWeight: 400,
    color: "#66737F4D",
  },
  selectedYearItem: {
    fontSize: 14,
    fontWeight: 600,
    color: TAB_BAR_COLOR.INACTIVE_COLOR,
    paddingHorizontal: 9,
  },
  wrapTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 8,
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.semi,
  },
  wrapGross: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 6,
    flex: 1,
  },
  grossLabel: {
    fontWeight: 400,
    fontSize: 10,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLOR.G700,
  },
  grossValue: {
    fontSize: 12,
    fontWeight: 600,
    fontFamily: MAIN_FONT.regular,
    color: "#200E32",
  },
  netLabel: {
    color: MAIN_COLOR.BACKGROUND_COLOR,
    fontSize: 10,
    // width: 58,
    fontWeight: 500,
    fontFamily: MAIN_FONT.regular,
  },
  contentNet: {
    display: "flex",
    justifyContent: "space-between",
    gap: 12,
    flex: 1,
  },
  wrapPrameter: {
    justifyContent: "space-between",
    flexDirection: "row",
    gap: 18,
  },
  wrapNet: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: MAIN_COLOR.yellow,
    borderRadius: 5,
    paddingHorizontal: 6,
    paddingVertical: 4,
    gap: 2,
    flex: 1,
  },
  netValue: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLOR.BACKGROUND_COLOR,
  },
  divider: {
    width: 1,
    height: "100%",
    borderRadius: 1,
    backgroundColor: "rgba(173, 185, 199, 0.3)",
    marginHorizontal: 14,
  },
  track: {
    height: 14,
    borderRadius: 2,
    backgroundColor: "white",
    borderColor: "#9a9a9a",
    borderWidth: 1,
  },
  thumb: {
    width: 20,
    height: 20,
    borderRadius: 2,
    backgroundColor: "#eaeaea",
    borderColor: "#9a9a9a",
    borderWidth: 1,
  },
  slider: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
  // siderItem: {
  //   alignItems: "flex-start",
  // },
});
