import React, { useEffect } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  ScrollView,
  View,
  Image,
  StatusBar,
} from 'react-native';
import { connect} from "react-redux"
import style from './style'

class App extends React.Component {

  render() {
  return (
    <View style={{...style.container, backgroundColor: this.props.BACKGROUND_COLOR_REMOVE? "transparent" : this.props.COLOR }}>
      <Image
        style={{...style.logo, tintColor: "#FFFFFF" }}
        resizeMode="contain"
        source={this.props.IMAGE_PATH || ""}
        tintColor="#FFFFFF" 
      />
    </View>
  );
}
}


function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // setUserState: (userState) => dispatch(setUserStateRedux(userState))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);