import { cloneDeep, forEach, omit, sumBy } from "lodash";
import { MARKET_PRICE_REACTIONS } from "../../test/marketPriceReactions";
import { PW_REACTIONS } from "../../test/pwReactions";
import {
  firstSmallNumberFollowedByLarge,
  calcMasterTimeLine,
  updateWiwo,
  findAndCalcValueAndEquityTarget,
} from "./utility-portfolio-roadmap";

const RATE_12 = 0.12;
const RATE_20 = 0.2;
const FEE_RATE_12 = 0.43;
const FEE_RATE_20 = 0.25;
const depositMapping = {
  12: 58500,
  20: 93750,
};
const DepositState = {
  BOTH: "both",
  TWELVE: "12",
  TWENTY: "20",
};
export const INCOME_TYPE = {
  SINGLE: "single",
  COUPLE: "couple",
};
/**
 * Step 2
 *
 * This function calculates the budget options for the buying structure
 * based on the provided values. The function does not take any parameters
 * and does not return any values.
 */
export const budgetOptionsStep = ({
  recommendedBorrowing,
  totalSavingsAndEquity,
  dataChange,
  listPurchase,
  flag,
}) => {
  // Calculate the budget options for 12% rate
  const value12Percent = calcDepositByPercent({
    recommendedBorrowing,
    rate: RATE_12,
    fee_rate: FEE_RATE_12,
    dataChange,
    listPurchase,
  });

  // Calculate the budget options for 20% rate
  const value20Percent = calcDepositByPercent({
    recommendedBorrowing,
    rate: RATE_20,
    fee_rate: FEE_RATE_20,
    dataChange,
    listPurchase,
  });

  // Get total deposit for 12% and 20%
  const value12PercentTotalDeposit = value12Percent.totalDepositBuyingCosts;
  const value20PercentTotalDeposit = value20Percent.totalDepositBuyingCosts;
  const depositState =
    dataChange?.depositSize === "12" &&
    listPurchase.length === dataChange?.indexChange
      ? DepositState?.TWELVE
      : dataChange?.depositSize === "20" &&
        listPurchase.length === dataChange?.indexChange
      ? DepositState?.TWENTY
      : DepositState?.BOTH;
  // Add/Edit depositSize
  if (
    dataChange?.depositSize &&
    listPurchase.length === dataChange?.indexChange
  ) {
    flag = false;
  }

  const tempPercentTotalDeposit = flag
    ? value20PercentTotalDeposit
    : value12PercentTotalDeposit;

  /* 
    1. Check if the total savings and equity is less than the total deposit for 12% and 20%
    2. Check if the total savings and equity is less than the total deposit for 12% 
    => recommended borrowing amount reduces by $10,000 and re-run step 2
  */
  const result =
    handleDepositCheck(
      totalSavingsAndEquity,
      value20PercentTotalDeposit,
      depositState === DepositState.BOTH ||
        depositState === DepositState.TWENTY,
      value20Percent
    ) ||
    handleDepositCheck(
      totalSavingsAndEquity,
      value12PercentTotalDeposit,
      !flag &&
        (depositState === DepositState.BOTH ||
          depositState === DepositState.TWELVE),
      value12Percent
    );

  if (result) return result;
  if (totalSavingsAndEquity < tempPercentTotalDeposit) {
    recommendedBorrowing = Math.max(recommendedBorrowing - 10000, 300000);

    return recommendedBorrowing < 300000 || recommendedBorrowing > 520000
      ? {}
      : budgetOptionsStep({
          recommendedBorrowing,
          totalSavingsAndEquity,
          flag,
        });
  }
  return {};
  // if (
  //   value20PercentTotalDeposit <= totalSavingsAndEquity &&
  //   (depositState === DepositState.BOTH || depositState === DepositState.TWENTY)
  // ) {
  //   return value20Percent;
  // } else if (
  //   value12PercentTotalDeposit <= totalSavingsAndEquity &&
  //   !flag &&
  //   (depositState === DepositState.BOTH || depositState === DepositState.TWELVE)
  // ) {
  //   return value12Percent;
  // } else {
  //   if (totalSavingsAndEquity < tempPercentTotalDeposit) {
  //     recommendedBorrowing = recommendedBorrowing - 10000;
  //     recommendedBorrowing =
  //       recommendedBorrowing <= 300000 ? 300000 : recommendedBorrowing;
  //     if (recommendedBorrowing < 300000 || recommendedBorrowing > 520000) {
  //       return {};
  //     } else {
  //       return budgetOptionsStep({
  //         recommendedBorrowing,
  //         totalSavingsAndEquity,
  //         flag,
  //       });
  //     }
  //   } else {
  //     return {};
  //   }
  // }
};

const handleDepositCheck = (
  totalSavingsAndEquity,
  totalDeposit,
  depositStateCondition,
  result
) => {
  return totalDeposit <= totalSavingsAndEquity && depositStateCondition
    ? result
    : null;
};

export const calcDepositByPercent = ({
  recommendedBorrowing,
  rate,
  fee_rate,
  dataChange,
  listPurchase,
}) => {
  let propertyPrice = recommendedBorrowing * (1 / (1 - rate));
  if (
    dataChange?.propertyPrice &&
    listPurchase.length === dataChange?.indexChange
  ) {
    propertyPrice = dataChange?.propertyPrice;
  }
  const deposit = propertyPrice * rate;
  const buyingCost = deposit * fee_rate;
  const depositBuyingCost = deposit + buyingCost;
  const debtOnProperty = Math.round(propertyPrice - deposit);
  const rentPW =
    dataChange?.targetRent && listPurchase.length === dataChange?.indexChange
      ? dataChange.targetRent
      : PW_REACTIONS[
          firstSmallNumberFollowedByLarge(
            propertyPrice,
            Object.keys(PW_REACTIONS)
          )
        ];
  return {
    propertyPrice,
    rateSelected: rate * 100,
    totalDepositBuyingCosts: depositBuyingCost,
    debtOnProperty,
    rentPW,
    deposit,
  };
};

/**
 * Step 3
 *
 * This function calculates the budget options for the buying structure
 * based on the provided values. The function does not take any parameters
 * and does not return any values.
 */
export const assumptionsAtTargetStep = ({
  propertyPrice,
  livingExpenses,
  salary,
  listPurchase,
  recommendedBorrowing,
  rentPW,
  masterTimeLines,
  paymentPurchasePerMonth,
  years,
  dataChange,
  oldNoOfMonth,
  deposit,
  debtOnProperty,
  income1,
  income2,
}) => {
  // The growth rate of the property value over time.
  const GROWTH_RATE = 0.2;
  const SALARY_RATE = 0.05;
  const EXPENSES_RATE = 0.03;
  let noOfMonth = oldNoOfMonth ? oldNoOfMonth + 1 : 0;
  // The target equity at a given time.
  let equityTarget = propertyPrice * GROWTH_RATE; // Calculates the target equity based on the property price and growth rate.
  // Check recursive with increase month
  // Calculates the target equity at a given time.
  let valueAtTime =
    dataChange?.targetPrice && listPurchase.length === dataChange?.indexChange
      ? dataChange.targetPrice
      : equityTarget + propertyPrice;
  // Calculate the master time line for the current purchase target
  const currentMasterTimeLine = calcMasterTimeLine({
    propertyPrice,
    rentPW,
    paymentPurchasePerMonth,
    debtOnProperty,
    availableEquity: equityTarget,
    months: years * 12,
    deposit,
  });
  if (oldNoOfMonth) {
    const newValueAtTime = currentMasterTimeLine[noOfMonth - 1]?.value;
    const newEquityTarget = newValueAtTime - propertyPrice;
    valueAtTime = newValueAtTime;
    equityTarget = newEquityTarget;
  }

  // An array of keys representing market prices.
  const prices = Object.keys(MARKET_PRICE_REACTIONS);

  /**
   * Finds the first key in the `prices` array that is smaller than
   * `propertyPrice` and followed by a larger number.
   *
   * @returns {string} The key that meets the criteria.
   */
  const key = firstSmallNumberFollowedByLarge(propertyPrice, prices);

  /**
   * An object containing market price reactions.
   * Each key represents a market price and the value is an array
   * of monthly reactions.
   */
  const marketPriceReaction = MARKET_PRICE_REACTIONS[key];

  /**
   * Finds the index of the first element in `marketPriceReaction`
   * that is smaller than `valueAtTime` and followed by a larger number.
   *
   * @returns {number} The index of the element that meets the criteria.
   */

  let salaryAtTime = 0;
  let salaryAtTimeIncome1 = 0;
  let salaryAtTimeIncome2 = 0;

  let expensesPM = 0;

  let rentTracks = [];

  if (listPurchase.length > 1) {
    noOfMonth = oldNoOfMonth
      ? noOfMonth
      : findAndCalcValueAndEquityTarget(
          propertyPrice,
          valueAtTime,
          currentMasterTimeLine
        )?.closestIndex;
    forEach(masterTimeLines, (timeLine, idx) => {
      const preTotalNoOfMonth = sumBy(listPurchase.slice(idx + 1), "noOfMonth");
      const newNoOfMonth = preTotalNoOfMonth + noOfMonth;
      rentTracks.push(
        (timeLine[newNoOfMonth - listPurchase.length - 1 + idx + 1] //Add 1 value because the initial listPurchase initially had 1 purchase
          ?.rentPerMonth *
          12) /
          52
      );
    });
    // Add/Edit Target Rent
    rentTracks.push(
      (currentMasterTimeLine[noOfMonth - 1]?.rentPerMonth * 12) / 52
    );

    masterTimeLines.push(currentMasterTimeLine);
  } else {
    noOfMonth = oldNoOfMonth
      ? noOfMonth
      : findAndCalcValueAndEquityTarget(
          propertyPrice,
          valueAtTime,
          currentMasterTimeLine
        )?.closestIndex;
    masterTimeLines.push(currentMasterTimeLine);

    // Add/Edit Target Rent

    rentTracks.push(
      (currentMasterTimeLine[noOfMonth - 1]?.rentPerMonth * 12) / 52
    );
  }
  // If oldNoOfMonth does not exist, the value of equity and valueAtTime will be reset
  if (!oldNoOfMonth) {
    let { newValueAtTime, newEquityTarget, closestIndex } =
      findAndCalcValueAndEquityTarget(
        propertyPrice,
        valueAtTime,
        currentMasterTimeLine
      );
    equityTarget = newEquityTarget;
    valueAtTime = newValueAtTime;
  }
  const coincideMonth = listPurchase.length >= 2 ? 1 : 0;
  if (
    dataChange?.income1 &&
    listPurchase.length === dataChange?.indexChange &&
    dataChange?.noOfMonth
  ) {
    salary =
      (12 * dataChange.income1) /
      (SALARY_RATE * (dataChange.noOfMonth - coincideMonth) + 12);
  }
  const monthTimeLinePurchase =
    listPurchase.length >= 2 ? noOfMonth - 1 : noOfMonth;
  salaryAtTime = ((salary * SALARY_RATE) / 12) * monthTimeLinePurchase + salary;
  salaryAtTimeIncome1 =
    ((income1 * SALARY_RATE) / 12) * monthTimeLinePurchase + income1;
  salaryAtTimeIncome2 =
    ((income2 * SALARY_RATE) / 12) * monthTimeLinePurchase + income2;

  if (
    dataChange?.livingExpenses &&
    listPurchase.length === dataChange?.indexChange &&
    dataChange?.noOfMonth
  ) {
    const newLivingExpenses =
      (12 * dataChange.livingExpenses) /
      (EXPENSES_RATE * (dataChange.noOfMonth - coincideMonth) + 12); //A =EXPENSES_RATE, B = noOfMonth, C = newLivingExpenses => newLivingExpenses = (C*12)/(A*B) + 12
    expensesPM =
      ((newLivingExpenses * EXPENSES_RATE) / 12) * monthTimeLinePurchase +
      newLivingExpenses;
  } else {
    expensesPM =
      ((livingExpenses * EXPENSES_RATE) / 12) * monthTimeLinePurchase +
      livingExpenses;
  }

  return {
    equityTarget,
    noOfMonth,
    valueAtTime,
    expensesPM,
    rentTracks,
    salaryAtTime,
    salaryAtTimeIncome1,
    salaryAtTimeIncome2,
  };
};

/**
 * Step 4
 *
 * This function calculates the budget options for the buying structure
 * based on the provided values. The function does not take any parameters
 * and does not return any values.
 */
export const findEquityAllowanceStep = async ({
  equityTarget,
  widgetProperties,
  expensesPM,
  salaryAtTime,
  rentTracks,
  recommendedBorrowing,
  wiwo,
  propertyPrice,
  rentPW,
  paymentPurchasePerMonth,
  masterTimeLines,
  listPurchase,
  customIncomes,
  years,
  newData,
  debtOnProperty,
  totalDepositBuyingCosts,
  totalSavingsAndEquity,
  deposit,
  salaryAtTimeIncome1,
  salaryAtTimeIncome2,
  incomeType,
}) => {
  let minDebtOnProperty = 0;
  let minTotalDepositBuyingCosts = 0;
  if (newData?.propertyPrice && listPurchase.length === newData?.indexChange) {
    const {
      propertyPrice: newPropertyPrice,
      rateSelected,
      totalDepositBuyingCosts: newTotalDepositBuyingCosts,
      debtOnProperty: newDebtOnProperty,
      rentPW,
      deposit,
    } = calcDepositByPercent({
      recommendedBorrowing,
      rate: RATE_20,
      fee_rate: FEE_RATE_20,
      dataChange: newData,
      listPurchase,
    });
    minDebtOnProperty = newDebtOnProperty;
    minTotalDepositBuyingCosts = newTotalDepositBuyingCosts;
  }
  const originalData = cloneDeep(widgetProperties);

  let availableEquity = equityTarget;
  let yourBorrowingAmount = 0;

  const wiwoBorrowingPower = originalData?.dataList.find(
    (item) => item.id === "wiwo-borrowing-power"
  );

  const wiwoRepaymentWidget = originalData?.dataList.find(
    (item) => item.id === "wiwo-repayment-widget"
  );

  wiwoRepaymentWidget.input.repaymentModel.principal = 999999999;
  if (listPurchase.length <= 1) {
    if (
      wiwoBorrowingPower.input.borrowingRequestModel.loanLiabilityGroups[0]
        .liabilities[0].remainingLimit !== debtOnProperty
    ) {
      wiwoBorrowingPower.input.borrowingRequestModel.loanLiabilityGroups[0].liabilities[0].remainingLimit =
        debtOnProperty;
    }
  }
  if (newData?.interestRate && listPurchase.length >= newData?.indexChange) {
    wiwoRepaymentWidget.input.repaymentModel.interestRate =
      newData?.interestRate;
  }
  const newLiabilities = [
    ...wiwoBorrowingPower.input.borrowingRequestModel.loanLiabilityGroups[0]
      .liabilities,
    {},
  ];

  let incomes =
    wiwoBorrowingPower.input.borrowingRequestModel.applicantList.map(
      (one) => one.incomeList
    );

  const primaryIncomes = incomes.map((incomeList) =>
    incomeList.find((income) => income.id === "primary")
  );
  if (incomeType === INCOME_TYPE.COUPLE) {
    const income1Widget = primaryIncomes[0].income;
    const income2Widget = primaryIncomes[1].income;

    const increase1Value = salaryAtTimeIncome1 - income1Widget;
    const increase2Value = salaryAtTimeIncome2 - income2Widget;
    wiwoBorrowingPower.input.borrowingRequestModel.applicantList = incomes.map(
      (applicantIncomeList, index) => ({
        incomeList: applicantIncomeList.map((income) => {
          if (income.id === "primary") {
            const increaseValue = index === 0 ? increase1Value : increase2Value;
            return {
              ...income,
              income: Math.round(income.income + increaseValue),
            };
          }
          return income;
        }),
      })
    );
  } else {
    const totalPrimaryIncome = sumBy(primaryIncomes, "income");

    const increaseValue =
      (salaryAtTime - totalPrimaryIncome) / primaryIncomes.length;

    // Update primary income to the widget again
    wiwoBorrowingPower.input.borrowingRequestModel.applicantList = incomes.map(
      (applicantIncomeList) => ({
        incomeList: applicantIncomeList.map((income) => {
          if (income.id === "primary") {
            return {
              ...income,
              income: Math.round(income.income + increaseValue),
            };
          }
          return income;
        }),
      })
    );
  }

  incomes = wiwoBorrowingPower.input.borrowingRequestModel.applicantList.map(
    (one) => one.incomeList
  );
  // Update expenses p/m to the widget again
  wiwoBorrowingPower.input.borrowingRequestModel.households[0].livingExpenseModel.livingExpenseMonthly =
    Math.round(expensesPM);

  let newIncomes = incomes.map((incomeList, index) => {
    return [
      ...incomeList.filter((one) => one.id !== "other"),
      ...customIncomes[index],
    ];
  });

  const householdIncomes = newIncomes.map((incomeList) =>
    sumBy(incomeList, "income")
  );
  const incomeObj = {
    income1: householdIncomes[0],
  };
  if (householdIncomes.length > 1) {
    incomeObj.income2 = householdIncomes[1];
  }
  // Edit
  // Determine which income list to update based on the income1 and income2 values.
  rentTracks.forEach((rentTrackIncome) => {
    const incomeIndex = incomeObj.income1 > incomeObj.income2 ? 1 : 0;
    const incomeList = newIncomes[incomeIndex];
    const updatedIncomeList = [
      ...incomeList,
      {
        id: "other",
        income: Math.round(rentTrackIncome),
        incomeType: "gross",
        incomeFrequency: "week",
        incomeCategory: "other",
      },
    ];
    newIncomes[incomeIndex] = updatedIncomeList;
    const otherIncomeArr = newIncomes[incomeIndex].filter(
      (one) => one.id === "other"
    );
    const sumOtherIncome = sumBy(otherIncomeArr, "income");

    const updatedIncome = sumBy(
      newIncomes[incomeIndex].filter((one) => one.id === "primary"),
      "income"
    );
    incomeObj[`income${incomeIndex + 1}`] = updatedIncome + sumOtherIncome * 52;
  });

  wiwoBorrowingPower.input.borrowingRequestModel.applicantList = newIncomes.map(
    (one) => ({
      incomeList: one,
    })
  );
  // Update secured loan details to the widget again
  for (let index = 9; index >= 0; index -= 0.5) {
    if (index === 2)
      return { availableEquity: null, newWidgetProperty: originalData };

    const newInvestLoan = {
      loanType: "investLoan",
      remainingLimit:
        listPurchase.length >= 2
          ? Math.round(availableEquity + debtOnProperty)
          : Math.round(availableEquity),
      remainingTermMonths: 360,
      hasIo: true,
      ioTermMonths: 60,
      toBePaidOut: false,
      liabilityTypeLabel: "Investment loan",
    };

    newLiabilities[newLiabilities.length - 1] = newInvestLoan;

    wiwoBorrowingPower.input.borrowingRequestModel.loanLiabilityGroups[0] = {
      id: "homeLoan",
      enabled: true,
      liabilities: newLiabilities,
    };

    wiwoBorrowingPower.input = omit(wiwoBorrowingPower.input, ["bpResult"]);
    // Clear output
    wiwoBorrowingPower.output = null;
    wiwoRepaymentWidget.output = null;
    const result = await updateWiwo(originalData, wiwo);

    yourBorrowingAmount =
      result.dataList[0].input.bpResult.borrowingPowerMaximum;
    const cashContribution =
      newData?.cashContribution && listPurchase.length === newData?.indexChange
        ? newData?.cashContribution
        : totalSavingsAndEquity - totalDepositBuyingCosts;
    const cashEquityNextPurchase = availableEquity + cashContribution;
    const minYourBorrowingAmount =
      newData?.propertyPrice && listPurchase.length === newData?.indexChange
        ? minDebtOnProperty
        : 300000;
    let minCashEquityNextPurchase =
      newData?.depositSize && listPurchase.length === newData?.indexChange
        ? depositMapping[Number(newData?.depositSize)]
        : 93750;
    if (
      newData?.propertyPrice &&
      listPurchase.length === newData?.indexChange
    ) {
      minCashEquityNextPurchase = minTotalDepositBuyingCosts;
    }
    if (
      yourBorrowingAmount >= minYourBorrowingAmount &&
      cashEquityNextPurchase > minCashEquityNextPurchase
    ) {
      const paymentPurchasePerMonth =
        result.dataList[1].output.repaymentResultModel.repaymentList[0]
          .paymentDisplayRounded;
      const currentMasterTimeLine = calcMasterTimeLine({
        propertyPrice,
        rentPW,
        paymentPurchasePerMonth,
        debtOnProperty,
        availableEquity,
        months: years * 12,
        deposit,
      });
      masterTimeLines[masterTimeLines.length - 1] = currentMasterTimeLine;
      return {
        availableEquity,
        yourBorrowingAmount: minDebtOnProperty
          ? minDebtOnProperty
          : yourBorrowingAmount,
        paymentDisplayRounded: paymentPurchasePerMonth,
        newWidgetProperty: result,
        totalIncome:
          result.dataList[0].input.bpResult.annualIncomeForSensitisedLookup,
        cashEquityNextPurchase,
        cashContribution,
      };
    }
    availableEquity = equityTarget * index * 0.1;
  }

  return {
    availableEquity,
    yourBorrowingAmount,
    newWidgetProperty: originalData,
  };
};
