import { BlurView } from "expo-blur";
import React from "react";
import {
  Image,
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
  View,
} from "react-native";
import { MAIN_COLOR, TAB_BAR_COLOR } from "../Utility/Colors";
import { MAIN_FONT } from "../Utility/Fonts/font";

const ModalBase = ({
  open,
  title,
  children,
  btnSave,
  btnCancel,
  disableSave,
  styleBtnClose,
  onSave,
  onClose,
  size = "medium",
}) => {
  const { height } = Dimensions.get("window");
  return (
    <Modal
      animationType="fade"
      transparent
      visible={open}
      presentationStyle="formSheet"
    >
      <BlurView
        tint="dark"
        intensity={12}
        style={{ position: "absolute", height: "100%", width: "100%" }}
      >
        <View
          style={{
            background: "rgba(0, 0, 0, 0.58)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <View
            style={{
              backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
              borderRadius: 15,
              paddingHorizontal: 18,
              paddingVertical: 20,
              width: size === "small" ? 525 : size === "medium" ? 654 : 800,
              maxHeight: "95vh",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                position: "relative",
                minHeight: 32,
                marginBottom: 22,
              }}
            >
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 24,
                  fontFamily: MAIN_FONT.regular,
                  color: MAIN_COLOR.G800,
                  flex: 1,
                }}
              >
                {title}
              </Text>
              <TouchableOpacity
                style={[
                  {
                    height: 32,
                    width: 32,
                    borderRadius: 5,
                    backgroundColor: MAIN_COLOR.yellow,
                  },
                  styleBtnClose,
                ]}
                onPress={onClose}
              >
                <Image
                  resizeMode="contain"
                  style={{ width: 20, height: 20, margin: "auto" }}
                  source={require("../Image/button/close_black/close@3x.png")}
                />
              </TouchableOpacity>
            </View>
            {children}
            <View style={styles.btnWrap}>
              <TouchableOpacity
                style={[styles.btn, styles.btnCancel]}
                onPress={onClose}
              >
                <Text style={styles.btnText}>{btnCancel}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                disabled={disableSave}
                style={[
                  styles.btn,
                  styles.btnSave,
                  disableSave && { backgroundColor: MAIN_COLOR.BORDER_COLOR },
                ]}
                onPress={onSave}
              >
                <Text style={styles.btnText}>{btnSave}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </BlurView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  btn: {
    width: 170,
    height: 41,
    paddingHorizontal: 24,
    paddingVertical: 10,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  btnWrap: {
    display: "flex",
    flexDirection: "row",
    marginTop: 22,
    alignItems: "center",
    justifyContent: "center",
    gap: 22,
  },
  btnCancel: {
    borderColor: "#ADB9C7",
    borderWidth: 1.5,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
  },
  btnSave: {
    backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
  },
  btnText: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: MAIN_FONT.regular,
    color: TAB_BAR_COLOR.INACTIVE_COLOR,
  },
});
export default ModalBase;
