import { StyleSheet } from "react-native";
import { MAIN_COLOR, TAB_BAR_COLOR } from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";
var styles;

export default styles = StyleSheet.create({
  container: {
    width: 280,
  },
  header: {
    backgroundColor: MAIN_COLOR.yellow,
    height: 58,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 18,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    margin: "auto",
    fontFamily: MAIN_FONT.regular,
    paddingBottom: 12,
  },
  type: {
    position: "absolute",
    paddingHorizontal: 32,
    paddingVertical: 6,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    borderRadius: 5,
    alignSelf: "center",
    bottom: "-30%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 9,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 4,
    shadowOpacity: 1.0,
    minWidth: 137,
    minHeight: 34,
  },
  titleType: {
    fontSize: 14,
    fontWeight: 500,
    color: "#292929",
    fontFamily: MAIN_FONT.regular,
    fontWeight: "bold",
  },
  iconType: {
    width: 18,
    height: 18,
  },
  info: {
    marginHorizontal: 13,
    marginBottom: 20,
    marginTop: 29,
    display: "flex",
    flexDirection: "column",
    gap: 8,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    zIndex: -1,
    overflow: "hidden",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.regular,
  },
  rowValue: {
    fontSize: 14,
    fontWeight: 600,
    color: TAB_BAR_COLOR.INACTIVE_COLOR,
    fontFamily: MAIN_FONT.semi,
  },
  divider: {
    height: 1,
    width: "100%",
    backgroundColor: "rgba(173, 185, 199, 0.3)",
    zIndex: -1,
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
  },
  timeLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: "#303A43",
    fontFamily: MAIN_FONT.regular,
  },
  time: {
    fontSize: 12,
    fontWeight: 400,
    color: MAIN_COLOR.G700,
    fontFamily: MAIN_FONT.regular,
  },
  btnEdit: {
    width: 40,
    height: 40,
    borderColor: "rgba(173, 185, 199, 0.3)",
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
  },
  btnView: {
    width: 24,
    height: 24,
    borderRadius: 5,
    borderColor: "rgba(173, 185, 199, 0.3)",
    top: -12,
    margin: "auto",
    left: 0,
    right: 0,
    position: "absolute",
    borderWidth: 1,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
  },
  tippyContainer: {
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    borderRadius: 5,
    padding: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 4,
    display: "flex",
    gap: 16,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.05)",
    borderStyle: "solid",
  },

  tippyItem: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  tippyDriver: {
    width: "100%",
    height: 1,
    backgroundColor: "#ADB9C74D",
  },
  tippyTitle: {
    fontSize: 12,
    fontWeight: 400,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.regular,
  },
});
