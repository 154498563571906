import React from 'react';
import moment from 'moment';
import {
  Dimensions,
  Keyboard,
  Linking,
  Platform,
  RefreshControl,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { NavigationEvents } from 'react-navigation';
import '@firebase/firestore';
import firebase from 'firebase';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setColors } from "../../Action/color";
import { user as setUser } from '../../Reducer/user';
import { setNotifications, setNotificationsObject } from '../../Reducer/tia';
import { MAIN_COLOR } from "../../Utility/Colors";
import LOADER from "../../Utility/ComponentCard/Loader";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import tasks, { getDateTime, getTime, titles } from '../../Zoho/Tasks';
import { Notifications } from '../../Zoho/Zoho';
import styles from "./style";
import { NOTIFICATIONS } from '../../Utility/ReduxType';
import fields from '../../Utility/Fields';
const { width } = Dimensions.get("window");

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      today: new Date(),
      week: [],
      notifications: [],
      raw: [],
      tasks: [],
      email: null,
      refreshing: false
    };
  }


  componentWillUnmount() {
    // Linking.removeEventListener();
  }

  componentDidUpdate() {
    // console.log(JSON.stringify(this.props));
  }

  goToTaskDetails(statusName, id, time, status) {

    // this.props.navigation.navigate("TaskDetails", {task: tasks[item.App_Task_No], status: "Complete"})

    if (statusName == 'MARKET UPDATES') {
      this.props.navigation.navigate("Portfolio");
      return null
    }

    let deal = this.props.TIA.opportunities.find((item, index) => item.id == id);

    if (deal) {
      let taskArr = tasks(deal);

      if (statusName === 'PROPERTY WALK-THROUGH') {
        this.props.navigation.navigate("PropertyDetails", { id: deal.id, video: taskArr[taskNo].videos })
      }
      else {

        if (status == "Complete") {

          let progressTime = null;

          if (statusName !== 'PROFILE REVIEW') {
            // this.state.notifications.map((item, index) => {

            //   if (item.Parent_Id.id == id && item.App_Task_No == taskNo - 1) {

            //     progressTime = getDateTime(item.App_Date_Time, 5);
            //   }
            //   else {
            //     console.log(item.Parent_Id.id, " ", item.App_Task_No, " ", taskNo);
            //   }
            // });

            // this.props.TIA.template.map()
          }



          this.props.navigation.navigate("TaskDetails", { task: taskArr[taskNo], status: status, time: time, progressTime: progressTime })
        }

        else {

          // console.log("status::", status, "\t progressTime::", time)

          this.props.navigation.navigate("TaskDetails", { task: taskArr[taskNo], status: status, time: null, progressTime: time })
        }
      }
    }
  }



  async refresh() {
    this.setState({ refreshing: true });
    var db = firebase.firestore();
    let allLeadsId = 'd22875df-8bfe-430f-8ab9-c33736223f47';
    let leads = this.props.TIA.client.custom_fields.filter((item, index) => item.id === allLeadsId);
    let leadsIds = {};

    if (leads[0].value) {
      leads[0].value.map((item, index) => {
        // leadsIds.push(db.doc(collectionName+item.id));
        leadsIds[item.id] = true;
      })
    }

    db.collection('notifications').get().then((querySnapshot) => {

      let arr = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        if (leadsIds[doc.id]) {
          this.props.setNotificationsObject({ id: doc.id, data: doc.data() });
          arr = arr.concat(Object.values(doc.data()));
          // console.log(arr)
        }
      });

      arr.sort(function (a, b) { return new Date(b.dateUpdated.seconds * 1000) - new Date(a.dateUpdated.seconds * 1000) });

      // console.log('::notificationsObject\t',this.props.TIA.notificationObjects);

      this.setState({ refreshing: false });

      this.props.setNotifications(arr);

    }).catch(error => {
      alert(error.message);
      this.setState({ refreshing: false });
    });


  }

  componentDidMount() {
    this.refresh();
  }

  // goToProjectDetails(id) {
  //   this.props.TIA.opportunities.map(item => {
  //     if (item.id == id) {
  //       this.props.navigation.navigate("ProjectDetails", { item: item });
  //     }
  //   })
  // }

  getStatusName(statusName, flag) {



    let keys = Object.keys(this.props.TIA.template);

    let index = keys.indexOf(statusName);



    if (index > 0) {
      if (flag) {
        return this.props.TIA.template[keys[index]][fields.appStageName]
      }

      return this.props.TIA.template[keys[index - 1]][fields.appStageName]
    }
    else {
      console.log('\n\n\n', JSON.stringify(Object.keys(this.props.TIA.template)))
      console.log(statusName, ' ', this.props.TIA.template[keys[index]][fields.appStageName], ' ', index);

    }


  }


  render() {

    const { notifications, email, refreshing, raw } = this.state;

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: MAIN_COLOR.BACKGROUND }}
        keyboardShouldPersistTaps={true}
        behavior={Platform.OS === "ios" ? "padding" : "padding"}
        keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}
      >
        <NavigationEvents
          onWillFocus={this._onFocus}
          onWillBlur={this._onBlurr}
        />


        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => this.refresh(email)}
            />
          }
          onPress={Keyboard.dismiss}
          accessible={false} >
          <View style={styles.scrollViewContentView}>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingHorizontal: 25, marginVertical: 25, }}>
              <Text style={{ fontSize: 16, color: MAIN_COLOR.G800, fontFamily: MAIN_FONT.semi, textAlign: 'left' }}>
                Recent
              </Text>
            </View>

            <View style={styles.centerView}>


              {
                this.props.TIA.notifications.length ? this.props.TIA.notifications.map((item, index) => {
                  var msDiff = new Date() - new Date(item.dateUpdated.seconds * 1000);//Future date - current date
                  var diff = Math.floor(msDiff / (1000 * 60));
                  var minutes = diff;



                  // console.log("minutes:", minutes, "\ttask#:", item);

                  return (
                    <>
                      {
                        (
                          // minutes >= 2 && 
                          item.statusName !== "MARKET UPDATES") &&
                        <TouchableOpacity
                          onPress={() => this.props.goToProjectDetails(item.taskId, item.statusName, index)}
                          activeOpacity={0.8} style={{ backgroundColor: '#FFFFFF', borderRadius: 20, padding: 25, marginVertical: 5, }}>

                          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                            <Text style={{ fontSize: 17, color: MAIN_COLOR.G800, fontFamily: MAIN_FONT.semi, marginBottom: 5 }}>
                              {this.getStatusName(item.statusName, true)}
                            </Text>
                            <Text style={{ fontSize: 14, color: MAIN_COLOR.G800, fontFamily: MAIN_FONT.regular, marginTop: 10 }}>
                              {getDateTime(item.dateUpdated.seconds * 1000, 0)}
                            </Text>

                          </View>
                          <Text style={{ fontSize: 14, color: MAIN_COLOR.G700, fontFamily: MAIN_FONT.regular, marginBottom: 5 }}>
                            {item.taskName}
                          </Text>


                        </TouchableOpacity>
                      }

                      <TouchableOpacity
                        onPress={() => this.props.goToProjectDetails(item.taskId, item.statusName, index)}
                        activeOpacity={0.8} style={{ backgroundColor: '#FFFFFF', borderRadius: 20, padding: 25, marginVertical: 5, }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                          <Text style={{ fontSize: 17, color: MAIN_COLOR.G800, fontFamily: MAIN_FONT.semi, marginBottom: 5 }}>
                            {this.getStatusName(item.statusName)}
                          </Text>
                          <Text style={{ fontSize: 14, color: MAIN_COLOR.G800, fontFamily: MAIN_FONT.regular, marginTop: 10 }}>
                            {getTime(item.dateUpdated.seconds * 1000)}  -  {moment(item.dateUpdated.seconds * 1000).format('DD/MM/YYYY')}
                          </Text>

                        </View>
                        <Text style={{ fontSize: 14, color: MAIN_COLOR.G700, fontFamily: MAIN_FONT.regular, marginBottom: 5 }}>
                          {item.taskName}
                        </Text>


                      </TouchableOpacity>
                    </>

                  )



                })
                  :

                  <Text style={{ fontSize: 14, color: MAIN_COLOR.G700, fontFamily: MAIN_FONT.regular, marginBottom: 5, textAlign: 'center', alignSelf: 'center' }}>
                    There is no notification yet.
                  </Text>
              }



            </View>
          </View>
        </ScrollView>
        {this.state.isLoading ? <LOADER /> : null}
      </SafeAreaView>
    );
  }
};


function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART,
    TIA: state.TIA
  }
}


function mapDispatchToProps(dispatch) {


  return bindActionCreators(
    {
      setNotifications,
      setNotificationsObject,
      setUser
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);