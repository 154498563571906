import React from "react";
import { Image, Text, View } from "react-native";
import styles from "./style";

const AccessDenied = () => {
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        resizeMode="contain"
        source={require("../../../../assets/content.png")}
      />
      <Text style={styles.subTitle}>
        You do not have permission to view this Scenario
      </Text>
    </View>
  );
};

export default AccessDenied;
