import moment from "moment";
import React from "react";
import {
  Dimensions,
  Linking,
  Platform,
  Image,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { bindActionCreators } from "redux";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { NavigationEvents } from "react-navigation";
import { connect } from "react-redux";
import { setEquity } from "../../Reducer/tia";
import { setColors } from "../../Action/color";
import { getProperty, removeProperty, removeCustomFeildValue, getDetailClient } from "../../Clickup/api";
import { user as setUser } from "../../Reducer/user";
import { MAIN_COLOR } from "../../Utility/Colors";
import LOADER from "../../Utility/ComponentCard/Loader";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import { format, getTime } from "../../Zoho/Tasks";
import styles from "./style";
import PortfolioItem from "./PortfolioItem";
import Button from "../../components/Button";
import SearchProperty from "./SearchProperty";
import Toast from "react-native-toast-message";
import { toastConfig } from "../../configs/toastConfig";
import Modal from "../../components/Modal";
import { setClient } from "../../Reducer/tia"
import lodash from "lodash"

const { width } = Dimensions.get("window");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      equity: 0,
      total: 0,
      refreshing: false,
      isLoading: false,
      currentComponent: "index",
      listNonTIAProperties: [],
      visibleRemoveProperty:false,
      propertyRemove:{},
    };
  }

  componentWillUnmount() {
    // Linking.removeEventListener();
  }

  componentDidUpdate(prevProps) {
    const {value: currentValue} = this.props.TIA.client.custom_fields.find(item => item.name === "Properties")
    const {value: oldValue} = prevProps.TIA.client.custom_fields.find(item => item.name === "Properties")
    if(!lodash.isEqual(currentValue, oldValue)){
      this.getListPropertyNonTIA(this.props.TIA.client)
    }
  }

  async refresh() {
    // let total = 0,
    //   equity = 0;
    // let arr = [];
    
    // this.props.TIA.opportunities.map((item, index) => {      
    //   if (item.status.status.toUpperCase() !== "MARKET UPDATES") {
    //     return -1;
    //   }

    //   if (!item["Proposed Property"]) {
    //     // alert(index)
    //     // alert(item['Proposed Property']);
    //     return -1;
    //   }
    //   this.setState({ isLoading: true });
    //   getProperty(item["Proposed Property"][0].id)
    //     .then((result) => {
    //       if (!result.err) {
    //         var object = result.custom_fields.reduce(
    //           (obj, item) => Object.assign(obj, { [item.name]: item.value }),
    //           {}
    //         );

    //         object['Current Potential Value'] = Number(object["Purchase Price"]) * (Number(object["Growth (%)"]) / 100 + 1);
    //         object['Target (20%)'] = Number(object["Purchase Price"]) * 0.2;
            
    //         if (object["Target (20%)"] && object["Purchase Price"]) {
    //           total += object["Target (20%)"];
    //           equity +=
    //             object["Current Potential Value"] - object["Purchase Price"];
    //           // alert(object['Current Potential Value'] + "-" +  object['Purchase Price'] + " = " + (object['Current Potential Value'] - object['Purchase Price']))
    //         } else {
    //           // alert(object['Target (20%)'] + "&&" + item['Purchase Price'])
    //         }

    //         arr.push({
    //           purchase_by: object["Purchased By"],
    //           image: object["Comparable Property Image"],
    //           purchase_date: object["Comparable Property Purchase Date"],
    //           sold_price: object["Comparable Property Purchase Price"],
    //           url: object["Comparable Property URL"],
    //           beds: object["Comparable Property Bed"],
    //           cars: object["Comparable Property Car"],
    //           baths: object["Comparable Property Bath"],
    //           area: object["Comparable Property Land Size"],
    //           price: object["Purchase Price"],
    //           growth: object["Growth (%)"],
    //           current: object["Current Potential Value"],
    //           date_updated: result["date_updated"],
    //           Suburb:
    //             object["PROPERTY STREET ADDRESS"] +
    //             ", " +
    //             object["PROPERTY SUBURB"],
    //           id: result.id,
    //         });

    //         this.props.setEquity(equity);

    //         this.setState({ properties: arr, total, equity, isLoading: false });
    //       }
    //     })
    //     .catch(() => {
    //       this.setState({ isLoading: false });
    //     });
    // });
  }

  async fetchPropertyData(id) {
    try {
      const propertyData = await getProperty(id);
      if(propertyData?.err) return null
      return {
        ...propertyData?.custom_fields?.reduce(
          (fields, field) => ({
            ...fields,
            [field.name]: field.value,
          }),
          {}
        ),
        id: propertyData.id,
        date_updated: propertyData.date_updated,
      };
    } catch (error) {
      return null;
    }
  }

  async getListPropertyNonTIA(_client) {
    const { client } = this.props.TIA;
    const data = _client || client
    const propertyIds = data.custom_fields
      .find((one) => one.name === "Properties")
      ?.value?.map((item) => item.id);

      const opportunityIds = this.props.TIA.opportunities?.filter(opportunity => {
        const { status, "Proposed Property": proposedProperty } = opportunity
        return (
          status?.status?.toUpperCase() === "MARKET UPDATES" &&
          !!proposedProperty?.[0]?.id
        )
      }).map((item) => item["Proposed Property"][0].id);
    
      const listPropertyIds = [
        ...(propertyIds || []),
        ...(opportunityIds || [])
      ]

    if (!listPropertyIds.length) return;
    const propertyDataPromises = listPropertyIds.map(this.fetchPropertyData);
    this.setState({isLoading:true})
    await Promise.all(propertyDataPromises).then((propertyData) => {
      let listNonTIAProperty = []
      let listTIAProperty = []
      propertyData.forEach(item => {
        if(item){
          item["Purchased By"] ? listNonTIAProperty.push(item) : listTIAProperty.push(item)
        }
      })

      this.setState({
        listNonTIAProperties: listNonTIAProperty.map((object) => ({
          purchase_by: object["Purchased By"],
          image: object["Comparable Property Image"],
          purchase_date: object["Comparable Property Purchase Date"],
          sold_price: object["Comparable Property Purchase Price"],
          url: object["Comparable Property URL"],
          beds: object["Comparable Property Bed"],
          cars: object["Comparable Property Car"],
          baths: object["Comparable Property Bath"],
          area: object["Comparable Property Land Size"],
          price: object["Purchase Price"],
          growth: object["Growth (%)"],
          current: object["Current Potential Value"],
          date_updated: object["date_updated"],
          Suburb:
            object["PROPERTY STREET ADDRESS"] +
            ", " +
            object["PROPERTY SUBURB"],
          id: object.id,
        })),
      });

      this.setState({
        properties: listTIAProperty.map((object) => ({
          purchase_by: object["Purchased By"],
          image: object["Comparable Property Image"],
          purchase_date: object["Comparable Property Purchase Date"],
          sold_price: object["Comparable Property Purchase Price"],
          url: object["Comparable Property URL"],
          beds: object["Comparable Property Bed"],
          cars: object["Comparable Property Car"],
          baths: object["Comparable Property Bath"],
          area: object["Comparable Property Land Size"],
          price: object["Purchase Price"],
          growth: object["Growth (%)"],
          current: object["Current Potential Value"],
          date_updated: object["date_updated"],
          Suburb:
            object["PROPERTY STREET ADDRESS"] +
            ", " +
            object["PROPERTY SUBURB"],
          id: object.id,
        })),
      });
    });
    this.setState({isLoading:false})
  }

  async getInfoClient(){
      const _client = await getDetailClient(this.props.TIA.client.id)
      this.props.setClient({..._client, email:this.props.TIA.client.email})
      this.getListPropertyNonTIA(_client)
  }

  setLoading = (value) => {
    this.setState({isLoading:value})
  }

  // async refresh() {
  //   let total = 0, equity = 0;
  //   let arr = [];

  //   this.props.TIA.opportunities.map((item, index) => {
  //     if (item.status.status.toUpperCase() !== "MARKET UPDATES") {
  //       return -1
  //     }
  //     if (!item['Proposed Property']) {
  //       // alert(index)
  //       // alert(item['Proposed Property']);
  //       return -1
  //     }
  //     getProperty(item['Proposed Property'][0].id).then((result) => {
  //       if (!result.err) {
  //         var object = result.custom_fields.reduce(
  //           (obj, item) => Object.assign(obj, { [item.name]: item.value }), {});
  //         if (object['Target (20%)'] && object['Purchase Price']) {
  //           total += object['Target (20%)'];
  //           equity += object['Current Potential Value'] - object['Purchase Price'];
  //         }

  //         arr.push({"state": object['PROPERTY STATE'], "price": object['Purchase Price'], "address": object['PROPERTY STREET ADDRESS'], "growth": object['Growth (%)'], "current": object['Current Potential Value'], "date_updated": result['date_updated'], Suburb: object['PROPERTY SUBURB'] })

  //         this.props.setEquity(equity);

  //         this.setState({ properties: arr, item: arr[0], index: 0, total, equity });
  //       }
  //     })
  //   });
  // }

  componentDidMount() {
    this.refresh();
    this.getInfoClient()
  }

  render() {
    const {
      properties,
      equity,
      total,
      refreshing,
      item: itemSelected,
      index,
      isLoading,
      listNonTIAProperties,
      visibleRemoveProperty,
      propertyRemove,
    } = this.state;

    
    const handleRemoveProperty = async() => {
      try {
        const { client } = this.props.TIA

        this.setState({isLoading:true})
        this.setState({visibleRemoveProperty:false})
        const { id: field_id, value } = client.custom_fields.find(item => item.name === "Properties")
        const { team_id } = value.find(item => item.id === propertyRemove.id)

        const result = await removeCustomFeildValue(propertyRemove.id, field_id, team_id)
        await removeProperty(propertyRemove.id, team_id)
        if(result?.err){
          Toast.show({
            type: "error",
            text1: "Delete failed!",
            position: "top",
            visibilityTime: 3000,
          });
        }else{
          const _client = {
            ...client,
            ["custom_fields"]:lodash.map(client["custom_fields"], field => {
              if(field.name === "Properties"){
                return {
                  ...field,
                ["value"]:lodash.filter(field["value"], item => item.id !== propertyRemove.id)
                }
              }
              return field
            })
          }
          this.props.setClient({..._client, email:this.props.TIA.client.email});
          await this.getListPropertyNonTIA(_client)
          Toast.show({
            type: "success",
            text1: "Delete successfully!",
            position: "top",
            visibilityTime: 3000,
          });
        }
      }finally{
        this.setState({isLoading:false})
      }
    }

    return (
      <SafeAreaView
        style={{ flex: 1, backgroundColor: MAIN_COLOR.BACKGROUND }}
        keyboardShouldPersistTaps={true}
        behavior={Platform.OS === "ios" ? "padding" : "padding"}
        keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}
      >
        <NavigationEvents
          onWillFocus={this._onFocus}
          onWillBlur={this._onBlurr}
        />
        <View
          style={{
            position: "fixed",
            right: 120,
            top: 0,
            width: 150,
            zIndex: 9999,
          }}
        >
          <Toast config={toastConfig} />
        </View>
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => this.refresh()}
            />
          }
        >
          {this.state.currentComponent === "index" && (
            <View style={styles.scrollViewContentView}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingHorizontal: 25,
                  marginVertical: 25,
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: MAIN_COLOR.G800,
                    fontFamily: MAIN_FONT.semi,
                    textAlign: "left",
                  }}
                >
                  Purchases
                </Text>
                <Button
                  leftIcon={require("../../Image/button/plus_icon/icons8-plus-64@2x.png")}
                  text="Add property"
                  style={{ borderColor: MAIN_COLOR.yellowBackground }}
                  onPress={() =>
                    this.setState({ currentComponent: "SearchProperty" })
                  }
                />
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={[styles.centerView, { width: "40%" }]}>
                  {properties.map((item, index) => {
                    return (
                      <TouchableOpacity
                        onPress={() => this.setState({ item, index })}
                        activeOpacity={0.8}
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderWidth: itemSelected?.id === item.id ? 1 : 0,
                          borderColor: MAIN_COLOR.PRIMARY_COLOR,
                          borderRadius: 20,
                          padding: 25,
                          marginBottom: 10,
                        }}
                      >
                        {/* <Text>{JSON.stringify(item)}</Text> */}

                        <Text
                          style={{
                            fontSize: 16,
                            color: MAIN_COLOR.G800,
                            fontFamily: MAIN_FONT.semi,
                            fontWeight: "500",
                            marginBottom: 5,
                          }}
                        >
                          {item.Suburb ? item.Suburb : "Property Address"},{" "}
                          {item.state}
                        </Text>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 13,
                              color: MAIN_COLOR.G700,
                              fontFamily: MAIN_FONT.regular,
                              marginBottom: 5,
                            }}
                          >
                            Last Update:
                          </Text>

                          <Text
                            style={{
                              fontSize: 14,
                              color: MAIN_COLOR.G800,
                              fontFamily: MAIN_FONT.regular,
                              textAlign: "left",
                            }}
                          >
                            {getTime(item.date_updated * 1)} -{" "}
                            {moment(item.date_updated * 1).format("DD/MM/YYYY")}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    );
                  })}
                  {listNonTIAProperties.length > 0 && (
                    <>
                      <View
                        style={{
                          height: 1,
                          backgroundColor: "#E7ECF2",
                          width: "100%",
                          marginVertical: 5,
                        }}
                      />
                      <Text
                        style={{
                          fontSize: 16,
                          color: MAIN_COLOR.G800,
                          fontFamily: MAIN_FONT.semi,
                          textAlign: "left",
                          marginBottom: 10,
                        }}
                      >
                        Imported Properties
                      </Text>
                      {listNonTIAProperties.map((item, index) => {
                        return (
                          <TouchableOpacity
                            onPress={() => this.setState({ item, index })}
                            activeOpacity={0.8}
                            style={{
                              backgroundColor: "#FFFFFF",
                              borderWidth: itemSelected?.id === item.id ? 1 : 0,
                              borderColor: MAIN_COLOR.PRIMARY_COLOR,
                              borderRadius: 20,
                              padding: 25,
                              marginBottom: 10,
                              position:"relative"
                            }}
                          >
                            <TouchableOpacity
                              style={{position:"absolute", top:12, right:12}}
                              onPress={() => {
                                this.setState({visibleRemoveProperty:true})
                                this.setState({propertyRemove:item})
                              }}
                            >
                              <Image 
                                style={{ width:20, height:20 }}
                                source={require("../../Image/button/close_black/close@2x.png")}
                              />
                            </TouchableOpacity>
                            <Text
                              style={{
                                fontSize: 16,
                                color: MAIN_COLOR.G800,
                                fontFamily: MAIN_FONT.semi,
                                fontWeight: "500",
                                marginBottom: 5,
                              }}
                            >
                              {item.Suburb ? item.Suburb : "Property Address"},{" "}
                              {item.state}
                            </Text>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 13,
                                  color: MAIN_COLOR.G700,
                                  fontFamily: MAIN_FONT.regular,
                                  marginBottom: 5,
                                }}
                              >
                                Last Update:
                              </Text>
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: MAIN_COLOR.G800,
                                  fontFamily: MAIN_FONT.regular,
                                  textAlign: "left",
                                }}
                              >
                                {getTime(item.date_updated * 1)} -{" "}
                                {moment(item.date_updated * 1).format(
                                  "DD/MM/YYYY"
                                )}
                              </Text>
                            </View>
                          </TouchableOpacity>
                        );
                      })}
                      {visibleRemoveProperty && 
                        <Modal 
                          title="Remove Property"
                          btnCancel="Cancel"
                          btnSave="Remove"
                          disableSave={isLoading}
                          open={visibleRemoveProperty}
                          onClose={() => this.setState({visibleRemoveProperty:false})}
                          onSave={handleRemoveProperty}
                        >
                            <Text>Are you sure you want to remove "{propertyRemove?.Suburb}"?</Text>
                        </Modal>
                      }
                    </>
                  )}
                </View>
                <View
                  style={[styles.centerView, { width: "60%", paddingLeft: 0 }]}
                >
                  {itemSelected && (
                    <PortfolioItem item={itemSelected} index={index} />
                  )}
                </View>
              </View>
            </View>
          )}

          {this.state.currentComponent === "SearchProperty" && (
            <SearchProperty
              setIsLoading={this.setLoading}
              goBack={() => this.setState({ currentComponent: "index" })}
            />
          )}
        </ScrollView>
        {this.state.isLoading ? <LOADER /> : null}
      </SafeAreaView>
    );
  }
}

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    TIA: state.TIA,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEquity,
      setClient
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
