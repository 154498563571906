import { sumBy } from "lodash";
import moment from "moment";
import React from "react";
import { Line } from "react-chartjs-2";
import { Text, View } from "react-native";
import { MAIN_COLOR } from "../../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../../Utility/Fonts/font";
import {
  externalTooltipHandler,
  formatDollar,
} from "../../../../../Utility/common";
import styles from "./style";
import { PropagateLoader } from "react-spinners";

const GrowthChart = ({ properties, result, loading }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    hover: {
      mode: "index",
      intersect: false,
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: MAIN_COLOR.G700,
        },
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        callbacks: {
          label: (value) => {
            return formatDollar(value.raw);
          },
        },
        external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        grid: {
          drawTicks: false,
        },
        ticks: {
          font: {
            family: MAIN_FONT.regular,
            size: 12,
          },
          padding: 10,
        },
      },
      y: {
        border: { dash: [4, 1] },
        grid: {
          tickColor: "#E8E6E6",
          drawTicks: false,
        },
        beginAtZero: true,
        ticks: {
          font: {
            family: MAIN_FONT.regular,
            size: 12,
          },
          padding: 10,
          major: {
            enable: true,
          },
          maxTicksLimit: 6,
          callback: (value) => formatDollar(value),
        },
      },
    },
  };

  const labels =
    result?.sales?.slice(-13).map((one, index) => {
      if (index % 3 === 0 || index === result.length - 1) {
        return moment(one.date).format("MMM");
      }
    }) || [];

  const values = result?.sales?.slice(-13).map((one) => one.value) || [];

  const data = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(250, 191, 1, 0.6)",
            "rgba(250, 191, 1, 0.3)",
            "rgba(250, 191, 1, 0.1)",
          ];
          if (!context.chart.chartArea) return;
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        borderColor: MAIN_COLOR.yellow,
        pointHoverBorderWidth: 2.5,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: MAIN_COLOR.yellow,
        pointBackgroundColor: MAIN_COLOR.yellow,
        pointHoverBorderColor: MAIN_COLOR.BACKGROUND_COLOR,
        lineTension: 0.4,
        pointRadius: 0,
        fill: false,
        spanGaps: true,
        clip: false,
      },
    ],
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: 10,
        }}
      >
        <Text style={styles.title}>Growth since purchase</Text>
        <Text style={styles.currentValue}>
          {Math.floor(result?.growthPercentage)}%
        </Text>
      </View>
      <View
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 16,
          height: "80%",
          position: "relative",
        }}
      >
        {loading ? (
          <PropagateLoader
            color="#486868"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        ) : (
          <Line
            options={options}
            data={data}
            plugins={[
              {
                afterDraw: function (chart, easing) {
                  if (
                    chart?.tooltip?._active &&
                    chart?.tooltip?._active?.length
                  ) {
                    const activePoint = chart.tooltip._active[0];
                    const ctx = chart.ctx;
                    const x = activePoint.element.x;
                    const topY = chart.scales.y.chart.tooltip.caretY + 10;
                    const bottomY = chart.scales.y.bottom;

                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = MAIN_COLOR.G800;
                    ctx.setLineDash([4, 4]);
                    ctx.stroke();
                    ctx.restore();
                  }
                },
              },
            ]}
          />
        )}
      </View>
    </View>
  );
};

export default GrowthChart;
