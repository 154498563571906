import { StyleSheet } from "react-native";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    flexShrink: 1,
    zIndex: -1,
  },
  image: {
    height: 150,
    width: 150,
  },
  title: {
    fontSize: 21,
    color: "#1A3F49",
    fontFamily: MAIN_FONT.bold,
    textAlign: "center",
    marginTop: 10,
  },
  subTitle: {
    fontSize: 16,
    color: "#A2ADB9",
    fontFamily: MAIN_FONT.regular,
    textAlign: "center",
    marginTop: 10,
  },
});
