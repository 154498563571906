import { StyleSheet } from "react-native";
import { MAIN_COLOR } from "../../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    padding: 16,
    borderWidth: 1,
    borderColor: "rgba(173, 185, 199, 0.3)",
    borderRadius: 8,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    // flex: 1,
    width: "40%",
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
    fontFamily: MAIN_FONT.semi,
    color: MAIN_COLOR.G800,
  },
  currentValue: {
    fontWeight: 600,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLOR.PRIMARY_COLOR,
  },
});
