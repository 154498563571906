import React from 'react';
import {
  Animated, Dimensions, Image,
  ImageBackground,
  Linking, Modal, Platform, ScrollView,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
// expo web
// import Pdf from 'react-native-pdf';
import { createElement } from "react-native-web";
import { NavigationEvents } from 'react-navigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { user as setUser } from '../../Reducer/user';
import { fetchVideoLoom, getContactByEmail, getProperty, updatePresentationViewed } from '../../Clickup/api';
import { MAIN_COLOR } from "../../Utility/Colors";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import { assesments, format, reports } from '../../Zoho/Tasks';

import LOADER from "../../Utility/ComponentCard/Loader";
import styles from "./style";
import ReactPlayer from 'react-player';
import Toast from 'react-native-toast-message';
import { toastConfig } from '../../configs/toastConfig';
import { BlurView } from "expo-blur";


class PropertyScreen extends React.Component {

  constructor(props) {
    super(props);



    this.state = {
      today: new Date(),
      selected: {},
      pdfs: [],
      proposed: props.property,
      booking: props.booking,
      markedDates: {},
      opacity: new Animated.Value(1),
      height: new Animated.Value(0),
      videoUrl: false,
      videos: [],
      selectedLink: false,
      property: {},
      refreshing: false,
      assignee: props.assignee,
      buyingTeamPhoto: null,
      showComparative: false,
      showLoomVideo: false,
      playing: false,
      disableBtnClose: true,
      videoLoomUrl: "",
      taskId: props.taskId
    };
    this.handleClose = this.handleClose.bind(this);
  }

  async generateThumbnail() {

    return (
      <View style={{ flex: 1, backgroundColor: 'red', width: '100%' }}>
        <Pdf
          singlePage={false}
          enablePaging
          spacing={100}
          // enableRTL
          // enableAnnotationRendering
          source={source}
          onLoadComplete={(numberOfPages, filePath) => {
            console.log(`number of pages: ${numberOfPages}`);
          }}
          onPageChanged={(page, numberOfPages) => {
            console.log(`current page: ${page}`);
          }}
          onError={(error) => {
            console.log(error);
          }}
          onPressLink={(uri) => {
            console.log(`Link presse: ${uri}`)
          }}
          activityIndicatorProps={{ color: 'red' }}
          style={{ flex: 1, backgroundColor: '#FFF' }} />
      </View>
    )
  }

  async refresh() {

    this.setState({ refreshing: true });

    getProperty(this.state.proposed.id).then((result) => {
      if (result) {

        var object = result.custom_fields.reduce(
          (obj, item) => Object.assign(obj, { [item.name]: item.value }), {});


        result['custom_fields'] = null;

        fetchVideoLoom(
          object["PROPERTY PRESO LOOM VIDEO "]?.match(/\/share\/([^?]+)/)[1]
        ).then((result) => this.setState({ videoLoomUrl: result.url}));
        
        this.setState({ property: { ...result, ...object } }, () => {

          // alert(this.state.property['Interest Only Loan At'])
        });

        if (
          object['COMPARITIVE PROPERTY 1 (PRICE)'] &&
          object['COMPARITIVE PROPERTY 1 (IMAGE)'] &&
          object['COMPARITIVE PROPERTY 2 (PRICE)'] &&
          object['COMPARITIVE PROPERTY 2 (IMAGE)'] &&
          object['COMPARITIVE PROPERTY 3 (PRICE)'] &&
          object['COMPARITIVE PROPERTY 3 (IMAGE)']
        ) {
          this.setState({showComparative: true});
        } else {
          this.setState({showComparative: false});
        }

        // if (result.message == "function executed successfully") {
        //   var data = "[" + result.details.output + "]";

        //   data = JSON.parse(data);

        //   this.setState({ property: data[0] }, () => {
        //     console.log("\n\n property:\n", this.state.property);
        //   });
        // }
        // this.setState({ refreshing: false });
      }
    })



  }

  componentDidMount() {
    const { assignee, taskId } = this.state;

    this.refresh();
    if (assignee) {
      getContactByEmail(assignee.email).then((result) => {
        const tasks = result?.tasks;
        if (tasks.length) {
          const custom_fields = tasks[0].custom_fields;
          const contactPhoto = custom_fields.find(
            (field) => field.name === "Buying Team Photo"
          )?.value[0].url;
          this.setState({ buyingTeamPhoto: contactPhoto });
        }
      });
    }
    // this.getVideoThumbnails();

    if (taskId) {
      getProperty(taskId).then((result) => {
        this.setState({
          showLoomVideo:
            !(result.custom_fields.find(
              (field) => field.name === "Presentation Viewed"
            )?.value === "true"),
        });
      })
    }
  }


  handleClose() {
    const { taskId, disableBtnClose } = this.state;
    if (disableBtnClose) {
      Toast.show({
        type: "info",
        text1: "Please finish watching the video before closing",
      });
    } else {
      updatePresentationViewed(taskId, true).then((result) => {
        if (!result.error) {
          this.setState({ showLoomVideo: false, playing: false });
        }
      });
    }
  }

  render() {

    const {
      videos,
      property,
      refreshing,
      assignee,
      showLoomVideo,
      playing,
      disableBtnClose,
      videoLoomUrl,
    } = this.state;

    console.log("Data", property);

    const openUrl = (url) => {

      let reg = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/

      // var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      //   '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      //   '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      //   '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      if (!!reg.test(url)) {

        Linking.openURL(url)
      }
    }

    const openMap = (address) => {


      // const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
      // // const latLng = `${lat},${lng}`;
      // const label = address;
      // const url = Platform.select({
      //   ios: `${scheme}${label}@`,
      //   android: `${scheme}(${label})`
      // });


      Linking.openURL("http://maps.google.com/?q="+address);
    }

    const web = (link) => {
      const Video = (props) => {
        const attrs = {
          src: props.source,
          // poster: props.poster,
          width: "100%",
          height: 550,
          alignSelf: 'center',
          frameborder: "0",
          webkitallowfullscreen: true,
          mozallowfullscreen: true,
          allowfullscreen: true,
          // style:"position: absolute; top: 0; left: 0; width: 100%; height: 100%;",
          controls: "controls"
        }

        return createElement("iframe", attrs)
      }

      return (<Video
        source={link}
        // poster={'https://www.fillmurray.com/480/300'}
        width={100}

      />)
    }


    return (
      <View style={{ flex: 1, paddingHorizontal: 25}} keyboardShouldPersistTaps={true} behavior={Platform.OS === "ios" ? "padding" : "padding"} keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}>
        <NavigationEvents
          onWillFocus={this._onFocus}
          onWillBlur={this._onBlurr}
        />


        <View style={[styles.scrollViewContentView, { borderRadius: 10, backgroundColor: MAIN_COLOR.BACKGROUND_COLOR }]}>
            <ImageBackground source={{ uri: property['PROPERTY IMAGE URL'] }}
              style={{
                flexDirection: 'row', justifyContent: 'space-between',
                alignItems: 'center', paddingTop: 200, paddingBottom: 100,
                paddingHorizontal: '5%',
                overflow: "hidden"
              }}
              imageStyle={{
                borderTopLeftRadius: 10,
                // height: 250,
                borderTopRightRadius: 10,
                backgroundColor: MAIN_COLOR.G800,
                resizeMode: 'cover',
                transform: [{scale: 1.7}],
              }} />

          <View style={[styles.row, { paddingVertical: 20, paddingHorizontal: '5%', justifyContent: 'space-between', alignItems: 'flex-start' }]}>

            <View style={{ width: "68%" }}>
              <Text style={{
                fontSize: 18,
                color: MAIN_COLOR.G800,
                fontFamily: MAIN_FONT.semi,
                marginBottom: 5,
              }}>
                {property['PROPERTY STREET ADDRESS']}


              </Text>

              <Text style={{
                fontSize: 16,
                color: MAIN_COLOR.G700,
                fontFamily: MAIN_FONT.regular,
              }}>
                {property['PROPERTY SUBURB']} {property['PROPERTY STATE']} {property['PROPERTY POSTCODE']}
              </Text>
            </View>

            <View style={{ alignItems: 'center' }}>

              <TouchableOpacity onPress={() => openMap(property['PROPERTY STREET ADDRESS'] + ' ' + property['PROPERTY SUBURB'] + ' ' + property['PROPERTY STATE'] + ' ' + property['PROPERTY POSTCODE'])} style={{ backgroundColor: MAIN_COLOR.BACKGROUND, borderRadius: 17, height: 48, width: 48, justifyContent: 'center', alignItems: 'center' }}>

                <Image
                  style={{ height: 22, width: 22 }}
                  resizeMode="contain"
                  source={require("../../assets/marker.png")}
                />

              </TouchableOpacity>

              <Text style={{ fontSize: 14, color: MAIN_COLOR.G800, fontFamily: MAIN_FONT.regular, textAlign: 'center', marginTop: 5 }}>
                Map View
              </Text>

            </View>

          </View>

          <View style={[styles.row, { paddingVertical: 0, paddingHorizontal: '5%', justifyContent: 'space-between', alignItems: 'flex-start' }]}>


            <View style={{ flexDirection: 'row', backgroundColor: MAIN_COLOR.BACKGROUND, borderRadius: 17, height: 48, width: "20%", justifyContent: 'center', alignItems: 'center' }}>
              <Image
                style={styles.icon}
                resizeMode="contain"
                source={require("../../assets/bed.png")}
              />
              <Text style={styles.iconTitle}>
                {property['NO. OF BEDROOMS']}
              </Text>
            </View>

            <View style={{ flexDirection: 'row', backgroundColor: MAIN_COLOR.BACKGROUND, borderRadius: 17, height: 48, width: "20%", justifyContent: 'center', alignItems: 'center' }}>
              <Image
                style={styles.icon}
                resizeMode="contain"
                source={require("../../assets/bath.png")}
              />
              <Text style={styles.iconTitle}>
                {property['NO. OF BATHROOMS']}
              </Text>
            </View>

            <View style={{ flexDirection: 'row', backgroundColor: MAIN_COLOR.BACKGROUND, borderRadius: 17, height: 48, width: "20%", justifyContent: 'center', alignItems: 'center' }}>
              <Image
                style={styles.icon}
                resizeMode="contain"
                source={require("../../assets/car.png")}
              />
              <Text style={styles.iconTitle}>
                {property['# OF PARKING']}
              </Text>
            </View>

            <View style={{ flexDirection: 'row', backgroundColor: MAIN_COLOR.BACKGROUND, borderRadius: 17, height: 48, width: "25%", justifyContent: 'center', alignItems: 'center' }}>
              <Text style={styles.iconTitle}>
                {property['AREA (M2)']}m2
              </Text>
            </View>
          </View>

          <View style={{ height: 1, backgroundColor: '#E7ECF2', alignSelf: 'center', width: '90%', marginTop: 30, marginBottom: 20 }} />

          <View style={{ marginHorizontal: "5%", backgroundColor: "#FFFFFF", borderRadius: 20, }}>
            <Text style={{ fontSize: 16, color: '#272D37', fontFamily: MAIN_FONT.regular, }}>
              Property Details
            </Text>

            <View style={[styles.row, { justifyContent: "space-between", marginTop: 10 }]}>

              <Text style={styles.priceTitle}>
                Asking Price
              </Text>

              <Text style={styles.price}>
                ${format(property['ASKING PRICE $'])}
              </Text>
            </View>

            <View style={[styles.row, { justifyContent: "space-between", marginTop: 10 }]}>

              <Text style={styles.priceTitle}>
                Target Price
              </Text>

              <Text style={styles.price}>
                ${format(property['TARGET PRICE'])}
              </Text>
            </View>

            <View style={[styles.row, { justifyContent: "space-between", marginTop: 10 }]}>

              <Text style={{ fontSize: 13, color: MAIN_COLOR.G800, fontFamily: MAIN_FONT.semi }}>
                  TIA Property Valuation
              </Text>

              <Text style={styles.price}>
                ${format(property['TIA Property Valuation'])}
              </Text>
            </View>

            <View style={{ height: 1, backgroundColor: '#E7ECF2', width: '100%', marginVertical: 20 }} />

            <View style={[styles.row, { justifyContent: "space-between", marginTop: 10 }]}>

              <Text style={styles.priceTitle}>
                Potential Rent
              </Text>

              <Text style={styles.price}>
                ${format(property['APPRAISED RENTAL RATE P/W $'])}
              </Text>
            </View>

            <View style={[styles.row, { justifyContent: "space-between", marginTop: 10 }]}>

              <Text style={styles.priceTitle}>
                Potential Gross Yield
              </Text>

              <Text style={styles.price}>
                {/* {format(property['Annual Gross Yield'])} % */}
                {format(Number(property["APPRAISED RENTAL RATE P/W $"]) * 52 / Number(property["TARGET PRICE"]) * 100)} %
              </Text>
            </View>

            <TouchableOpacity onPress={() => openUrl(property['PROPERTY URL'])} style={{ flexDirection: 'row', backgroundColor: MAIN_COLOR.PRIMARY_COLOR, borderRadius: 10, width: 300, padding: 10, justifyContent: 'center', alignSelf: 'center', marginTop: 10 }}>
              <Image
                style={{ height: 20, width: 20, marginRight: 5 }}
                resizeMode="contain"
                source={require("../../assets/house.png")}
              />
              <Text style={{
                color: '#FFFFFF',
                fontFamily: MAIN_FONT.semi,
                fontSize: 14,
                textAlign: 'center'
              }}>
                View Property
              </Text>
            </TouchableOpacity>


          </View>

          <View style={{ height: 1, backgroundColor: '#E7ECF2', alignSelf: 'center', width: '90%', marginTop: 30, marginBottom: 20 }} />

            {
             this.state.showComparative && (
              <>
                <Text style={{ fontSize: 16, color: '#272D37', fontFamily: MAIN_FONT.regular, marginLeft: '5%', marginBottom: 20 }}>
                  Comparative Properties
                </Text>
                {
                  Object.keys(property).length ?
                    <>
                      <View style={{ flexDirection: 'row' }}>
      
      
                        <TouchableOpacity onPress={() => openUrl(property['COMPARATIVE PROPERTY 1 (URL)'])} style={{ padding: 5, backgroundColor: MAIN_COLOR.BACKGROUND, width: "29%", marginLeft: "5%", borderRadius: 10 }}>
      
                          <View style={{ height: "auto", width: 'auto', borderRadius: 5, aspectRatio: 3 / 2, overflow: "hidden" }}>
                            <Image
                              source={{ uri: property['COMPARITIVE PROPERTY 1 (IMAGE)']?.replace('-format=avif,', '') }}
                              resizeMode="cover"
                              style={{ height: "auto", width: 'auto', borderRadius: 5, aspectRatio: 3 / 2, transform: [{scale: 2}], }}
                            />
                          </View>
      
                          <Text style={[styles.price, { textAlign: 'center', marginVertical: 5 }]}>
                            ${format(property['COMPARITIVE PROPERTY 1 (PRICE)'])}
                          </Text>
      
                          {/* <Text style={[styles.priceTitle, { textAlign: 'center', fontSize: 12 }]}>
              {item.Comparative_Date}
            </Text> */}
      
                          <Text style={[styles.priceTitle, { textAlign: 'center', fontSize: 14, marginVertical: 5, color: MAIN_COLOR.PRIMARY_COLOR, textDecorationLine: "underline" }]}>
                            View
                          </Text>
      
                        </TouchableOpacity>
      
      
      
                        <TouchableOpacity onPress={() => openUrl(property['COMPARATIVE PROPERTY 2 [URL]'])} style={{ padding: 5, backgroundColor: MAIN_COLOR.BACKGROUND, width: "29%", marginLeft: "1.5%", borderRadius: 10 }}>
      
                          <View style={{ height: "auto", width: 'auto', borderRadius: 5, aspectRatio: 3 / 2, overflow: "hidden" }}>
                            <Image
                              source={{ uri: property['COMPARITIVE PROPERTY 2 (IMAGE)']?.replace('-format=avif,', '') }}
                              resizeMode="cover"
                              style={{ height: "auto", width: 'auto', borderRadius: 5, aspectRatio: 3 / 2, transform: [{scale: 2}], }}
                            />
                          </View>
      
                          <Text style={[styles.price, { textAlign: 'center', marginVertical: 5 }]}>
                            ${format(property['COMPARITIVE PROPERTY 2 (PRICE)'])}
                          </Text>
      
                          {/* <Text style={[styles.priceTitle, { textAlign: 'center', fontSize: 12 }]}>
              {item.Comparative_Date}
            </Text> */}
      
                          <Text style={[styles.priceTitle, { textAlign: 'center', fontSize: 14, marginVertical: 5, color: MAIN_COLOR.PRIMARY_COLOR, textDecorationLine: "underline" }]}>
                            View
                          </Text>
      
                        </TouchableOpacity>
      
      
                        <TouchableOpacity onPress={() => openUrl(property['COMPARATIVE PROPERTY 3 [URL]'])} style={{ padding: 5, backgroundColor: MAIN_COLOR.BACKGROUND, width: "29%", marginLeft: "1.5%", borderRadius: 10 }}>

                          <View style={{ height: "auto", width: 'auto', borderRadius: 5, aspectRatio: 3 / 2, overflow: "hidden" }}>
                            <Image
                              source={{ uri: property['COMPARITIVE PROPERTY 3 (IMAGE)']?.replace('-format=avif,', '') }}
                              resizeMode="cover"
                              style={{ height: "auto", width: 'auto', borderRadius: 5, aspectRatio: 3 / 2, transform: [{scale: 2}], }}
                            />
                          </View>
      
                          <Text style={[styles.price, { textAlign: 'center', marginVertical: 5 }]}>
                            ${format(property['COMPARITIVE PROPERTY 3 (PRICE)'])}
                          </Text>
      
                          {/* <Text style={[styles.priceTitle, { textAlign: 'center', fontSize: 12 }]}>
              {item.Comparative_Date}
            </Text> */}
      
                          <Text style={[styles.priceTitle, { textAlign: 'center', fontSize: 14, marginVertical: 5, color: MAIN_COLOR.PRIMARY_COLOR, textDecorationLine: "underline" }]}>
                            View
                          </Text>
      
                        </TouchableOpacity>
      
      
                      </View>
                    </>
                    :
                    null
                }
              </>
            )
          }

          <View style={{ height: 1, backgroundColor: '#E7ECF2', alignSelf: 'center', width: '90%', marginTop: 30, marginBottom: 20 }} />


          <Text style={{ fontSize: 16, color: '#272D37', fontFamily: MAIN_FONT.regular, marginLeft: '5%', }}>
            About this Property
          </Text>
          {
            assesments(property).map((item, index) => {

              let ratio = 0.68;
              if (item.url) {
                Image.getSize(item.url, (w, h) => {
                  ratio = h / w
                });
              }

              return (
                <TouchableOpacity onPress={() => this.setState({ selected: { ...this.state.selected, [index]: !this.state.selected[index] } }, () => console.log(this.state.selected))} style={{ width: "90%", borderRadius: 10, padding: 5, backgroundColor: MAIN_COLOR.BACKGROUND, paddingHorizontal: '5%', marginLeft: "5%", marginVertical: 5 }}>


                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>

                    <Text style={[styles.price, { textAlign: 'left', marginVertical: 5, fontFamily: MAIN_FONT.regular }]}>
                      {item.title}
                    </Text>

                    <Image
                      source={require('../../assets/right.png')}
                      resizeMode="cover"
                      style={{ height: 14, width: 14, borderRadius: 5 }}
                    />

                  </View>

                  {
                    this.state.selected[index] &&

                    <>

                      {
                        !!item.description && isNaN(item.description) &&
                        <Text style={[styles.price, { textAlign: 'left', marginVertical: 5, fontFamily: MAIN_FONT.regular, color: MAIN_COLOR.PRIMARY_COLOR }]}>
                          {item.description && item.description.toUpperCase()}
                        </Text>
                      }


                      {
                        item.url &&
                        <Image
                          source={{ uri: item.url }}
                          resizeMode="cover"
                          style={{ height: (Dimensions.get('window').width - 250 - 500 - 100)*0.6, width: "100%", borderRadius: 10, marginVertical: 10 }}
                        />
                      }


                      <Text style={[styles.price, { textAlign: 'left', marginVertical: 5, fontFamily: MAIN_FONT.regular, color: '#7D888F' }]}>
                        {item.explanation} {item.detail && (<Text style={[{fontWeight: "bold"}]}>{ item.detail }</Text>)}
                      </Text>

                      {

                        index === 0 && property.Easements_Yes_No == "Yes" &&

                        <TouchableOpacity onPress={() => openUrl(property.Easements_if_Yes_add_URL)} style={{ flexDirection: 'row', borderWidth: 2, borderColor: MAIN_COLOR.PRIMARY_COLOR, borderRadius: 10, padding: 10, justifyContent: 'center', alignSelf: 'center', marginVertical: 10 }}>

                          <Text style={{
                            color: MAIN_COLOR.PRIMARY_COLOR,
                            fontFamily: MAIN_FONT.semi,
                            fontSize: 14,
                            textAlign: 'center'
                          }}>
                            View Easement Map
                          </Text>
                        </TouchableOpacity>
                      }

                      {

                        index === 1 && property.Property_in_Flood_Zones == "Yes" &&

                        <TouchableOpacity onPress={() => openUrl(property.Floodzone_Map_URL)} style={{ flexDirection: 'row', borderWidth: 2, borderColor: MAIN_COLOR.PRIMARY_COLOR, borderRadius: 10, padding: 10, justifyContent: 'center', alignSelf: 'center', marginVertical: 10 }}>

                          <Text style={{
                            color: MAIN_COLOR.PRIMARY_COLOR,
                            fontFamily: MAIN_FONT.semi,
                            fontSize: 14,
                            textAlign: 'center'
                          }}>
                            View Floodzone Map
                          </Text>
                        </TouchableOpacity>
                      }

                    </>
                  }

                </TouchableOpacity>
              )
            }

            )
          }

          <Text style={{ fontSize: 16, color: '#272D37', fontFamily: MAIN_FONT.regular, marginLeft: '5%', marginVertical: 20 }}>
            Why is this a good Option?
          </Text>

          {/* {
              // videos.length ?
              //   videos.map((item, index) =>
              //     <TouchableOpacity
              //       onPress={() =>
              //         Platform.OS == "ios" ?
              //           this.setState({ videoUrl: item.videoUrl }, () => {
              //             this.fullScreen()
              //           })
              //           :
              //           this.props.navigation.navigate("WebView", { link: item.videoUrl, video: true })
              //       }


              //       activeOpacity={0.8} style={{ width: '100%', marginBottom: 20 }}>

              //       <ImageBackground
              //         source={{ uri: item.thumbnailUrl }}
              //         resizeMode="cover"
              //         style={{ height: 160, width: '90%', resizeMode: 'cover', justifyContent: 'center', alignItems: 'center', marginLeft: "5%" }}
              //         imageStyle={{ height: 160, width: '100%', resizeMode: 'cover', borderRadius: 20, }}
              //       >


              //         <Image
              //           source={require('../../assets/play.png')}
              //           resizeMode="contain"
              //           style={{ height: 40, width: 40 }}
              //         />

              //       </ImageBackground>

              //     </TouchableOpacity>
              //   )

              property['Walk Through Videos'] ?
                property['Walk Through Videos'].split('\n').map((item, index) =>
                  <TouchableOpacity


                    activeOpacity={0.8} style={{ width: '90%', alignSelf: 'center', marginBottom: 20 }}>

                    <Video
                      // ref={ref => {
                      //   this.player[index] = ref;
                      // }}
                      source={{ uri: item }}
                      posterResizeMode="cover"
                      // poster={item.thumbnailUrl}
                      // onFullscreenPlayerWillDismiss={() => alert(this.state.videoUrl)}

                      // onEnd={() => alert("end")}
                      // fullscreen

                      // fullscreenOrientation="all"
                      // navigator={this.props.navigator}
                      fullscreen={true}
                      resizeMode={'cover'}
                      controls={true}
                      paused={this.state.videoUrl !== index}
                      // controls={Platform.os == "android" ? true : false}                // Store reference
                      // onBuffer={this.onBuffer}                // Callback when remote video is buffering
                      // onError={this.videoError}               // Callback when video cannot be loaded
                      style={{ height: 180, width: '100%', borderRadius: 20, }}
                    />

                    {
                      console.log("video::", item)
                    }


                  </TouchableOpacity>
                )
                :

                <Text style={{ fontSize: 14, color: MAIN_COLOR.G700, fontFamily: MAIN_FONT.regular, marginBottom: 20, textAlign: 'center', alignSelf: 'center' }}>
                  No Videos
                </Text>

            } */}

          {/* {
              property['PROPERTY PRESO LOOM VIDEO '] &&


              <View
                activeOpacity={0.8} style={{ width: '90%', alignSelf: 'center', marginBottom: 20 }}>
                <WebView
                  androidHardwareAccelerationDisabled
                  allowsFullscreenVideo
                  startInLoadingState
                  allowsInlineMediaPlayback
                  style={{ height: 210, width: '100%', borderRadius: 20 }}
                  source={{
                    // uri: property['PROPERTY PRESO VIDEO URL']
                    html:
                      `<iframe src=${property['PROPERTY PRESO LOOM VIDEO '].replace('share', 'embed')} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>`
                    // html: `<html><iframe width="100%" height="100%" src=${taskUpdate.video_url.replace("vimeo.com", "player.vimeo.com/video")} webkit-playsinline frameborder="0" webkitallowfullscreen={true} mozallowfullscreen allowfullscreen></iframe></html>`
                  }} />

              </View>
            } */}

            <View style={{width: "90%", alignSelf: 'center'}}>

          {
            web(property['PROPERTY PRESO LOOM VIDEO ']?.replace('share', 'embed'))
          }
</View>

          {/* {

              Platform.OS == "ios" ?

                <Video
                  ref={ref => {
                    this.player = ref;
                  }}
                  source={{ uri: this.state.videoUrl }}
                  onFullscreenPlayerDidDismiss={() => this.setState({ videoUrl: null })}
                  // fullscreen

                  // fullscreenOrientation="all"
                  // navigator={this.props.navigator}
                  fullscreen={true}
                  resizeMode={'cover'}
                  paused={false}
                  // controls={Platform.os == "android" ? true : false}                // Store reference
                  // onBuffer={this.onBuffer}                // Callback when remote video is buffering
                  // onError={this.videoError}               // Callback when video cannot be loaded
                  style={{ height: 0, width: '100%', borderRadius: 40, }}
                />
                :

                <>

                </>

            } */}


          <View style={{ height: 1, backgroundColor: '#E7ECF2', alignSelf: 'center', width: '90%', marginTop: 30, marginBottom: 20 }} />

          <Text style={{ fontSize: 16, color: '#272D37', fontFamily: MAIN_FONT.regular, marginLeft: '5%', }}>
            Approximate Funds Associated
          </Text>


          <ScrollView horizontal contentContainerStyle={{justifyContent: 'space-between', width: '100%', minWidth: 900, paddingHorizontal: '5%'}} style={{ marginBottom: 20 }} >

            {

              reports(property).map((item, index) =>
                <View style={{ backgroundColor: '#FFFFFF', padding: 10, borderRadius: 20, minWidth: 320, width: "33%", marginHorizontal: index == 1 && "1%", }}>
                  <Text style={{
                    fontSize: 14,
                    color: MAIN_COLOR.G800,
                    fontFamily: MAIN_FONT.semi,
                    textAlign: 'center'
                  }}>
                    {index == 0 ? "PURCHASE COSTS" : index == 1 ? "ANNUAL EXPENSES" : "POTENTIAL RESULTS"}
                  </Text>


                  <View style={{ backgroundColor: MAIN_COLOR.BACKGROUND, padding: 10, marginBottom: "5%", marginTop: 5, borderRadius: 10, height: "90%" }}>
                    {
                      item.map((element, index) =>
                        <View style={[styles.row, {
                          // backgroundColor: 'red', marginVertical: 1,
                          justifyContent: "space-between", alignItems: 'flex-end'
                          // , marginTop: index % 4 == 0 ? 10:0
                        }]}>

                          <Text style={[styles.priceTitle, {fontFamily: element.bold? MAIN_FONT.bold:MAIN_FONT.regular, }]}>
                            {element.title.toUpperCase()}
                          </Text>

                          <Text style={[styles.price, {fontFamily: element.bold? MAIN_FONT.bold:MAIN_FONT.semi}]}>
                            {!element.title.includes("yield") && "$"}{format(element.value)}{element.title.includes("yield") && "%"}
                          </Text>
                        </View>
                      )
                    }
                  </View>

                </View>
              )

            }

          </ScrollView>

          {
            assignee && 
            <>
              <View style={[styles.row, { backgroundColor: MAIN_COLOR.BACKGROUND, marginLeft: "5%", width: "90%", paddingTop: 10, borderRadius: 20, gap: 20, display: 'flex', justifyContent: 'center' }]}>
                <Image source={this.state.buyingTeamPhoto} style={{ minWidth: 200, height: 125, resizeMode: "cover", borderBottomLeftRadius: 20 }} />
                <Text style={[styles.price, { textAlign: 'left', marginBottom: 30, fontFamily: MAIN_FONT.regular, width: "64%", marginTop: 30, paddingRight: 5, fontSize: 14, display: 'flex', justifyContent: 'center' }]}>
                  {`Book a call through the link below with ${assignee.username?.split(" ")[0]} & Mike after reviewing the above information.`}
                </Text>
              </View>
              <TouchableOpacity onPress={() => openUrl(this.state.booking)} style={{ flexDirection: 'row', backgroundColor: MAIN_COLOR.PRIMARY_COLOR, width: 300, borderRadius: 10, padding: 12.5, justifyContent: 'center', alignSelf: 'center', marginVertical: 40 }}>

                <Text style={{
                  color: '#FFFFFF',
                  fontFamily: MAIN_FONT.semi,
                  fontSize: 14,
                  textAlign: 'center'
                }}>
                  BOOK A QUICK CALL
                </Text>
              </TouchableOpacity>
            </>
          }

        </View>

        {!Object.keys(property).length ? <LOADER /> : null}

        <Modal
          animationType="fade"
          transparent
          visible={!!videoLoomUrl && showLoomVideo}
        >
          <BlurView tint="dark" intensity={12} style={{ position: 'absolute', height:'100%', width:'100%' }}>
            <View style={{ background: 'rgb(72,72,72, 0.6)', flex: 1 }}>
              <View style={{ marginTop: 40, marginBottom: 20 }}>
                <Text style={{ fontSize: 20, color: '#FFFF', fontFamily: MAIN_FONT.semi, textAlign: 'center' }}>
                  Property selection
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  alignSelf: "center",
                  flex: 1,
                  alignItems: 'center',
                  position: 'absolute',
                  top: 0,
                  bottom: 0
                }}
              >
                <View style={{ width: '100%'}}>
                  {!playing && (
                    <TouchableOpacity
                        style={styles.btnPlay}
                        onPress={() => this.setState({ playing: true }) }
                      >
                        <Image
                          style={styles.iconPlay}
                          resizeMode="contain"
                          source={require("../../Image/button/play/play.png")}
                        />
                    </TouchableOpacity>
                  )}
                  <ReactPlayer
                    url={videoLoomUrl}
                    controls
                    width="70%"
                    height="500px"
                    playing={playing}
                    style={{ alignSelf: "center", zIndex: 1 }}
                    onPlay={() => this.setState({ playing: true})}
                    onEnded={() => this.setState({ disableBtnClose: false })}
                  />
                </View>
                <TouchableOpacity
                  style={{
                    backgroundColor: disableBtnClose ? "#E7ECF2" : MAIN_COLOR.PRIMARY_COLOR,
                    borderRadius: 10,
                    width: '25%',
                    padding: 10,
                    marginTop: 40
                  }}
                  onPress={this.handleClose}
                  >
                  <Text
                    style={{
                      color: disableBtnClose ? MAIN_COLOR.G800 : MAIN_COLOR.BACKGROUND_COLOR,
                      fontFamily: MAIN_FONT.semi,
                      fontSize: 14,
                      textAlign: "center",
                    }}
                    >
                    Close
                  </Text>
                </TouchableOpacity>
              </View>
              <Toast config={toastConfig} />
            </View>
          </BlurView>
        </Modal>
      </View>
    );
  }
};

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    TIA: state.TIA
  }
}

function mapDispatchToProps(dispatch) {


  return bindActionCreators(
    {
      setUser,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyScreen);