import React from "react";
import { NumericFormat } from "react-number-format";
import Input from "./TextInput";

const InputNumber = ({
  thousandSeparator,
  decimalScale,
  prefixIcon,
  showPrefixIcon = true,
  precision,
  hasDelimiter,
  onChange,
  ...props
}) => {
  return (
    <NumericFormat
      {...(showPrefixIcon && {
        prefixIcon:
          prefixIcon ?? require("../assets/portfolio-roadmap/dollar.png"),
      })}
      {...(thousandSeparator && {
        thousandSeparator: ",",
      })}
      customInput={Input}
      onValueChange={(value) => onChange(value.floatValue)}
      isAllowed={(values) => {
        const { floatValue } = values;
        return !hasDelimiter || (floatValue === undefined || floatValue <= 100);
      }}
      allowNegative={false}
      {...props}
    />
  );
};

export default InputNumber;
