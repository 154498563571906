import React from "react";
import { Text, View } from "react-native";
import styles from "./style";
import { formatDollar } from "../../../../../Utility/common";

const Costs = ({ properties, result }) => {
  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.label}>Costs</Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          display: "flex",
          marginTop: "-15px",
        }}
      >
        <View style={styles.info}>
          <View style={styles.row}>
            <Text style={styles.name}>Purchase Price</Text>
            <Text style={styles.value}>
              {formatDollar(properties["Purchase Price"])}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name}>Potential Value</Text>
            <Text style={styles.value}>
              {formatDollar(Math.round(result?.potentialValue))}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name}>Potential Equity</Text>
            <Text style={styles.value}>
              {formatDollar(Math.round(result?.potentialEquity))}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name}>Current Rent</Text>
            <Text style={styles.value}>
              {formatDollar(properties["CURRENT RENTAL RATE P/W $"])} p/w
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name}>Gross Cash flow</Text>
            <Text style={styles.value}>
              {formatDollar(properties["Annual Gross Income"])} p/a
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name}>Net Cash flow</Text>
            <Text style={styles.value}>
              {formatDollar(properties["Annual Net Cashflow"])} p/a
            </Text>
          </View>
        </View>
        <View style={styles.separator}></View>

        <View style={styles.info}>
          <View style={styles.row}>
            <Text style={styles.name}>Deposit</Text>
            <Text style={styles.value}>
              {Number(properties["Deposit Percent"]).toFixed(2)}%
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name}>Loan Type</Text>
            <Text style={styles.value}>{"I/O"}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name}>Interest Rate</Text>
            <Text style={styles.value}>{"6.5%"}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name}>Expenses p/a</Text>
            <Text style={styles.value}>
              {formatDollar(properties["Total Annual Expenses"])}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name}>Gross Yield</Text>
            <Text style={styles.value}>
              {Number(properties["Annual Gross Yield"]).toFixed(2)}% Gross
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name}>Net Yield</Text>
            <Text style={styles.value}>
              {Number(properties["Annual Net Yield %"]).toFixed(2)}% Gross
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Costs;
