import React from 'react';
import {
  View,
  Text
} from 'react-native';

// static
import { MAIN_COLOR } from "../../../Utility/Colors";
// style
import styles from './style';

export class CheckListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: props?.item ?? {}
    }
  }

  render() {
    const { itemContainer, groupRadio, groupRadioNonChecked, radioItem, textName } = styles;
    const { item } = this.state;
    return (
      <View style={itemContainer}>
        <View style={item?.resolved ? groupRadio : groupRadioNonChecked}>
        </View>
        <Text style={textName}>
          {item?.name ?? ''}
        </Text>
      </View>
    );
  }
}