import React from "react";
import { Image, Text, View } from "react-native";
import styles from "./style";

const EmptyState = () => {
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        resizeMode="contain"
        source={require("../../../../assets/content.png")}
      />
      <Text style={styles.title}>Finance planner</Text>
      <Text style={styles.subTitle}>
        Please finish your Finance planner to see roadmap
      </Text>
    </View>
  );
};

export default EmptyState;
