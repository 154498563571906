import * as Yup from "yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Image, ScrollView, Text, TouchableOpacity, View } from "react-native";
import Toast from "react-native-toast-message";
import { usePermission } from "../../../../hooks/usePermission";

import styles from "./style";
import {
  addNewScenario,
  deleteScenario,
  getDetailsByScenarioId,
} from "../../../../Clickup/api";
import {
  setLoading,
  setScenarioSelected,
} from "../../../../Reducer/portfolio-roadmap";

import ModalBase from "../../../../components/Modal";
import Input from "../../../../components/TextInput";
import Switch from "../../../../components/Switch";

import { ROLE } from "../../../../Utility/portfolio-roadmap";
import { MAIN_COLOR } from "../../../../Utility/Colors";

const SaveScenario = ({
  setDisabledClickOutside,
  userId,
  listScenarios,
  fetchData,
  setShowPopup,
  setAccessDenied,
}) => {
  const { scenarioSelected } = useSelector((state) => state.PORTFOLIO_ROADMAP);
  const dispatch = useDispatch();

  const schemaForm = Yup.object().shape({
    valueScenario: Yup.string()
      .required()
      .test((value) => value && value.trim().length > 0),
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      valueScenario: "",
    },
    resolver: yupResolver(schemaForm),
  });

  const [scenario, setScenario] = useState(null);
  const [tab, setTab] = useState(0);
  const [visibleAddScenarioModal, setVisibleAddScenarioModal] = useState(false);
  const [visibleRemoveScenarioModal, setVisibleRemoveScenarioModal] =
    useState(false);
  const { isView } = usePermission("shareOther");
  const [listOfScenario, setListOfScenario] = useState([]);

  const handleSave = async ({ valueScenario }) => {
    if (valueScenario.trim() === "") return;
    try {
      const payload = {
        name: valueScenario.trim(),
        user_id: userId,
        investing_timeline: scenarioSelected?.investingTimeLine,
        year_of_result: scenarioSelected?.yearsOfResult,
        cash_savings: scenarioSelected?.cashSavings,
        available_equity: scenarioSelected?.availableEquity,
        widget_properties: JSON.stringify(scenarioSelected?.widgetProperties),
        custom_income: JSON.stringify(scenarioSelected?.customIncomes),
        total_gross_income: scenarioSelected?.totalGrossIncome || 0,
        total_primary_income: scenarioSelected?.totalPrimaryIncome || 0,
        purchase_target:
          scenarioSelected?.purchaseTargets?.map((item) => ({
            target_price: Math.round(item.valueAtTime),
            target_ren_pw: Math.round(item.targetRentPW),
            type_properties: item.incomeType,
            living_expenses_pm: Math.round(item.expensesPM),
            total_income: Math.round(item.totalIncome),
            deposit_size: Math.round(item.rateSelected),
            cash_contribution: Math.round(item.cashContribute),
            loan_amount: Math.round(item.loanAmount),
            interest_rate: 6.5,
            loan_type: "io",
            type_properties: "house",
            income_type: item.incomeType.toLowerCase(),
            no_of_month: item.noOfMonth,
            recommended_borrowing: Math.round(item.recommendedBorrowing),
            years: item.years,
            interest_per_month: Math.round(item.interestPerMonth),
            property_price: Math.round(item.propertyPrice),
            equity_from_purchase: Math.round(item.equityFromPurchase),
            rent_pw_time_line: Math.round(item.rentPW),
            purchase_date: item.purchaseDate,
            dependant: item.dependant,
            income_1: Math.round(item?.income1),
            income_2: Math.round(item?.income2),
            deposit: Math.round(item?.deposit),
          })) || [],
      };
      const result = await addNewScenario(payload);
      // DO NOT REMOVE
      console.log("scenarioId", result?.data?.id);
      // DO NOT REMOVE
      Toast.show({
        type: "success",
        text1: "Added Scenario Successfully!",
        position: "top",
        visibilityTime: 3000,
      });
      fetchData();
      setShowPopup(false);
    } catch (error) {
      console.log(error);
    }

    setVisibleAddScenarioModal(false);
    reset();
  };
  const handleScenarioSelect = async (scenario) => {
    if (scenario.id === scenarioSelected?.id) return;
    try {
      setAccessDenied(false);
      dispatch(setLoading(true));
      const response = await getDetailsByScenarioId(scenario.id, userId);
      dispatch(setScenarioSelected(response));
      const params = new URLSearchParams(window.location.search);
      params.set("scenario_id", scenario.id);
      window.history.replaceState({}, "", `scenario-detail?${params}`);
      setShowPopup(false);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleRemove = async () => {
    try {
      await deleteScenario(scenario.id, userId);
      Toast.show({
        type: "success",
        text1: "Removed Scenario Successfully!",
        position: "top",
        visibilityTime: 3000,
      });
      fetchData();
      setShowPopup(false);
    } catch (error) {
      console.log(error);
    } finally {
      setVisibleRemoveScenarioModal(false);
    }
  };

  const handleSetTab = (value) => {
    setTab(value);
    setListOfScenario(
      value === 0 ? listScenarios?.scenarios : listScenarios?.shared_scenarios
    );
  };

  useEffect(() => {
    const value = visibleAddScenarioModal || visibleRemoveScenarioModal;
    setDisabledClickOutside(value);
  }, [visibleAddScenarioModal, visibleRemoveScenarioModal]);

  useEffect(() => {
    setListOfScenario(
      isView ? listScenarios?.scenarios : listScenarios?.shared_scenarios
    );
  }, []);

  return (
    <>
      {isView && (
        <Switch
          styleContainer={{ height: 36, marginBottom: 12, marginTop: 6 }}
          selectionMode={tab}
          label1="My scenarios"
          label2="Shared by others"
          option1={0}
          option2={1}
          onSelectSwitch={handleSetTab}
          selectionColor={MAIN_COLOR.yellow}
        />
      )}
      <View style={styles.container}>
        <ScrollView contentContainerStyle={styles.scenarioList}>
          {!!listOfScenario?.length &&
            listOfScenario.map((item, index) => (
              <TouchableOpacity
                key={index}
                onPress={() => handleScenarioSelect(item)}
                style={[
                  styles.item,
                  item.id === scenarioSelected?.id && {
                    backgroundColor: "#F0F1F8",
                  },
                ]}
              >
                <View style={styles.flexRow}>
                  <View style={[styles.wrapIcon, { width: 38, height: 38 }]}>
                    <Image
                      resizeMode="contain"
                      style={{ width: 24, height: 24, margin: "auto" }}
                      source={require("../../../../assets/portfolio-roadmap/status-up.png")}
                    />
                  </View>
                  <View>
                    <Text style={styles.name} numberOfLines={1}>
                      {item.name}
                    </Text>
                    <Text style={styles.time}>
                      {moment(item.created_date).format("h.mm A • DD/MM/YYYY")}
                    </Text>
                  </View>
                </View>
                {tab === 0 && isView && (
                  <TouchableOpacity
                    style={[styles.wrapIcon, { width: 28, height: 28 }]}
                    onPress={() => {
                      setScenario(item);
                      setVisibleRemoveScenarioModal(true);
                    }}
                  >
                    <Image
                      resizeMode="contain"
                      style={{ width: 16, height: 16, margin: "auto" }}
                      source={require("../../../../assets/portfolio-roadmap/trash.png")}
                    />
                  </TouchableOpacity>
                )}
              </TouchableOpacity>
            ))}
        </ScrollView>
        {isView && (
          <TouchableOpacity
            style={styles.btnAdd}
            onPress={() => setVisibleAddScenarioModal(true)}
          >
            <Image
              resizeMode="contain"
              style={{ width: 24, height: 24 }}
              source={require("../../../../assets/portfolio-roadmap/archive-add.png")}
            />
            <Text style={styles.btnText}>Add Scenario</Text>
          </TouchableOpacity>
        )}
      </View>
      <ModalBase
        open={visibleAddScenarioModal}
        title="Add Scenario"
        onClose={() => setVisibleAddScenarioModal(false)}
        btnCancel="Cancel"
        btnSave="Save"
        size="small"
        onSave={handleSubmit(handleSave)}
        disableSave={
          !isValid ||
          (!!listScenarios?.length &&
            listScenarios?.some(
              (scenario) => scenario.name === watch("valueScenario").trim()
            ))
        }
      >
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              label="Scenario Name"
              value={value}
              placeholder="Enter type"
              onChangeText={onChange}
            />
          )}
          name="valueScenario"
        />
      </ModalBase>
      {visibleRemoveScenarioModal && (
        <ModalBase
          open={visibleRemoveScenarioModal}
          title="Remove Scenario"
          onClose={() => setVisibleRemoveScenarioModal(false)}
          btnCancel="Cancel"
          btnSave="Remove"
          size="small"
          onSave={handleRemove}
        >
          <Text>Are you sure you want to remove {scenario?.name}?</Text>
        </ModalBase>
      )}
    </>
  );
};

export default SaveScenario;
