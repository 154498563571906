import React from 'react';
import {
  BackHandler,
  Dimensions,
  Image,
  Keyboard,
  Linking,
  Platform,
  SafeAreaView,
  Text, TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View
} from 'react-native';
import { showMessage } from "react-native-flash-message";
import { MaterialIndicator } from 'react-native-indicators';
import { RFValue } from "react-native-responsive-fontsize";
import { NavigationEvents } from 'react-navigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setClient } from '../../Reducer/tia';
import { MAIN_COLOR } from "../../Utility/Colors";
import LOADER from "../../Utility/ComponentCard/Loader";
//Component
import { Register, addPassword, addToken } from '../../Clickup/api';
import stylesCommonComponents from "../../Utility/ComponentStyle/style";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import styles from "./style";

const { width } = Dimensions.get("window");

class RegisterScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: props.navigation.state.params.EMAIL,
      password: "",
      token: {data: ""},
      showPopupMessageBox: false,
      showPassword: false,
      popupIconName: "", // iconName = {"MINUS"} // "MINUS", "INFO", "CHECK"
      popupMessageTitle: "", // "Invalid Access"
      popupMessageText: "", // "Your account has not been activated. Speak to contact the supplier"
      popupButtonText: "", // "GO BACK"
      popupTypeId: 0,
      signing: false
    };

  }

  _onBlurr = () => {
    BackHandler.removeEventListener('hardwareBackPress',
      this._handleBackButtonClick);
  }

  _onFocus = () => {
    BackHandler.addEventListener('hardwareBackPress',
      this._handleBackButtonClick);
  }

  _handleBackButtonClick = () => {
    BackHandler.exitApp();
    return true;
  }

  onPressForgotPassword = () => {
    this.props.navigation.navigate("ForgotPasswordScreen");
  };

  onPressRegister = () => {
    this.props.navigation.navigate("RegisterScreen", { SCREEN_TYPE: "REGISTER_EMAIL", SOCAIL_ID: "", SOCAIL_NAME: "", EMAIL: "" }); // SCREEN_TYPE = REGISTER_EMAIL , REGISTER_FACEBOOK , REGISTER_GOOGLE

  };




  componentWillUnmount() {
    Linking.removeEventListener();
  }

  componentDidUpdate() {
    // console.log(JSON.stringify(this.props));
  }

  componentDidMount() {

    // this.registerForPushNotificationsAsync();

  }

  // async registerForPushNotificationsAsync() {

  //   if (firebase.apps.length === 0) {
  //     firebase.initializeApp({
  //       apiKey: "AIzaSyDXqonxP2xZNHPx8YXiG7ayhZzGjVFXlXI",
  //       authDomain: "stratprops-7d489.firebaseapp.com",
  //       projectId: "stratprops-7d489",
  //       storageBucket: "stratprops-7d489.appspot.com",
  //       messagingSenderId: "768442873006",
  //       appId: "1:768442873006:web:e108b38fd5de5e002ce93f",
  //       measurementId: "G-W076XZN9YY"
  //     });
  //   }

  //   let token;
  //   if (Constants.isDevice) {
  //     const { status: existingStatus } = await Notifications.getPermissionsAsync();
  //     let finalStatus = existingStatus;

  //     if (existingStatus !== 'granted') {
  //       const { status } = await Notifications.requestPermissionsAsync();
  //       finalStatus = status;
  //     }
  //     if (finalStatus !== 'granted') {
  //       alert('Failed to get push token for push notification!');
  //       return;
  //     }

  //     let experienceId = '@hunnainkhan/theinvestorsagency';

  //     return Notifications.getExpoPushTokenAsync({ experienceId }).then((token) => {

  //       this.setState({ token })

  //       return token
  //     }).catch(error => {
  //       alert(error);
  //     })

  //   } else {
  //     alert('Must use physical device for Push Notifications');
  //   }

  //   if (Platform.OS === 'android') {
  //     Notifications.setNotificationChannelAsync('default', {
  //       name: 'default',
  //       importance: Notifications.AndroidImportance.MAX,
  //       vibrationPattern: [0, 250, 250, 250],
  //       lightColor: '#FF231F7C',
  //     });
  //   }

  //   return token;
  // }

  onPressLogin = async () => {

    const {email, password, token} = this.state;

    !Boolean(email) ? this.setState({ emailRequired: true }) : null;
    !Boolean(password) ? this.setState({ passwordRequired: true }) : null;

    if (Boolean(email) && Boolean(password)) {

      this.setState({ signing: true });

      let result = await Register(email);

      if (result) {
        if(result.err) {
          showMessage({
            message: result.err,
            type: "info",
            color: '#FFF',
            backgroundColor: MAIN_COLOR.PRIMARY_COLOR
          });
          this.setState({ signing: false });
        }
        else {
          if (result.tasks?.length) {     
            
            // this.props.setClient({...result.tasks[0], email: email});
            // this.props.navigation.navigate("MainScreen");
            addToken(result.tasks[0], token.data);
            addPassword(result.tasks[0], email, password, this.props);

          }
          else {
            showMessage({
              message: "Email or Password is incorrect.",
              type: "info",
              color: '#FFF',
              backgroundColor: MAIN_COLOR.PRIMARY_COLOR
            });
            this.setState({ signing: false });
          }
        }
      }
     

    }
    else {
      showMessage({
        message: "Username or Password can't be empty.",
        type: "info",
        color: '#FFF',
        backgroundColor: MAIN_COLOR.PRIMARY_COLOR
      });
    }
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: MAIN_COLOR.BACKGROUND }} keyboardShouldPersistTaps={true} behavior={Platform.OS === "ios" ? "padding" : "padding"} keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}>
        <NavigationEvents
          onWillFocus={this._onFocus}
          onWillBlur={this._onBlurr}
        />
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false} contentContainerStyle={{ flex: 1 }}>
          <View style={styles.scrollViewContentView}>
            <View style={[styles.topView, { backgroundColor: '#ffffff' }]}>
              <Image
                style={styles.logoImage}
                resizeMode="contain"
                source={require("../../assets/header_logo.svg")}
              />
            </View>

            <Text style={{ fontFamily: MAIN_FONT.bold, color: '#272D37', fontSize: 18, marginLeft: '5%', marginBottom: 5 }}>
              Hey! Welcome to TIA
            </Text>

            <Text style={{ fontFamily: MAIN_FONT.light, color: '#272D37', fontSize: 14, marginLeft: '5%' }}>
              Please select a password to sign up!
            </Text>


            <View style={styles.centerView}>

              <Text style={{ fontFamily: MAIN_FONT.regular, color: '#272D37', marginBottom: 5 }}>
                Email 
                <Text style={{fontSize: 12}}>
                 {' '}(Your unique address. This can’t be edited)
                </Text>
              </Text>

              <View style={{ paddingHorizontal: 20, borderWidth: 0.5, borderRadius: 10, borderColor: '#ADB9C7', marginBottom: 20 }}>


                <TextInput
                  ref={(input) => { this.emailTextInput = input; }}
                  keyboardType="default"
                  autoCorrect={false}
                  style={{ height: 50, width: '100%', fontFamily: MAIN_FONT.regular }}
                  placeholderTextColor="#ADB9C7"
                  value={this.state.email}
                  underlineColorAndroid="transparent"
                  // onChangeText={email => this.setState({ email, emailRequired: false })}
                  placeholder="Enter You Username"
                  returnKeyType={"next"}
                  onSubmitEditing={() => { this.passwordTextInput.focus(); }}
                  blurOnSubmit={false}
                  autoCapitalize="none"
                //clearButtonMode="always"
                />
              </View>

              {/* Text password */}
              <Text style={{ fontFamily: MAIN_FONT.regular, color: '#272D37', marginBottom: 5 }}>
                Password
              </Text>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 20, borderWidth: 0.5, borderRadius: 10, borderColor: '#ADB9C7' }}>
                <TextInput
                  ref={(input) => { this.passwordTextInput = input; }}
                  secureTextEntry={!this.state.showPassword}
                  keyboardType="default"
                  autoCorrect={false}
                  focusable
                  autoFocus
                  style={{ height: 50, width: '90%', fontFamily: MAIN_FONT.regular, }}
                  placeholderTextColor="#ADB9C7"
                  value={this.state.password}
                  underlineColorAndroid="transparent"
                  onChangeText={password => this.setState({ password, passwordRequired: false })}
                  placeholder="Enter Your Password"
                  returnKeyType={"done"}
                  onSubmitEditing={this.onPressLogin}
                //clearButtonMode="always"
                />
                <TouchableOpacity onPress={() => this.setState({ showPassword: !this.state.showPassword })}>
                  <Image source={require('../../assets/eye.png')} style={{ height: RFValue(16), width: RFValue(16), resizeMode: 'contain' }} />
                </TouchableOpacity>
              </View>

              <TouchableOpacity
                style={[stylesCommonComponents.button, { height: 50 }]}
                onPress={this.onPressLogin}
                activeOpacity={0.8}

              >
                {
                  this.state.signing ?
                    <MaterialIndicator size={RFValue(16.75)} color={MAIN_COLOR.BACKGROUND_COLOR} />
                    :
                    <Text
                      style={{
                        fontFamily: MAIN_FONT.semi,
                        fontSize: RFValue(14),
                        color: "#FFFFFF",
                      }}
                      numberOfLines={1} >Register</Text>
                }
              </TouchableOpacity>
            </View>
          </View>
        </TouchableWithoutFeedback>
        {this.state.isLoading ? <LOADER /> : null}
      </SafeAreaView>
    );
  }
};


function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART
  }
}


function mapDispatchToProps(dispatch) {


  return bindActionCreators(
     {
       setClient
     },
     dispatch,
   );
 }

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreen);