export const MAIN_COLOR = {
  PRIMARY_COLOR: "#FABF01", //#C4014B
  BACKGROUND_COLOR: "#FFFFFF",
  yellow: "#fabf00",
  green: "#00b156",
  blue: "rgb(76, 115, 255)",
  darkGray: "rgb(142, 188, 192)",
  text_light: "rgb(120, 110, 100)",
  logo_blue: "#fabf00",
  blueBackground: "#00043F",
  darkGray: "#1e1e1e",
  yellowBackground: "#fabf00",
  BACKGROUND_COLOR_DARK: "#292F33",
  TRANSPARENT_BACKGROUND: "#00000099",
  BORDER_COLOR: "#D3D3D3",

  BACKGROUND: "rgb(243, 244, 249)",
  G100: "#EEF2F6",
  G200: "#E8F1F2",
  G600: "#7D888F",
  G700: "#66737F",
  G800: "#36414C",
};

export const FLASH_MESSAGE = {
  BACKGROUND_COLOR: "#55ACEE",
  TEXT_COLOR: "#FFFFFF",
};

export const TAB_BAR_COLOR = {
  BACKGROUND_COLOR: "#FFF",
  ACTIVE_COLOR: MAIN_COLOR.PRIMARY_COLOR,
  INACTIVE_COLOR: "#200E32",
};

export const SAFE_AREA_COLOR = {
  BACKGROUND_COLOR: "#FFFFFF",
  TRANSPARENT_BACKGROUND: "#00000099",
};
