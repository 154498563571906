import React from 'react';
import {
  Text, TouchableOpacity, View
} from 'react-native';
import { connect } from 'react-redux';

import styles from './style';

import { MAIN_COLOR } from '../../Colors';
import ROUNDED_ICON from "../../IconComponent/RoundedIcon";

//Component 

class App extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      list: props.LIST
    };
  }

  render() {
    const { TITLE, DESCRIPTION, BUTTON_LABEL } = this.props;
    return (
      <View style={styles.container}>

        <View style={styles.innerContainer}>

          <View style={styles.topView}>
            <Text style={styles.title} >{TITLE}</Text>
          </View>

          <View style={styles.contentView}>
            <Text style={styles.descText} >{DESCRIPTION}</Text>
            <TouchableOpacity style={styles.buttonView} onPress={() => this.props.onPressClose && this.props.onPressClose(1)}>
              <Text style={styles.title} >{BUTTON_LABEL}</Text>
            </TouchableOpacity>
          </View>

          <TouchableOpacity style={styles.closeButtonView} onPress={() => this.props.onPressClose && this.props.onPressClose(0)}>
            <ROUNDED_ICON COLOR={MAIN_COLOR.BACKGROUND_COLOR_DARK} IMAGE_PATH={require("../../../Image/button/close/la_close.png")} />
          </TouchableOpacity>

        </View>

      </View>
    );
  }
};


function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART
  }
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);