import React from "react";
import { Text, View } from "react-native";
import styles from "./style";
import { MAIN_COLOR } from "../../../../Utility/Colors";
import { useMediaQuery } from "react-responsive";

const CardValue = ({ value, isHightLight, label, width }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <View
      style={[
        styles.container,
        { ...(isTabletOrMobile ? { width: "max-content", minWidth: "unset !important" } : { width }) },
      ]}
    >
      <View style={styles.borderLeft}></View>
      <View style={styles.content}>
        <Text style={[styles.label, isTabletOrMobile && { fontSize: 10 }]}>
          {label}
        </Text>
        <Text
          style={[
            styles.value,
            isHightLight && { color: MAIN_COLOR.PRIMARY_COLOR },
            isTabletOrMobile && { fontSize: 16 },
          ]}
        >
          {value ?? "N/A"}
        </Text>
      </View>
    </View>
  );
};

export default CardValue;
