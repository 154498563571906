import React from "react";
import { Image, StyleSheet, Text, TextInput, View } from "react-native";
import { MAIN_COLOR } from "../Utility/Colors";
import { MAIN_FONT } from "../Utility/Fonts/font";

const Input = ({
  label,
  required,
  editable,
  prefixIcon,
  suffixIcon,
  styleLabel,
  styleInput,
  styleSuffix,
  stylePrefix,
  styleWrapInput,
  styleContainer,
  size = "medium",
  borderColor,
  ...props
}) => {
  const height = size === "small" ? 30 : size === "medium" ? 42 : 50;

  return (
    <View style={[styles.container, styleContainer]}>
      {label && (
        <Text style={[styles.label, styleLabel]}>
          {label} {required && <Text style={{ color: "#FD4438" }}>*</Text>}
        </Text>
      )}
      <View style={[styles.inputWrap, styleWrapInput]}>
        {prefixIcon && (
          <View style={[styles.prefix, stylePrefix]}>
            <Image
              resizeMode="contain"
              style={{ height: 16, width: 16, margin: "auto" }}
              source={prefixIcon}
            />
          </View>
        )}
        <TextInput
          style={[
            styles.input,
            prefixIcon && { marginLeft: 32 },
            { height },
            suffixIcon && { paddingRight: 36 },
            styleInput,
          ]}
          editable={editable}
          {...props}
        />
        {suffixIcon && (
          <View style={styles.suffix}>
            <Image
              resizeMode="contain"
              style={[
                {
                  height: 20,
                  width: 20,
                  margin: "auto",
                },
                styleSuffix,
              ]}
              source={suffixIcon}
            />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    padding: 0,
    flex: 1,
    maxHeight: 71,
  },
  label: {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    lineHeight: 20,
    color: "#353030",
  },
  inputWrap: {
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "rgba(173, 185, 199, 0.3)",
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  prefix: {
    position: "absolute",
    backgroundColor: "#F0F1F8",
    height: "100%",
    width: 32,
    borderRightWidth: 1,
    borderRightColor: "rgba(173, 185, 199, 0.3)",
  },
  suffix: {
    position: "absolute",
    right: 14,
  },
  input: {
    paddingVertical: 8,
    paddingHorizontal: 14,
    fontSize: 16,
    fontWeight: 400,
    fontFamily: MAIN_FONT.regular,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    width: "100%",
  },
});

export default Input;
