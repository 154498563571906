import moment from "moment";
import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { Dimensions, Image, Text, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import { MAIN_COLOR } from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";
import {
  externalTooltipHandler,
  formatDollar,
  formatDollarUnit,
} from "../../../../Utility/common";
import styles from "./style";
import { isEmpty } from "lodash";

const RetirementOverview = ({ result, lastRetirementOverviewDraff }) => {
  const { width } = Dimensions.get("window");
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        callbacks: {
          label: (value) => {
            return formatDollar(value.raw);
          },
        },
        external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        position: "bottom",
        font: {
          family: MAIN_FONT.regular,
          size: 12,
        },
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          callback: (value, index) => {
            const firstYear = labels[0];
            if (!index) return 1;
            return labels[index] - firstYear + 1;
          },
        },
      },
      y: {
        border: { dash: [4, 4] },
        grid: {
          tickColor: "#E8E6E6",
          drawTicks: false,
        },
        stacked: true,
        beginAtZero: true,
        position: "left",
        ticks: {
          font: {
            family: MAIN_FONT.regular,
            size: 12,
          },
          padding: 10,
          callback: (value) => `${formatDollarUnit(value)}`,
        },
      },
      y1: {
        grid: {
          display: false,
        },
        stacked: true,
        position: "right",
        ticks: {
          font: {
            family: MAIN_FONT.regular,
            size: 12,
          },
          callback: (value) => `${formatDollar(value)}K`,
        },
      },
    },
  };

  const filteredData = useMemo(() => {
    const firstYear = moment(result?.[0]?.date).year();

    let modifiedResult = result?.filter((item, index) => {
      const month = moment(item.date).month();
      const year = moment(item.date).year();

      return (
        (month === 11 && (year - firstYear) % 3 === 0) ||
        index === result.length - 1
      );
    });

    const lastItem = lastRetirementOverviewDraff;
    const lastItemIndex = modifiedResult?.length - 1;

    if (!isEmpty(lastItem) && lastItemIndex !== -1) {
      modifiedResult[lastItemIndex] = {
        ...modifiedResult[lastItemIndex],
        grossCFValue: lastItem.grossCFValue,
        equityValue: lastItem.equityValue,
        value: lastItem.value,
      };
    }

    return modifiedResult;
  }, [result, lastRetirementOverviewDraff]);

  const labels = filteredData?.map((item) => moment(item?.date).format("YYYY"));

  const data = {
    labels,
    datasets: [
      {
        data: filteredData?.map((one) => one.grossCFValue),
        backgroundColor: "#33B21E",
        barPercentage: 0.6,
        maxBarThickness: 25,
      },
      {
        data: filteredData?.map((one) => one.equityValue),
        backgroundColor: "#ADB9C7",
        barPercentage: 0.6,
        maxBarThickness: 25,
      },
      {
        data: filteredData?.map((one) => one.value),
        backgroundColor: MAIN_COLOR.yellow,
        borderRadius: 18,
        barPercentage: 0.6,
        maxBarThickness: 25,
      },
    ],
  };

  return (
    <View
      style={
        [styles.container, width < 1025 && { height: 276, width: 345 }]
        // isTabletOrMobile ? { flex: 1 } : { height: 344 },
      }
    >
      <View style={styles.header}>
        <Text style={styles.title}>Overview</Text>
      </View>
      <View style={styles.noteItem}>
        <Text style={styles.unitLabel}>Value</Text>
        <Text style={styles.unitLabel}>Cash Flow</Text>
      </View>
      <View style={{ flexGrow: 1, flex: 1, position: "relative" }}>
        <View style={{ flex: 1 }}>
          <Bar data={data} options={options} />
        </View>
        <Text style={[styles.unitLabel, { position: "absolute", bottom: 4 }]}>
          Years
        </Text>
      </View>
      <View style={styles.notes}>
        <View style={styles.noteItem}>
          <View style={[styles.round]} />
          <Text style={styles.textLabel}>Value</Text>
        </View>
        <View style={styles.noteItem}>
          <View style={[styles.round, { backgroundColor: "#ADB9C7" }]} />
          <Text style={styles.textLabel}>Equily</Text>
        </View>
        <View style={styles.noteItem}>
          <View style={[styles.round, { backgroundColor: "#33B21E" }]} />
          <Text style={styles.textLabel}>Cash Flow</Text>
        </View>
      </View>
    </View>
  );
};

export default React.memo(RetirementOverview);
