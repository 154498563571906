import React from "react";
import { Image, Text, TouchableOpacity, StyleSheet, View } from "react-native";

import { MAIN_FONT } from "../Utility/Fonts/font";
import { MAIN_COLOR } from "../Utility/Colors";

const Button = ({
  leftIcon,
  rightIcon,
  text,
  primary,
  disabled,
  onPress,
  style,
  textStyle,
  iconStyle,
  ...passProps
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      style={[
        styles.container,
        style,
        primary && styles.primary,
        disabled && styles.disabled,
      ]}
      {...passProps}
    >
      <View style={styles.inner}>
        {leftIcon && (
          <Image
            resizeMode="contain"
            style={[styles.icon, iconStyle]}
            source={leftIcon}
          />
        )}
        <Text style={[styles.title, textStyle]}>{text}</Text>
        {rightIcon && (
          <Image
            resizeMode="contain"
            style={[styles.icon, iconStyle]}
            source={rightIcon}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

export default Button;

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    borderWidth: 1,
    paddingHorizontal: 24,
    paddingVertical: 8,
  },
  inner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },
  title: {
    fontFamily: MAIN_FONT.regular,
    fontWeight: 500,
    fontSize: 16,
    color: "#200E32",
  },
  icon: {
    width: 26,
    height: 26,
  },
  primary: {
    backgroundColor: MAIN_COLOR.yellowBackground,
    borderColor: MAIN_COLOR.yellowBackground,
  },
  disabled: {
    opacity: 0.6,
  },
});
