import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Toast from "react-native-toast-message";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { View, Text, ActivityIndicator, ScrollView } from "react-native";

import styles from "./styles";
import { MAIN_COLOR } from "../../../Utility/Colors";
import { styles as styleSelect } from "../../../components/Select";
import { getDetailProperty, saveScenario, getDetailClient } from "../../../Clickup/api";
import {
  OPTIONS_LOAN_STRUCTURE,
  filterDataDetailProperty,
} from "../../../Utility/portfolio-roadmap";
import {setClient} from "../../../Reducer/tia"

import OverviewProperty from "./InfoOverview";
import Modal from "../../../components/Modal";
import Switch from "../../../components/Switch";
import Select from "../../../components/Select";
import TextInput from "../../../components/TextInput";
import DatePicker from "../../../components/DatePicker";
import NumberInput from "../../../components/InputNumber";
import moment from "moment";

function DetailProperty({ propertyId, open, onHide, goBack, setIsLoading }) {
  const { client } = useSelector((state) => state.TIA);
  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useState(null);

  const dispatch = useDispatch()

  const schemaForm = Yup.object().shape({
    settlement_date: Yup.string().required(""),
    deposit_percent: Yup.number().required(),
    property_management_percent: Yup.number().required(),
    loan_structure: Yup.number().required(),
    council_rates_annually: Yup.string().required(),
    maintenance_annually: Yup.string().required(),
    bank_fees_annually: Yup.number().required(),
    purchase_price: Yup.number().required(),
    current_rent: Yup.number().required(),
    interest_rate: Yup.number().required(),
    insurance_annually: Yup.string().required(),
    utilities_annually: Yup.string().required(),
    debt_remaining: Yup.number().required(),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      settlement_date: "",
      deposit_percent: "",
      property_management_percent: "",
      loan_structure: "",
      council_rates_annually: "",
      maintenance_annually: "",
      bank_fees_annually: "",
      purchase_price: "",
      current_rent: "",
      interest_rate: "",
      purchased_SMSF: "",
      insurance_annually: "",
      utilities_annually: "",
      debt_remaining: "",
    },
    resolver: yupResolver(schemaForm),
  });

  const handleSave = async (formValues) => {
    try {
      const [street, city, last] = property.address.fullAddress.split(", ");
      const [state, zip] = last.split(" ");

      const data = {
        client_id: client.id,
        ...formValues,
        ...property,
        settlement_date: moment(formValues.settlement_date).unix(),
        address: {
          ...property.address,
          streetAddress: street,
          suburb: city,
          state,
          postCode: zip,
        },
      };
      await saveScenario(data);
      goBack();
      onHide();
      setIsLoading(true)
      await new Promise((resovle, reject) => {
        setTimeout(async() => {
          const _client = await getDetailClient(client.id)
          dispatch(setClient({..._client, email:client.email}))
          resovle()
        },4000)
      })
      setIsLoading(false)
      Toast.show({
        type: "success",
        text1: "Saved change!",
        position: "top",
        visibilityTime: 3000,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        setLoading(true);
        const result = await getDetailProperty(propertyId);
        setLoading(false);
        const dataValid = filterDataDetailProperty(result.data);
        reset({
          settlement_date: dataValid.saleDate,
          purchase_price: dataValid.listingPrice,
          loan_structure: 1,
          purchased_SMSF: 0,
        });
        setProperty(dataValid);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchDetail();
  }, []);

  return (
    <Modal
      open={open}
      btnSave="Save"
      btnCancel="Cancel"
      disableSave={!isValid}
      styleBtnClose={styles.styleBtnClose}
      title={<OverviewProperty property={property} />}
      onSave={handleSubmit(handleSave)}
      onClose={onHide}
    >
      <View
        style={{
          backgroundColor: "#ADB9C74D",
          height: 1,
          width: "unset",
          marginBottom: 20,
          marginHorizontal: -19,
        }}
      />
      {loading ? (
        <ActivityIndicator size="large" color={MAIN_COLOR.yellow} />
      ) : !!property ? (
        <ScrollView>
          <View style={{ display: "flex", flexDirection: "row", gap: 12 }}>
            <View style={{ display: "flex", gap: 18, flex: 1 }}>
              <Controller
                control={control}
                name="settlement_date"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    required
                    label="Settlement Date"
                    value={value}
                    onChange={onChange}
                    suffixIcon={require("../../../assets/calendar.png")}
                  />
                )}
              />

              <Controller
                control={control}
                name="deposit_percent"
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    required
                    value={value}
                    hasDelimiter
                    prefixIcon={false}
                    label="Deposit Percent"
                    onChange={onChange}
                    suffix="%"
                  />
                )}
              />
              <Controller
                name="property_management_percent"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    required
                    value={value}
                    hasDelimiter
                    prefixIcon={false}
                    label="Property Management Percent"
                    onChange={onChange}
                    suffix="%"
                  />
                )}
              />
              <Controller
                name="loan_structure"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    options={OPTIONS_LOAN_STRUCTURE}
                    label="Loan Structure"
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="council_rates_annually"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    required
                    value={value}
                    label="Council Rates Annually"
                    onChangeText={onChange}
                  />
                )}
              />
              <Controller
                name="maintenance_annually"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    required
                    value={value}
                    label="Maintenace Annually"
                    onChangeText={onChange}
                  />
                )}
              />
              <Controller
                name="bank_fees_annually"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    required
                    value={value}
                    thousandSeparator
                    label="Bank Fees Annually"
                    onChange={onChange}
                  />
                )}
              />
            </View>
            <View style={{ display: "flex", gap: 18, flex: 1 }}>
              <Controller
                name="purchase_price"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    required
                    value={value}
                    thousandSeparator
                    label="Purchase Price"
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="current_rent"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    required
                    value={value}
                    thousandSeparator
                    onChange={onChange}
                    label="Current Rent"
                  />
                )}
              />
              <Controller
                name="interest_rate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    required
                    value={value}
                    hasDelimiter
                    prefixIcon={false}
                    label="Interest Rate"
                    onChange={onChange}
                    suffix="%"
                  />
                )}
              />
              <Controller
                control={control}
                name="purchased_SMSF"
                render={({ field: { onChange, value } }) => (
                  <View style={styleSelect.container}>
                    <Text style={styleSelect.label}>
                      Was this purchased in an SMSF?
                    </Text>
                    <Switch
                      styleContainer={{ width: "100%", height: 42 }}
                      selectionMode={value}
                      label1="No"
                      label2="Yes"
                      option1={0}
                      option2={1}
                      onSelectSwitch={onChange}
                      selectionColor={MAIN_COLOR.yellow}
                    />
                  </View>
                )}
              />
              <Controller
                name="insurance_annually"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    required
                    value={value}
                    label="Insurance Annually"
                    onChangeText={onChange}
                  />
                )}
              />
              <Controller
                name="utilities_annually"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    required
                    value={value}
                    label="Utilities Annually"
                    onChangeText={onChange}
                  />
                )}
              />

              <Controller
                name="debt_remaining"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    required
                    thousandSeparator
                    value={value}
                    label="Debt Remaining"
                    onChange={onChange}
                  />
                )}
              />
            </View>
          </View>
        </ScrollView>
      ) : (
        <Text style={styles.notData}>No Data</Text>
      )}
      <View
        style={{
          backgroundColor: "#ADB9C74D",
          height: 1,
          width: "unset",
          marginTop: 20,
          marginHorizontal: -19,
        }}
      />
    </Modal>
  );
}

export default DetailProperty;
