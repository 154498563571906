import React from 'react';
import {
   StyleSheet,
} from 'react-native';

import { widthPercentageToDP as wp } from 'react-native-responsive-screen';

import { MAIN_COLOR } from "../../Utility/Colors"

var styles;

export default styles = StyleSheet.create({

   container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: MAIN_COLOR.BACKGROUND_COLOR
   },

   logo: {
      width: wp("60%"),
      height: wp("34%"),
      // tintColor: MAIN_COLOR.PRIMARY_COLOR
   }

});


