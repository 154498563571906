import React, { useState } from "react";
import { PropagateLoader } from "react-spinners";
import { Controller, useForm } from "react-hook-form";
import {
  View,
  Text,
  Image,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
} from "react-native";

import { MAIN_COLOR } from "../../Utility/Colors";
import { searchProperty } from "../../Clickup/api";
import { MAIN_FONT } from "../../Utility/Fonts/font";

import Button from "../../components/Button";
import DetailProperty from "./DetailProperty";
import TextInput from "../../components/TextInput";

function SearchProperty({ goBack, setIsLoading }) {
  const [loading, setLoading] = useState(false);
  const [resultSearch, setResultSearch] = useState([]);
  const [activeProperty, setActiveProperty] = useState(null);
  const [valueSearchFinal, setValueSearchFinal] = useState("");
  const [openModalDetail, setOpenModalDetail] = useState(false);

  const {
    reset,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: { valueSearch: "" },
  });

  const handleClearResultSearch = () => {
    setValueSearchFinal("");
    setResultSearch([]);
    reset();
  };

  const handleSelectProperty = async (item) => {
    setActiveProperty(item.propertyId);
    setOpenModalDetail(true);
  };

  const handleHideModalDetail = () => {
    setOpenModalDetail(false);
    setActiveProperty(null);
  };

  const handleSearch = async ({ valueSearch }) => {
    try {
      setLoading(true);
      const results = await searchProperty(valueSearch);
      setResultSearch(results.data);
      setValueSearchFinal(valueSearch);
    } catch (error) {
      setResultSearch([]);
      setValueSearchFinal("");
      return error.response;
    } finally {
      setLoading(false);
    }
  };

  return (
    <View>
      <View style={styles.container}>
        <TouchableOpacity
          onPress={goBack}
          style={{
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 16,
            width: "max-content",
          }}
        >
          <View
            style={[
              styles.flexRowBetween,
              {
                borderRadius: 15,
                width: 48,
                height: 48,
                backgroundColor: "#F5F6FA",
                justifyContent: "center",
              },
            ]}
          >
            <Image
              source={require("../../assets/arrow_left.png")}
              style={{ width: 7, height: 12 }}
            />
          </View>
          <Text
            style={{ fontSize: 20, fontWeight: 600, color: MAIN_COLOR.G800 }}
          >
            Add Property
          </Text>
        </TouchableOpacity>
        <View style={styles.inner}>
          <View style={styles.boxSearch}>
            <Controller
              name="valueSearch"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  value={value}
                  placeholder="Search Property"
                  styleInput={styles.inputSearch}
                  stylePrefix={styles.prefixIconSearch}
                  suffixIcon={require("../../assets/arrow_down_small.png")}
                  prefixIcon={require("../../assets/portfolio-roadmap/search-normal.png")}
                  onChangeText={onChange}
                />
              )}
            />
            <Button
              primary
              text="Search"
              disabled={!isDirty}
              style={styles.btnSearch}
              textStyle={styles.textBtnSearch}
              onPress={handleSubmit(handleSearch)}
            />
          </View>

          {valueSearchFinal && (
            <View style={styles.boxTextResult}>
              <Text style={styles.textValueResult}>
                Search results of "{valueSearchFinal}"
              </Text>
              <TouchableOpacity onPress={handleClearResultSearch}>
                <Text style={styles.btnClearResult}>Clear All</Text>
              </TouchableOpacity>
            </View>
          )}
          <View
            style={{
              borderColor: "#ADB9C74D",
              borderWidth: 1,
              marginVertical: 25,
            }}
          />
          {!loading &&
            valueSearchFinal &&
            (!!resultSearch.length ? (
              <ScrollView>
                {resultSearch.map((item) => {
                  return (
                    <Button
                      key={item.propertyId}
                      text={item.address.fullAddress}
                      textStyle={styles.textItemProperty}
                      iconStyle={{ width: 20, height: 20 }}
                      leftIcon={require("../../assets/location.png")}
                      style={[
                        styles.itemProperty,
                        activeProperty === item.propertyId &&
                          styles.itemPropertyActive,
                      ]}
                      onPress={() => handleSelectProperty(item)}
                    />
                  );
                })}
              </ScrollView>
            ) : (
              <Text style={{ textAlign: "center", fontSize: 20 }}>
                No Results Found
              </Text>
            ))}

          {openModalDetail && (
            <DetailProperty
              setIsLoading={setIsLoading}
              goBack={goBack}
              open={openModalDetail}
              propertyId={activeProperty}
              onHide={handleHideModalDetail}
            />
          )}
        </View>
      </View>
      <View style={{ height: 300 }}>
        {loading && (
          <PropagateLoader
            color={MAIN_COLOR.yellowBackground}
            style={{ margin: "auto" }}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "relative",
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    borderRadius: 15,
    padding: 24,
    marginHorizontal: 36,
    marginVertical: 22,
  },
  inner: {
    borderRadius: 15,
    padding: 28,
    width: "70%",
    shadowColor: "#00000014",
    shadowOffset: { width: 1, height: 1 },
    shadowRadius: 15,
    marginHorizontal: "auto",
    marginTop: 16,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
  },
  boxSearch: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 41,
    gap: 16,
  },
  textBtnSearch: {
    flex: 1,
    alignItems: "center",
    fontWeight: 600,
    fontFamily: MAIN_FONT.regular,
    height: "100%",
  },
  inputSearch: {
    borderRadius: 5,
    outline: "unset",
    marginLeft: 0,
    paddingRight: 36,
    paddingLeft: 48,
    height: "100%",
  },
  prefixIconSearch: {
    borderRightWidth: 0,
    borderRightColor: "unset",
    backgroundColor: "transparent",
    paddingLeft: 16,
    width: 20,
    height: 20,
  },
  btnSearch: {
    width: 183,
    borderRadius: 8,
    textAlign: "center",
  },
  boxTextResult: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 25,
  },
  textValueResult: {
    fontSize: 16,
    color: "#66737F",
    fontWeight: 600,
    fontFamily: MAIN_FONT.regular,
  },
  btnClearResult: {
    color: "#FD4438",
    fontWeight: 400,
    fontSize: 16,
    fontFamily: MAIN_FONT.regular,
  },
  itemProperty: {
    display: "flex",
    flexDirection: "row",
    gap: 18,
    borderWidth: 0,
    paddingHorizontal: 18,
    paddingVertical: 14,
    borderBottomWidth: 1,
    borderBottomColor: "#ADB9C74D",
    borderRadius: 0,
    height: 52,
  },
  textItemProperty: {
    flex: 1,
    fontSize: 16,
    color: MAIN_COLOR.G800,
    lineHeight: 24,
    fontWeight: 600,
  },
  itemPropertyActive: {
    borderWidth: 1,
    borderColor: MAIN_COLOR.yellowBackground,
    borderBottomColor: MAIN_COLOR.yellowBackground,
    borderRadius: 5,
  },
  flexRowBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btnGoBack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 0,
    width: 200,
    height: 48,
  },
});

export default SearchProperty;
