import { StyleSheet } from "react-native";
import { MAIN_COLOR } from "../../../Utility/Colors";
import { MAIN_FONT } from "../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    padding: 20,
    paddingHorizontal: 20,
    borderWidth: 1,
    borderRadius: 12,
    marginHorizontal: 10,
    borderColor: `rgba(189, 197, 207, 0.3)`,
    shadowColor: `rgba(189, 197, 207, 0.3)`,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowRadius: 12,
    shadowOpacity: 1,
    height: 394,
    width: 448,
    overflow: "hidden",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderRadius: 8,
    width: "100%",
    backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
    marginBottom: 26,
    height: 58
  },
  sideRight: {
    alignItems: "flex-end",
  },
  sideLeft: {
    alignItems: "flex-start",
  },
  chart: {
    width: 300,
    height: 380,
  },
  title: {
    fontSize: 18,
    fontFamily: MAIN_FONT.semi,
    textAlign: "center",
    color: "#1C1C1C",
  },
  subTitle: {
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    textAlign: "center",
    color: "#585757",
  },
  subTitleCard: {
    fontSize: 12,
    color: "#2B2B2B",
  },
  center: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 4,
  },
  icon: {
    width: 18,
    height: 18,
    marginRight: 4,
  },
});
