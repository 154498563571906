import NetInfo from "@react-native-community/netinfo";
import * as Notifications from 'expo-notifications';
import React from 'react';
import { Linking, Platform, View, Dimensions } from 'react-native';
import {
  SkypeIndicator
} from 'react-native-indicators';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//Action 
import { MAIN_COLOR } from '../../Utility/Colors';
import { getDataFromAsyncStorage } from "../../Action/asyncCtrl";
import { user as setUser, setUserInfo, setUserToken, setAccessToken } from '../../Reducer/user';
import { showMessage } from "react-native-flash-message";
import { APP_VERSION } from '../../Utility/AppInfo';
import { setTemplate } from '../../Reducer/tia';
import { getTemplate } from '../../Clickup/api';
import POPUP_MESSAGE from "../../Utility/ComponentCard/PopupMessage";
import fields from '../../Utility/Fields';
import style from './style';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      supplierSelector: false,
      popupMessageText: "",
      chat: false
    };
  }

  componentWillUnmount() {
    this.dimensionsSubscription?.remove();
  }

  componentDidMount() {
    this.dimensionsSubscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        alert(window.width)
        this.setState({ window, screen });
      }
    );

    Linking.getInitialURL()
      .then((url) => {

        if (url.includes("https://theinvestorsagency.com.au/client_portal/?resetPassword?email=")) {

          if (this.props.TIA.client.email) {
            showMessage({
              message: "Please logout to initiate a password reset.",
              type: "info",
              color: '#FFF',
              backgroundColor: MAIN_COLOR.PRIMARY_COLOR
            });
          }
         


        };
      })
      .catch(console.warn);

    let notification = Notifications.addNotificationResponseReceivedListener(() => {
      this.setState({ chat: true });
    });

    this.handleNetworkChange();

    getTemplate().then(result => {

      if (result) {
        if (!result.err) {

          let template = {};

          let tasks = result.tasks.reverse();

          tasks.map((item, index) => {

            let item2 = tasks[index + 1];

            let arr = {};

            item.custom_fields.filter((field, i) => {
              if (field.name === 'Link Video' || field.name === 'Task Description'
                // || field.name === '2 minute update'
                || field.name === fields.appStageName) {
                arr[field.name] = field.value;
              }
            });

            if (index < tasks.length - 1) {

              item2.custom_fields.filter((field, i) => {



                if (field.name === 'Progress' || field.name == 'Booking URL' || field.name === '2 minute update' || field.name === 'Link' || field.name === 'Files' || field.name === 'Videos' || field.name === 'Facebook Review' || field.name === 'Google Review' || field.name === 'Review') {
                  arr[field.name] = field.value;
                }
              });
            }

            template = { ...template, [item.name.toUpperCase()]: arr }

          });

          this.props.setTemplate(template);
        }
        else {
          showMessage({
            message: result.err,
            type: "info",
            color: '#FFF',
            backgroundColor: MAIN_COLOR.PRIMARY_COLOR
          });
        }
      }
    }).catch((error) => {
      showMessage({
        message: "Unable to connect please try again later.",
        type: "info",
        color: '#FFF',
        backgroundColor: MAIN_COLOR.PRIMARY_COLOR
      });
    })
  }

  async checkUserDeviceInfo() {
    if (this.props.TIA.client.email) {
      this.props.navigation.navigate(this.state.chat ? "Notifications" : "MainScreen");
    }
    else {
      this.props.navigation.navigate("LoginScreen");
    }
  }

  handleNetworkChange = () => {
    NetInfo.fetch().then(state => {
      if (state.isConnected) {
        this.checkUserDeviceInfo();
      }
      else {
        this.setState({ isNetworkErrorView: true, isTechnicalError: false })
      }

    });
  };

  onPressTryAgain = () => {
    this.checkUserDeviceInfo()
  }


  onPressClosePopup = () => {
    this.setState({ showPopupUpdate: !this.state.showPopupUpdate })
    this.checkUserDeviceInfo();
  }

  loadInBrowser = (url) => {
    console.log("URL :", url)
    Linking.openURL(url).catch(err => alert("Requested URL is not valid"));
  };


  render() {
    return (

      <View style={style.container}>
        {/* <Image
          style={style.logo}
          resizeMode="contain"
          source={require("../../Image/app_logo/logo_icon/logo.png")}
        /> */}
        <SkypeIndicator size={48} color={MAIN_COLOR.yellowBackground} />
        {/* {this.state.isNetworkErrorView ? <POPUP_MESSAGE
          TITLE="Network Issue"
          BUTTON_LABEL="Close"
          DESCRIPTION="Please make sure you have proper internet connection."
          onPressClose={this.onPressTryAgain}
        /> : null} */}
      </View>
      //  </SafeAreaView>
    );
  }
};


function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    TIA: state.TIA
  }
}


function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      setUserToken,
      setUserInfo,
      setAccessToken,
      setTemplate
    },
    dispatch,
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(App);