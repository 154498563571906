import Slider from "@react-native-community/slider";
import Tippy from "@tippyjs/react";
import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import "tippy.js/dist/tippy.css";
import { MAIN_COLOR } from "../Utility/Colors";
import { MAIN_FONT } from "../Utility/Fonts/font";
import { formatDollar, formatDollarUnit } from "../Utility/common";

const SliderBase = ({
  styleContainer,
  styleSlider,
  styleLimit,
  styleLabel,
  maximumValue,
  orientation,
  minimumValue,
  inverted,
  label,
  color,
  value,
  onChange,
}) => {
  function handleTouchMove(e) {
    const touch = e.touches[0];
    if (touch.clientX) {
      e.preventDefault();
    }
  }

  function disableTouchScroll() {
    window.addEventListener("touchmove", handleTouchMove, { passive: false });
  }

  function enableScroll() {
    window.removeEventListener("touchmove", handleTouchMove);
  }

  return (
    <View style={[styles.container, styleContainer]}>
      <View style={[styles.boxLabel, styleLabel]}>
        <Text style={styles.label}>{label}</Text>
        <Text
          style={[styles.tippyBox, { backgroundColor: color, color: "#FFF" }]}
        >
          {formatDollar(Math.round(value))}
        </Text>
      </View>
      <View
        style={[
          orientation === "horizontal"
            ? styles.horizontalContent
            : styles.verticalContent,
        ]}
      >
        <View style={{ width: "100%" }}>
          <Slider
            orientation={orientation || "vertical"}
            value={value}
            style={[{ position: "relative", height: 25 }, styleSlider]}
            maximumTrackTintColor="#E7ECF2"
            minimumTrackTintColor={color}
            minimumValue={minimumValue}
            maximumValue={maximumValue}
            trackStyle={styles.trackStyle}
            thumbStyle={[styles.thumbStyle, { backgroundColor: color }]}
            onSlidingComplete={(value) => {
              onChange(value);
              enableScroll();
            }}
            inverted={inverted}
            onTouchStart={disableTouchScroll}
          />
          <View
            style={[
              {
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              },
              styleLimit,
            ]}
          >
            <Text style={styles.labelNet}>
              {formatDollarUnit(minimumValue)}
            </Text>
            <Text style={styles.labelNet}>
              {formatDollarUnit(maximumValue)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  boxLabel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: 4,
  },
  tippy: {
    backgroundColor: "#fff",
  },
  tippyBox: {
    paddingHorizontal: 10,
    paddingVertical: 4,
    borderRadius: 5,
    fontSize: 12,
    fontWeight: 600,
    fontFamily: MAIN_FONT.regular,
  },
  label: {
    fontSize: 10,
    fontWeight: 500,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.regular,
  },
  labelNet: {
    fontSize: 10,
    fontWeight: 400,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.regular,
  },
  verticalContent: {
    paddingTop: 12,
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
  },
  horizontalContent: {
    width: "100%",
    flex: 1,
  },
  trackStyle: {
    borderWidth: 1,
    borderColor: "rgba(173, 185, 199, 0.3)",
    borderRadius: 14,
    backgroundColor: "#F5F6FA",
    width: "100%",
  },
  thumbStyle: {
    height: 14,
    width: 14,
    paddingHorizontal: 6,
    borderRadius: "50%",
    borderWidth: 1,
    borderColor: MAIN_COLOR.BACKGROUND_COLOR,
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 1,
    shadowColor: MAIN_COLOR.G700,
  },
});

export default SliderBase;
