// open -n -a /Applications/Google\ Chrome.app/Contents/MacOS/Google\ Chrome --args --user-data-dir="/tmp/chrome_dev_test" --disable-web-security
// "homepage": "/client_portal",
import React from 'react';
import {
    BackHandler,
    Dimensions,
    Image,
    Keyboard,
    Linking,
    Platform,
    SafeAreaView,
    Text,
    TextInput,
    TouchableOpacity, View
} from 'react-native';
import { showMessage } from "react-native-flash-message";
// import dynamicLinks from '@react-native-firebase/dynamic-links';
import { MaterialIndicator } from 'react-native-indicators';
import { bindActionCreators } from 'redux';
// import { FirebaseRecaptchaVerifierModal, FirebaseRecaptchaBanner } from 'expo-firebase-recaptcha';
import ReCAPTCHA from "react-google-recaptcha";
import { RFValue } from "react-native-responsive-fontsize";
import { connect } from 'react-redux';
import { Login } from '../../Clickup/api';
import { reset, setClient } from '../../Reducer/tia';
import { MAIN_COLOR } from "../../Utility/Colors";
import LOADER from "../../Utility/ComponentCard/Loader";
//Component
import stylesCommonComponents from "../../Utility/ComponentStyle/style";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import styles from "./style";

const { width, height } = Dimensions.get("window");

class ForgetPasswordScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            token: { data: "" },
            showPopupMessageBox: false,
            showPassword: false,
            popupIconName: "", // iconName = {"MINUS"} // "MINUS", "INFO", "CHECK"
            popupMessageTitle: "", // "Invalid Access"
            popupMessageText: "", // "Your account has not been activated. Speak to contact the supplier"
            popupButtonText: "", // "GO BACK"
            popupTypeId: 0,
            signing: false,
            window: Dimensions.get('window'),
            notTab: Dimensions.get('window').width > 1000 ? true : false,
            isSmall: Dimensions.get('window').height < 900 ? true : false
        };

        // this.ReCaptchaRef = this.ReCaptchaRef.bind(this);
    }

    _onBlurr = () => {
        BackHandler.removeEventListener('hardwareBackPress',
            this._handleBackButtonClick);
    }

    _onFocus = () => {
        BackHandler.addEventListener('hardwareBackPress',
            this._handleBackButtonClick);
    }

    _handleBackButtonClick = () => {
        BackHandler.exitApp();
        return true;
    }

    onPressForgotPassword = () => {
        this.props.navigation.navigate("ForgotPasswordScreen");
    };

    onPressRegister = () => {
        this.props.navigation.navigate("RegisterScreen", { SCREEN_TYPE: "REGISTER_EMAIL", SOCAIL_ID: "", SOCAIL_NAME: "", EMAIL: "" }); // SCREEN_TYPE = REGISTER_EMAIL , REGISTER_FACEBOOK , REGISTER_GOOGLE

    };


    handleOpenURL = (title, url) => {
        if (url.url) {
            url = url.url;
        }

        if (url.includes("https://theinvestorsagency.page.link/register?e=")) {
            url = url.replace("https://theinvestorsagency.page.link/register?e=", "");
            let email = url.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
            let token = url.replace(email + "&t=", "");
            this.props.navigation.navigate("RegisterScreen", { EMAIL: email.length == 1 ? email[0] : email });
        }
        else if (url.includes("theinvestorsagency://e%3D")) {
            url = url.replace("theinvestorsagency://e%3D", "");
            let email = url.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
            email = email[0];
            this.props.navigation.navigate("RegisterScreen", { EMAIL: email });
        }
    }

    componentWillUnmount() {
        // Linking.removeEventListener();
    }

    handleUrl(link) {
        showMessage({
            message: link.url,
            type: "info",
            color: '#FFF',
            backgroundColor: MAIN_COLOR.PRIMARY_COLOR
        });
        let url = link.url;

        if (url.includes("https://theinvestorsagency.page.link/register?e=")) {
            url = url.replace("https://theinvestorsagency.page.link/register?e=", "");
            this.props.navigation.navigate("RegisterScreen", { EMAIL: url });
        };
    }

    componentDidMount() {
        Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                this.setState({ notTab: window.width > 1000 ? true : false, isSmall: window.height < 900 ? true : false })
            }
        );
        this.props.reset({});
        
        //before submit
        Linking.getInitialURL()
            .then((initialUrl) => {
                if (initialUrl) {
                    dynamicLinks().resolveLink(initialUrl)
                        .then((resolvedLink) => {
                            this.handleUrl(resolvedLink);
                        })
                        .catch(console.warn);
                }
            })
            .catch(console.warn);
    }
    
    onPressSubmit = async () => {
        const { email } = this.state;
        !Boolean(email) ? this.setState({ emailRequired: true }) : null;
        if (Boolean(email)) {
            this.setState({ signing: true });

            var config = {
                method: 'get',
                url: `https://us-central1-stratprops-7d489.cloudfunctions.net/resetPassword?email=${email}`,

            };

            const axios = require('axios');

            axios(config)
                .then((response) => {
                    console.log(response)

                    // showMessage({
                    //     message: "A corresponding email will be sent to your account shortly.",
                    //     type: "info",
                    //     color: '#FFF',
                    //     backgroundColor: MAIN_COLOR.PRIMARY_COLOR
                    // });
                    // this.setState({ signing: false });
                }).catch((error) => {
                    console.log(error)
                    // showMessage({
                    //     message: error.message,
                    //     type: "info",
                    //     color: '#FFF',
                    //     backgroundColor: MAIN_COLOR.PRIMARY_COLOR
                    // });
                    // this.setState({ signing: false });
                });
        }
        else {
            showMessage({
                message: "Email can't be empty.",
                type: "info",
                color: '#FFF',
                backgroundColor: MAIN_COLOR.PRIMARY_COLOR
            });
        }
    };

    render() {
        const { notTab, isSmall, signing } = this.state;
        return (
            <SafeAreaView style={{ flex: 1, backgroundColor: MAIN_COLOR.BACKGROUND, flexDirection: notTab ? 'row' : 'column' }} keyboardShouldPersistTaps={true} behavior={Platform.OS === "ios" ? "padding" : "padding"} keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}>

                <View onPress={Keyboard.dismiss} accessible={false} style={{ padding: '5%', height: notTab ? '100%' : isSmall ? '100%' : '68%', width: notTab ? '50%' : '100%', backgroundColor: '#FFF' }}>

                    <TouchableOpacity onPress={() => this.props.navigation.goBack(null)} style={{ backgroundColor: MAIN_COLOR.BACKGROUND, borderRadius: 17, height: 48, width: 48, justifyContent: 'center', alignItems: 'center' }}>
                        <Image
                            style={{ height: 12, width: 12 }}
                            resizeMode="contain"
                            source={require("../../assets/arrow_left.png")}
                        />
                    </TouchableOpacity>


                    <View style={[styles.topView]}>
                        <Image
                            style={styles.logoImage}
                            resizeMode="contain"
                            source={require("../../assets/header_logo.svg")}
                        />
                    </View>
                    <View style={[styles.scrollViewContentView, { width: notTab ? '100%' : '68%', alignSelf: 'center' }]}>
                        <Text style={{ fontFamily: MAIN_FONT.bold, color: '#272D37', fontSize: 18, marginBottom: 5 }}>
                            Hey! Welcome back
                        </Text>

                        <Text style={{ fontFamily: MAIN_FONT.light, color: '#272D37', fontSize: 14, }}>
                            Submit your email address
                        </Text>


                        <View style={styles.centerView}>
                            <Text style={{ fontFamily: MAIN_FONT.regular, color: '#272D37', marginBottom: 5 }}>
                                Email
                            </Text>

                            <View style={{ paddingHorizontal: 20, width: '90%', borderWidth: 0.5, borderRadius: 10, borderColor: '#ADB9C7', marginBottom: 20 }}>


                                <TextInput
                                    ref={(input) => { this.emailTextInput = input; }}
                                    keyboardType="web-search"
                                    autoCorrect={false}
                                    outline='none'
                                    style={{ height: 50, outline: 'none', width: '90%', fontFamily: MAIN_FONT.regular, }}
                                    placeholderTextColor="#ADB9C7"
                                    value={this.state.email}
                                    underlineColorAndroid="transparent"
                                    onChangeText={email => !signing && this.setState({ email, emailRequired: false })}
                                    placeholder="Enter Your Email"
                                    returnKeyType={"next"}
                                    onSubmitEditing={() => { this.passwordTextInput.focus(); }}
                                    blurOnSubmit={false}
                                    autoCapitalize="none"
                                />
                            </View>


                            <TouchableOpacity
                                style={[stylesCommonComponents.button, { height: 50, width: '60%', alignSelf: 'center', marginTop: 50 }]}
                                onPress={() => this.onPressSubmit()}
                                activeOpacity={0.8}
                            >
                                {
                                    signing ?
                                        <MaterialIndicator size={RFValue(16.75)} color={MAIN_COLOR.BACKGROUND_COLOR} />
                                        :
                                        <Text
                                            style={{
                                                fontFamily: MAIN_FONT.semi,
                                                fontSize: 16,
                                                color: "#FFFFFF",
                                            }}
                                            numberOfLines={1} >Submit</Text>
                                }
                            </TouchableOpacity>
                        </View>
                    </View>

                </View>

                <View style={{
                    justifyContent: 'center',
                    flexDirection: notTab ? 'column' : 'row',
                    alignItems: 'center', overflow: 'hidden',
                    height: notTab ? '100%' : isSmall ? 0 : '32%',
                    width: notTab ? '50%' : '100%'
                }}>
                    <Image
                        style={{ height: 200, width: "50%" }}
                        resizeMode="contain"
                        source={require("../../assets/content.png")}
                    />

                    <View style={{ flexDirection: 'column', justifyContent: 'flex-start', width: notTab ? '100%' : '50%' }}>
                        <Text style={{ fontSize: 21, color: '#1A3F49', fontFamily: MAIN_FONT.bold, textAlign: notTab ? 'center' : 'left', marginTop: notTab ? 25 : 0 }}>
                            The Investors Agency
                        </Text>

                        <Text style={{ fontSize: 16, color: '#A2ADB9', fontFamily: MAIN_FONT.regular, textAlign: notTab ? 'center' : 'left', marginTop: 10 }}>
                            You’re on your way to better property management.
                        </Text>
                    </View>
                </View>
                {this.state.isLoading ? <LOADER /> : null}
            </SafeAreaView>
        );
    }
};


function mapStateToProps(state) {
    return {
        COLORS: state.COLORS,
        USER: state.USER,
        CART: state.CART
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setClient,
            reset,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordScreen);