import React from 'react';
import {
   StyleSheet,
} from 'react-native';

import { MAIN_COLOR } from "../../../Utility/Colors"

var styles;

export default styles = StyleSheet.create({

   container: {
      width: 40,
      height: 40, 
      borderRadius: 40 / 2,
      justifyContent: "center",
      alignItems: "center",
      borderWidth: 0.5,
      borderColor: "#FFFFFF50",
      backgroundColor: MAIN_COLOR.PRIMARY_COLOR
   },

   logo: {
      width: 20,
      height: 20,
      tintColor: "#FFFFFF",
   }

});


