import { StyleSheet } from "react-native";
import { MAIN_COLOR, TAB_BAR_COLOR } from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    gap: 22,
  },
  scenarioList: {
    maxHeight: 180,
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 5,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  wrapIcon: {
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#F0F1F8",
  },
  name: {
    fontFamily: MAIN_FONT.regular,
    fontSize: 14,
    fontWeight: 500,
    color: "#200E32",
    width:150,
  },
  time: {
    fontFamily: MAIN_FONT.regular,
    fontSize: 10,
    fontWeight: 400,
    color: MAIN_COLOR.G700,
  },
  btnText: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: MAIN_FONT.regular,
    color: TAB_BAR_COLOR.INACTIVE_COLOR,
  },
  btnAdd: {
    paddingHorizontal: 24,
    paddingVertical: 10,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
    gap: 6,
  },
});
