import React, { useState } from "react";

import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { MAIN_COLOR } from "../Utility/Colors";
import { MAIN_FONT } from "../Utility/Fonts/font";

const CustomSwitch = ({
  selectionMode,
  roundCorner,
  label1,
  label2,
  option1,
  option2,
  onSelectSwitch,
  selectionColor,
  styleContainer,
}) => {
  const [getSelectionMode, setSelectionMode] = useState(selectionMode);
  const [getRoundCorner, setRoundCorner] = useState(roundCorner);

  const updatedSwitchData = (val) => {
    setSelectionMode(val);
    onSelectSwitch(val);
  };

  const borderRadius = getRoundCorner ? 25 : 5;

  return (
    <View
      style={[styles.container, styleContainer, { borderRadius: borderRadius }]}
    >
      {label1 && (
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => updatedSwitchData(option1)}
          style={[
            styles.button,
            {
              backgroundColor:
                getSelectionMode == option1
                  ? selectionColor
                  : MAIN_COLOR.BACKGROUND_COLOR,
              borderTopLeftRadius: borderRadius,
              borderBottomLeftRadius: borderRadius,
            },
          ]}
        >
          <Text
            style={[
              styles.text,
              {
                fontWeight: getSelectionMode == option1 ? 600 : 400,
              },
            ]}
          >
            {label1}
          </Text>
        </TouchableOpacity>
      )}
      {label2 && (
        <TouchableOpacity
          TouchableOpacity
          activeOpacity={1}
          onPress={() => updatedSwitchData(option2)}
          style={[
            styles.button,
            {
              backgroundColor:
                getSelectionMode == option2
                  ? selectionColor
                  : MAIN_COLOR.BACKGROUND_COLOR,
              borderTopRightRadius: borderRadius,
              borderBottomRightRadius: borderRadius,
            },
          ]}
        >
          <Text
            style={[
              styles.text,
              {
                fontWeight: getSelectionMode == option2 ? 600 : 400,
              },
            ]}
          >
            {label2}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 36,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    borderWidth: 1,
    borderColor: "rgba(173, 185, 199, 0.3)",
    flexDirection: "row",
    justifyContent: "center",
    overflow: "hidden",
  },
  button: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 14,
    lineHeight: 16,
    fontFamily: MAIN_FONT.regular,
  },
});

export default CustomSwitch;
