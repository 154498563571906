import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Animated,
  Easing,
  Image,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Carousel from "react-native-reanimated-carousel";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { useSelector } from "react-redux";
import { getListMarketHistory } from "../../../../Clickup/api";
import { MAIN_COLOR } from "../../../../Utility/Colors";
import Costs from "./Costs";
import GrowthChart from "./GrowthChart";
import styles from "./style";
import { BlurView } from "expo-blur";
const PurchaseDetails = ({ properties, onSelect, purchaseSelected }) => {
  const opacity = useRef(new Animated.Value(0)).current;

  const ref = useRef();
  const { client } = useSelector((state) => state.TIA);

  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const animate = (easing) => {
    opacity.setValue(0);
    Animated.timing(opacity, {
      toValue: 1,
      duration: 800,
      easing,
      useNativeDriver: true,
    }).start();
  };

  const sizeWidth = opacity.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 800],
  });

  const animatedStyles = [
    {
      opacity,
      width: sizeWidth,
    },
  ];

  const formatPropertyText = (quantity, unitLabel) => {
    const pluralizedUnit = quantity !== 0 ? `${unitLabel}s` : unitLabel;
    return `${quantity} ${pluralizedUnit}`;
  };

  const onClose = () => onSelect(null);

  useEffect(() => {
    animate(Easing.elastic(0));
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setResult(null);
        setLoading(true);
        const payload = {
          state: properties["PROPERTY STATE"],
          property_suburb: properties["PROPERTY SUBURB"],
          no_bed_room: properties["NO. OF BEDROOMS"] * 1,
          purchase_date: moment(properties["Purchase date "] * 1).format(
            "DD-MM-YYYY"
          ),
          purchase_price: properties["Purchase Price"] * 1,
          id: properties.id,
        };
        const response = await getListMarketHistory(payload);
        const {
          sales_median_12mths,
          growth_percentage,
          potential_value,
          potential_equity,
        } = response.data;

        const data = [];
        let totalPotentialEquity = 0;

        for (let i = 0; i <= sales_median_12mths.length - 1; i++) {
          totalPotentialEquity += sales_median_12mths[i].potential_equity;
          data.push({
            date: sales_median_12mths[i].end_date,
            value: totalPotentialEquity,
          });
        }
        setResult({
          sales: data,
          growthPercentage: growth_percentage,
          potentialValue: potential_value,
          potentialEquity: potential_equity,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [properties]);

  const images = properties["Image IDs"]
    ? properties["Image IDs"]
        .split(",")
        .map(
          (url) =>
            `https://insights.proptrack.com/imagery/250x165/${
              url.split(".")[0]
            }/image.jpg`
        )
    : properties["PROPERTY IMAGE URL"];

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <Animated.View style={[styles.container, animatedStyles]}>
        <View style={[styles.flexRow, { gap: 13 }]}>
          <View style={[styles.imageContainer]}>
            <View style={styles.topLeftSection}>
              <BlurView intensity={10} style={styles.blur} />
              <Image
                resizeMode="contain"
                style={{
                  width: 24,
                  height: 24,
                  tintColor: MAIN_COLOR.BACKGROUND_COLOR_DARK,
                }}
                source={require("../../../../assets/portfolio-roadmap/house_option.png")}
              />
              <Text style={styles.textHouse}>House</Text>
            </View>
            {images ? (
              Array.isArray(images) ? (
                <Carousel
                  loop={false}
                  width={305}
                  height={126}
                  autoPlay={true}
                  style={{ borderRadius: 8 }}
                  data={images}
                  scrollAnimationDuration={1000}
                  pagingEnabled
                  renderItem={({ index }) => (
                    <Image
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                      key={index}
                      resizeMode="cover"
                      source={images[index]}
                    />
                  )}
                />
              ) : (
                <Image
                  style={{
                    height: "100%",
                    transform: [{ scale: 2.4 }],
                  }}
                  resizeMode="contain"
                  source={images}
                />
              )
            ) : (
              <Image
                style={{
                  height: "100%",
                  transform: [{ scale: 1 }],
                  backgroundColor: "#F5F6FA",
                }}
                resizeMode="contain"
                source={require("../../../../assets/portfolio-roadmap/no_img.png")}
              />
            )}
            <View style={styles.topRightSection}>
              <BlurView intensity={10} style={styles.blur} />
              <Text style={styles.txtPurchaseBy}>Purchased by</Text>
              <Text style={styles.txtAgency}>
                {properties["Purchased By"]
                  ? "Independently"
                  : "The Investors Agency"}
              </Text>
            </View>
          </View>
          <View style={styles.info}>
            <View style={styles.nameWrap}>
              <Text style={styles.name} numberOfLines={2}>
                {properties["PROPERTY STREET ADDRESS"]}
              </Text>
              <TouchableOpacity style={styles.btnGoBack} onPress={onClose}>
                <Image
                  resizeMode="contain"
                  style={{ height: 14, width: 14, margin: "auto" }}
                  source={require("../../../../assets/arrow_left.png")}
                />
              </TouchableOpacity>
            </View>
            <View style={[styles.flexRow, { gap: 4 }]}>
              <Image
                style={styles.icon}
                resizeMode="contain"
                source={require("../../../../assets/location.png")}
              />
              <Text style={styles.address}>
                {properties["PROPERTY SUBURB"]} {properties["PROPERTY STATE"]}{" "}
                {properties["PROPERTY POSTCODE"]}
              </Text>
            </View>
            <View>
              <Text style={[styles.address]}>
                {properties?.["Purchase date "] &&
                  moment(Number(properties["Purchase date "])).format(
                    "DD/MM/YYYY"
                  )}
              </Text>
            </View>
            <View style={styles.params}>
              <View style={styles.param}>
                <Image
                  resizeMode="contain"
                  style={styles.icon}
                  source={require("../../../../assets/portfolio-roadmap/bed.png")}
                />
                <Text style={styles.paramValue}>
                  {formatPropertyText(properties["NO. OF BEDROOMS"], "Bed")}
                </Text>
              </View>
              <View style={styles.param}>
                <Image
                  resizeMode="contain"
                  style={styles.icon}
                  source={require("../../../../assets/portfolio-roadmap/bath.png")}
                />
                <Text style={styles.paramValue}>
                  {formatPropertyText(properties["NO. OF BATHROOMS"], "Bath")}
                </Text>
              </View>
              <View style={styles.param}>
                <Image
                  resizeMode="contain"
                  style={styles.icon}
                  source={require("../../../../assets/portfolio-roadmap/car.png")}
                />
                <Text style={styles.paramValue}>
                  {formatPropertyText(properties["# OF PARKING"], "Car")}
                </Text>
              </View>
              <View style={styles.param}>
                <Image
                  resizeMode="contain"
                  style={styles.icon}
                  source={require("../../../../assets/portfolio-roadmap/area.png")}
                />
                <Text style={styles.paramValue}>
                  {properties["AREA (M2)"]}m2
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.flexRow, { gap: 13, marginTop: 18 }]}>
          <GrowthChart
            result={result}
            properties={properties}
            loading={loading}
          />
          <Costs result={result} properties={properties} />
        </View>
      </Animated.View>
    </OutsideClickHandler>
  );
};

export default PurchaseDetails;
