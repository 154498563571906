import { combineReducers, createStore } from "redux";
import { persistReducer } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { colors } from "./color";
import { user } from "./user";
import { tia } from "./tia";
import { portfolioRoadmap } from "./portfolio-roadmap";

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  blacklist: ["PORTFOLIO_ROADMAP"],
};

const combineReducer = combineReducers({
  COLORS: colors,
  USER: user,
  TIA: tia,
  PORTFOLIO_ROADMAP: portfolioRoadmap,
});

const persist = persistReducer(persistConfig, combineReducer);

export default persist;
export const store = createStore(persist);
