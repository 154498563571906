import React from "react";
import { StyleSheet, Dimensions } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

import { MAIN_FONT } from "../../Utility/Fonts/font";
import { MAIN_COLOR, MAIN_HEADER } from "../../Utility/Colors";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";

const { width } = Dimensions.get("window");

var styles;

export default styles = StyleSheet.create({
  textWelcome: {
    fontFamily: MAIN_FONT.bold,
    color: "#272D37",
    fontSize: 18,
    marginBottom: 5,
  },
  textSubTitle: {
    fontFamily: MAIN_FONT.light,
    color: "#272D37",
    fontSize: 14,
  },
  labelForm: {
    fontFamily: MAIN_FONT.regular,
    color: "#272D37",
    marginBottom: 5,
  },
  safeArea: {
    flex: 1,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
  },
  boxInputForm: {
    paddingHorizontal: 20,
    width: "90%",
    borderWidth: 0.5,
    borderRadius: 10,
    borderColor: "#ADB9C7",
    marginBottom: 20,
  },
  inputForm: {
    height: 50,
    outline: "none",
    width: "90%",
    fontFamily: MAIN_FONT.regular,
  },
  boxForgotPassword: {
    width: "90%",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: 20,
    paddingRight: 0,
  },
  textForgotPassword: {
    fontFamily: MAIN_FONT.regular,
    color: "#272D37",
    marginBottom: 5,
  },
  btnSubmit: {
    height: 50,
    width: "60%",
    alignSelf: "center",
    marginTop: 50,
  },
  textSubmit: {
    fontFamily: MAIN_FONT.semi,
    fontSize: 16,
    color: "#FFFFFF",
  },
  container: {
    flex: 1,
  },

  scrollViewContainer: {
    width,
  },

  scrollViewContentView: {
    flex: 1,
    width: "100%",
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
  },

  topView: {
    // flex: 0.68,
    marginBottom: 25,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },

  centerView: {
    flex: 1,
    width: "100%",
    justifyContent: "flex-start",
    paddingVertical: 20,
  },

  logoImage: {
    // width: wp("65%"),
    height: 100,
    width: 250,
    resizeMode: "contain",
    // tintColor: MAIN_COLOR.PRIMARY_COLOR
  },

  loginText: {
    marginBottom: wp("1%"),
    marginLeft: wp("1%"),
    fontFamily: MAIN_FONT.titillium_web_regular,
    fontSize: RFValue(16),
    color: "#000",
  },

  forgotPasswordView: {
    marginTop: wp("2%"),
    flexDirection: "row-reverse",
  },

  forgotPassword: {
    width: 150,
    flexDirection: "row-reverse",
  },

  forgotPasswordText: {
    marginTop: wp("1%"),
    marginBottom: wp("1%"),
    fontFamily: MAIN_FONT.titillium_web_regular,
    fontSize: RFValue(14),
    color: "#000",
  },

  loginButton: {
    marginTop: wp("3%"),
    padding: 15,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderRadius: 4,
    borderColor: "#D3D3D3",
    backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
  },

  loginButtonText: {
    //marginLeft: wp("4.5%"),
    //width: "100%",
    fontFamily: MAIN_FONT.titillium_web_regular,
    fontSize: RFValue(14),
    color: "#FFFFFF",
  },

  orComponents: {
    height: wp("6%"),
    marginTop: wp("3.5%"),
    marginBottom: wp("3.5%"),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  orText: {
    fontFamily: MAIN_FONT.titillium_web_regular,
    fontSize: RFValue(17),
    color: "#757575",
  },

  socialButton: {
    width: wp("90%"),
    padding: 13,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderRadius: 10,
    borderColor: "#D3D3D3",
    backgroundColor: "#FFFFFF",
  },

  socialButtonTextGoogle: {
    marginLeft: wp("4.5%"),
    width: "70%",
    fontFamily: MAIN_FONT.titillium_web_regular,
    fontSize: RFValue(14),
    color: "#00000099",
    textAlign: "center",
  },

  socialButtonTextFacebook: {
    marginLeft: wp("4.5%"),
    width: "70%",
    fontFamily: MAIN_FONT.titillium_web_regular,
    fontSize: RFValue(14),
    color: "#FFFFFF",
  },

  socialButtonImage: {
    width: wp("5%"),
    height: wp("5%"),
    marginLeft: wp("3%"),
  },

  socialButtonArrowImageGoogle: {
    width: wp("5%"),
    height: wp("5%"),
    marginRight: wp("8%"),
    tintColor: "#757575",
  },

  socialButtonArrowImageFacebook: {
    width: wp("5%"),
    height: wp("5%"),
    marginRight: wp("8%"),
    tintColor: "#FFFFFF",
  },

  registerComponents: {
    height: wp("6.5%"),
    marginTop: wp("2%"),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  haveAnAccountText: {
    fontFamily: MAIN_FONT.titillium_web_regular,
    fontSize: RFValue(16),
    color: "#00000090",
  },

  registerText: {
    fontFamily: MAIN_FONT.titillium_web_bold,
    fontSize: RFValue(16),
    color: "#000",
    textDecorationLine: "underline",
  },
});
