import React from 'react';
import {
  Animated, Image,
  ImageBackground,
  Linking, Platform, ScrollView,
  Text,
  Dimensions,
  TouchableOpacity,
  View
} from 'react-native';
import { createElement } from "react-native-web";
import VPlayer from "react-vplayer";
import WebView from 'react-native-webview';
import { NavigationEvents } from 'react-navigation';
import { setLoader } from '../../Reducer/tia';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setColors } from "../../Action/color";
import { getContactByEmail, getPresentation } from '../../Clickup/api';
import { user as setUser } from '../../Reducer/user';
import { MAIN_COLOR } from "../../Utility/Colors";
import { MAIN_FONT } from "../../Utility/Fonts/font";

import { widthPercentageToDP } from 'react-native-responsive-screen';
import LOADER from "../../Utility/ComponentCard/Loader";
import styles from "./style";
import SpecificData from './SpecificData/SpecificData';



class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      today: new Date(),
      selected: {},
      pdfs: [],
      booking: props.booking,
      market: props.market,
      dimensions: { window: Dimensions.get('window'), screen: Dimensions.get('screen') },
      markedDates: {},
      opacity: new Animated.Value(1),
      height: new Animated.Value(0),
      videoUrl: false,
      videos: [],
      selectedLink: false,
      presentation: {},
      refreshing: false,
      assignee: props.assignee,
      buyingTeamPhoto: null
    };

  }


  async refresh() {

    // this.props.setLoader(true);

    getPresentation(this.state.market.id).then((result) => {
      if (result) {
        var object = result.custom_fields.reduce(
          (obj, item) => Object.assign(obj, { [item.name]: item.value }), {});

        result['custom_fields'] = null;

        var presentation = { ...result, ...object };

        let Images_Economic_Driver = Object.keys(presentation).filter((item, index) => item.includes('Economic Drivers (Image'));

        let economic_drivers = [];

        Images_Economic_Driver.map((item, index) => {

          index=index+1;

          if (presentation[`Economic Drivers (Image - ${index})`] && presentation[`Economic Drivers (Description - ${index})`] && presentation[`Economic Drivers (URL - ${index})`] && presentation[`Economic Drivers (Title - ${index})`]) {
            economic_drivers.push({
              image: presentation[`Economic Drivers (Image - ${index})`],
              description: presentation[`Economic Drivers (Description - ${index})`],
              url: presentation[`Economic Drivers (URL - ${index})`],
              title: presentation[`Economic Drivers (Title - ${index})`]
            });
          }
          else {
            console.log(
              "else",
               presentation[`Economic Drivers (Image - ${index})`],
              presentation[`Economic Drivers (Description - ${index})`],
              presentation[`Economic Drivers (URL - ${index})`],
              presentation[`Economic Drivers (Title - ${index})`]
            );
          }
        })

        let Images_Project = Object.keys(presentation).filter((item, index) => item.includes('Projects (Image'));

        let projects = [];

        Images_Project.map((item, index) => {
          if (presentation[`Projects (Image - ${index})`] && presentation[`Projects (Description - ${index})`] && presentation[`Projects (URL - ${index})`] && presentation[`Projects (Title - ${index})`]) {
            projects.push({
              image: presentation[`Projects (Image - ${index})`],
              description: presentation[`Projects (Description - ${index})`],
              url: presentation[`Projects (URL - ${index})`],
              title: presentation[`Projects (Title - ${index})`]
            });
          } else {
            console.log(index, "Prject index missing are\n", {
              image: presentation[`Projects (Image - ${index})`],
              description: presentation[`Projects (Description - ${index})`],
              url: presentation[`Projects (URL - ${index})`],
              title: presentation[`Projects (Title - ${index})`]
            })
          }
        })

        console.log("presentations\n\n\n\n", presentation, "\n\n\n\n")

        this.props.setLoader(false);

        this.setState({ presentation, projects, economic_drivers });
      }
    }).catch(()=>{
      this.props.setLoader(false);
    })
  }


  onChange = ({ window, screen }) => {
    this.setState({ dimensions: { window, screen } });
  };


  componentDidMount() {
    const { assignee } = this.state;

    this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange);

    this.refresh();

    if (assignee) {
      getContactByEmail(assignee.email).then((result) => {
        const tasks = result?.tasks;
        if (tasks.length) {
          const custom_fields = tasks[0].custom_fields;
          const contactPhoto = custom_fields.find((field) => field.name === 'Buying Team Photo')?.value[0].url;
          this.setState({ buyingTeamPhoto: contactPhoto })
        }
      });
    }
  }

  render() {
    const { videos, presentation, economic_drivers, projects, assignee } =
      this.state;
    console.log("Data", presentation.name?.trim());
    const openUrl = (url) => {
      let reg = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
      if (!!reg.test(url)) {

        Linking.openURL(url)
      }
    }


    const web = (link) => {
      const Video = (props) => {
        const attrs = {
          src: props.source,
          // poster: props.poster,
          width: "100%",
          height: 550,

          frameborder: "0",
          webkitallowfullscreen: true,
          mozallowfullscreen: true,
          allowfullscreen: true,
          // style:"position: absolute; top: 0; left: 0; width: 100%; height: 100%;",
          controls: "controls"
        }

        return createElement("iframe", attrs)
      }

      return (<Video
        source={link}
        // poster={'https://www.fillmurray.com/480/300'}
        width={100}

      />)
    }

    return (
      <View style={{ flex: 1, paddingHorizontal: 25 }} keyboardShouldPersistTaps={true} behavior={Platform.OS === "ios" ? "padding" : "padding"} keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}>
        <NavigationEvents
          onWillFocus={this._onFocus}
          onWillBlur={this._onBlurr}
        />
        <View style={[styles.scrollViewContentView, { borderRadius: 10, backgroundColor: MAIN_COLOR.BACKGROUND_COLOR }]}>


          {
            <ImageBackground source={{ uri: presentation?.['Market Image URL'] }} 
            style={{ flexDirection: 'row', justifyContent: 'space-between',
             alignItems: 'center', paddingTop: 200, paddingBottom: 100, 
             paddingHorizontal: '5%', 
            }} 
            imageStyle={{
              borderTopLeftRadius: 10,
              // height: 250,
              borderTopRightRadius: 10,
              backgroundColor: MAIN_COLOR.G800,
              resizeMode: 'cover'
            }}/>
          }

          <View style={[{ marginTop: 20, paddingHorizontal: '5%', justifyContent: 'space-between', alignItems: 'flex-start' }]}>
            <Text style={{
              fontSize: 14,
              color: MAIN_COLOR.G700,
              fontFamily: MAIN_FONT.regular,
            }}>
              The region of
            </Text>

            <Text style={{
              fontSize: 18,
              color: MAIN_COLOR.G800,
              fontFamily: MAIN_FONT.semi,
              marginBottom: 5,
              lineHeight: 27,
              textTransform: 'capitalize'
            }}>
              {presentation?.["Region/Council/LGA"]} {presentation?.status?.status}
            </Text>

            <Text style={{
              marginTop: 20,
              fontSize: 14,
              color: MAIN_COLOR.G600,
              fontFamily: MAIN_FONT.regular,
            }}>
              {presentation['About the market']}
            </Text>
          </View>


          <Text style={styles.textHeading}>
            The Regions Overview
          </Text>

          <View


            activeOpacity={0.8} style={{ width: '90%', alignSelf: 'center', marginBottom: 20 }}>
            {/* 

<VPlayer
                                                  source={[
                                                    {
                                                      url:
                                                      'https://www.loom.com/share/9844d6038e3f4168aabd3d62239dce75',
                                                      // type: "video/mp4",
                                                      quality: 720
                                                    },
                                                    {
                                                      url:
                                                      'https://www.loom.com/share/9844d6038e3f4168aabd3d62239dce75',
                                                      // type: "video/mp4",
                                                      quality: 1080
                                                    },
                                                    {
                                                      url:
                                                      'https://www.loom.com/share/9844d6038e3f4168aabd3d62239dce75'
                                                      // ?.replace('share', 'embed'),
                                                      ,
                                                      // type: "video/mp4",
                                                      quality: 480
                                                    },
                                                    {
                                                      url:
                                                      'https://www.loom.com/share/9844d6038e3f4168aabd3d62239dce75',
                                                      // type: "video/mp4",
                                                      quality: 360
                                                    }
                                                  ]}
                                                  // quality=""
                                                /> */}

            {/* <WebView
                androidHardwareAccelerationDisabled
                allowsFullscreenVideo
                startInLoadingState
                allowsInlineMediaPlayback
                style={{ height: 210, width: '100%', borderRadius: 20 }}
                source={{
                  // uri: property['PROPERTY PRESO VIDEO URL']
                  html:
                    `<iframe src=${presentation['The Region Overview Video (Loom/Vimeo)']?.replace('share', 'embed')} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>`
                  // html: `<html><iframe width="100%" height="100%" src=${taskUpdate.video_url.replace("vimeo.com", "player.vimeo.com/video")} webkit-playsinline frameborder="0" webkitallowfullscreen={true} mozallowfullscreen allowfullscreen></iframe></html>`
                }} /> */}

            {
              web(presentation['The Region Overview Video (Loom/Vimeo)']?.replace('share', 'embed'))
            }

          </View>


          <Text style={styles.textHeading}>
            Economic Drivers & Influences
          </Text>

          <View style={{ width: '100%', flexDirection: 'row', paddingHorizontal: '5%', justifyContent: 'flex-start', flexWrap: 'wrap' }}>

            {
              economic_drivers?.length ?
                economic_drivers.map((item, index) =>
                  <TouchableOpacity onPress={() => openUrl(item.url)} style={{ padding: 7.5, marginBottom: 20, backgroundColor: '#F5F6FA', width: "22%", borderRadius: 10, marginRight: '3%', justifyContent: 'space-evenly' }}>
                    <Text style={[styles.textSemi]}>
                      {item.title}
                    </Text>

                    <Image
                      source={{
                        uri: item.image
                        // .replace('-format=avif,', '') 
                      }}
                      resizeMode="cover"
                      style={{ height: this.dimensions?.width * 0.1 > 80 ? this.dimensions?.width * 0.1 : 80, width: '100%', borderRadius: 10, marginVertical: 5 }}
                    />

                    <Text style={[styles.textLight]}>
                      {item.description}
                    </Text>
                    <Text style={[styles.priceTitle, { textAlign: 'center', fontSize: 14, bottom: 0, marginVertical: 5, color: MAIN_COLOR.PRIMARY_COLOR, textDecorationLine: "underline" }]}>
                      View
                    </Text>
                  </TouchableOpacity>
                )
                :
                null
            }

          </View>

          <Text style={styles.textHeading}>
            Projects & Plans
          </Text>

          <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', paddingHorizontal: '5%' }}>

            {
              projects?.length ?
                projects.map((item, index) =>
                  <TouchableOpacity onPress={() => openUrl(item.url)} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 12.5, marginBottom: 20, backgroundColor: '#F5F6FA', width: "47.5%", borderRadius: 10 }}>
                    <Image
                      source={{
                        uri: item.image
                      }}
                      resizeMode="cover"
                      style={{ height: '100%', resizeMode: "cover", width: "20%", borderRadius: 10 }}
                    />
                    <View style={{ width: '75%' }}>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={[styles.textSemi, { width: "75%", textAlign: 'left' }]}>
                          {item.title}
                        </Text>
                        <Text style={[styles.priceTitle, { textAlign: 'center', fontSize: 14, marginVertical: 5, color: MAIN_COLOR.PRIMARY_COLOR, textDecorationLine: "underline" }]}>
                          View
                        </Text>
                      </View>

                      <Text style={[styles.textLight, { maxWidth: "100%", marginLeft: 0 }]}>
                        {item.description}
                      </Text>
                    </View>
                  </TouchableOpacity>
                )
                :
                null
            }

          </View>


          <Text style={styles.textHeading}>
            Suburb Specific Data
          </Text>

          <Text style={[styles.textLight, { width: "100%", margin: 0, paddingHorizontal: '5%', fontSize: 14 }]}>
            The information and data above reflects the general activity in the region, while the graphs below display the most recent suburb specific health metrics.
          </Text>

          <Text style={[styles.textSemi, { width: "100%", textAlign: 'left', marginTop: 10, paddingHorizontal: '5%', fontSize: 14, color: MAIN_COLOR.G600 }]}>
            These graphs will be explained in more detail when we present the property to you.
          </Text>

          <View style={{
            flexDirection: 'row',
            alignSelf: 'center',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: MAIN_COLOR.BACKGROUND,
            borderRadius: 40,
            marginVertical: 20,
            // width: "90%"
          }}>
            <Text style={[styles.textSemi, { marginVertical: 10, fontSize: 14, textAlign: 'center', paddingHorizontal: 35}]}>
              CURRENT VACANCY RATE:
            </Text>
            <View style={{ alignItems: 'center', justifyContent: 'center', borderRadius: 40, paddingVertical: 5, paddingHorizontal: 15, backgroundColor: MAIN_COLOR.PRIMARY_COLOR }}>
              <Text style={[styles.textSemi, { color: '#FFFFFF', fontSize: 18 }]}>
                {presentation['Current Vacancy Rate']}%
              </Text>


            </View>
          </View>

          <SpecificData name={presentation.name?.trim()} />

          {
            assignee &&
            <>
              <View style={[styles.row, { backgroundColor: MAIN_COLOR.BACKGROUND, marginLeft: "5%", width: "90%", paddingTop: 10, borderRadius: 20, gap: 20, display: 'flex', justifyContent: 'center' }]}>
                <Image source={this.state.buyingTeamPhoto} style={{ minWidth: 200, height: 125, resizeMode: "cover", borderBottomLeftRadius: 20 }} />
                <Text style={[styles.price, { textAlign: 'left', marginBottom: 30, fontFamily: MAIN_FONT.regular, width: "64%", marginTop: 30, paddingRight: 5, fontSize: 14, display: 'flex', justifyContent: 'center' }]}>
                  {`Book a call through the link below with ${assignee.username?.split(" ")[0]} & Mike after reviewing the above information.`}
                </Text>
              </View>


              <TouchableOpacity onPress={() => openUrl(this.state.booking)} style={{ flexDirection: 'row', backgroundColor: MAIN_COLOR.PRIMARY_COLOR, borderRadius: 10, width: 300, padding: 12.5, justifyContent: 'center', alignSelf: 'center', marginVertical: 40 }}>
                <Text style={{
                  color: '#FFFFFF',
                  fontFamily: MAIN_FONT.semi,
                  fontSize: 14,
                  textAlign: 'center'
                }}>
                  BOOK A QUICK CALL
                </Text>
              </TouchableOpacity>
            </>
          }

        </View>

        {!Object.keys(presentation).length ? <LOADER /> : null}


      </View>
    );
  }
};


function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    TIA: state.TIA
  }
}

function mapDispatchToProps(dispatch) {


  return bindActionCreators(
    {
      setUser,
      setLoader,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);