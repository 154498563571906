import moment from 'moment';
import React from 'react';
import {
  Animated, Image,
  Linking,
  Platform,
  SafeAreaView,
  Dimensions,
  ScrollView,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import { showMessage } from "react-native-flash-message";
import * as Progress from 'react-native-progress';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { NavigationEvents } from 'react-navigation';
import { connect } from 'react-redux';
import { getTemplate } from '../../Clickup/api';
import { bindActionCreators } from 'redux';
import { setLoader } from '../../Reducer/tia';
import { user as setUser } from '../../Reducer/user';
import { MAIN_COLOR } from "../../Utility/Colors";
import LOADER from "../../Utility/ComponentCard/Loader";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import { getDateTime, getTime, zohoTitles } from '../../Zoho/Tasks';
import { getProperty, getMarket } from '../../Clickup/api';
import fields from '../../Utility/Fields';
import styles from "./style";
import TaskDetails from '../TaskDetails';
import Presentation from '../Presentation';
import PropertyDetails from '../PropertyDetails';

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      today: new Date(),
      deal: props.deal,
      checklists: props?.checklists ?? [],
      clickUpStatusList: props?.clickUpStatusList ?? [],
      notificationIndex: props.notificationIndex,
      monthly: false,
      progress: 0,
      stage: props.deal.status.status.toUpperCase(),
      dimensions: { window: Dimensions.get('window'), screen: Dimensions.get('screen') },
      templateKeys: [],
      week: [],
      markedDates: {},
      notifications: {},
      opacity: new Animated.Value(1),
      height: new Animated.Value(0),
      template: {},
      tasks: []
    };

  }

  componentWillUnmount() {
    this.dimensionsSubscription?.remove();
  }

  componentDidUpdate() {

  }

  getVariables(str, name) {
    let vars = str.match(/\*(.*?)\*/g);
    let template = this.state.template;
    let arr = {};

    if (vars) {
      vars.map((item, index1) => {

        // console.log('item1::', item);

        let item1 = item.replace(/\*/g, '')

        if (item1.includes('[')) {
          let vars2 = item1.match(/\[(.*?)\]/g);

          vars2.map((item2, index2) => {
            item1 = item1.replace(item2, '');

            item2 = item2.replace('[', '');
            item2 = item2.replace(']', '');

            if (this.state.deal[item1]) {
              let obj = this.state.deal[item1][0];
              if (obj) {
                str = str.replace(item, obj[item2])
              }
              else {
                str = str.replace(item, this.state.deal[item1][item2])
              }
              this.setState({ vars: { ...this.state.vars, [item]: this.state.deal[item1][item2] } });
            }
            // else {
            //   this.setState({vars: {...this.state.vars, [item]: ""}});
            // }






          })
        }
        else {
          if (name === 'Videos' || name === 'Files' || name === 'Link' || name === 'FACEBOOK REVIEW' || name === 'GOOGLE REVIEW' || name === 'REVIEW') {
            // if(item1==='INVOICE URL') {


            // }

            if (this.state.deal[item1]) {
              arr[item1] = this.state.deal[item1];
            }
          }
          else {

            // 11 May
            if (item1 === 'B&P Unconditional Date' || item1 === 'Finance Unconditional Date' || item1 === 'Settlement Date') {
              str = str.replace(item, moment(this.state.deal[item1] * 1).format('DD/MM/YYYY'))
            }
            else {
              str = str.replace(item, this.state.deal[item1])
            }

          }

          this.setState({ vars: { ...this.state.vars, [item]: this.state.deal[item1] } });
        }
      })
    }

    if (name === 'Videos' || name === 'Files' || name === 'Link' || name === 'FACEBOOK REVIEW' || name === 'GOOGLE REVIEW' || name === 'REVIEW') {
      return arr;
    }
    else {
      return str;
    }
  }

  checkMarket() {
    if (this.state.deal['Market'][0]) {

      let market = this.state.deal['Market'][0];

      getMarket(market.id).then((result) => {

        if (!result.err) {

          var object = result.custom_fields.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.value }), {});

          // let videos = object['Walk Through Videos'].split('\n').map((item, index) =>{
          //   return {index: item}
          // })





          result['custom_fields'] = null;
          this.setState({ deal: { ...this.state.deal, ['Market Preso URL']: object['Market Preso URL'], ['Market Preso Video URL']: object['Market Preso Video URL'], ['Market Data']: object['Market Data'].map((item, index) => item.url) } }, () => {

            if (this.state.deal['Proposed Property']) {
              this.checkProperty();
            }
            else {
              this.populate();
            }
          });

        }
        else {
          if (this.state.deal['Proposed Property']) {
            this.checkProperty();
          }
          else {
            this.populate();
          }

        }
      })
    }
    else {
      this.populate();
    }
  }

  checkProperty() {
    if (this.state.deal['Proposed Property'][0]) {

      let deal = this.state.deal['Proposed Property'][0];

      getProperty(deal.id).then((result) => {

        if (!result.err) {

          var object = result.custom_fields.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.value }), {});

          // let videos = object['Walk Through Videos'].split('\n').map((item, index) =>{
          //   return {index: item}
          // })


          result['custom_fields'] = null;
          this.setState({ deal: { ...this.state.deal, ['Rent Appraisal Link']: object['Rent Appraisal Link'], ['Walk Through Videos']: object['Walk Through Videos'], ['Pre Settlement Inspection Videos']: object['Pre Settlement Inspection Videos'], ['Pre Settlement Inspec. Comments']: object['Pre Settlement Inspec. Comments'], ['PROPERTY STREET ADDRESS']: object['PROPERTY STREET ADDRESS'], ['Building Report']: object['Building Report'], ['Pest Report']: object['Pest Report'],
          ['Property Condition Notes']: object['Property Condition Notes']
          } }, () => {
            this.populate();
          });
          
        }
        else {
          this.populate();
        }
      })
    }
    else {
      this.populate();
    }
  }

  populate() {

    this.setState({ refreshing: true });
    let template = {};



    Object.keys(this.props.TIA.template).map((key, index) => {


      let item = this.props.TIA.template[key]

      if (!index) {
        // console.log('item::', item)
      }




      // //  let filtered =  item.custom_fields.filter((field, i)=> (field.name==='Progress' || field.name===fields.appStageName || field.name === 'Task Description'
      // //   || field.name === 'Link' || field.name === 'Files' || field.name === '2 minute update' || field.name === 'Videos'
      // //   ));



      if (item) {
        Object.keys(item).map((key2, index2) => {
          let item2 = item[key2];
          if (item2) {

            // console.log((key2))

            let r = this.getVariables(item2, key2);
            if (index === 0) {
              // console.log("\nkey:\n", key2, "\nvalue:\n", r);
            }

            template = { ...template, [key.toUpperCase()]: { ...template[key.toUpperCase()], [key2]: r } }
          }
        });
      }


      let arr = Object.keys(template);

      let stageIndex = arr.indexOf(this.state.stage);
      let progress;
      if (stageIndex < 1) {
        progress = 0;
      }
      else {
        progress = (stageIndex * 100) / (arr.length - 1);
      }
      this.props.setLoader(false);
      this.setState({ template: template, templateKeys: arr, stageIndex: stageIndex - 1, progress: progress, refreshing: false, notifications: this.props.TIA.notificationObjects[this.state.deal.id] ? this.props.TIA.notificationObjects[this.state.deal.id] : {} }, () => { });
    })
  }

  async refresh() {
    if (this.state.deal['Market']) {
      this.checkMarket();
    }
    else if (this.state.deal['Proposed Property']) {
      this.checkProperty();
    }
    else {
      this.populate();
    }
  }

  async componentDidMount() {
    this.props.setLoader(true);
    this.refresh();
    this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange);
  }

  getHeader() {
    return (<View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 10, marginTop: 25, marginHorizontal: '5%' }}>

      <TouchableOpacity onPress={() => this.props.navigation.goBack()} style={{ backgroundColor: '#FFFFFF', borderRadius: 17, height: 48, width: 48, justifyContent: 'center', alignItems: 'center' }}>

        <Image
          style={{ height: 12 }}
          resizeMode="contain"
          source={require("../../assets/arrow_left.png")}
        />

      </TouchableOpacity>


      <Text style={{ fontSize: 16, color: '#36414C', fontFamily: MAIN_FONT.semi, textAlign: 'center' }}>
        Project Details
      </Text>



      <View opacity={0} style={{ backgroundColor: '#FFFFFF', borderRadius: 10, height: 48, width: 48, justifyContent: 'center', alignItems: 'center' }}>

        <Image
          style={{ height: 15 }}
          resizeMode="contain"
          source={require("../../assets/arrow_left.png")}
        />

      </View>

    </View>)
  }

  getProjectHeader(deal) {
    return (
      <View style={{ backgroundColor: '#FFFFFF', borderRadius: 20, padding: '5%', marginBottom: 10, }}>

        <Text style={{ fontSize: 16, color: '#36414C', fontFamily: MAIN_FONT.semi, marginBottom: 5 }}>
          {deal.name}
        </Text>

        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: 5 }}>

          <Text style={{ fontSize: 14, color: '#66737F', fontFamily: MAIN_FONT.regular }}>
            Last Update :
          </Text>

          <Text style={{ fontSize: 14, color: MAIN_COLOR.G800, fontFamily: MAIN_FONT.regular, textAlign: 'right' }}>
            {getTime(deal.date_updated * 1)}  -  {moment(deal.date_updated * 1).format('DD/MM/YYYY')}
          </Text>
        </View>


        <View style={{ height: 1, width: '100%', marginVertical: 5 }} />


        <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
          <Text style={{ fontSize: 14, color: '#66737F', fontFamily: MAIN_FONT.regular, marginVertical: 5 }}>
            Project Progress:
          </Text>
          <Text style={{ fontSize: 14, color: '#36414C', fontFamily: MAIN_FONT.regular, marginVertical: 5 }}>
            {this.state.progress.toFixed(0)}%
          </Text>
        </View>

        <Progress.Bar progress={this.state.progress / 100} animationType="spring" width={500 - 50 - 50} height={15} borderRadius={10} borderColor={"transparent"} color={MAIN_COLOR.PRIMARY_COLOR} unfilledColor={"#E7ECF2"} />


      </View>
    )
  }

  render() {
    const { height, task, notifications, deal, tasks, template, stage, templateKeys, stageIndex, refreshing, index, checklists } = this.state;
    const addMin = (time) => {

      var currentDateObj = new Date(time);

      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 1 * 60 * 1000;
      var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);

      return getDateTime(newDateObj);
    }
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: MAIN_COLOR.BACKGROUND, }} keyboardShouldPersistTaps={true} behavior={Platform.OS === "ios" ? "padding" : "padding"} keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}>
        <NavigationEvents
          onWillFocus={this._onFocus}
          onWillBlur={this._onBlurr}
        />
        <View style={[styles.scrollViewContentView, { flexDirection: 'row', minWidth: 950 }]}>
          {/* {
            this.getHeader()
          } */}
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={[
              // styles.centerView, 
              {
                minWidth: 450,
                maxWidth: 450,
                paddingHorizontal: 25,
                paddingBottom: 30
              }]}

          >
            {/* {
              this.getProjectHeader(deal)
            } */}
            {/* <Text style={{ fontSize: 16, color: '#272D37', fontFamily: MAIN_FONT.regular }}>
              Project Tasks
            </Text> */}
            {
              templateKeys.length ? templateKeys.map((key, index) => {

                //don't show last stage
                if (index === Object.keys(template).length - 1) {
                  return null;
                }

                let item = template[key];

                let prevNotification = notifications[templateKeys[index]];
                let currentNotification = notifications[templateKeys[index + 1]];

                let status;
                let time = null;
                if (currentNotification) {
                  time = currentNotification.dateUpdated.seconds * 1000;
                }

               
                if (index == stageIndex) {
                  status = "Complete";
                }
                else if (index < stageIndex) {
                  if (currentNotification) {
                    status = "Complete";
                  }
                  else if (prevNotification) {
                    status = "In Progress";
                  }
                  else {
                    status = "Due"
                  }
                }
                else if (index === stageIndex + 1) {
                  if (!prevNotification) {
                    status = "Due";
                    showMessage({
                      message: 'Issue encountered with data please report to Suppport.',
                      type: "info",
                      color: '#FFF',
                      backgroundColor: MAIN_COLOR.PRIMARY_COLOR
                    });
                  }
                  else {
                    status = "In Progress";
                  }

                }
                else if (index > stageIndex + 1) {
                  status = "Due";
                }

                if (index === 4 && !deal["Walk-through videos uploaded?"]) {
                  status = "Due";
                }

                const inComplete = checklists?.find(check => check?.task_id === task?.id)?.items?.some(check => check?.resolved === false);
        
                console.log('key', key, task);
                if (key === "UNCONDITIONAL" && status !== "Due") {
                  if (!inComplete) {
                    status = 'Complete';
                  } else {
                    status = 'In Progress'
                  }
                }
                var minutes = 0;

                if (status == "In Progress") {
                  var msDiff = new Date() - new Date(prevNotification?.dateUpdated?.seconds * 1000);    //Future date - current date

                  var diff = Math.floor(msDiff / (1000 * 60));
                  minutes = diff;
                }

                let filesCount = 0, linksCount = 0, videosCount = 0;

                if (status == "Complete") {

                  filesCount = item.Files ? Object.keys(item.Files).length : 0;
                  videosCount = item.Videos ? Object.keys(item.Videos).length : 0;
                  linksCount = item.Link ? Object.keys(item.Link).length : 0;

                  if (videosCount) {
                    // alert(JSON.stringify(item.Videos))
                    if (item.Videos['Walk Through Videos']) {
                      videosCount = item.Videos['Walk Through Videos'].split('\n').length;
                    }
                    else if (item.Videos['Pre Settlement Inspection Videos']) {
                      videosCount = item.Videos['Pre Settlement Inspection Videos'].split('\n').length;
                    }
                  }
                  if (filesCount) {
                    if (item.Files['Market Data']) {
                      filesCount = item.Files['Market Data'].length;
                    }
                  }
                }



                if (index === 0 && !task) {
                  let progressTime;
                  if (status == "In Progress") {
                    progressTime = addMin(prevNotification?.dateUpdated?.seconds * 1000)
                  }
                  else {
                    progressTime = addMin(currentNotification?.dateUpdated?.seconds * 1000)
                  }
                  this.setState({
                    index,
                    task:
                      { id: this.state.deal.id, task: item, key, status: status, time: time && getDateTime(time), progressTime: progressTime, filesCount, videosCount, linksCount }
                  })
                }

                if (status === "Complete" && this.state.notificationIndex == index) {

                  this.setState({notificationIndex: null})
                  
                  let progressTime = addMin(currentNotification?.dateUpdated.seconds * 1000)
              
                  if (index === 3 && time) {
                    if (deal['Proposed Property']) {
                      this.setState({
                        index,
                        task: { property: deal['Proposed Property'] ? deal['Proposed Property'][0] : null, video: item.Videos, task: item, key, status: status, video: item.Videos, progressTime: progressTime, filesCount, videosCount, linksCount, booking: item['Booking URL'] }
                      });
                    }
                    else {
                      showMessage({
                        message: 'No property assigned.',
                        type: "info",
                        color: '#FFF',
                        backgroundColor: MAIN_COLOR.PRIMARY_COLOR
                      });
                    }
                  }
                  else if (index === 2 && status === "Complete") {
                    // this.props.navigation.navigate("Presentation", { property: deal['Proposed Property'][0], video: item.Videos, booking: item['Booking URL'] });

                    this.setState({
                      index,
                      task: { market: deal['Markets'] ? deal['Markets'][0] : null, video: item.Videos, task: item, key, status: status, video: item.Videos, progressTime: progressTime, filesCount, videosCount, linksCount, booking: item['Booking URL'] },
                    });

                  }
                  else {

                    this.setState({
                      index: index,
                      task: { id: this.state.deal.id, task: item, key, status: status, time: time && getDateTime(time), comments: index === 7 && this.state.deal['Pre Settlement Inspec. Comments'], progressTime: progressTime, filesCount, videosCount, linksCount }
                    })
                  }


                }

                return (<TouchableOpacity onPress={() => {
                  let progressTime;
                  console.log(';ssssssss ', status);
                  if (status == "In Progress") {
                    progressTime = addMin(prevNotification?.dateUpdated?.seconds * 1000)
                  }
                  else {
                    progressTime = addMin(currentNotification?.dateUpdated?.seconds * 1000)
                  }

                  if (index === 3 && time) {
                    if (deal['Proposed Property']) {
                      // this.props.navigation.navigate("PropertyDetails", { property: deal['Proposed Property'][0], video: item.Videos, booking: item['Booking URL'] })

                      this.setState({
                        index,
                        task: { property: deal['Proposed Property'] ? deal['Proposed Property'][0] : null, video: item.Videos, task: item, key, status: status, video: item.Videos, progressTime: progressTime, filesCount, videosCount, linksCount, booking: item['Booking URL'] }
                      });

                    }
                    else {
                      showMessage({
                        message: 'No property assigned.',
                        type: "info",
                        color: '#FFF',
                        backgroundColor: MAIN_COLOR.PRIMARY_COLOR
                      });
                    }
                  }
                  else if (index === 2 && status === "Complete") {
                    // this.props.navigation.navigate("Presentation", { property: deal['Proposed Property'][0], video: item.Videos, booking: item['Booking URL'] });

                    this.setState({
                      index,
                      task: { market: deal['Markets'] ? deal['Markets'][0] : null, video: item.Videos, task: item, key, status: status, video: item.Videos, progressTime: progressTime, filesCount, videosCount, linksCount, booking: item['Booking URL'] }
                    });

                  }
                  else {

                    this.setState({
                      index: index,
                      task: { id: this.state.deal.id, task: item, key, status: status, time: time && getDateTime(time), comments: index === 7 && this.state.deal['Pre Settlement Inspec. Comments'], progressTime: progressTime, filesCount, videosCount, linksCount }
                    })
                    // this.props.navigation.navigate("TaskDetails", { id: this.state.deal.id, task: item, key, status: status, time: time && getDateTime(time), progressTime: progressTime, filesCount, videosCount, linksCount })
                  }

                }} style={{ backgroundColor: '#FFFFFF', borderColor: this.state.index === index ? MAIN_COLOR.PRIMARY_COLOR : MAIN_COLOR.BACKGROUND, borderWidth: 1, borderRadius: 20, padding: '5%', marginBottom: 10, }}>

                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: 5 }}>
                    <Text style={{ fontSize: 17, color: '#36414C', fontFamily: MAIN_FONT.semi, width: '60%' }}>
                      {item[fields.appStageName]}
                    </Text>
                    <View style={{ backgroundColor: status == "In Progress" ? '#FEF1C8' : status == "Complete" ? MAIN_COLOR.PRIMARY_COLOR : '#EEF2F6', height: 25, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10, borderRadius: 25 }}>
                      <Text style={{ fontSize: 14, color: status == "Complete" ? '#FFF' : '#66737F', fontFamily: status == "Complete" ? MAIN_FONT.regular : MAIN_FONT.light, textAlign: 'right' }}>
                        {status}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.dateTitle}>
                    <Text style={{ fontSize: 14, color: '#66737F', fontFamily: MAIN_FONT.regular, }}>
                      Last Update :
                    </Text>
                    <Text style={styles.dateTime}>
                      {status === "In Progress" ? getDateTime(prevNotification?.dateUpdated?.seconds * 1000) : time ? getDateTime(time) : "N/A"}
                    </Text>
                  </View>
                  <Text style={styles.taskDescription}>
                    {item['Task Description']}
                  </Text>
                  {
                    (videosCount || filesCount || linksCount) ?
                      <View style={styles.fileViewContainer}>
                        {
                          filesCount ?

                            <View style={{ flexDirection: 'row' }}>
                              <Image
                                style={styles.fileIcon}
                                resizeMode="contain"
                                source={require("../../assets/file.png")}
                              />
                              <Text style={styles.fileType}>
                                {filesCount} Files
                              </Text>
                            </View>
                            :
                            null
                        }
                        {
                          videosCount ?
                            <View style={{ flexDirection: 'row', }}>
                              <Image
                                style={styles.fileIcon}
                                resizeMode="contain"
                                source={require("../../assets/play_circle.png")}
                              />
                              <Text style={styles.fileType}>
                                {videosCount} Videos
                              </Text>
                            </View>
                            :
                            null
                        }
                        {
                          linksCount ?
                            <View style={{ flexDirection: 'row' }}>
                              <Image
                                style={styles.fileIcon}
                                resizeMode="contain"
                                source={require("../../assets/link.png")}
                              />
                              <Text style={styles.fileType}>
                                {linksCount} Links
                              </Text>
                            </View>
                            :
                            null

                        }
                      </View>
                      :
                      null
                  }
                </TouchableOpacity>)
              })
                :
                null
            }
          </ScrollView>
          <ScrollView style={{ minWidth: 500, paddingBottom: 40  }}>

            {
              task ?
                index == 2 && task.market ?
                  <Presentation market={task.market} booking={task.booking} assignee={deal.assignees.length ? deal.assignees[0] : null } />
                  :
                  index == 3 && task.property ?
                    <PropertyDetails
                      property={deal['Proposed Property'][0]}
                      video={task.video}
                      booking={task.booking}
                      assignee={deal.assignees.length ? deal.assignees[0] : null}
                      taskId={deal.id}
                    />
                    :
                    <TaskDetails
                      clickUpStatusList={this.state.clickUpStatusList}
                      checklists={this.state.checklists}
                      comments={task.comments}
                      linkVideo={task['Link Video']}
                      id={task.id} task={task.task} key={task.key} status={task.status} time={task.time} progressTime={task.progressTime} filesCount={task.filesCount} videosCount={task.videosCount} linksCount={task.linksCount}
                      propertyConditionNotes={ deal['Property Condition Notes'] || ""}
                      solicitorName={deal["SOLICITOR'S NAME"] || null}
                      solicitorPhone= {deal["SOLICITOR'S PHONE"] || null}
                      solicitorEmail= {deal["Solicitor's Email"] || null}
                      brokerName= {deal["MORTGAGE BROKER"] || null}
                      brokerEmail= {deal["MORTGAGE BROKER EMAIL"] || null}
                      brokerPhone= {deal["MORTGAGE BROKER PHONE"] || null}
                      oppotunityAssigneeEmail= {deal?.assignees?.length ? deal?.assignees[0]?.email : null}
                      oppotunityHeadBuyerAgentEmail= {deal['OPPORTUNITY OWNER']?.length ? deal['OPPORTUNITY OWNER'][0]?.email : null}
                      bpInspectorName= {deal['B&P Inspector'] || null}
                      bpInspectorEmail= {deal['B&P Inspector Email'] || null}
                      bpInspectorPhone= {deal['B&P Inspector Phone'] || null}
                      propertyManagerName= {deal['Property Manager'] || null}
                      propertyManagerEmail= {deal['Property Manager Email'] || null}
                      propertyManagerPhone= {deal['Property Manager Phone'] || null}
                    />
                :
                null
            }
          </ScrollView>
        </View>
        {/* {!templateKeys.length ? <LOADER /> : null} */}
      </SafeAreaView>
    );
  }
};

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    TIA: state.TIA
  }
}

function mapDispatchToProps(dispatch) {


  return bindActionCreators(
    {
      setUser,
      setLoader,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);