import React from 'react';
import {
    Image, ScrollView, Text, View
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { connect } from 'react-redux';
import { SLIDE_MENU } from '../../Utility/ItemInfo';
import style from "./style";
//Component 


class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            color: "",
        };
    }


    renderFlatListItem({ item, index }) {
        return (
            <View style={style.qualificationView}>
                <View style={style.qualifPointImg} />
                <Text style={style.qualifiText}>{item}</Text>
            </View>
        )
    }

    renderMenuItem = (item, index) => {
        return (
            <TouchableOpacity style={{ ...style.navItemBar }} 
            // onPress={() => item.label === "Share"? onShare() : this.props.navigation.navigate(item.navigate_screen) && this.props.navigation.closeDrawer()}
            >
                {/* <Image
                    style={style.menuIcon}
                    resizeMode="cover"
                    source={item.icon}
                /> */}
                {
                    item.label === 'Portfolio'?
                    <Ionicons name={item.icon} style={[style.menuIcon, {fontSize: 16}]}/>
                    :
                    item.label === 'Project'?
                    <Feather name={item.icon} style={[style.menuIcon, {fontSize: 16}]}/>
                    :
                    <AntDesign name={item.icon} style={[style.menuIcon, {fontSize: 16}]}/>
                }                


                <Text style={style.navText} numberOfLines={1}>{item.label}</Text>
            </TouchableOpacity>
        )
    }

    render() {

        const user_info = {};

        return (
            <View style={{ ...style.container }}>
                <View style={style.topView}>
                    <View style={style.itemImage}>
                        <Image
                            style={{ width: "100%", height: "100%" }}
                            //resizeMode="contain"
                            source={{uri:   user_info.image}}
                        />
                    </View>
                    <Text style={style.nameText} numberOfLines={1}>TIA</Text>
                    {/* <Image
                        style={style.itemImageBackground}
                        resizeMode="cover"
                        source={{uri:  user_info.image}}
                    /> */}
                </View>
                <ScrollView contentContainerStyle={{ flex: 1 }}>

                    <View style={style.centerView}>
                         {SLIDE_MENU.map((item,index)=> this.renderMenuItem(item,index))}
                    </View>

                </ScrollView>

                <View style={style.bottomView}>
                    <TouchableOpacity 
                    // onPress={()=>{clearAsyncStorage() && this.props.navigation.navigate("LoginScreen", {registered: true})}} 
                    style={style.navItemBar_bottom}>
                        <Text style={[style.navText_bottom, {fontWeight: 'bold'}]} numberOfLines={1}>Logout</Text>
                    </TouchableOpacity>
                    {/* <TouchableOpacity style={{ ...style.navItemBar_bottom }} onPress={()=> this.props.navigation.navigate("ContactUsScreen")}>
                        <Text style={style.navText_bottom} numberOfLines={1}>Contact Us</Text>
                    </TouchableOpacity> */}

                    <Text style={style.versionText} numberOfLines={1}>Version 1</Text>


                </View>

            </View>
        );
    }

}

function mapStateToProps(state) {
    return {
      USER: state.USER,
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(App);