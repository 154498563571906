import { StyleSheet } from "react-native";
import { MAIN_COLOR } from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderWidth: 1,
    borderColor: "rgba(173, 185, 199, 0.3)",
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    borderRadius: 15,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: 12,
  },
  title: {
    fontFamily: MAIN_FONT.semi,
    fontWeight: 600,
    fontSize: 16,
    color: MAIN_COLOR.G800,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  notes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  noteItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  round: {
    width: 16,
    height: 16,
    borderRadius: "50%",
    marginRight: 12,
    backgroundColor: MAIN_COLOR.yellow,
  },
  textLabel: {
    fontWeight: 400,
    fontSize: 12,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.regular,
  },
  unitLabel: {
    fontWeight: 500,
    fontSize: 9,
    fontFamily: MAIN_FONT.regular,
    color: "#200E32",
  },
});
