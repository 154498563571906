import { Dimensions, StyleSheet } from "react-native";
import { MAIN_FONT } from "../../../Utility/Fonts/font";
import { MAIN_COLOR } from "../../../Utility/Colors";

var styles;

export default styles = StyleSheet.create({
  container: {
    marginTop: 32,
    display: "flex",
    flexDirection: "row",
    gap: 20,
    zIndex: -1,
    position: "absolute",
    padding: 25,
    top: 80,
    bottom: 0,
    left: 0,
    right: 0,
    flex: 1,
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.regular,
  },
  listPurchase: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    maxHeight: 1030,
  },
  content: {
    marginTop: 16,
    position: "relative",
    flex: 1,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    gap: 21,
  },
  blurView: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
  },
});
