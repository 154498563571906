/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import {
  View,
  ActivityIndicator,
  SafeAreaView,
  Dimensions,
} from "react-native";
import { connect } from "react-redux";
import {
  BarIndicator,
  DotIndicator,
  MaterialIndicator,
} from "react-native-indicators";
import styles from "./style";
import { MAIN_COLOR } from "../../Colors";
import { PropagateLoader } from "react-spinners";

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
  render() {
    return (
      <View
        style={{
          height: Dimensions.get("window").height,
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          position: "absolute",
          width: "100%",
        }}
      >
        <PropagateLoader color="#486868" style={{ margin: "auto" }} />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // setUserState: (userState) => dispatch(setUserStateRedux(userState))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
