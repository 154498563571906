import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { MAIN_COLOR } from "../../Colors";
import { MAIN_FONT } from "../../Fonts/font";
import { Linking } from "react-native";

const ContactCard = ({
  type = "Legal Partner",
  name,
  phoneNumber,
  email,
  employeeAvatar,
  onCopyPhone,
  backgroundColor,
  styles
}) => {
  const getDefaultAvatar = (type) => {
    switch (type) {
      case "Legal Partner":
        return require("../../../assets/default-legal-partner.png");
      case "Financial Partner":
        return require("../../../assets/default-financial-partner.png");
      case "Property Manager":
        return require("../../../assets/default-property-manager.png");
      case "Building and Pest Inspector":
        return require("../../../assets/default-b&p.png");
      case "Quantity Surveyor":
        return require("../../../assets/default-quantity-surveyor.png");
      default:
        return require("../../../assets/default-legal-partner.png");
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return;

    const cleanedPhone = phoneNumber
      .replace(/\s/g, "")
      .replace(/\b0+/g, "")
      .replace(/^\+61\s*/, "");
    if (cleanedPhone.length === 9) {
      return cleanedPhone.replace(/^(\d)(\d{4})(\d{4})$/, "+61 $1 $2 $3");
    }

    return cleanedPhone.replace(/^(\d{4})(\d{3})(\d{3})$/, "+61 $1 $2 $3");
  };

  const onCall = () => {
    // Linking.openURL(`tel:${phoneNumber}`);
    navigator.clipboard.writeText(formatPhoneNumber(phoneNumber));
    onCopyPhone();
  };

  const onEmail = () => {
    Linking.openURL(`mailto:${email}`);
  };

  return (
    <View
      style={{
        padding: 17,
        backgroundColor: backgroundColor ?? "#FFFFFF",
        borderRadius: 20,
        ...styles,
      }}
    >
      {employeeAvatar ? (
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View>
            <Text
              style={{
                fontSize: 16,
                color: MAIN_COLOR.G800,
                fontFamily: MAIN_FONT.semi,
                marginBottom: 6,
              }}
            >
              {name}
            </Text>
            <Text
              style={{
                fontSize: 13,
                color: MAIN_COLOR.G700,
                fontFamily: MAIN_FONT.regular,
              }}
            >
              {type}
            </Text>
          </View>
          <Image
            source={{ uri: employeeAvatar }}
            resizeMode="cover"
            style={{
              height: 66,
              width: 66,
              borderRadius: 33,
              marginRight: 19,
              borderWidth: 2,
              borderColor: MAIN_COLOR.PRIMARY_COLOR,
            }}
          />
        </View>
      ) : (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Image
            source={getDefaultAvatar(type)}
            resizeMode="cover"
            style={{
              height: 66,
              width: 66,
              borderRadius: 33,
              marginRight: 19,
            }}
          />
          <View style={{ flexShrink: 1 }}>
            <Text
              style={{
                fontSize: 16,
                color: MAIN_COLOR.G800,
                fontFamily: MAIN_FONT.semi,
                marginBottom: 6,
              }}
            >
              {type}
            </Text>
            <Text
              style={{
                fontSize: 13,
                color: MAIN_COLOR.G700,
                fontFamily: MAIN_FONT.regular,
              }}
            >
              {name}
            </Text>
          </View>
        </View>
      )}
      <View style={{ flexDirection: "row", marginTop: 14 }}>
        <TouchableOpacity
          style={{
            flexDirection: "row",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: employeeAvatar
              ? MAIN_COLOR.PRIMARY_COLOR
              : backgroundColor ? '#FFFFFF' : MAIN_COLOR.G100,
            borderRadius: 8,
            padding: 8,
            marginRight: 7.5,
          }}
          onPress={onCall}
          disabled={!phoneNumber}
        >
          <Image
            style={{
              marginRight: 8,
              resizeMode: "contain",
              width: 16,
              height: 16,
            }}
            source={
              employeeAvatar
                ? require("../../../assets/white-call.png")
                : require("../../../assets/call.png")
            }
          />
          <Text
            style={{
              color: employeeAvatar ? "#FFFFFF" : MAIN_COLOR.G800,
              // fontFamily: MAIN_FONT.medium,
              fontSize: 13,
              whiteSpace: "nowrap",
            }}
          >
            {formatPhoneNumber(phoneNumber)}
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={{
            flexDirection: "row",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: employeeAvatar
              ? MAIN_COLOR.PRIMARY_COLOR
              : backgroundColor ? '#FFFFFF' : MAIN_COLOR.G100,
            borderRadius: 8,
            padding: 8,
            marginLeft: 7.5,
          }}
          onPress={onEmail}
          disabled={!email}
        >
          <Image
            style={{
              marginRight: 8,
              resizeMode: "contain",
              width: 16,
              height: 16,
            }}
            source={
              employeeAvatar
                ? require("../../../assets/white-sms.png")
                : require("../../../assets/sms.png")
            }
          />
          <Text
            style={{
              color: employeeAvatar ? "#FFFFFF" : MAIN_COLOR.G800,
              // fontFamily: MAIN_FONT.medium,
              fontSize: 13,
            }}
          >
            Email
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default ContactCard;
