import WebView from 'react-native-webview';
import React from 'react';
import {
  Image, Linking, Platform,
  SafeAreaView, Text, TouchableOpacity, View,
  Share
} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import { NavigationEvents } from 'react-navigation';
import { connect } from 'react-redux';
import { setColors } from "../../Action/color";
import { user as setUser } from '../../Reducer/user';
import { MAIN_COLOR } from "../../Utility/Colors";
import LOADER from "../../Utility/ComponentCard/Loader";
import { MAIN_FONT } from "../../Utility/Fonts/font";
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      link: props.navigation.state.params.link,
      loading: true,
      video: props.navigation.state.params.video

    };

  }

  componentWillUnmount() {
    Linking.removeEventListener();
  }

  render() {

    const { link, loading, video } = this.state;

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: video? "#000000":MAIN_COLOR.BACKGROUND }} keyboardShouldPersistTaps={true} behavior={Platform.OS === "ios" ? "padding" : "padding"} keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}>
        <NavigationEvents
          onWillFocus={this._onFocus}
          onWillBlur={this._onBlurr}
        />


        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 10, marginTop: 25, marginHorizontal: '5%' }}>

          <TouchableOpacity onPress={() => this.props.navigation.goBack()} style={{ backgroundColor: '#FFFFFF', borderRadius: 17, height: 48, width: 48, justifyContent: 'center', alignItems: 'center' }}>

            <Image
              style={{ height: 12 }}
              resizeMode="contain"
              source={require("../../assets/arrow_left.png")}
            />

          </TouchableOpacity>


          <Text style={{ fontSize: 16, color: '#36414C', fontFamily: MAIN_FONT.semi, textAlign: 'center' }}>
            Web Link
          </Text>



          <TouchableOpacity onPress={() => {
            Share.share({
              message: ""+link
            })
          }

          } style={{ backgroundColor: '#ffffff', borderRadius: 10, height: 48, width: 48, justifyContent: 'center', alignItems: 'center' }}>

            <Icon name="sharealt" style={{ fontSize: 18, color: MAIN_COLOR.G800 }} />

          </TouchableOpacity>

        </View>

        <WebView
          style={{ flex: 1, backgroundColor: video?'#000000':'transparent' }}
          javaScriptEnabled={true}
          source={{ uri: link }}
          // startInLoadingState
          opacity={0.99}
          scrollEnabled
          originWhitelist={['*']}
          decelerationRate={"normal"}
          contentMode="mobile"
          useWebKit={true}
          allowFileAccess={true}
          scalesPageToFit
          androidHardwareAccelerationDisabled
          domStorageEnabled={true}

          allowsFullscreenVideo
          allowsInlineMediaPlayback
          allowsBackForwardNavigationGestures
          onLoadStart={()=>this.setState({loading: true})}
          onLoadEnd={()=>this.setState({loading: false})}

        />

        {(loading && !video) ? <LOADER /> : null}

      </SafeAreaView>
    );
  }
};


function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setUserInfo: (state, action) => dispatch(setUser(state, action)),
    setUserToken: (state, action) => dispatch(setUser(state, action)),
    setActiveSupplier: (id, host) => dispatch(setActiveSupplier(id, host)),
    setColorsToRedux: (data) => dispatch(setColors(data)),
    setActiveSupplierInfo: (data) => dispatch(setActiveSupplierInfo(data)),
    setSupplierList: (list) => dispatch(setSupplierList(list)),
    setCartInfo: (id, total) => dispatch(setCartInfo(id, total))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);