import React from 'react';
import {
  Animated,
  Dimensions,
  Easing,
  Share,
  Image,
  Keyboard,
  Linking, Modal, Platform, RefreshControl, SafeAreaView,
  ScrollView,
  Text, TouchableOpacity, View
} from 'react-native';
import moment from 'moment';
import { Calendar } from 'react-native-calendars';
import { Document, Page } from 'react-pdf';
import { showMessage } from "react-native-flash-message";
import * as Progress from 'react-native-progress';
import RBSheet from "react-native-raw-bottom-sheet";
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import Icon from 'react-native-vector-icons/AntDesign';
import { NavigationEvents } from 'react-navigation';
import { bindActionCreators } from 'redux';
import '@firebase/firestore';
import firebase from 'firebase';
import { connect, useDispatch } from 'react-redux';
import { clearAsyncStorage } from '../../Action/asyncCtrl';
import { setColors } from "../../Action/color";
import { user as setUser } from '../../Reducer/user';
import { setOpportunities, setNotifications, setNotificationsObject, setLoader, setOpenDetails, setCurrentScreen } from '../../Reducer/tia';
import { MAIN_COLOR } from "../../Utility/Colors";
import LOADER from "../../Utility/ComponentCard/Loader";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import Presentation from "../Presentation";
import Portfolio from "../Folio";
import NotificationsScreen from "../Notifications";
import ProjectDetails from "../ProjectDetails";
import tasks, { zohoTitles, getTime, format } from '../../Zoho/Tasks';
import { NOTIFICATIONS, OPPORTUNITIES, PROPERTIES } from '../../Utility/ReduxType';
import { Login, Notifications } from '../../Zoho/Zoho';
import styles from "./style";
import { getBrandingByLeadSourceValue, getLeads } from '../../Clickup/api';
import { getImageSize } from 'react-image-size';
import PortfolioRoadmap from '../PortfolioRoadmap';
import MediaQuery from 'react-responsive';
import { ROLES, usePermission } from '../../hooks/usePermission';
import { ROLE_OPTIONS } from '../../Utility/portfolio-roadmap';
import AsyncStorage from '@react-native-async-storage/async-storage';

const FeaturePermissionWrapper = ({ children, feature }) => {
  const { isView } = usePermission(feature);
  return isView ? children : <></>
}

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      today: new Date(),
      monthly: false,
      week: [],
      markedDates: {},
      checklists: [],
      selectedIndex: 0,
      clickUpStatusList: [],
      refreshing: false,
      opacity: new Animated.Value(1),
      currentScreen: this.props.TIA.role === "Partner" ? "PortfolioRoadmap" : this.props.TIA.currentScreen,
      height: new Animated.Value(0),
      email: this.props.TIA.client.email,
      password: null,
      dimensions: { window: Dimensions.get('window'), screen: Dimensions.get('screen') },
      leads: [],
      notTab: Dimensions.get('window').width > 1024 ? true : false,
      isSmall: Dimensions.get('window').height < 900 ? true : false,
      allBrandingLogo: [],
      mountInHome:false
    };

  }

  getNextSevenDays = () => {

    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6 

    let arr = [];

    for (let i = 0; i < 7; i++) {
      arr.push(new Date(curr.setDate(first + i)));
    }

    this.setState({ week: arr, selected: new Date().getDate() })

  }

  componentWillUnmount() {
    this.dimensionsSubscription?.remove();
  }

  componentDidUpdate(prevProp, prevState) {
    if(prevState.currentScreen !== "Home" && this.state.currentScreen === "Home"){
      if(!this.state.mountInHome){
        this.props.setLoader(true);
        this.refresh();
      }
    }   
  }

  getNotifications() {

    var db = firebase.firestore();
    let allLeadsId = 'd22875df-8bfe-430f-8ab9-c33736223f47';
    let leads = this.props.TIA.client.custom_fields.filter((item, index) => item.id === allLeadsId);
    let leadsIds = {};

    if (leads[0].value) {
      leads[0].value.map((item, index) => {
        // leadsIds.push(db.doc(collectionName+item.id));
        leadsIds[item.id] = true;
      })
    }

    db.collection('notifications').get().then((querySnapshot) => {

      let arr = [];
      querySnapshot.forEach((doc) => {
        if (leadsIds[doc.id]) {
          this.props.setNotificationsObject({ id: doc.id, data: doc.data() });
          arr = arr.concat(Object.values(doc.data()));
        }
        arr.sort(function (a, b) { return new Date(b.dateUpdated.seconds * 1000) - new Date(a.dateUpdated.seconds * 1000) });

        this.getMarkedDates(arr);
        this.props.setNotifications(arr);
      });

      db.collection('notifications').onSnapshot((querySnapshot) => {
        arr = [];
        querySnapshot.forEach((doc) => {
          if (leadsIds[doc.id]) {
            this.props.setNotificationsObject({ id: doc.id, data: doc.data() });
            arr = arr.concat(Object.values(doc.data()));
          }
        });
        arr.sort(function (a, b) { return new Date(b.dateUpdated.seconds * 1000) - new Date(a.dateUpdated.seconds * 1000) });
        this.props.setNotifications(arr);
        this.getMarkedDates(arr);
        this.getOpportunities();
      })



    }).catch(error => {
      alert(error.message);
    });
  }

  async getOpportunities() {

    let allLeadsId = 'd22875df-8bfe-430f-8ab9-c33736223f47';
    let allLeads = [];
    let leads = this.props.TIA.client.custom_fields.filter((item, index) => item.id === allLeadsId);

    if (leads[0].value) {
      leads[0].value.map((item, index) => {
        getLeads(item.id).then(result => {
          const checklists = result?.checklists ?? [];
          this.setState({ checklists: this.state.checklists.concat([...checklists]), clickUpStatusList: this.state.clickUpStatusList.concat([{ taskId: result?.id, status: result?.status?.status ?? '' }]) });
          var object = result?.custom_fields?.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.value }), {});
          result['custom_fields'] = null;
          allLeads.push({ ...result, ...object });
          if (allLeads.length === leads[0].value.length) {
            this.props.setLoader(false);
            this.setState({ refreshing: false, leads: allLeads });

            this.props.setOpportunities(allLeads);

            Promise.all(allLeads.map((one) => getBrandingByLeadSourceValue(one["Lead Source"]))).then(async (result) => {
              for (let i = 0; i < result.length; i++) {
                const item = result[i];
                const coLabelingLogo = item?.tasks?.[0]?.custom_fields.find((one) => one.name === "Co-labeling logo")?.value?.[0].url;
                if (coLabelingLogo) {
                  const {height, width} = await getImageSize(coLabelingLogo);
                  const fixedWidth = (40 * width / height) > 70 ? 70 : (40 * width / height);
                  item.fixedWidth = fixedWidth;
                }
              }
              this.setState({ allBrandingLogo: result});
            });
          }
        }).catch(error => {
          this.props.setLoader(false);
          alert(error.message)
          showMessage({
            message: error.message,
            type: "info",
            color: '#FFF',
            backgroundColor: MAIN_COLOR.PRIMARY_COLOR
          });
        })
      })
    } else {
      this.props.setLoader(false);
    }
  }

  async refresh() {
    this.getOpportunities();
    this.getNotifications();
  }

  onChange = ({ window, screen }) => {
    this.setState({ dimensions: { window, screen } });
  };

  componentDidMount() {
    const { role } = this.props.TIA
    Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        this.setState({ notTab: window.width > 1024 ? true : false, isSmall: window.height < 900 ? true : false })
      }
    );


    this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange);
    
    this.getNextSevenDays();
    if (role.toLowerCase() === ROLES.CLIENT) {
      if(this.state.currentScreen === "Home"){
        this.props.setLoader(true);
        this.refresh();
        this.setState({mountInHome:true})
      }
    } else {
      this.setState({ currentScreen: "PortfolioRoadmap" });
    }

    this.props.setOpenDetails('');

    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (window.location.pathname === "/verify-email" && params.get("email")) {
      this.props.navigation.navigate("SignUpScreen");
    }
    if (window.location.pathname.indexOf("scenario-detail") !== -1 && params.get("scenario_id")) {
      this.setState({ currentScreen: "PortfolioRoadmap" });
    }
  }

  renderFlatListItem(item, index) {
    let stage = item?.status?.status?.toUpperCase();
    const coLabelingLogo = this.state.allBrandingLogo[
      index
    ]?.tasks?.[0]?.custom_fields.find((one) => one.name === "Co-labeling logo")?.value?.[0].url;
    const fixedWidth = this.state.allBrandingLogo[index]?.fixedWidth || 70;
    let allStages = Object.keys(this.props.TIA.template);

    let progress = 0;

    let stageIndex = allStages.indexOf(stage);

    if (stageIndex < 1) {
      progress = 0;
    }
    else {
      progress = (stageIndex * 100) / (allStages.length - 1);
    }

    return (
      <TouchableOpacity
        onPress={
          () =>
            this.setState({
              currentScreen: "ProjectDetails",
              selectedIndex: index,
            })
          // this.props.navigation.navigate("ProjectDetails", { item: item })
        } style={{ backgroundColor: '#FFFFFF', borderRadius: 10, padding: 25, width: 360, marginBottom: 20, marginRight: 20 }}>
        <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <View style={{ flexShrink: 1, width: "100%" }}>
            {
              coLabelingLogo ? (
                <View style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                  <View style={{ minHeight: 54, flex: 2, marginBottom: 5 }}>
                    <Text style={{ fontSize: 17, color: '#36414C', fontFamily: MAIN_FONT.semi }}>
                      {item.name}
                    </Text>
                  </View>
                  <View style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    {!!coLabelingLogo && (
                      <Image
                        style={{
                          height: 40,
                          width: fixedWidth,
                        }}
                        resizeMode='contain'
                        source={coLabelingLogo}
                      />
                    )}
                  </View>
                </View>
              ) : (
                <View style={{ minHeight: 54, flex: 2, marginBottom: 5}}>
                  <Text style={{ fontSize: 17, color: '#36414C', fontFamily: MAIN_FONT.semi }}>
                    {item.name}
                  </Text>
                </View>
              )
            }


            <Text style={{ fontSize: 14, color: '#66737F', fontFamily: MAIN_FONT.regular, marginBottom: 5 }}>
              Last Update :
            </Text>

            <Text style={{ fontSize: 14, color: '#66737F', fontFamily: MAIN_FONT.regular, marginBottom: 5 }}>
              {getTime(item.date_updated * 1)}  -  {moment(item.date_updated * 1).format('DD/MM/YYYY')}
            </Text>
          </View>
        </View>

        <View style={{ height: 1, backgroundColor: '#E7ECF2', width: '100%', marginVertical: 5 }} />

        <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
          <Text style={{ fontSize: 14, color: '#66737F', fontFamily: MAIN_FONT.regular, marginVertical: 5 }}>
            Project Progress:
          </Text>
          <Text style={{ fontSize: 14, color: '#36414C', fontFamily: MAIN_FONT.regular, marginVertical: 5 }}>
            {progress.toFixed(0)}%
          </Text>
        </View>


        <Progress.Bar progress={progress / 100} animationType="spring" width={310} height={15} borderRadius={10} borderColor={"transparent"} color={MAIN_COLOR.PRIMARY_COLOR} unfilledColor={"#E7ECF2"} />


      </TouchableOpacity>
    )
  }

  goToTaskDetails(taskNo, id) {

    // if (taskNo == 11) {
    //   this.props.navigation.navigate("Portfolio");
    //   return null
    // }


    // let deal = this.props.TIA.client.USER_INFO.find((item, index) => item.id == id);

    // if (deal) {
    //   let taskArr = tasks(deal);
    //   this.setState({ monthly: false });
    //   this.props.navigation.navigate("TaskDetails", { task: taskArr[taskNo], status: "Complete" })
    // }
  }

  getMarkedDates(notifications) {
    let markedDates = {};
    notifications.map((item, index) => {

      markedDates[moment(item.dateUpdated.seconds * 1000).format('YYYY-MM-DD')] = {
        selected: true,
        marked: true,
        selectedColor: MAIN_COLOR.PRIMARY_COLOR,
        dotColor: '#000',
        date: moment(item.dateUpdated.seconds * 1000),
        taskId: item.taskId,
        statusName: item.statusName
      };


    });
    this.setState({ markedDates });
  }


  render() {

    const { today, week, selected, markedDates, monthly, height, email, password, refreshing, leads, notTab, isSmall } = this.state;

    const maxHeight = height.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1000]  // <-- value that larger than your content's height
    });

    let start_date = { startingDay: true, color: MAIN_COLOR.PRIMARY_COLOR, textColor: 'white', backgroundColor: 'red', };
    let date = { disabled: true, color: 'black', textColor: 'gray', startingDay: false };
    let end_date = { disabled: true, endingDay: true, color: MAIN_COLOR.PRIMARY_COLOR, backgroundColor: 'red', textColor: 'white', };

    const weekly = () => {

      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

      return (
        <Animated.View style={[styles.topView, { backgroundColor: '#ffffff', width: "100%", flexDirection: 'row', borderBottomStartRadius: notTab ? 0 : 20, borderBottomEndRadius: notTab ? 0 : 20 }]}>
          <View style={[styles.row, { width: "50%" }]}>
            <TouchableOpacity onPress={() => this.RBSheet.open()} style={{ backgroundColor: MAIN_COLOR.PRIMARY_COLOR, height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, marginRight: 10 }}>
              <Text style={{ fontSize: 21, color: '#FFFFFF', textAlign: 'center' }}>
                {this.props.TIA.client.name[0]}
              </Text>

            </TouchableOpacity>
            <View>
              <Text style={{ fontSize: 16, color: '#272D37', fontFamily: MAIN_FONT.regular }}>
                Welcome back,
              </Text>

              <Text style={{ fontSize: 24, color: '#272D37', fontFamily: MAIN_FONT.semi }}>
                {this.props.TIA.client.name}
              </Text>
            </View>
          </View>

          <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({ monthly: true }, () => {
            showContent();
          })}
            style={{ width: "50%" }}
          >
            <View style={[styles.row, { alignItems: 'flex-end', justifyContent: 'space-between' }]}>

              <Text style={{ fontSize: 16, color: '#36414C', fontFamily: MAIN_FONT.regular }}>
                Today
              </Text>

              <View style={[styles.row, {
                alignItems: 'flex-end',
                width: 'auto',

                // shadowOffset: { width: 1, height: 1 },
                // shadowOpacity: 0.7,
                // shadowColor: '#EFEFEF',
                tintColor: MAIN_COLOR.logo_blue,
                // shadowRadius: 2,
                // elevation: 3


              }]}>
                <Text style={{
                  fontSize: 14, color: '#66737F', fontFamily: MAIN_FONT.regular,
                }}>
                  {moment(today).format('DD/MM/YYYY')}
                </Text>
                <Image
                  style={{ width: 20, height: 20, marginLeft: 5 }}
                  resizeMode="contain"
                  source={require("../../assets/arrow_down.png")}
                />
              </View>
            </View>

            <View style={[styles.row, { marginBottom: 0, alignItems: 'flex-end', justifyContent: 'space-between' }]}>
              {
                week.map((item, index) => {
                  return (
                    <View
                      // onPress={() => { this.setState({ selected: d }) }}
                      style={{ height: 50, width: 50, borderRadius: 25, justifyContent: 'center', alignItems: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#8f9ca9', fontFamily: MAIN_FONT.regular }}>{days[index]}</Text>
                    </View>
                  )
                })
              }
            </View>

            <View style={[styles.row, { alignItems: 'flex-end', justifyContent: 'space-between' }]}>
              {
                week.map((item, index) => {
                  let d = item.getDate();
                  return (
                    <View
                      // onPress={() => { this.setState({ selected: d }) }}
                      style={{ height: 50, width: 50, borderRadius: 25, justifyContent: 'center', alignItems: 'center', borderWidth: d === selected ? 2 : 0, borderColor: MAIN_COLOR.PRIMARY_COLOR, backgroundColor: '#FFF' }}>
                      <View style={{ height: 35, width: 35, borderRadius: 20, justifyContent: 'center', alignItems: 'center', backgroundColor: d === selected ? '#272D37' : '#E7ECF2' }}>
                        <Text style={{ fontSize: 12, color: d === selected ? '#ffffff' : '#66737F', fontFamily: d === selected ? MAIN_FONT.semi : MAIN_FONT.regular }}>
                          {
                            d
                          }
                        </Text>
                      </View>
                    </View>
                  )
                })
              }
            </View>
          </TouchableOpacity>
        </Animated.View>
      )
    };

    const showContent = () => {
      const { opacity, height } = this.state;

      Animated.timing(height, {
        toValue: 1,
        duration: 500,
        easing: Easing.linear,
        useNativeDriver: false  // <-- neccessary
      }).start(() => {
        Animated.timing(opacity, {
          toValue: 1,
          duration: 500,
          easing: Easing.linear,
          useNativeDriver: false  // <-- neccessary
        }).start();
      });
    };

    const disableContent = () => {
      const { opacity, height } = this.state;

      Animated.timing(height, {
        toValue: 1,
        duration: 500,
        easing: Easing.linear,
        useNativeDriver: false  // <-- neccessary
      }).start(() => {
        Animated.timing(opacity, {
          toValue: 1,
          duration: 500,
          easing: Easing.linear,
          useNativeDriver: false  // <-- neccessary
        }).start();
      });
    };


    const calendar = () => {
      return (

        <TouchableOpacity activeOpacity={1} style={[{ backgroundColor: '#fff', width: notTab ? "38%" : "68%", alignSelf: 'flex-end', paddingBottom: 20, borderBottomRightRadius: 20, borderBottomLeftRadius: 20 }, {

        }]}>

          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingTop: 25, paddingHorizontal: '5%', marginBottom: 10, backgroundColor: '#FFF' }}>

            <TouchableOpacity onPress={() => this.setState({ monthly: false }, () => {
              this.setState({
                opacity: new Animated.Value(1),
                height: new Animated.Value(0)
              })
              // disableContent();
            })} activeOpacity={0.8} style={{ borderRadius: 17, height: 48, width: 48, justifyContent: 'center', alignItems: 'center' }}>

              <Image
                style={{ height: 16 }}
                resizeMode="contain"
                source={require("../../assets/arrow_left.png")}
              />

            </TouchableOpacity>


            <Text style={{ fontSize: 16, color: '#36414C', fontFamily: MAIN_FONT.semi, textAlign: 'center' }}>
              Select Date
            </Text>



            <TouchableOpacity onPress={() => { this.setState({ monthly: false }) }} opacity={0} style={{ backgroundColor: '#FFFFFF', borderRadius: 10, height: 48, width: 48, justifyContent: 'center', alignItems: 'center' }}>

              <Icon name="close" style={{ fontSize: 18, color: MAIN_COLOR.G800 }} />

            </TouchableOpacity>


          </View>

          <Calendar

            style={{ fontFamily: MAIN_FONT.semi, width: '90%', left: '5%' }}

            renderDay={() => "A"}

            // renderArrow={(direction) => direction === 'left' ? <View style={{ width: 40, justifyContent: 'center', alignSelf: 'center', height: 40, borderRadius: 40, borderWidth: 1, borderColor: '#1e1e1e' }}>
            //   <Image source={require('../../assets/arrow_left.png')} style={{ width: 15, height: 15, alignSelf: 'center', resizeMode: 'contain' }} />
            // </View>
            //   : <View style={{ width: 40, justifyContent: 'center', alignSelf: 'center', height: 40, borderRadius: 40, borderWidth: 1, borderColor: '#1e1e1e' }}>
            //     <Image source={require('../../assets/arrow_left.png')} style={{ width: 15, height: 15, alignSelf: 'center', resizeMode: 'contain' }} />
            //   </View>}

            accessibilityLabel="red"
            onDayPress={(day) => {

              let item = markedDates[day.dateString];

              // alert(item.taskId)

              if (item) {

                let lead = this.props.TIA.opportunities.map((lead, index) => {

                  if (lead.id === item.taskId) {
                    this.setState({ monthly: false })
                    this.setState({ currentScreen: "ProjectDetails", selectedIndex: index });
                    // this.props.navigation.navigate("ProjectDetails", { item: lead })
                  }
                });




              }



              // this.goToTaskDetails(item.App_Task_No, item.Parent_Id.id);

              // if (dates.length) {
              //   if (new Date(day.dateString) > new Date(dates[dates.length - 1])) {


              //     if (new Date(day.dateString) > new Date(this.state.endDate)) {
              //       let obj1 = { [day.dateString]: end_date, ...this.state.markedDates };

              //       if (this.state.endDate !== dates[dates.length - 1]) {
              //         obj1[this.state.endDate] = date;
              //       }

              //       this.setState({ markedDates: obj1, endDate: day.dateString });
              //     }
              //     else {

              //       let obj1 = { [day.dateString]: date, ...this.state.markedDates };
              //       this.setState({ markedDates: obj1 })
              //     }

              //   }
              //   else {

              //     if (new Date(day.dateString) < new Date(this.state.startDate)) {
              //       let obj1 = { [day.dateString]: start_date, ...this.state.markedDates };

              //       if (this.state.startDate !== day.dateString) {
              //         obj1[this.state.startDate] = date;
              //       }

              //       this.setState({ markedDates: obj1, startDate: day.dateString });
              //     }
              //     else {

              //       let obj1 = { [day.dateString]: date, ...this.state.markedDates };
              //       this.setState({ markedDates: obj1 })
              //     }


              //   }
              // }
              // else {
              //   let obj1 = { [day.dateString]: start_date, ...this.state.markedDates };
              //   this.setState({ markedDates: obj1, endDate: day.dateString, startDate: day.dateString })
              // }


              // (obj1)

              // if(new Date(day.dateString)< new Date(dates[dates.length-1]))
              // {
              //     alert('s')
              // }
              // else
              // {

              // }


            }}

            // customHeader={(item)=>{
            //   ("header:\n", item);
            //   return(
            //     <View>
            //     </View>
            //   )
            // }}
            // weekly

            onDayLongPress={(day) => { this.setState({ datesToMark: { [day]: { dots: [{ key: 'vacation', color: 'blue', selectedDotColor: 'white' }, { key: 'massage', color: 'red', selectedDotColor: 'white' }], selected: true }, } }) }}
            // selected = {'2019-05-08'}
            // theme = {{selectedDayTextColor:'yellow', selectedDayBackgroundColor:'red'}}
            hideExtraDays={false}

            // headerStyle={{ fontFamily: MAIN_FONT.semi, backgroundColor: 'rgba(220,220,220,0.1)', marginTop: 50 }}
            // onPressArrowLeft={substractMonth => substractMonth()}
            // onPressArrowRight={addMonth => addMonth()}
            month

            eventTitle="TIA"
            markingType={'dot'}
            markedDates={markedDates}
            headerStyle={{
              backgroundColor: MAIN_COLOR.G100,
              borderRadius: 20,
              height: 60,
              paddingTop: 0,
              marginBottom: 20,
              justifyContent: 'space-between',
              zIndex: 100,
              paddingBottom: -0,
              borderWidth: 5,
              borderColor: '#FFF',
            }}

            theme={{
              // textDayHeaderFontFamily: 'nextMedium',

              todayTextColor: '#3e3e3e',
              selectedDayBackgroundColor: MAIN_COLOR.PRIMARY_COLOR,
              selectedDayTextColor: '#000',
              textDayFontFamily: MAIN_FONT.semi,
              textDayFontWeight: '400',
              textMonthFontFamily: MAIN_FONT.semi,
              textDayFontSize: 12,
              textDayStyle: {},
              textMonthFontSize: 14,
              dotColor: MAIN_COLOR.PRIMARY_COLOR,
              arrowColor: MAIN_COLOR.G700,
              monthTextColor: '#272D37',
              textMonthFontWeight: '500',
            }}


          />
        </TouchableOpacity>



      )
    }

    const images = {
      "Home": {
        focused: require("../../assets/Bulk/Home.png"),
        unfocused: require("../../assets/Light/Home.png"),
      },

      "Portfolio": {
        focused: require("../../assets/Bulk/Activity.png"),
        unfocused: require("../../assets/Light/Activity.png"),
      },

      "Notifications": {
        focused: require("../../assets/Bulk/Notification.png"),
        unfocused: require("../../assets/Light/Notification.png"),
      },

      "PortfolioRoadmap": {
        focused: require("../../assets/Bulk/Roadmap.png"),
        unfocused: require("../../assets/Light/Roadmap.svg"),
      }
    }

    const getHeader = () => {
      let progress = 0;
      if (this.state.leads.length) {
        let item = this.state.leads[this.state.selectedIndex];
        let stage = item?.status?.status?.toUpperCase();
        let allStages = Object.keys(this.props.TIA.template);

        let stageIndex = allStages.indexOf(stage);

        if (stageIndex < 1) {
          progress = 0;
        }
        else {
          progress = (stageIndex * 100) / (allStages.length - 1);
        }
      }

      if (this.state.currentScreen === "PortfolioRoadmap") {
        return <></>;
      }

      return (
        <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: MAIN_COLOR.BACKGROUND }}>
          {

            this.state.currentScreen === "Home" ?
                weekly()
              :
              this.state.currentScreen === "Portfolio" ?
                <View style={{ flexDirection: 'row', width: "100%", backgroundColor: '#FFFFFF', borderBottomEndRadius: notTab ? 0 : 25, borderBottomStartRadius: notTab ? 0 : 25, paddingHorizontal: 25, paddingVertical: 20, }}>
                  <View style={{ flexDirection: 'row', width: "40%", alignItems: 'center', }}>
                    <Text style={{ fontSize: 16, flexShrink: 1, color: '#36414C', fontFamily: MAIN_FONT.semi, textAlign: 'center' }}>
                      Portfolio
                    </Text>
                  </View>

                  <View style={{ flexDirection: 'row', width: "60%", justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Text style={{ fontSize: 16, flexShrink: 1, color: '#36414C', fontFamily: MAIN_FONT.semi, textAlign: 'center' }}>
                      Potential Equity:
                    </Text>
                    <Text style={{ fontSize: 22, flexShrink: 1, color: MAIN_COLOR.PRIMARY_COLOR, marginLeft: 10, fontFamily: MAIN_FONT.semi, textAlign: 'center' }}>
                      {'$' + format(this.props.TIA.equity)}
                    </Text>
                  </View>
                </View>
                :
                this.state.currentScreen === "Notifications" ?
                  <View style={{ flexDirection: 'row', width: "100%", paddingHorizontal: 25, paddingVertical: 20 }}>
                    <View style={{ flexDirection: 'row', width: "40%", alignItems: 'center', }}>
                      <Text style={{ fontSize: 16, flexShrink: 1, color: '#36414C', fontFamily: MAIN_FONT.semi, textAlign: 'center' }}>
                        Notifications
                      </Text>
                    </View>

                    <View style={{ flexDirection: 'row', width: "60%", justifyContent: 'flex-end', alignItems: 'center' }}>
                      <Text style={{ fontSize: 16, flexShrink: 1, color: '#36414C', fontFamily: MAIN_FONT.semi, textAlign: 'center' }}>
                        {/* Potential Equity: */}
                      </Text>
                      <Text style={{ fontSize: 22, flexShrink: 1, color: MAIN_COLOR.PRIMARY_COLOR, marginLeft: 10, fontFamily: MAIN_FONT.semi, textAlign: 'center' }}>
                        {/* {'$' + format(this.props.TIA.equity)} */}
                      </Text>
                    </View>
                  </View>
                  :
                  <View style={{ width: "100%", }}>
                    <View style={{ flexDirection: 'row', width: "100%", paddingHorizontal: 25, paddingVertical: 20, backgroundColor: '#FFFFFF', borderBottomLeftRadius: notTab ? 0 : 25, borderBottomRightRadius: notTab ? 0 : 25 }}>
                      <View style={{ flexDirection: 'row', width: "40%", alignItems: 'center', }}>
                        <TouchableOpacity onPress={() => this.setState({ currentScreen: "Home" })} style={{ backgroundColor: MAIN_COLOR.G100, borderRadius: 17, height: 48, width: 48, justifyContent: 'center', alignItems: 'center' }}>
                          <Image
                            style={{ height: 12, width: 12, tintColor: MAIN_COLOR.G800 }}
                            resizeMode="contain"
                            source={require("../../assets/arrow_left.png")}
                          />
                        </TouchableOpacity>
                        <Text style={{ fontSize: 16, flexShrink: 1, color: '#36414C', marginHorizontal: 20, fontFamily: MAIN_FONT.semi, textAlign: 'center' }}>
                          {this.state.leads[this.state.selectedIndex].name}
                        </Text>
                      </View>

                      <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', paddingVertical: 10, width: '60%' }}>
                        <Text style={{ fontSize: 14, flexShrink: 1, marginRight: 30, color: MAIN_COLOR.G700, fontFamily: MAIN_FONT.regular, textAlign: 'center' }}>
                          Project Progresss
                        </Text>

                        <Progress.Bar progress={progress / 100} animationType="spring" width={this.state.dimensions.window.width * 0.4 * 0.7} height={15} borderRadius={10} borderColor={"transparent"} color={MAIN_COLOR.PRIMARY_COLOR} unfilledColor={"#E7ECF2"} />
                        <Text style={{ fontSize: 14, marginLeft: 30, marginHorizontal: 10, color: MAIN_COLOR.G800, fontFamily: MAIN_FONT.regular, textAlign: 'center' }}>
                          {progress.toFixed(0)}%
                        </Text>
                      </View>
                    </View>
                    <View style={{ width: "100%", backgroundColor: MAIN_COLOR.BACKGROUND, padding: 25 }}>
                      <Text style={{ fontSize: 16, color: '#272D37', fontFamily: MAIN_FONT.regular }}>
                        Project Tasks
                      </Text>
                    </View>
                  </View>

          }
        </View>
      )
    }

    const getPanel1 = () => (

      <View style={{ alignItems: 'center', width: 250, backgroundColor: '#FFF', shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowOffset: {
      width: 1,
	    height: 0,
    },
    shadowOpacity: 0.65,
    shadowRadius: 15,
    elevation: 5, borderColor: "rgba(0, 0, 0, 0.15)" }}>
        <Image
          style={{ height: "18%", width: 200 }}
          resizeMode="contain"
          source={require("../../assets/header_logo.svg")}
        />
        <View style={{ width: "100%", minHeight: "60%" }}>
          <FeaturePermissionWrapper feature="homeScreen">
            <TouchableOpacity
              onPress={() => {
                this.setState({ currentScreen: "Home" })
                this.props.setCurrentScreen("Home")
              }}
              style={{
                flexDirection: 'row', width: '100%',
                paddingHorizontal: 10, paddingLeft: 20, marginVertical: 20, borderLeftWidth: this.state.currentScreen === "Home" ? 3 : 0,
                borderLeftColor: MAIN_COLOR.yellow, padding: 7.5,
                alignItems: 'center', backgroundColor: (this.state.currentScreen !== "Portfolio" && this.state.currentScreen !== "Notifications" && this.state.currentScreen !== "PortfolioRoadmap") ? 'rgba(250, 192, 0, 0.1)' : 'rgba(250, 192, 0, 0)'
              }}>
              <Image style={{ width: 22, height: 22, marginTop: -2, tintColor: (this.state.currentScreen !== "Portfolio" && this.state.currentScreen !== "Notifications" && this.state.currentScreen !== "PortfolioRoadmap") ? MAIN_COLOR.yellow : MAIN_COLOR.G800, marginRight: 15, }} resizeMode="contain" tintColor={tintColor} source={(this.state.currentScreen !== "Portfolio" && this.state.currentScreen !== "Notifications" && this.state.currentScreen !== "PortfolioRoadmap") ? images.Home.focused : images.Home.unfocused} />
              <Text style={{ fontSize: 16, lineHeight: 16, color: (this.state.currentScreen !== "Portfolio" && this.state.currentScreen !== "Notifications" && this.state.currentScreen !== "PortfolioRoadmap") ? MAIN_COLOR.yellow : MAIN_COLOR.G800, fontFamily: MAIN_FONT.regular, textAlign: 'center' }}>
                HOME
              </Text>
            </TouchableOpacity>
          </FeaturePermissionWrapper>
          <FeaturePermissionWrapper feature="portfolioScreen">
            <TouchableOpacity
              onPress={() => {
                this.setState({ currentScreen: "Portfolio" })
                this.props.setCurrentScreen("Portfolio")
              }}
              style={{
                flexDirection: 'row', width: '100%',
                paddingHorizontal: 10, paddingLeft: 20, marginVertical: 20, borderLeftWidth: this.state.currentScreen === "Portfolio" ? 3 : 0,
                borderLeftColor: MAIN_COLOR.yellow, padding: 7.5,
                alignItems: 'center', backgroundColor: this.state.currentScreen === "Portfolio" ? 'rgba(250, 192, 0, 0.1)' : 'rgba(250, 192, 0, 0)'
              }}>
              <Image style={{ width: 22, height: 22, marginTop: -2, tintColor: this.state.currentScreen === "Portfolio" ? MAIN_COLOR.yellow : MAIN_COLOR.G800, marginRight: 15, }} resizeMode="contain" tintColor={tintColor} source={this.state.currentScreen === "Portfolio" ? images.Portfolio.focused : images.Portfolio.unfocused} />
              <Text style={{ fontSize: 16, lineHeight: 16, color: this.state.currentScreen === "Portfolio" ? MAIN_COLOR.yellow : MAIN_COLOR.G800, fontFamily: MAIN_FONT.regular, textAlign: 'center' }}>
                PORTFOLIO
              </Text>
            </TouchableOpacity>
          </FeaturePermissionWrapper>
          <FeaturePermissionWrapper feature="notificationScreen">
            <TouchableOpacity
              onPress={() => {
                this.setState({ currentScreen: "Notifications" })
                this.props.setCurrentScreen("Notifications")
              }}
              style={{
                flexDirection: 'row', width: '100%',
                paddingHorizontal: 10, paddingLeft: 20, marginVertical: 20, borderLeftWidth: this.state.currentScreen === "Notifications" ? 3 : 0,
                borderLeftColor: MAIN_COLOR.yellow, padding: 7.5,
                alignItems: 'center', backgroundColor: this.state.currentScreen === "Notifications" ? 'rgba(250, 192, 0, 0.1)' : 'rgba(250, 192, 0, 0)'
              }}>
              <Image style={{ width: 22, height: 22, marginTop: -2, tintColor: this.state.currentScreen === "Notifications" ? MAIN_COLOR.yellow : MAIN_COLOR.G800, marginRight: 15, }} resizeMode="contain" tintColor={tintColor} source={this.state.currentScreen === "Notifications" ? images.Notifications.focused : images.Notifications.unfocused} />
              <Text style={{ fontSize: 16, lineHeight: 16, color: this.state.currentScreen === "Notifications" ? MAIN_COLOR.yellow : MAIN_COLOR.G800, fontFamily: MAIN_FONT.regular, textAlign: 'center' }}>
                NOTIFICATIONS
              </Text>
            </TouchableOpacity>
          </FeaturePermissionWrapper>
          <FeaturePermissionWrapper feature="portfolioRoadmap">
            <TouchableOpacity
              onPress={() => {
                this.setState({ currentScreen: "PortfolioRoadmap" })
                this.props.setCurrentScreen("PortfolioRoadmap")
              }}
              style={{
                flexDirection: 'row', width: '100%',
                paddingHorizontal: 10, paddingLeft: 20, marginVertical: 20, borderLeftWidth: this.state.currentScreen === "PortfolioRoadmap" ? 3 : 0,
                borderLeftColor: MAIN_COLOR.yellow, padding: 7.5,
                alignItems: 'center', backgroundColor: this.state.currentScreen === "PortfolioRoadmap" ? 'rgba(250, 192, 0, 0.1)' : 'rgba(250, 192, 0, 0)'
              }}>
              <Image style={{ width: 22, height: 22, marginTop: -2, tintColor: this.state.currentScreen === "PortfolioRoadmap" ? MAIN_COLOR.yellow : MAIN_COLOR.G800, marginRight: 15, }} resizeMode="contain" tintColor={tintColor} source={this.state.currentScreen === "PortfolioRoadmap" ? images.PortfolioRoadmap.focused : images.PortfolioRoadmap.unfocused} />
              <Text style={{ fontSize: 16, lineHeight: 16, color: this.state.currentScreen === "PortfolioRoadmap" ? MAIN_COLOR.yellow : MAIN_COLOR.G800, fontFamily: MAIN_FONT.regular, textAlign: 'center' }}>
                PORTFOLIO ROADMAP
              </Text>
            </TouchableOpacity>
          </FeaturePermissionWrapper>
        </View>
        <TouchableOpacity onPress={() => {
          this.props.navigation.navigate("LoginScreen")
          this.props.setCurrentScreen("Home")
        }} style={{
          flexDirection: 'row', width: '100%', bottom: 0, paddingHorizontal: 10, paddingLeft: 20, marginVertical: 20, borderLeftWidth: 3,
          borderLeftColor: 'transparent',
          padding: 7.5, alignItems: 'center', backgroundColor: 'rgba(250, 192, 0, 0)',
          position: "absolute"
        }}>
          <Image style={{ width: 22, height: 22, marginTop: -2, tintColor: MAIN_COLOR.red, marginRight: 15, }} resizeMode="contain" tintColor={MAIN_COLOR.red} source={require('../../assets/logout.png')} />
          <Text style={{ fontSize: 16, lineHeight: 16, color: MAIN_COLOR.red, fontFamily: MAIN_FONT.regular, textAlign: 'center' }}>
            LOGOUT
          </Text>
        </TouchableOpacity>
      </View>
    )

    const getPanel = () => (

      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: "100%", backgroundColor: '#ffffff', paddingVertical: 15, borderBottomWidth: 2, borderBottomColor: MAIN_COLOR.BACKGROUND }}>
        <Image
          style={{ height: 50, width: 250, marginLeft: 20 }}
          resizeMode="contain"
          source={require("../../assets/tia_long.png")}
        />
        <View style={{ flexDirection: 'row' }}>
          <FeaturePermissionWrapper feature="homeScreen">
            <TouchableOpacity
              onPress={() => {
                this.setState({ currentScreen: "Home" })
                this.props.setCurrentScreen("Home")
              }}
              style={{
                padding: 10,
                alignItems: 'center'
              }}>
              <Image style={{ width: 22, height: 22, marginTop: -2, tintColor: (this.state.currentScreen !== "Portfolio" && this.state.currentScreen !== "Notifications" && this.state.currentScreen !== "PortfolioRoadmap" ) ? MAIN_COLOR.yellow : MAIN_COLOR.G800, marginRight: 15, }} resizeMode="contain" tintColor={tintColor} source={(this.state.currentScreen !== "Portfolio" && this.state.currentScreen !== "Notifications" && this.state.currentScreen !== "PortfolioRoadmap") ? images.Home.focused : images.Home.unfocused} />
            </TouchableOpacity>
          </FeaturePermissionWrapper>
          <FeaturePermissionWrapper feature="portfolioScreen">
            <TouchableOpacity
              onPress={() => {
                this.setState({ currentScreen: "Portfolio" })
                this.props.setCurrentScreen("Portfolio")
              }}
              style={{
                padding: 10,
                alignItems: 'center'
              }}>
              <Image style={{ width: 22, height: 22, marginTop: -2, tintColor: this.state.currentScreen === "Portfolio" ? MAIN_COLOR.yellow : MAIN_COLOR.G800, marginRight: 15, }} resizeMode="contain" tintColor={tintColor} source={this.state.currentScreen === "Portfolio" ? images.Portfolio.focused : images.Portfolio.unfocused} />
            </TouchableOpacity>
          </FeaturePermissionWrapper>
          <FeaturePermissionWrapper feature="notificationScreen">
            <TouchableOpacity
              onPress={() => {
                this.setState({ currentScreen: "Notifications" })
                this.props.setCurrentScreen("Notifications")
              }}
              style={{
                padding: 10,
                alignItems: 'center'
              }}>
              <Image style={{ width: 22, height: 22, marginTop: -2, tintColor: this.state.currentScreen === "Notifications" ? MAIN_COLOR.yellow : MAIN_COLOR.G800, marginRight: 15, }} resizeMode="contain" tintColor={tintColor} source={this.state.currentScreen === "Notifications" ? images.Notifications.focused : images.Notifications.unfocused} />
            </TouchableOpacity>
          </FeaturePermissionWrapper>
          <FeaturePermissionWrapper feature="portfolioRoadmap">
            <TouchableOpacity
              onPress={() => {
                this.setState({ currentScreen: "PortfolioRoadmap" })
                this.props.setCurrentScreen("PortfolioRoadmap")
              }}
              style={{
                padding: 10,
                alignItems: 'center'
              }}>
              <Image style={{ width: 22, height: 22, marginTop: -2, tintColor: this.state.currentScreen === "PortfolioRoadmap" ? MAIN_COLOR.yellow : MAIN_COLOR.G800, marginRight: 15, }} resizeMode="contain" tintColor={tintColor} source={this.state.currentScreen === "PortfolioRoadmap" ? images.PortfolioRoadmap.focused : images.PortfolioRoadmap.unfocused} />
            </TouchableOpacity>
          </FeaturePermissionWrapper>
          <TouchableOpacity onPress={() => {
            this.props.navigation.navigate("LoginScreen")
            this.props.setCurrentScreen("Home")
          }} style={{
            padding: 10
          }}>
            <Image style={{ width: 22, height: 22, marginTop: -2, tintColor: MAIN_COLOR.red, marginRight: 15, }} resizeMode="contain" tintColor={MAIN_COLOR.red} source={require('../../assets/logout.png')} />
          </TouchableOpacity>
        </View>
      </View>
    )

    const getHome = () => (
      <View style={[styles.scrollViewContentView]}>
        <View style={{ flex: 1, paddingHorizontal: '2.5%', paddingVertical: '2.5%', borderRadius: 0 }}>
          {
            leads.length ?
              <View style={{ flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                {this.state.leads.map((item, index) => this.renderFlatListItem(item, index))}
              </View>
              :
              <View style={{ justifyContent: 'center', alignItems: 'center', width: "100%", height: '100%' }}>
                <Image
                  style={{ height: 150, width: 150 }}
                  resizeMode="contain"
                  source={require("../../assets/content.png")}
                />
                <Text style={{ fontSize: 21, color: '#1A3F49', fontFamily: MAIN_FONT.bold, textAlign: 'center', marginTop: 50 }}>
                  Welcome to TIA
                </Text>

                <Text style={{ fontSize: 16, color: '#A2ADB9', fontFamily: MAIN_FONT.regular, textAlign: 'center', marginTop: 10 }}>
                  You’re on your way to better property management.
                </Text>
              </View>
          }

        </View>

        <RBSheet
          ref={ref => {
            this.RBSheet = ref;
          }}
          // height={300}
          // openDuration={250}

          customStyles={{
            container: {
              justifyContent: "center",
              borderTopEndRadius: 20,
              borderTopStartRadius: 20,
              alignItems: "center"
            }
          }}
        >
          <View style={{ flex: 1, width: '100%' }}>
            <Text style={{ fontWeight: 'bold', letterSpacing: 1, marginVertical: '5%', textAlign: 'center' }}>PROFILE</Text>

            <View style={{ height: 1, backgroundColor: '#EEE', width: '100%' }}></View>
            <TouchableOpacity
              onPress={() => { clearAsyncStorage() && this.props.navigation.navigate("LoginScreen", { registered: true }) }}
              style={{ paddingVertical: 15 }}
            >
              <Text style={{ letterSpacing: 1, marginHorizontal: '5%', color: '#333', fontFamily: MAIN_FONT.regular }}>Logout</Text>
            </TouchableOpacity>
          </View>
        </RBSheet>
      </View>
    )



    let focused = true;
    let tintColor = MAIN_COLOR.yellow;

    const goToProjectDetails = (id, statusName) => {

      let arr = ['SEARCH PROCESS', 'MARKET SELECTION', 'PROPERTY SELECTION', 'PROPERTY WALK-THROUGH', 'CONTRACT, B&P & PM', 'BUILDING & PEST REPORTS', 'UNCONDITIONAL', 'SETTLED', 'MARKET UPDATES']

      this.props.TIA.opportunities.map((item, index) => {
        if (item.id == id) {
          this.setState({ currentScreen: "ProjectDetails", selectedIndex: index, notificationIndex: arr.indexOf(statusName) });
        }
      })
    }

    return (
      <SafeAreaView style={{ flex: 1, flexDirection: notTab ? 'row' : 'column', backgroundColor: '#FFFFFF', }} keyboardShouldPersistTaps={true} behavior={Platform.OS === "ios" ? "padding" : "padding"} keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}>
        <NavigationEvents
          onWillFocus={this._onFocus}
          onWillBlur={this._onBlurr}
        />
        {
          notTab ?
            getPanel1()
            :
            getPanel()
        }
        {/* <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => this.refresh(email, password)}
            />
          }
          // contentContainerStyle={{minWidth: 750}}
          onPress={Keyboard.dismiss} accessible={false}> */}
        <View style={{ flex: 1, zIndex: -1 }}>
          {
            getHeader()
          }
          {/* <ScrollView contentContainerStyle={{ minHeight: "100%", width: "100%" }}> */}
          <ScrollView horizontal
            contentContainerStyle={{
              // minWidth: 950,
              // minHeight: "200%", 
              width: "100%",
              height: "100%",
              // minHeight: Dimensions.get('screen').height, 
              // justifyContent: 'flex-start', 
              // padding: '2.5%', 
              // paddingTop: 0, 
              // paddingLeft: 0, 
              // backgroundColor: 'rgb(243, 244, 249)'
            }}>

            {
              this.state.currentScreen === "Home" ?
                getHome()
                :
                this.state.currentScreen === "ProjectDetails" ?
                  <>
                    <ProjectDetails deal={this.state.leads[this.state.selectedIndex]} notificationIndex={this.state.notificationIndex} checklists={this.state.checklists} clickUpStatusList={this.state.clickUpStatusList} />
                  </>
                  :
                  this.state.currentScreen === "Portfolio" ?
                    <Portfolio />
                    :
                    this.state.currentScreen === "Notifications" ?
                      <NotificationsScreen goToProjectDetails={goToProjectDetails} />
                      :
                      this.state.currentScreen === 'PortfolioRoadmap' ? 
                        <PortfolioRoadmap /> 
                        : null
            }


          </ScrollView>
          {/* </ScrollView> */}
        </View>
        <Modal
          animationType={'none'}
          transparent={false}
          visible={monthly}
          transparent
          onRequestClose={() => {
            this.setState({ monthly: false })
          }}
        >
          <TouchableOpacity activeOpacity={1} onPress={() => this.setState({ monthly: false })} style={{ flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.25)' }}>
            {
              calendar()
            }
          </TouchableOpacity>
        </Modal>

        {/* </ScrollView> */}
        {this.props.TIA.loader ? <LOADER /> : null}
      </SafeAreaView>
    );
  }
};


function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    TIA: state.TIA,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setOpportunities,
      setNotifications,
      setNotificationsObject,
      setLoader,
      setUser,
      setOpenDetails,
      setCurrentScreen
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);