import { useMemo } from "react";
import { useSelector } from "react-redux";

export const ROLES = {
  PARTNER: "partner",
  CLIENT: "client",
};

export const usePermission = (feature) => {
  const { client } = useSelector((state) => state.TIA);

  const role = client?.role ?? ROLES.CLIENT;

  const permissions = useMemo(() => {
    switch (role) {
      case ROLES.PARTNER: {
        return {
          saveScenario: ["VIEW"],
          shareOther: [],
          addPartner: [],
          purchaseTarget: ["VIEW"],
          financePlanner: ["VIEW"],
          homeScreen: [],
          portfolioScreen: [],
          notificationScreen: [],
          portfolioRoadmap: ["VIEW"],
        };
      }
      case ROLES.CLIENT: {
        return {
          saveScenario: ["EDIT", "DELETE", "VIEW"],
          shareOther: ["VIEW"],
          addPartner: ["EDIT", "DELETE", "VIEW"],
          purchaseTarget: ["EDIT", "VIEW"],
          financePlanner: ["VIEW", "EDIT"],
          homeScreen: ["VIEW"],
          portfolioScreen: ["VIEW"],
          notificationScreen: ["VIEW"],
          portfolioRoadmap: ["VIEW"],
        };
      }
    }
  }, [role]);

  const permissionFeature = permissions[feature];

  return {
    isView: !!permissionFeature.includes("VIEW"),
    isEdit: !!permissionFeature.includes("EDIT"),
    isDelete: !!permissionFeature.includes("DELETE"),
  };
};
