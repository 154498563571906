import React, { useState } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import OutsideClickHandler from "react-outside-click-handler";
import { MAIN_COLOR } from "../../../../Utility/Colors";
import FormWidget from "./FormWidget";
import styles from "./style";

import { usePermission } from "../../../../hooks/usePermission";
import { BlurView } from "expo-blur";

const FinancePlanner = ({ listPurchases }) => {
  const [open, setOpen] = useState(false);

  const openShowDetails = () => setOpen(!open);

  const { isEdit } = usePermission("financePlanner");

  return (
    <>
      {open && <BlurView tint="light" intensity={40} style={styles.blurView} />}
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <View style={{ zIndex: 3, position: "unset" }}>
          <TouchableOpacity
            style={[
              styles.container,
              open && { borderColor: MAIN_COLOR.yellow },
            ]}
            onPress={openShowDetails}
            disabled={!isEdit}
          >
            <Image
              resizeMode="cover"
              style={styles.icon}
              source={require("../../../../assets/portfolio-roadmap/calculator.svg")}
            />
            <Text style={styles.text}>Finance Planner</Text>
            <Image
              resizeMode="cover"
              style={styles.arrowDown}
              source={require("../../../../assets/portfolio-roadmap/arrow-down.svg")}
            />
          </TouchableOpacity>
          <FormWidget
            open={open}
            toggleShowWidget={() => setOpen(false)}
            listPurchases={listPurchases}
          />
        </View>
      </OutsideClickHandler>
    </>
  );
};

export default FinancePlanner;
