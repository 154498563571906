import React from 'react';
import {
    Dimensions,
    Image,
    StyleSheet,
    Text
} from 'react-native';
import { RFValue } from "react-native-responsive-fontsize";
//import { getInset } from 'react-native-safe-area-view';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { createAppContainer, createSwitchNavigator, DrawerItems } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { createDrawerNavigator } from 'react-navigation-drawer';
import File from "../Screen/File";
import Folio from "../Screen/Folio";
import ForgotPassword from "../Screen/ForgotPassword";
import Home from "../Screen/Home";
import Login from "../Screen/Login";
import SignUp from '../Screen/Signup';
import Notifications from "../Screen/Notifications";
import ProjectDetails from "../Screen/ProjectDetails";
import PropertyDetails from "../Screen/PropertyDetails";
import Presentation from "../Screen/Presentation";
import Register from "../Screen/Register";
import Splash from "../Screen/SplashScreen";
import TaskDetails from "../Screen/TaskDetails";
import DrawerBar from "../Screen/DrawerBar";
import WebView from "../Screen/WebView";
import { TAB_BAR_COLOR } from '../Utility/Colors';
import { MAIN_FONT } from "../Utility/Fonts/font";
import ResetPassword from '../Screen/ResetPassword';
import PortfolioRoadmap from '../Screen/PortfolioRoadmap';





const images = {
    "Home": {
        focused: require("../assets/Bulk/Home.png"),
        unfocused: require("../assets/Light/Home.png"),
    },

    "Portfolio": {
        focused: require("../assets/Bulk/Activity.png"),
        unfocused: require("../assets/Light/Activity.png"),
    },

    "Notifications": {
        focused: require("../assets/Bulk/Notification.png"),
        unfocused: require("../assets/Light/Notification.png"),
    },

    "PortfolioRoadmap": {
        focused: require("../assets/Bulk/Roadmap.png"),
        unfocused: require("../assets/Light/Roadmap.png")
    }
}

const styles = StyleSheet.create({

    tabIcon: {
        width: wp("6%"),
        height: wp("6%"),
        //borderWidth: 1
    },

});



const BottomTabNavigator = createBottomTabNavigator(
    {
        Home: {
            screen: Home,
            navigationOptions: {
                tabBarLabel: ({ focused, tintColor }) => <Text style={{ color: focused ? tintColor : '#36414C', fontFamily: focused ? MAIN_FONT.bold : MAIN_FONT.regular, fontSize: 13, width: "100%", textAlign: 'center',}}>HOME</Text>,
                tabBarIcon: ({ focused, tintColor }) => (
                    <Image style={{ ...styles.tabIcon, tintColor }} resizeMode="contain" tintColor={tintColor} source={focused?images.Home.focused:images.Home.unfocused} />
                ),
            },
        },
        Portfolio: {
            screen: Folio,
            navigationOptions: {
                tabBarLabel: ({ focused, tintColor }) => <Text style={{ color: focused ? tintColor : '#36414C', fontFamily: focused ? MAIN_FONT.bold : MAIN_FONT.regular, fontSize: 13, width: "100%", textAlign: 'center',}}>PORTFOLIO</Text>,
                tabBarIcon: ({ focused, tintColor }) => (
                    <Image style={{ ...styles.tabIcon, tintColor }} resizeMode="contain" tintColor={tintColor} source={focused?images.Portfolio.focused:images.Portfolio.unfocused} />
                ),
            },
        },
        Notifications: {
            screen: Notifications,
            navigationOptions: {
                tabBarLabel: ({ focused, tintColor }) => <Text style={{ color: focused ? tintColor : '#36414C', fontFamily: focused ? MAIN_FONT.bold : MAIN_FONT.regular, fontSize: 13, width: "100%", textAlign: 'center',}}>NOTIFICATIONS</Text>,
                tabBarIcon: ({ focused, tintColor }) => (
                    <Image style={{ ...styles.tabIcon, tintColor }} resizeMode="contain" tintColor={tintColor} source={focused?images.Notifications.focused:images.Notifications.unfocused} />
                ),
            },
        },
        PortfolioRoadmap: {
            screen: PortfolioRoadmap,
            navigationOptions: {
                tabBarLabel: ({ focused, tintColor }) => <Text style={{ color: focused ? tintColor : '#36414C', fontFamily: focused ? MAIN_FONT.bold : MAIN_FONT.regular, fontSize: 13, width: "100%", textAlign: 'center',}}>PORTFOLIO ROADMAP</Text>,
                tabBarIcon: ({ focused, tintColor }) => (
                    <Image style={{ ...styles.tabIcon, tintColor }} resizeMode="contain" tintColor={tintColor} source={focused?images.PortfolioRoadmap.focused:images.PortfolioRoadmap.unfocused} />
                ),
            },
        },

    },
    {
        initialRoute: "Home",
        resetOnBlur: true,
        tabBarOptions: {
            showLabel: true,
            activeTintColor: TAB_BAR_COLOR.ACTIVE_COLOR,
            inactiveTintColor: TAB_BAR_COLOR.INACTIVE_COLOR,
            
            //activeBackgroundColor: MAIN_COLOR.PRIMARY_COLOR,
            style: {
                backgroundColor: TAB_BAR_COLOR.BACKGROUND_COLOR,
                //height: Platform.OS === "ios" ? wp("13%") + bottomPadding : wp("13%") + bottomPadding,
                height: 0,
                opacity: 0,
                // position: 'absolute',
                // top: 0,
                // left: 0,
                flexDirection: 'row',
                width: "0%",
                borderTopWidth: 0,
                shadowColor: "#ADB9C7",
                elevation: 3,
                shadowOpacity: 0.2,
                position: 'absolute', 
                left: 0
            },
            labelStyle: {
                fontSize: RFValue(9),
                // marginBottom: Platform.OS === "ios" ? wp("0.5%") + 15 : wp("0.5%") + 15,
                // marginBottom: 10,
                fontWeight: "600"
            },
            // safeAreaInset: { bottom: 'never', top: 'never' },
        }
    }

);

const DrawerNavigator = createDrawerNavigator(
    {
        // Profile: createStackNavigator({
        //     // For each screen that you can navigate to, create a new entry like this:
        //     MainProfile: {
        //         screen: Search,
        //     },
        //     Edit: {
        //         screen: Profile,
        //     },
        // }, {
        //     navigationOptions: {

        //         cardStack: {
        //             gesturesEnabled: false
        //         }
        //     },
        //     headerMode: "none",
        // }),

        Home: { screen: Home },
    },
    {
        navigationOptions: {
            drawerLockMode: 'locked-closed',
            cardStack: {
                gesturesEnabled: false
            }
        },
        headerMode: "none",
        drawerType: "slide",
        // disableGestures: true,
        edgeWidth: 100,
        drawerLockMode: 'locked-closed',  
        overlayColor: "transparent",
        minWidth: 100,
        initialRouteName: "Home",
        // drawerWidth: wp("80%"),
        contentComponent: props => <DrawerBar {...props} />,
    }
);

const MainTabNavigator = createStackNavigator(
    {
        Home: {
            screen: BottomTabNavigator,
            navigationOptions: {
                headerShown: false
            },
        },
        ProjectDetails: {
            screen: ProjectDetails,
            navigationOptions: {
                headerShown: false
            },
        },
        TaskDetails: {
            screen: TaskDetails,
            navigationOptions: {
                headerShown: false
            },
        },
        PropertyDetails: {
            screen: PropertyDetails,
            navigationOptions: {
                headerShown: false
            },
        },
        Presentation: {
            screen: Presentation,
            navigationOptions: {
                headerShown: false
            },
        },
        File: {
            screen: File,
            navigationOptions: {
                headerShown: false
            },
        },

        WebView: {
            screen: WebView,
            navigationOptions: {
                headerShown: false
            },
        },
        
    }
)

// const MainNavigator = createStackNavigator(
//     {
//         SplashScreen: { screen: Splash },
//         LoginScreen: { screen: Login },
//         RegisterScreen: { screen: Register },
//         OTPScreen: { screen: OTP },
//         ForgotPasswordScreen: { screen: ForgotPassword },
//         MainScreen: {
//             screen: DrawerNavigator,
//             navigationOptions: {
//                 gesturesEnabled: false,
//             }
//         },

//     },
//     {
//         initialRouteName: "SplashScreen",
//         headerMode: "none",
//         defaultNavigationOptions: {
//             headerVisible: false,
//             //gestureEnabled: false,
//         },
//     }
// );

const switchNavigator = createSwitchNavigator(
    {

        SplashScreen: Splash,
        GuestScreen: createStackNavigator(
            {

                LoginScreen: { screen: Login },
                SignUpScreen: { screen: SignUp },
                RegisterScreen: { screen: Register },
                // OTPScreen: { screen: OTP },
                ForgotPasswordScreen: { screen: ForgotPassword },
                ResetPasswordScreen: { screen: ResetPassword },
            },
            {
                initialRouteName: "LoginScreen",
                headerMode: "none",
                defaultNavigationOptions: {
                    headerVisible: false,
                    //gestureEnabled: false,
                },
            }
        ),
        MainScreen: createStackNavigator({
            MainScreen1: {
                screen: MainTabNavigator,
                navigationOptions: {
                    gesturesEnabled: false,
                }
            },
            SplashScreen: { screen: Splash },

        },
            {
                initialRouteName: "MainScreen1",
                headerMode: "none",
                defaultNavigationOptions: {
                    headerVisible: false,
                    //gestureEnabled: false,
                }
            })
    },
    {
        initialRouteName: 'SplashScreen',
        headerMode: 'none'
    }
)


const App = createAppContainer(switchNavigator);

export default App;

