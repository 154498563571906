import React from 'react';
import {
   StyleSheet, Dimensions
} from 'react-native';

import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

import { RFValue } from 'react-native-responsive-fontsize';
import { MAIN_COLOR } from '../../Utility/Colors';
const { width, height } = Dimensions.get('window');

var styles;

export default styles = StyleSheet.create({
   container: {
      flex: 1,  
      backgroundColor: MAIN_COLOR.darkGray
   },


   topView: {
      height: hp("38%"),
      justifyContent: "center",
      alignItems: "center", 
      backgroundColor: MAIN_COLOR.yellowBackground,
      borderBottomLeftRadius: 80,
      borderBottomRightRadius: 80,
   },


   centerView: {
      flex: 5, 
      backgroundColor: MAIN_COLOR.darkGray, 
      paddingTop: wp("5%")
   },

   bottomView: { 
      width: "100%",
      justifyContent: "center",
      alignItems: "flex-start", 
      position: "absolute",
      bottom: 0,
      backgroundColor: MAIN_COLOR.yellowBackground, 
      borderTopRightRadius: 80, 
   },

 
   nameText: {
      fontSize: RFValue(13),
      fontWeight: 'bold',
      color: MAIN_COLOR.darkGray,
      marginTop: wp("2%")
   },

   
   itemImage: {
      width: 100,
      height: 100, 
      borderRadius: 500 / 2,
      overflow: "hidden",
      borderWidth: 2,
      backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
      borderColor: MAIN_COLOR.BACKGROUND_COLOR
   },

   itemImageBackground: {
      position: "absolute",
      opacity: 0.18,
      zIndex: -1, 
      height: "100%",
      width: "100%",
      borderBottomLeftRadius: 80,
      borderBottomRightRadius: 80,
   },

   navItemBar: { 
     width: "80%",
     flexDirection: "row",
     alignItems: "center",
     padding: wp("2%"),
     paddingLeft: wp("4.5%"),
     marginTop: wp("3%"),
     borderBottomWidth: 0.5,
     borderColor: MAIN_COLOR.BORDER_COLOR,
   //   shadowColor: MAIN_COLOR.yellowBackground,
   //   shadowOffset: { width: 0, height: 1 },
   //   shadowOpacity: 0.3,
   //   shadowRadius: 2,  
     // elevation: 3
   },

   navText: {
      fontSize: RFValue(13),
      color: MAIN_COLOR.yellowBackground,  
   },

   versionText: {
     fontSize: RFValue(12),
     color: "#FFFFFF70", 
     marginLeft: "auto",
     padding: wp("4%"),
   },

   navItemBar_bottom: { 
      width: "80%", 
      paddingLeft: wp("4.5%"), 
      marginTop: wp("4%"), 
    },
 
    navText_bottom: {
       fontSize: RFValue(13),
       color: MAIN_COLOR.darkGray,  
    },

    menuIcon: {
       width: wp("6%"),
       tintColor: MAIN_COLOR.yellowBackground,
       color: MAIN_COLOR.yellowBackground,
       marginRight: wp("3%")
    }



});