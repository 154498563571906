var _wiwo = [];

import { cloneDeep, omit } from "lodash";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Text, TouchableOpacity } from "react-native";
import "../../../../../Utility/portfolio-roadmap";
import { updateWiwo } from "../../../../../Utility/portfolio-roadmap";
import { RENTAL_PW } from "./index.const";
import styles from "./style";

const REMAINING_TERM = 12 * 5;

const Wiwo = ({ children, onAnalyseStructure, open }) => {
  const {
    setValue,
    watch,
    formState: { isValid },
  } = useFormContext();

  const wiwoData = watch("widgetProperties");

  const submit = async () => {
    const currentBorrowingValue = getBorrowingValue(wiwoData);
    const customIncomes = getAllOtherIncome(wiwoData);
    let nearestData = {
      repayment: null,
      otherIncome: null,
      borrowingValue: null,
    };
    let start = Math.round(currentBorrowingValue / 2);
    let end = currentBorrowingValue;
    let step = 10000;
    const accuracy = 2000;
    while (
      (nearestData.borrowingValue &&
        nearestData.repayment &&
        nearestData.borrowingValue - nearestData.repayment > accuracy &&
        step > 1) ||
      step >= 100
    ) {
      await getNearestRepayment({
        start,
        end,
        step,
        nearestData,
      });

      start = nearestData.repayment;
      end = nearestData.borrowingValue;
      step = step / 10;
    }

    const propertyPrice = nearestData.repayment / 0.8;
    const otherIncome = calcOtherIncome(propertyPrice);

    const otherIncomes = getOtherIncome(wiwoData);

    let incomes = [
      {
        ...getPrimaryIncome(wiwoData),
      },
      {
        id: "other",
        income: otherIncome,
        incomeType: "gross",
        incomeFrequency: "week",
        incomeCategory: "other",
      },
    ];

    if (otherIncomes) {
      incomes = [...incomes, ...otherIncomes];
    }

    let liabilities = getLiabilities(wiwoData);
    liabilities = [
      ...liabilities,
      {
        loanType: "homeLoan",
        remainingLimit: nearestData.repayment,
        remainingTermMonths: 360,
        hasIo: true,
        ioTermMonths: REMAINING_TERM,
        toBePaidOut: false,
        liabilityTypeLabel: "Owner occupied home loan",
      },
    ];

    const newData = await updateWiwoData(wiwoData, {
      incomes,
      liabilities,
    });
    setValue("widgetProperties", newData);

    setValue("customIncomes", customIncomes);

    onAnalyseStructure(newData, currentBorrowingValue);
  };

  const updateWiwoData = async (originalData, dataChanges) => {
    const { incomes, creditCardLimit, livingExpense, liabilities } =
      dataChanges;

    const newData = cloneDeep(originalData);

    const wiwoBorrowingPower = newData?.dataList.find(
      (item) => item.id === "wiwo-borrowing-power"
    );

    const wiwoRepaymentWidget = newData?.dataList.find(
      (item) => item.id === "wiwo-repayment-widget"
    );
    wiwoRepaymentWidget.output = null;
    wiwoRepaymentWidget.input.repaymentModel.principal = 999999999;

    if (incomes?.length) {
      if (incomes.some((item) => item.id === "primary")) {
        wiwoBorrowingPower.input.borrowingRequestModel.applicantList[0].incomeList =
          [...incomes];
      } else {
        wiwoBorrowingPower.input.borrowingRequestModel.applicantList[0].incomeList.push(
          ...incomes
        );
      }
    }

    if (liabilities?.length) {
      const loanLiabilityGroups =
        wiwoBorrowingPower.input.borrowingRequestModel.loanLiabilityGroups;
      if (loanLiabilityGroups?.length) {
        loanLiabilityGroups[0].liabilities = [...liabilities];
      } else {
        loanLiabilityGroups.push({
          id: "homeLoan",
          enabled: true,
          liabilities,
        });
      }
    }

    wiwoBorrowingPower.input = omit(wiwoBorrowingPower.input, ["bpResult"]);
    wiwoBorrowingPower.output = null;

    return new Promise((resolve, reject) => {
      _wiwo.push([
        "setData",
        "wiwo-begidubi",
        {
          data: newData,
        },
        function (e, result) {
          if (result.success) {
            _wiwo.push([
              "getData",
              "wiwo-begidubi",
              function (e, result) {
                if (result.success) {
                  resolve(result.data);
                } else {
                  reject(e);
                }
              },
            ]);
          } else {
            reject(e);
          }
        },
      ]);
    });
  };

  const calcOtherIncome = (repayment) => {
    return RENTAL_PW[`${Math.round(repayment / 10000) * 10000}`] ?? 0;
  };

  const getBorrowingValue = (data) => {
    const wiwoBorrowingPower = data?.dataList?.find(
      (item) => item.id === "wiwo-borrowing-power"
    );

    return wiwoBorrowingPower?.output?.borrowingPowerMaximum ?? 0;
  };

  const getAllOtherIncome = (data) => {
    const wiwoBorrowingPower = data?.dataList.find(
      (item) => item.id === "wiwo-borrowing-power"
    );

    const otherIncomes =
      wiwoBorrowingPower.input.borrowingRequestModel.applicantList.map(
        (one) => {
          return one.incomeList.filter((item) => item.id === "other");
        }
      );

    return otherIncomes;
  };

  const getOtherIncome = (data) => {
    const wiwoBorrowingPower = data?.dataList.find(
      (item) => item.id === "wiwo-borrowing-power"
    );

    return wiwoBorrowingPower.input.borrowingRequestModel.applicantList[0].incomeList.filter(
      (item) => item.id === "other"
    );
  };

  const getLiabilities = (data) => {
    const wiwoBorrowingPower = data?.dataList.find(
      (item) => item.id === "wiwo-borrowing-power"
    );

    const loanLiabilityGroups =
      wiwoBorrowingPower.input.borrowingRequestModel.loanLiabilityGroups;

    if (loanLiabilityGroups.length) return loanLiabilityGroups[0].liabilities;

    return [];
  };

  const getPrimaryIncome = (data) => {
    const wiwoBorrowingPower = data?.dataList.find(
      (item) => item.id === "wiwo-borrowing-power"
    );

    return wiwoBorrowingPower.input.borrowingRequestModel.applicantList[0].incomeList.find(
      (item) => item.id === "primary"
    );
  };

  const getNearestRepayment = async ({ start, end, step, nearestData }) => {
    let wiwoDataTemp = cloneDeep(wiwoData);
    for (let repayment = start; repayment <= end; repayment += step) {
      try {
        const propertyPrice = repayment / 0.8;
        const otherIncome = calcOtherIncome(propertyPrice);

        const otherIncomes = getOtherIncome(wiwoData);

        let incomes = [
          {
            ...getPrimaryIncome(wiwoData),
          },
          {
            id: "other",
            income: otherIncome,
            incomeType: "gross",
            incomeFrequency: "week",
            incomeCategory: "other",
          },
        ];

        if (otherIncomes) {
          incomes = [...incomes, ...otherIncomes];
        }

        let liabilities = getLiabilities(wiwoData);
        liabilities = [
          ...liabilities,
          {
            loanType: "homeLoan",
            remainingLimit: nearestData.repayment,
            remainingTermMonths: 360,
            hasIo: true,
            ioTermMonths: REMAINING_TERM,
            toBePaidOut: false,
            liabilityTypeLabel: "Owner occupied home loan",
          },
        ];

        wiwoDataTemp = await updateWiwoData(wiwoData, {
          incomes,
          liabilities,
        });

        const newBorrowingValue = getBorrowingValue(wiwoDataTemp);
        if (
          !nearestData.repayment ||
          !nearestData.borrowingValue ||
          (newBorrowingValue - repayment >= 0 &&
            newBorrowingValue - repayment <
              nearestData.borrowingValue - nearestData.repayment)
        ) {
          nearestData.borrowingValue = newBorrowingValue;
          nearestData.repayment = repayment;
          nearestData.otherIncome = otherIncome;

          // setWiwoData(wiwoDataTemp);
          setValue("widgetProperties", wiwoDataTemp);
        } else if (newBorrowingValue - repayment < 0) {
          break;
        }
      } catch (error) {
        console.log("🚀 ~ getNearestRepayment ~ error:", error);
      }
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://delivery.widgetworks.com.au/wiwo/widget-scout/7.1.0/min/widget-scout/javascripts/widget-scout.js";
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      // eslint-disable-next-line no-underscore-dangle
      _wiwo = window._wiwo || [];
      _wiwo.push([
        function (iframeUtil) {
          iframeUtil.refresh("wiwo-begidubi");
        },
      ]);
      _wiwo.push([
        "on",
        "pageTrack",
        function (e, data) {
          if (data === "/loans") {
            _wiwo.push([
              "getData",
              "wiwo-begidubi",
              async function (e, result) {
                if (result.success) {
                  const originalData = cloneDeep(result.data);
                  originalData.dataList[1].input.repaymentModel.interestRate = 0.065;
                  const response = await updateWiwo(originalData, _wiwo);
                  setValue("widgetProperties", response);
                }
              },
            ]);
          }
        },
      ]);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [open]);

  return (
    <>
      <iframe
        style={{ border: "none" }}
        data-wiwo-license="begidubi"
        id="wiwo-begidubi"
        src="about:blank"
        width="100%"
        data-wiwo-init="false"
        loading="eager"
      ></iframe>
      <TouchableOpacity style={styles.btn} onPress={submit} disabled={!isValid}>
        <Text style={styles.btnText}>Analyse Structure</Text>
      </TouchableOpacity>
    </>
  );
};

export default Wiwo;
