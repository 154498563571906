export const ACTION_TYPES = {
  SET_PURCHASED_PROPERTIES: "SET_PURCHASED_PROPERTIES",
  SET_PURCHASE_TARGETS: "SET_PURCHASE_TARGETS",
  SET_LOADING: "SET_LOADING",
  SET_SCENARIO_SELECTED: "SET_SCENARIO_SELECTED",
  SET_NEW_DATA_PURCHASE_TARGET: "SET_NEW_DATA_PURCHASE_TARGET",
};
export const setPurchasedProperties = (content) => ({
  type: ACTION_TYPES.SET_PURCHASED_PROPERTIES,
  payload: content,
});

export const setPurchaseTargets = (content) => ({
  type: ACTION_TYPES.SET_PURCHASE_TARGETS,
  payload: content,
});

export const setLoading = (content) => ({
  type: ACTION_TYPES.SET_LOADING,
  payload: content,
});

export const setScenarioSelected = (content) => ({
  type: ACTION_TYPES.SET_SCENARIO_SELECTED,
  payload: content,
});
export const setNewDataPurchaseTarget = (content) => ({
  type: ACTION_TYPES.SET_NEW_DATA_PURCHASE_TARGET,
  payload: content,
});

export const setActiveScenario = (content) => ({
  type: ACTION_TYPES.SET_ACTIVE_SCENARIO,
  payload: content,
});

const initialState = {
  purchasedProperties: [],
  loading: false,
  scenarioSelected: null,
  newDataRedux: [],
};

export const portfolioRoadmap = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_PURCHASED_PROPERTIES: {
      return {
        ...state,
        purchasedProperties: action.payload,
      };
    }
    case ACTION_TYPES.SET_PURCHASE_TARGETS: {
      return {
        ...state,
        purchaseTargets: action.payload,
      };
    }
    case ACTION_TYPES.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case ACTION_TYPES.SET_SCENARIO_SELECTED: {
      return {
        ...state,
        scenarioSelected: action.payload,
      };
    }
    case ACTION_TYPES.SET_ACTIVE_SCENARIO: {
      return {
        ...state,
        scenarios: [...action.payload],
      };
    }
    case ACTION_TYPES.SET_NEW_DATA_PURCHASE_TARGET: {
      return {
        ...state,
        newDataRedux: [...state?.newDataRedux, action.payload],
      };
    }
    default:
      return state;
  }
};
