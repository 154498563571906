import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Image,
  RefreshControl,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { connect } from "react-redux";
import { getContactByEmail } from "../../../Clickup/api";
import ContactCard from "../../../Utility/ComponentCard/ContactCard/ContactCard";
import { MAIN_FONT } from "../../../Utility/Fonts/font";
import styles from "./style";
import { orderBy } from "lodash";
import MediaQuery from "react-responsive";
import Toast from "react-native-toast-message";
import { InfoToast } from "react-native-toast-message";
import { MAIN_COLOR } from "../../../Utility/Colors";

const PropertyContacts = ({ portfolio, TIA, goBack, showContacts }) => {
  const [refreshing, setRefreshing] = useState(false);
  const [propertyContacts, setPropertyContacts] = useState([]);
  const [loading, setLoading] = useState(true);

  const getContactQuantitySurvey = async () => {
    const result = await getContactByEmail("ayden.hassan@mcgqs.com.au");
    const tasks = result?.tasks;
    if (tasks.length) {
      const quantitySurveyorPhone = tasks[0].custom_fields.find(
        (field) => field.name === "Phone"
      )?.value;
      return {
        type: "Quantity Surveyor",
        name: "Ayden Hassan",
        phone: quantitySurveyorPhone,
        email: "ayden.hassan@mcgqs.com.au",
        order: 8,
      };
    }
  };

  const getContactHeadBuyerAgent = async (email) => {
    const result = await getContactByEmail(email);
    const tasks = result?.tasks;
    if (tasks.length) {
      const custom_fields = tasks[0].custom_fields;
      const headBuyerAgentName = tasks[0].name;
      const headBuyerAgentEmail = custom_fields.find(
        (field) => field.name === "Email"
      )?.value;
      const headBuyerAgentPhone = custom_fields.find(
        (field) => field.name === "Phone"
      )?.value;

      const avatar = custom_fields.find(
        (field) => field.name === "Contact photo"
      )?.value;
      const headBuyerAgentAvatar = avatar?.length ? avatar[0]?.url : null;
      return {
        type: "Head Buyers Agent",
        name: headBuyerAgentName,
        email: headBuyerAgentEmail,
        phone: headBuyerAgentPhone,
        employeeAvatar: headBuyerAgentAvatar,
        order: 1,
      };
    }
  };

  const getContactAdministration = async () => {
    const result = await getContactByEmail(
      "taliesha@theinvestorsagency.com.au"
    );
    const tasks = result?.tasks;
    if (tasks.length) {
      const custom_fields = tasks[0].custom_fields;
      const adminStrationPhone = custom_fields.find(
        (field) => field.name === "Phone"
      )?.value;
      const avatar = custom_fields.find(
        (field) => field.name === "Contact photo"
      )?.value;
      const adminStrationAvatar = avatar?.length ? avatar[0]?.url : null;
      return {
        type: "Administration Manager",
        phone: adminStrationPhone,
        employeeAvatar: adminStrationAvatar,
        email: "taliesha@theinvestorsagency.com.au",
        name: "Taliesha Vukic",
        order: 2,
      };
    }
  };

  const getContactBuyerAgent = async (email) => {
    if (email) {
      const result = await getContactByEmail(email);
      const tasks = result?.tasks;
      if (tasks.length) {
        const custom_fields = tasks[0].custom_fields;
        const buyerAgentName = tasks[0].name;
        const buyerAgentEmail = custom_fields.find(
          (field) => field.name === "Email"
        )?.value;
        const buyerAgentPhone = custom_fields.find(
          (field) => field.name === "Phone"
        )?.value;

        const avatar = custom_fields.find(
          (field) => field.name === "Contact photo"
        )?.value;
        const buyerAgentAvatar = avatar?.length ? avatar[0]?.url : null;
        return {
          type: "Associate Buyers Agent",
          name: buyerAgentName,
          email: buyerAgentEmail,
          phone: buyerAgentPhone,
          employeeAvatar: buyerAgentAvatar,
          order: 3,
        };
      }
    }
  };

  const fetchData = () => {
    const item = TIA.opportunities.find(
      (one) => one?.["Proposed Property"]?.[0]?.id === portfolio.id
    );

    if (item) {
      const legalPartner = {
        type: "Legal Partner",
        name: item["SOLICITOR'S NAME"],
        phone: item["SOLICITOR'S PHONE"],
        email: item["Solicitor's Email"],
        order: 5,
      };
      const financialPartner = {
        type: "Financial Partner",
        name: item["MORTGAGE BROKER"],
        phone: item["MORTGAGE BROKER PHONE"],
        email: item["MORTGAGE BROKER EMAIL"],
        order: 6,
      };
      const inspector = {
        type: "Building and Pest Inspector",
        name: item["B&P Inspector"],
        phone: item["B&P Inspector Phone"],
        email: item["B&P Inspector Email"],
        order: 7,
      };
      const manager = {
        type: "Property Manager",
        name: item["Property Manager"],
        phone: item["Property Manager Phone"],
        email: item["Property Manager Email"],
        order: 4,
      };

      const promises = [getContactAdministration(), getContactQuantitySurvey()];

      if (item?.assignees?.[0]?.email) {
        promises.push(getContactBuyerAgent(item?.assignees[0]?.email));
      }
      if (item?.["OPPORTUNITY OWNER"]?.[0]?.email) {
        promises.push(
          getContactHeadBuyerAgent(item?.["OPPORTUNITY OWNER"][0]?.email)
        );
      }

      Promise.all(promises).then((result) => {
        const contacts = orderBy(
          [manager, legalPartner, financialPartner, inspector, ...result],
          ["order", "asc"]
        ).filter((one) => one.name && one.email && one.phone);
        setPropertyContacts(contacts);
        setLoading(false);
      });
    }
  };

  const onCopyPhone = () => {
    Toast.hide();
    setTimeout(() => {
      Toast.show({
        type: "info",
        text1: "Phone number copied!",
        position: "bottom",
        visibilityTime: 3000,
      });
    }, 300);
  };

  useEffect(() => {
    fetchData();
  }, [showContacts]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity onPress={goBack} style={styles.btnBack}>
          <Image
            style={{ height: 16, width: 12 }}
            resizeMode="contain"
            source={require("../../../assets/arrow_left.png")}
          />
        </TouchableOpacity>
        <Text
          style={{
            fontSize: 16,
            color: "#36414C",
            fontFamily: MAIN_FONT.semi,
          }}
        >
          Contacts
        </Text>
      </View>
      {loading ? (
        <ActivityIndicator size="large" />
      ) : (
        <ScrollView
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={fetchData} />
          }
        >
          <MediaQuery minWidth={1440}>
            {(isBreakPoint) => (
              <View
                style={{
                  ...styles.contentScrollView,
                  ...(isBreakPoint && {
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    gap: 13,
                  }),
                }}
              >
                {propertyContacts.map((item, index) => (
                  <View
                    key={index}
                    style={{
                      paddingVertical: 13,
                      ...(isBreakPoint && {
                        width: "48%",
                        paddingVertical: 0,
                      }),
                    }}
                  >
                    <ContactCard
                      backgroundColor="#F5F6FA"
                      type={item.type}
                      name={item.name}
                      phoneNumber={item.phone}
                      email={item.email}
                      {...(!!item.employeeAvatar && {
                        employeeAvatar: item.employeeAvatar,
                      })}
                      onCopyPhone={onCopyPhone}
                    />
                  </View>
                ))}
              </View>
            )}
          </MediaQuery>
        </ScrollView>
      )}
      <Toast
        config={{
          info: (props) => (
            <InfoToast
              {...props}
              style={{ borderLeftWidth: 0, width: "187px" }}
              contentContainerStyle={{
                backgroundColor: "#E9E9EB",
                borderRadius: 6,
              }}
              text1Style={{
                fontSize: 12,
                lineHeight: 22,
                color: MAIN_COLOR.darkGray,
                fontFamily: MAIN_FONT.regular,
              }}
            />
          ),
        }}
      />
    </View>
  );
};

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    TIA: state.TIA,
  };
}

export default connect(mapStateToProps)(PropertyContacts);
