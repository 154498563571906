import {
  StyleSheet,
} from 'react-native';

import { MAIN_FONT } from "../../../Utility/Fonts/font";
import { MAIN_COLOR } from "../../../Utility/Colors"

const styles = StyleSheet.create({
  itemContainer: {
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    gap: 2,
  },
  groupRadio: {
    width: 20,
    height: 20,
    marginRight: 12,
    borderRadius: "50%",
    borderWidth: 2,
    backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
    borderColor: MAIN_COLOR.PRIMARY_COLOR
  },
  groupRadioNonChecked: {
    flexDirection: "row",
    width: 20,
    height: 20,
    marginRight: 12,
    borderRadius: "50%",
    borderWidth: 2,
    borderColor: MAIN_COLOR.G100,
  },
  textName: {
    fontSize: 13,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.regular,
    marginVertical: 5,
  },
});

export default styles;
