import { Dimensions, StyleSheet } from "react-native";
import { MAIN_COLOR, TAB_BAR_COLOR } from "../../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    width: 422,
    height: Dimensions.get("window").height - 246,
    minHeight: 592,
    position: "absolute",
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    borderRadius: 15,
    top: 104,
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLOR.G800,
  },
  subTitle: {
    color: MAIN_COLOR.G700,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    fontWeight: 400,
  },
  investTimeLine: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: 20,
  },
  canBorrow: {
    fontWeight: 600,
    fontSize: 16,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLOR.G800,
    textAlign: "center",
    margin: "auto",
    width: "80%",
  },
  showBorrow: {
    backgroundColor: MAIN_COLOR.yellow,
    paddingVertical: 5,
  },
  showBorrowBtn: {
    fontWeight: 400,
    fontSize: 20,
    fontFamily: MAIN_FONT.semi,
    color: "#200E32",
    textAlign: "center",
    margin: "auto",
    width: "80%",
  },
  basedOn: {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLOR.G700,
    textAlign: "center",
  },
  btn: {
    height: 41,
    paddingHorizontal: 24,
    paddingVertical: 10,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: MAIN_COLOR.G800,
    margin: "auto",
    marginTop: 26,
  },
  btnText: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLOR.BACKGROUND_COLOR,
  },
});
