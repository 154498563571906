import { StyleSheet } from "react-native";
import { MAIN_COLOR, TAB_BAR_COLOR } from "../../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    paddingHorizontal: 18,
    paddingVertical: 16,
    borderWidth: 1,
    borderColor: "rgba(173, 185, 199, 0.3)",
    borderRadius: 8,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    display: "flex",
    flexDirection: "column",
    gap: 20,
    flexShrink: 1,
    width: "60%",
    // flex: 2,
    // flexDirection: "row",
    // alignItems: "center",
  },
  label: {
    fontWeight: 600,
    fontSize: 14,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.regular,
  },
  info: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 5,
  },
  name: {
    fontSize: 12,
    fontWeight: 400,
    color: MAIN_COLOR.G700,
    fontFamily: MAIN_FONT.regular,
    lineHeight: 20,
  },
  value: {
    fontWeight: 600,
    fontSize: 12,
    color: TAB_BAR_COLOR.INACTIVE_COLOR,
    fontFamily: MAIN_FONT.regular,
    lineHeight: 22,
  },
  separator: {
    width: 2,
    backgroundColor: "#E6EAEE",
    height: "100%",
    marginHorizontal: 10,
  },
});
