import React, { useEffect, useState } from "react";
import { ActivityIndicator, Image, ScrollView, Text, View } from "react-native";
import { LineChart } from "react-native-chart-kit";
import { MAIN_COLOR } from "../../../Utility/Colors/index.js";
import { getPublicMarketHistory } from "../../../Clickup/api.js";
import {
  MARKET_INSIGHT_FIELDS,
  MARKET_INSIGHT_LABELS,
} from "../../../Utility/Fields/index.js";
import styles from "./style.js";
import { MAIN_FONT } from "../../../Utility/Fonts/font.js";
import { isEmpty } from "lodash";

const SpecificData = ({ name }) => {
  const [marketHistories, setMarketHistories] = useState([]);
  const [loading, setLoading] = useState(false);

  const chartConfig = {
    style: {
      borderRadius: 16,
    },
    color: (opacity = 1) => MAIN_COLOR.BACKGROUND_COLOR,
    labelColor: (opacity = 1) => `#969696`,
    decimalPlaces: 1,
    propsForDots: { r: "0", strokeWidth: "0" },
    backgroundGradientFrom: MAIN_COLOR.BACKGROUND_COLOR,
    backgroundGradientTo: MAIN_COLOR.BACKGROUND_COLOR,
    fillShadowGradient: MAIN_COLOR.BACKGROUND_COLOR,
    propsForBackgroundLines: {
      stroke: "#E8E6E6",
      strokeDasharray: "",
    },
    propsForHorizontalLabels: {
      dx: 4,
      dy: 2,
      fontFamily: MAIN_FONT.regular,
      fontSize: 14,
      color: MAIN_COLOR.G700,
    },
    propsForVerticalLabels: {
      fontFamily: MAIN_FONT.regular,
      fontSize: 14,
      color: MAIN_COLOR.G700,
    },
  };

  const calcPercentageGrowth = (newValue, oldValue) => {
    const value = (((newValue - oldValue) / (oldValue || 1)) * 100).toFixed(1);
    return value > 0 ? `+ ${value}` : value;
  };

  const fetchData = async (name) => {
    try {
      setLoading(true);
      const response = await getPublicMarketHistory(name);
      if (!isEmpty(response.data)) {
        const result = MARKET_INSIGHT_FIELDS.map((one) => {
          return {
            key: one,
            data: response.data[one],
            ...MARKET_INSIGHT_LABELS[one],
          };
        });
        setMarketHistories(result);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (name) fetchData(name);
  }, [name]);

  if (!name) return <></>;

  if (loading) return <ActivityIndicator size="large" />;

  return (
    <View
      style={{ paddingHorizontal: "5%", marginVertical: 10, marginBottom: 20 }}
    >
      <ScrollView horizontal style={{ paddingVertical: 30 }}>
        {marketHistories.length ? (
          <>
            {marketHistories.map((market) => {
              const value = calcPercentageGrowth(
                Number(market.data.slice(-1)[0].value ?? 0),
                Number(market.data[0].value ?? 0)
              );
              const formatData = market.data.filter(
                (one, index) => !(index % 3)
              );
              return (
                <View style={styles.container}>
                  <Text style={styles.title}>{name} market insights</Text>
                  <Text style={styles.subTitle}>{market.title}</Text>
                  <View
                    style={{
                      marginVertical: 20,
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <LineChart
                      key={market.key}
                      segments={3}
                      bezier
                      yAxisInterval={1}
                      formatYLabel={(val) => {
                        if (
                          [" Days", " Supply", " pw"].includes(market.ySuffix)
                        ) {
                          return Number(val).toFixed(0).toString();
                        }
                        return val;
                      }}
                      data={{
                        labels: formatData.map(({ label }) =>
                          label.replace(/[0-9]/g, "")
                        ),
                        datasets: [
                          {
                            data: formatData.map(({ value }) => value ?? 0),
                            color: (opacity = 1) => MAIN_COLOR.yellow,
                            strokeWidth: 3,
                          },
                        ],
                      }}
                      width={470}
                      height={198}
                      {...(market.yPrefix && {
                        yAxisLabel: market.yPrefix,
                      })}
                      {...(market.ySuffix && {
                        yAxisSuffix: market.ySuffix,
                      })}
                      {...(market.formatter && {
                        formatYLabel: (value) =>
                          market.formatter(Number(value).toFixed(0)),
                      })}
                      hidePointsAtIndex={market.data.filter(
                        (one, index) => index % 2
                      )}
                      chartConfig={chartConfig}
                      style={{
                        paddingRight: market.chartPdr,
                      }}
                    />
                  </View>
                  <View style={styles.cardContainer}>
                    <View style={styles.sideLeft}>
                      <View style={styles.center}>
                        <Image
                          resizeMode="cover"
                          style={styles.icon}
                          source={market.icon}
                        />
                        <Text style={styles.subTitleCard}>
                          {market.subTitle}
                        </Text>
                      </View>
                      <Text
                        style={[
                          styles.title,
                          { textAlign: "left", color: "#2B2B2B", fontSize: 16 },
                        ]}
                      >
                        {market?.yPrefix}
                        {market.formatter
                          ? market.formatter(market.data.slice(-1)[0].value)
                          : market.data.slice(-1)[0].value}
                        {market?.unitCard || market?.ySuffix}
                      </Text>
                    </View>
                    <View style={styles.sideRight}>
                      <View style={styles.center}>
                        <Image
                          resizeMode="cover"
                          style={styles.icon}
                          source={require("../../../assets/market/change-months.svg")}
                        />
                        <Text style={styles.subTitleCard}>
                          Change in 12 mths
                        </Text>
                      </View>
                      <Text
                        style={[
                          styles.title,
                          {
                            textAlign: "right",
                            color: "#2B2B2B",
                            fontSize: 16,
                          },
                        ]}
                      >
                        {value}%
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
          </>
        ) : null}
      </ScrollView>
    </View>
  );
};

export default SpecificData;
