import React from "react";
import {
  Animated,
  Dimensions,
  Image,
  Linking,
  Modal,
  PermissionsAndroid,
  Platform,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Autolink } from "react-native-autolink";
import { createElement } from "react-native-web";
import VPlayer from "react-vplayer";
// import Pdf from 'react-native-pdf';
import { showMessage } from "react-native-flash-message";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import WebView from "react-native-webview";
import { NavigationEvents } from "react-navigation";
import { connect } from "react-redux";
import { setColors } from "../../Action/color";
import { user as setUser } from "../../Reducer/user";
import { MAIN_COLOR } from "../../Utility/Colors";
import LOADER from "../../Utility/ComponentCard/Loader";
import fields from "../../Utility/Fields";
import { MAIN_FONT } from "../../Utility/Fonts/font";
// import * as WebBrowser from 'expo-web-browser';
import styles from "./style";
import { walkThrough } from "../../Zoho/Tasks";
import ContactCard from "../../Utility/ComponentCard/ContactCard/ContactCard";
import { getContactByEmail } from "../../Clickup/api";
import MediaQuery from "react-responsive";
import CheckList from '../../Utility/ComponentCheckList';
import Toast from 'react-native-toast-message';
import { InfoToast } from "react-native-toast-message";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      today: new Date(),
      monthly: false,
      week: [],
      pdfs: [],
      pngs: [],
      clickUpStatusList: props?.clickUpStatusList ?? [],
      id: props.id,
      task: props.task,
      comments: props.comments,
      status: props.status,
      checklists: props?.checklists ?? [],
      time: props.time,
      progressTime: props.progressTime,
      markedDates: {},
      opacity: new Animated.Value(1),
      height: new Animated.Value(0),
      videoUrl: false,
      controlUrl: false,
      videos: props.task.Videos ? props.task.Videos : [],
      links: props.task?.Link ? props.task?.Link : [],
      files: props.task?.Files ? props.task?.Files : [],
      linkVideo: props.task["Link Video"] ? props.task["Link Video"] : [],
      filesCount: props.filesCount,
      linksCount: props.linksCount,
      videosCount: props.videosCount,
      selectedLink: false,
      videoNull: false,
      fileName: false,
      propertyConditionNotes: props.propertyConditionNotes,
      selected: {},
      solicitorName: props.solicitorName,
      solicitorPhone: props.solicitorPhone,
      solicitorEmail: props.solicitorEmail,
      brokerName: props.brokerName,
      brokerEmail: props.brokerEmail,
      brokerPhone: props.brokerPhone,
      adminStrationPhone: null,
      adminStrationAvatar: null,
      oppotunityAssigneeEmail: props.oppotunityAssigneeEmail,
      buyerAgentName: null,
      buyerAgentEmail: null,
      buyerAgentPhone: null,
      buyerAgentAvatar: null,
      oppotunityHeadBuyerAgentEmail: props.oppotunityHeadBuyerAgentEmail,
      headBuyerAgentName: null,
      headBuyerAgentEmail: null,
      headBuyerAgentPhone: null,
      headBuyerAgentAvatar: null,
      bpInspectorName: props.bpInspectorName,
      bpInspectorEmail: props.bpInspectorEmail,
      bpInspectorPhone: props.bpInspectorPhone,
      propertyManagerName: props.propertyManagerName,
      propertyManagerEmail: props.propertyManagerEmail,
      propertyManagerPhone: props.propertyManagerPhone,
      quantitySurveyorPhone: null,
    };

    this.player = [];
  }

  getNextSevenDays = () => {
    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    let arr = [];

    for (let i = 0; i < 7; i++) {
      arr.push(new Date(curr.setDate(first + i)));
    }

    this.setState({ week: arr, selected: new Date().getDate() });
  };

  fullScreen() {
    this.player[this.state.videoUrl].presentFullscreenPlayer();
  }

  componentWillUnmount() {}

  componentDidUpdate() {
    // console.log(JSON.stringify(this.props));
  }

  // async openLink() {
  //   try {
  //     const url = 'https://www.loom.com/share/87cff056f75f48449440b6b62b828902'
  //     if (await InAppBrowser.isAvailable()) {
  //       const result = await InAppBrowser.open(url, {
  //         // iOS Properties
  //         dismissButtonStyle: 'cancel',
  //         preferredBarTintColor: '#FABF01',
  //         preferredControlTintColor: 'white',
  //         readerMode: false,
  //         animated: true,
  //         modalPresentationStyle: 'fullScreen',
  //         modalTransitionStyle: 'coverVertical',
  //         modalEnabled: true,
  //         enableBarCollapsing: false,
  //         // Android Properties
  //         showTitle: true,
  //         toolbarColor: '#FABF01',
  //         secondaryToolbarColor: 'black',
  //         navigationBarColor: 'black',
  //         navigationBarDividerColor: 'white',
  //         enableUrlBarHiding: true,
  //         enableDefaultShare: true,
  //         forceCloseOnRedirection: false,
  //         // Specify full animation resource identifier(package:anim/name)
  //         // or only resource name(in case of animation bundled with app).
  //         animations: {
  //           startEnter: 'slide_in_right',
  //           startExit: 'slide_out_left',
  //           endEnter: 'slide_in_left',
  //           endExit: 'slide_out_right'
  //         },
  //         headers: {
  //           'my-custom-header': 'my custom header value'
  //         }
  //       })
  //     alert(JSON.stringify(result))
  //     }
  //     else Linking.openURL(url)
  //   } catch (error) {
  //     alert(error.message)
  //   }
  // }

  async generateThumbnail() {
    return (
      <View style={{ flex: 1, backgroundColor: "red", width: "100%" }}>
        <Pdf
          singlePage={false}
          enablePaging
          spacing={100}
          // enableRTL
          // enableAnnotationRendering
          source={source}
          onLoadComplete={(numberOfPages, filePath) => {
            console.log(`number of pages: ${numberOfPages}`);
          }}
          onPageChanged={(page, numberOfPages) => {
            console.log(`current page: ${page}`);
          }}
          onError={(error) => {
            console.log(error);
          }}
          onPressLink={(uri) => {
            console.log(`Link presse: ${uri}`);
          }}
          activityIndicatorProps={{ color: "red" }}
          style={{ flex: 1, backgroundColor: "#FFF" }}
        />
      </View>
    );

    // PdfThumbnail.generate('https://juventudedesporto.cplp.org/files/sample-pdf_9359.pdf', 0).then(result => {
    //   console.log("Pdf Result:\n", result);
    //   this.setState({ thumbnail: result })
    // });
  }

  async downloadFile(fileName, path) {
    fileName = fileName.replace(/ /g, "");
    console.log("fileName:: ", fileName);

    var RNFS = require("react-native-fs");

    var DownloadDir = RNFS.CachesDirectoryPath; //+ '/test.txt';

    const folderExists = await RNFS.exists(DownloadDir);
    if (!folderExists) {
      await RNFS.mkdir(DownloadDir);
    }

    path = path.replace("/view", "&export=download");

    RNFS.downloadFile({
      fromUrl: path, //.replace('usp=sharing',''),
      toFile: DownloadDir + "/" + this.state.id + fileName + ".pdf",
    }).promise.then((r) => {

      if (r.statusCode === 200) {
        let pdfs = this.state.pdfs;

        pdfs.push(DownloadDir + "/" + this.state.id + fileName + ".pdf");
        this.setState({ pdfs }, () => {
          console.log("pdfs:::", this.state.pdfs);
        });
      }
    });
  }

  async downloadPng(fileName, path) {
    var RNFS = require("react-native-fs");
    var DownloadDir = RNFS.CachesDirectoryPath; //+ '/test.txt';
    const folderExists = await RNFS.exists(DownloadDir);
    if (!folderExists) {
      await RNFS.mkdir(DownloadDir);
    }
    path = path.replace("/view", "&export=download");
    RNFS.downloadFile({
      fromUrl: path,
      cacheable: true,
      toFile: DownloadDir + "/" + this.state.id + fileName + ".png",
    }).promise.then((r) => {
      console.log(
        "results:",
        r,
        DownloadDir + "/" + this.state.id + fileName + ".png"
      );

      if (r.statusCode === 200) {
        let pngs = this.state.pngs;

        pngs.push(DownloadDir + "/" + this.state.id + fileName + ".png");
        this.setState({ pngs }, () => {
          console.log("pngs::", this.state.pngs);
        });
      } else {
        showMessage({
          message: path,
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
        });
      }
    });
  }

  onCopyPhone() {
    Toast.hide();
    setTimeout(() => {
      Toast.show({
        type: "info",
        text1: "Phone number copied!",
        position: "bottom",
        visibilityTime: 3000,
      });
    }, 300);
  }
  // downloadFile(fileName, path) {

  //   // path = path.replace("/view?usp=sharing", "");

  //   // const { config, fs } = RNFetchBlob;

  //   // const { DownloadDir } = fs.dirs;

  //   // console.log("download directory:", DownloadDir)

  //   // const options = {
  //   //   fileCache: true,
  //   //   path: DownloadDir + fileName + ".pdf"

  //   // };

  //   config(options).fetch('GET', path).then(async (res) => {
  //     let pdfs = this.state.pdfs;

  //     let base = await res.base64();

  //     pdfs.push(res.data);
  //     this.setState({ pdfs }, () => {
  //       console.log(this.state.pdfs);
  //     });
  //   });
  // }

  getImages(arr) {
    let pngs = [];
    if (!arr.length) {
      this.setState({ pngs: [] });
      return null;
    }

    arr.map((item, index) => {
      let search = item.search("https://drive.google.com/file/d/");
      // if (search === -1) {
      //   return
      // }

      if (search === 0) {
        this.downloadPng(
          "Image_" + index,
          item.replace(
            "https://drive.google.com/file/d/",
            "https://drive.google.com/uc?id="
          )
        );
      } else {
        let pngs = this.state.pngs;
        pngs.push(item);
        this.setState({ pngs });
      }
    });
  }

  getFiles() {
    const { files } = this.state;

    // alert(JSON.stringify(files))

    let pdfs = [];

    if (!files) {
      this.setState({ pdfs: [] });

      return null;
    }

    Object.keys(files).map((item, index) => {
      if (!files[item]) {
        this.setState({ fileNull: true });
        return;
      }

      pdfs.push(files[item]);
      // console.log('item::', files[item])

      // let search = files[item].search("https://drive.google.com/file/d/");
      // console.log("search::", search);
      // if (search === 0) {
      //   this.downloadFile(item, files[item].replace("https://drive.google.com/file/d/", "https://drive.google.com/uc?id="));
      // }
      // else if (files[item].includes(".pdf")) {
      //   pdfs.push(item);
      // }
      // else {
      //   this.setState({ links: { ...this.state.links, [item]: files[item] } })
      // }
    });

    this.setState({ pdfs });
  }

  componentDidMount() {
    PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
      {
        title: "Cool Photo App Camera Permission",
        message:
          "Cool Photo App needs access to your camera " +
          "so you can take awesome pictures.",
        buttonNeutral: "Ask Me Later",
        buttonNegative: "Cancel",
        buttonPositive: "OK",
      }
    );

    PermissionsAndroid.requestMultiple([
      PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
      PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
    ]).then((result) => {
      console.log(result);
    });

    if (this.state.status != "Complete" && this.state.status != "In Progress") {
      // this.props.navigation.goBack(null);
    }

    if (this.state.files["Market Data"]) {
      // this.getImages(this.state.files['Market Data']);

      this.setState({ pngs: this.state.files["Market Data"] });

      // alert(this.state.files['Market Data'][0])
    } else {
      this.getFiles();
    }

    if (this.state.task[fields.appStageName] == "Profile Review") {
      getContactByEmail("taliesha@theinvestorsagency.com.au").then((result) => {
        const tasks = result?.tasks;
        if (tasks.length) {
          const custom_fields = tasks[0].custom_fields;
          const adminStrationPhone = custom_fields.find(
            (field) => field.name === "Phone"
          )?.value;

          const avatar = custom_fields.find(
            (field) => field.name === "Contact photo"
          )?.value;
          const adminStrationAvatar = avatar?.length ? avatar[0]?.url : null;
          this.setState({
            adminStrationPhone,
            adminStrationAvatar,
          });
        }
      });
    }

    if (
      this.state.task[fields.appStageName] == "Search Process" &&
      this.state.oppotunityAssigneeEmail
    ) {
      getContactByEmail(this.state.oppotunityAssigneeEmail).then((result) => {
        const tasks = result?.tasks;
        if (tasks.length) {
          const custom_fields = tasks[0].custom_fields;
          const buyerAgentName = tasks[0].name;
          const buyerAgentEmail = custom_fields.find(
            (field) => field.name === "Email"
          )?.value;
          const buyerAgentPhone = custom_fields.find(
            (field) => field.name === "Phone"
          )?.value;

          const avatar = custom_fields.find(
            (field) => field.name === "Contact photo"
          )?.value;
          const buyerAgentAvatar = avatar?.length ? avatar[0]?.url : null;

          this.setState({
            buyerAgentName,
            buyerAgentEmail,
            buyerAgentPhone,
            buyerAgentAvatar,
          });
        }
      });
    }

    if (
      this.state.task[fields.appStageName] == "Search Process" &&
      this.state.oppotunityHeadBuyerAgentEmail
    ) {
      getContactByEmail(this.state.oppotunityHeadBuyerAgentEmail).then(
        (result) => {
          const tasks = result?.tasks;
          if (tasks.length) {
            const custom_fields = tasks[0].custom_fields;
            const headBuyerAgentName = tasks[0].name;
            const headBuyerAgentEmail = custom_fields.find(
              (field) => field.name === "Email"
            )?.value;
            const headBuyerAgentPhone = custom_fields.find(
              (field) => field.name === "Phone"
            )?.value;

            const avatar = custom_fields.find(
              (field) => field.name === "Contact photo"
            )?.value;
            const headBuyerAgentAvatar = avatar?.length ? avatar[0]?.url : null;
            this.setState({
              headBuyerAgentName,
              headBuyerAgentEmail,
              headBuyerAgentPhone,
              headBuyerAgentAvatar,
            });
          }
        }
      );
    }

    if (this.state.task[fields.appStageName] == "Settlement") {
      getContactByEmail("ayden.hassan@mcgqs.com.au").then((result) => {
        const tasks = result?.tasks;
        if (tasks.length) {
          const custom_fields = tasks[0].custom_fields;
          const quantitySurveyorPhone = custom_fields.find(
            (field) => field.name === "Phone"
          )?.value;

          this.setState({
            quantitySurveyorPhone,
          });
        }
      });
    }
  }

  render() {
    const {
      pdfs,
      pngs,
      linkVideo,
      videosCount,
      linksCount,
      videos,
      task,
      files,
      status,
      height,
      selectedLink,
      time,
      progressTime,
      videoNull,
      fileNull,
      links,
    } = this.state;
    const MyLatLngMatcher = {
      pattern:
        /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/g,
      onPress: () => alert("LatLng pressed!"),
    };
    const web = (link) => {
      const Video = (props) => {
        const attrs = {
          src: props.source,
          // poster: props.poster,
          width: "100%",
          height: "100%",
          fullScreen: true,
          controls: true,
          frameborder: "0",
          webkitallowfullscreen: true,
          mozallowfullscreen: true,
          allowfullscreen: true,
          style: "",
          // controls: "controls"
        };

        return createElement("video", attrs);
      };

      return (
        <Video
          source={link}
          // poster={'https://www.fillmurray.com/480/300'}
        />
      );
    };

    const thumbnailResult = (source, index) => {
      // console.log(":::", task.iles[index])

      if (!source.uri) return;

      return (
        <TouchableOpacity
          onPress={() =>
            // this.props.navigation.navigate("File", { pdf: source, link: files[index] })
            {
              Linking.openURL(source.uri);
            }
          }
          onStartShouldSetResponder={() => true}
          style={{ width: 100, marginRight: 15 }}
        >
          <Image
            source={require("../../assets/pdf_.png")}
            style={{ height: 100, width: 100, resizeMode: "contain" }}
          />
        </TouchableOpacity>
      );
    };

    const maxHeight = height.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1000], // <-- value that larger than your content's height
    });

    let start_date = {
      startingDay: true,
      color: MAIN_COLOR.PRIMARY_COLOR,
      textColor: "white",
      backgroundColor: "red",
    };
    let date = {
      disabled: true,
      color: "black",
      textColor: "gray",
      startingDay: false,
    };
    let end_date = {
      disabled: true,
      endingDay: true,
      color: MAIN_COLOR.PRIMARY_COLOR,
      backgroundColor: "red",
      textColor: "white",
    };

    const _handlePressButtonAsync = async (url) => {
      //  WebBrowser.openBrowserAsync(url);

      WebBrowser.openBrowserAsync(url, {
        showInRecents: true,
        controlsColor: "#333",
        showTitle: false,
        enableBarCollapsing: true,
        toolbarColor: PRIMARY_COLOR,
      });
    };

    const renderVideos = (videos) => {
      // alert(JSON.stringify(videos))

      return videos.split("\n").map((item, index) => {
        return (
          <>
            {!item.includes(".mp4") ? (
              <View
                key={item + index}
                activeOpacity={0.8}
                style={{
                  width: "100%",
                  alignSelf: "center",
                  marginBottom: 20,
                  borderRadius: 20,
                }}
              >
                <WebView
                  androidHardwareAccelerationDisabled
                  allowsFullscreenVideo
                  startInLoadingState
                  allowsInlineMediaPlayback
                  style={{ height: 180, width: "100%", borderRadius: 20 }}
                  source={{
                    // uri: property['PROPERTY PRESO VIDEO URL']
                    html: `<iframe src=${item} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>`,
                    // html: `<html><iframe width="100%" height="100%" src=${taskUpdate.video_url.replace("vimeo.com", "player.vimeo.com/video")} webkit-playsinline frameborder="0" webkitallowfullscreen={true} mozallowfullscreen allowfullscreen></iframe></html>`
                  }}
                />
              </View>
            ) : (
              <View
                onPress={() => {
                  this.setState({ videoUrl: index });
                  this.player[index].presentFullscreenPlayer();
                }}
                key={item + index}
                activeOpacity={0.8}
                style={{
                  alignSelf: "center",
                  marginBottom: 20,
                  borderRadius: 20,
                }}
              >
                {web(item)}
              </View>
            )}
          </>
        );
      });
    };

    const renderBox = (item, index) => {
      return (
        <TouchableOpacity
          onPress={() =>
            this.setState(
              {
                selected: {
                  ...this.state.selected,
                  [index]: !this.state.selected[index],
                },
              },
              () => console.log(this.state.selected)
            )
          }
          style={{
            width: "100%",
            borderRadius: 10,
            padding: 5,
            backgroundColor: "rgba(255, 255, 255, 1)",
            paddingHorizontal: "5%",
            marginTop: 10,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text
              style={[
                styles.price,
                {
                  textAlign: "left",
                  marginVertical: 5,
                  fontFamily: MAIN_FONT.regular,
                },
              ]}
            >
              {item.title}
            </Text>

            <Image
              source={require("../../assets/right.png")}
              resizeMode="cover"
              style={{ height: 14, width: 14, borderRadius: 5 }}
            />
          </View>

          {this.state.selected[index] && (
            <>
              {!!item.description && (
                <Text
                  style={[
                    styles.price,
                    {
                      textAlign: "left",
                      marginVertical: 5,
                      fontFamily: MAIN_FONT.regular,
                      color: MAIN_COLOR.PRIMARY_COLOR,
                    },
                  ]}
                >
                  {item.description && item.description.toUpperCase()}
                </Text>
              )}

              <Text
                style={[
                  styles.price,
                  {
                    textAlign: "left",
                    marginVertical: 5,
                    fontFamily: MAIN_FONT.regular,
                    color: "#7D888F",
                  },
                ]}
              >
                {item.explanation}
              </Text>
            </>
          )}
        </TouchableOpacity>
      );
    };

    return (
      <SafeAreaView
        style={{ flex: 1, backgroundColor: MAIN_COLOR.BACKGROUND }}
        keyboardShouldPersistTaps={true}
        behavior={Platform.OS === "ios" ? "padding" : "padding"}
        keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}
      >
        <NavigationEvents
          onWillFocus={this._onFocus}
          onWillBlur={this._onBlurr}
        />

        {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 10, marginTop: 25, marginHorizontal: '5%' }}>

          <TouchableOpacity onPress={() => this.props.navigation.goBack()} style={{ backgroundColor: '#FFFFFF', borderRadius: 17, height: 48, width: 48, justifyContent: 'center', alignItems: 'center' }}>

            <Image
              style={{ height: 12 }}
              resizeMode="contain"
              source={require("../../assets/arrow_left.png")}
            />

          </TouchableOpacity>


          <Text style={{ fontSize: 16, color: '#36414C', fontFamily: MAIN_FONT.semi, textAlign: 'center' }}>
            Task Details
          </Text>



          <View opacity={0} style={{ backgroundColor: '#FFFFFF', borderRadius: 10, height: 48, width: 48, justifyContent: 'center', alignItems: 'center' }}>

            <Image
              style={{ height: 15 }}
              resizeMode="contain"
              source={require("../../assets/arrow_left.png")}
            />

          </View>

        </View> */}

        <ScrollView>
          <View style={styles.scrollViewContentView}>
            <View style={styles.centerView}>
              <View
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 20,
                  padding: 40,
                }}
              >
                {linkVideo ? renderVideos(linkVideo) : null}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    marginBottom: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 17,
                      color: "#36414C",
                      fontFamily: MAIN_FONT.semi,
                      width: "60%",
                    }}
                  >
                    {task[fields.appStageName]}
                  </Text>
                  <View
                    style={{
                      backgroundColor:
                        status == "In Progress"
                          ? "#FEF1C8"
                          : status == "Complete"
                          ? MAIN_COLOR.PRIMARY_COLOR
                          : "#EEF2F6",
                      paddingHorizontal: 10,
                      borderRadius: 25,
                      height: 30,
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        color: status == "Complete" ? "#fff" : "#66737F",
                        fontFamily:
                          status == "Complete"
                            ? MAIN_FONT.regular
                            : MAIN_FONT.light,
                        textAlign: "right",
                      }}
                    >
                      {status}
                    </Text>
                  </View>
                </View>
                {status == "Complete" ? (
                  <>
                    <View style={styles.dateTitle}>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#66737F",
                          fontFamily: MAIN_FONT.regular,
                        }}
                      >
                        Last Update :
                      </Text>

                      <Text style={styles.dateTime}>{time}</Text>
                    </View>

                    <Autolink
                      // Required: the text to parse for links
                      text={"\n" + task.Progress}
                      // Optional: enable email linking
                      email
                      // Optional: enable hashtag linking to instagram
                      hashtag="instagram"
                      // Optional: enable @username linking to twitter
                      mention="twitter"
                      // Optional: enable phone linking
                      phone="phone"
                      // Optional: enable URL linking
                      url
                      style={styles.taskDescription}
                      // Optional: custom linking matchers
                      matchers={[
                        { ...MyLatLngMatcher },
                        {
                          pattern: "Google Review",
                          onPress: () =>
                            Linking.openURL(
                              "https://www.google.com/search?q=google+reviews+the+investors+agency&rlz=1C1CHBF_en-GBAU956AU956&sxsrf=AOaemvIGhM8X6XVjlpD_3Ftg1MALtrOXmw%3A1638336474356&ei=2genYdmOFene1sQP7NCGuAM&ved=0ahUKEwjZ48387sH0AhVpr5UCHWyoATcQ4dUDCA8&uact=5&oq=google+reviews+the+investors+agency&gs_lcp=Cgdnd3Mtd2l6EAM6BwgAEEcQsAM6BwgAELADEEM6EAguEMcBENEDEMgDELADEEM6EAguEMcBEKMCEMgDELADEEM6BQgAEIAEOgQIABBDOgYIABAWEB46CAgAEBYQChAeOggIIRAWEB0QHjoHCCEQChCgAUoECEEYAFD6BljfMWCpNWgDcAJ4AIABlQKIAaAhkgEGMC4xOC41mAEAoAEByAEOwAEB&sclient=gws-wiz#lrd=0x6b0d558af53f0915:0x7fc4825f5701c315,1,,,"
                            ),
                        },
                        {
                          pattern: "Facebook Review",
                          onPress: () =>
                            Linking.openURL(
                              "https://www.facebook.com/theinvestorsagency/reviews/?ref=page_internal"
                            ),
                        },
                      ]}
                    />

                    {task["Facebook Review"] && (
                      <TouchableOpacity
                        onPress={() => Linking.openURL(task["Facebook Review"])}
                        style={{
                          flexDirection: "row",
                          backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
                          borderRadius: 10,
                          padding: 12.5,
                          width: 300,
                          justifyContent: "center",
                          alignSelf: "center",
                          marginVertical: 10,
                        }}
                      >
                        <Text
                          style={{
                            color: "#FFFFFF",
                            fontFamily: MAIN_FONT.semi,
                            fontSize: 14,
                            textAlign: "center",
                          }}
                        >
                          Facebook Review
                        </Text>
                      </TouchableOpacity>
                    )}

                    {task["Google Review"] && (
                      <TouchableOpacity
                        onPress={() => Linking.openURL(task["Google Review"])}
                        style={{
                          flexDirection: "row",
                          backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
                          borderRadius: 10,
                          padding: 12.5,
                          width: 300,
                          justifyContent: "center",
                          alignSelf: "center",
                          marginVertical: 10,
                        }}
                      >
                        <Text
                          style={{
                            color: "#FFFFFF",
                            fontFamily: MAIN_FONT.semi,
                            fontSize: 14,
                            textAlign: "center",
                          }}
                        >
                          Google Review
                        </Text>
                      </TouchableOpacity>
                    )}

                    {task["Review"] && (
                      <TouchableOpacity
                        onPress={() => Linking.openURL(task["Review"])}
                        style={{
                          flexDirection: "row",
                          backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
                          borderRadius: 10,
                          padding: 12.5,
                          width: 300,
                          justifyContent: "center",
                          alignSelf: "center",
                          marginVertical: 10,
                        }}
                      >
                        <Text
                          style={{
                            color: "#FFFFFF",
                            fontFamily: MAIN_FONT.semi,
                            fontSize: 14,
                            textAlign: "center",
                          }}
                        >
                          Review
                        </Text>
                      </TouchableOpacity>
                    )}

                    <View
                      style={{
                        height: 1,
                        backgroundColor: "#E7ECF2",
                        width: "100%",
                        marginVertical: 20,
                      }}
                    />
                  </>
                ) : null}

                {
                  //check:: it should be from templates
                  //task[fields.appStageName] !== "Profile Review" &&
                  progressTime && (
                    <>
                      <View style={styles.dateTitle}>
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#66737F",
                            fontFamily: MAIN_FONT.regular,
                          }}
                        >
                          Last Update :
                        </Text>

                        <Text style={styles.dateTime}>{progressTime}</Text>
                      </View>
                      {task["2 minute update"]?.trim() !== "" && (
                        <Text style={styles.taskDescription}>
                          {task["2 minute update"]?.trim()}
                        </Text>
                      )}
                    </>
                  )
                }

                {this.state.comments && (
                  <>
                    <View
                      style={{
                        height: 1,
                        backgroundColor: "#E7ECF2",
                        width: "100%",
                        marginVertical: 20,
                      }}
                    />
                    <View style={styles.dateTitle}>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#66737F",
                          fontFamily: MAIN_FONT.regular,
                        }}
                      >
                        Comments :
                      </Text>

                      <Text style={styles.dateTime}>{time}</Text>
                    </View>
                    <Text style={styles.taskDescription}>
                      {"\n"}

                      {this.state.comments}
                    </Text>
                  </>
                )}
              </View>
              {this.state.checklists
                ?.find((item) => item?.task_id === this.state.id)
                ?.items?.filter((check) => check?.resolved === true).length &&
              this.state.task[fields.appStageName] === "Unconditional" &&
              this.state.clickUpStatusList.some(
                (clickUp) =>
                  ["settled", "market updates"].includes(
                    String(clickUp?.status).toLowerCase()
                  ) && clickUp?.taskId === this.state.id
              ) ? (
                <View style={{ marginTop: 6 }}>
                  <CheckList
                    checklists={this.state.checklists.filter(
                      (item) => item?.task_id === this.state.id
                    )}
                    />
                </View>
              ) : (
                <></>
              )}
              <>
                {!!(
                  (task[fields.appStageName] == "Profile Review" &&
                    (this.state.solicitorName ||
                      this.state.brokerName ||
                      this.state.adminStrationPhone)) ||
                  (task[fields.appStageName] == "Search Process" &&
                    (this.state.buyerAgentName ||
                      this.state.headBuyerAgentName)) ||
                  (task[fields.appStageName] ==
                    "Contract, Building & Pest and Property Manager" &&
                    (this.state.bpInspectorName ||
                      this.state.propertyManagerName)) ||
                  (task[fields.appStageName] == "Settlement" &&
                    (this.state.propertyManagerName ||
                      this.state.quantitySurveyorPhone))
                ) && (
                  <Text
                    style={{
                      fontSize: 16,
                      color: "#272D37",
                      fontFamily: MAIN_FONT.regular,
                      marginTop: 20,
                    }}
                  >
                    Contacts
                  </Text>
                )}
                <MediaQuery minWidth={1281}>
                  {(isBreakPoint) => (
                    <>
                      {task[fields.appStageName] == "Profile Review" ? (
                        <div
                          {...(isBreakPoint && {
                            style: {
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "16px",
                            },
                          })}
                        >
                          {this.state.solicitorName &&
                            this.state.solicitorEmail &&
                            this.state.solicitorPhone && (
                              <View
                                style={{
                                  marginTop: 25,
                                  ...(isBreakPoint && {
                                    width: 350,
                                  }),
                                }}
                              >
                                <ContactCard
                                  type={"Legal Partner"}
                                  name={this.state.solicitorName}
                                  phoneNumber={this.state.solicitorPhone}
                                  email={this.state.solicitorEmail}
                                  onCopyPhone={this.onCopyPhone}
                                />
                              </View>
                            )}

                          {this.state.brokerName &&
                            this.state.brokerPhone &&
                            this.state.brokerEmail && (
                              <View
                                style={{
                                  marginTop: 25,
                                  ...(isBreakPoint && {
                                    width: 350,
                                  }),
                                }}
                              >
                                <ContactCard
                                  type={"Financial Partner"}
                                  name={this.state.brokerName}
                                  phoneNumber={this.state.brokerPhone}
                                  email={this.state.brokerEmail}
                                  onCopyPhone={this.onCopyPhone}
                                />
                              </View>
                            )}

                          {this.state.adminStrationPhone && (
                            <View
                              style={{
                                marginTop: 25,
                                ...(isBreakPoint && {
                                  width: 350,
                                }),
                              }}
                            >
                              <ContactCard
                                type={"Administration Manager"}
                                name={"Taliesha Vukic"}
                                phoneNumber={this.state.adminStrationPhone}
                                email={"taliesha@theinvestorsagency.com.au"}
                                employeeAvatar={this.state.adminStrationAvatar}
                                onCopyPhone={this.onCopyPhone}
                              />
                            </View>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </MediaQuery>

                <MediaQuery minWidth={1281}>
                  {(isBreakPoint) => (
                    <>
                      {task[fields.appStageName] == "Search Process" ? (
                        <div
                          {...(isBreakPoint && {
                            style: {
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "16px",
                            },
                          })}
                        >
                          {this.state.buyerAgentName &&
                            this.state.buyerAgentEmail &&
                            this.state.buyerAgentPhone && (
                              <View
                                style={{
                                  marginTop: 25,
                                  ...(isBreakPoint && {
                                    width: 350,
                                  }),
                                }}
                              >
                                <ContactCard
                                  type={"Associate Buyers Agent"}
                                  name={this.state.buyerAgentName}
                                  phoneNumber={this.state.buyerAgentPhone}
                                  email={this.state.buyerAgentEmail}
                                  employeeAvatar={this.state.buyerAgentAvatar}
                                  onCopyPhone={this.onCopyPhone}
                                />
                              </View>
                            )}

                          {this.state.headBuyerAgentName &&
                            this.state.headBuyerAgentEmail &&
                            this.state.headBuyerAgentPhone && (
                              <View
                                style={{
                                  marginTop: 25,
                                  ...(isBreakPoint && {
                                    width: 350,
                                  }),
                                }}
                              >
                                <ContactCard
                                  type={"Head Buyers Agent"}
                                  name={this.state.headBuyerAgentName}
                                  phoneNumber={this.state.headBuyerAgentPhone}
                                  email={this.state.headBuyerAgentEmail}
                                  employeeAvatar={
                                    this.state.headBuyerAgentAvatar
                                  }
                                  onCopyPhone={this.onCopyPhone}
                                />
                              </View>
                            )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </MediaQuery>

                <MediaQuery minWidth={1281}>
                  {(isBreakPoint) => (
                    <>
                      {task[fields.appStageName] ==
                      "Contract, Building & Pest and Property Manager" ? (
                        <div
                          {...(isBreakPoint && {
                            style: {
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "16px",
                            },
                          })}
                        >
                          {this.state.bpInspectorName &&
                            this.state.bpInspectorPhone &&
                            this.state.bpInspectorEmail && (
                              <View
                                style={{
                                  marginTop: 25,
                                  ...(isBreakPoint && {
                                    width: 350,
                                  }),
                                }}
                              >
                                <ContactCard
                                  type={"Building and Pest Inspector"}
                                  name={this.state.bpInspectorName}
                                  phoneNumber={this.state.bpInspectorPhone}
                                  email={this.state.bpInspectorEmail}
                                  onCopyPhone={this.onCopyPhone}
                                />
                              </View>
                            )}

                          {this.state.propertyManagerName &&
                            this.state.propertyManagerEmail &&
                            this.state.propertyManagerPhone && (
                              <View
                                style={{
                                  marginTop: 25,
                                  ...(isBreakPoint && {
                                    width: 350,
                                  }),
                                }}
                              >
                                <ContactCard
                                  type={"Property Manager"}
                                  name={this.state.propertyManagerName}
                                  phoneNumber={this.state.propertyManagerPhone}
                                  email={this.state.propertyManagerEmail}
                                  onCopyPhone={this.onCopyPhone}
                                />
                              </View>
                            )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </MediaQuery>

                <MediaQuery minWidth={1281}>
                  {(isBreakPoint) => (
                    <>
                      {task[fields.appStageName] == "Settlement" ? (
                        <>
                          {this.state.quantitySurveyorPhone && (
                            <View
                              style={{
                                marginTop: 25,
                                ...(isBreakPoint && {
                                  width: 350,
                                }),
                              }}
                            >
                              <ContactCard
                                type={"Quantity Surveyor"}
                                name={"Ayden Hassan"}
                                phoneNumber={this.state.quantitySurveyorPhone}
                                email={"ayden.hassan@mcgqs.com.au"}
                                onCopyPhone={this.onCopyPhone}
                              />
                            </View>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </MediaQuery>
              </>

              {task[fields.appStageName] === "Property Walk-Through" &&
                walkThrough({
                  "Property Condition Notes": this.state.propertyConditionNotes,
                }).map((item, index) => {
                  return renderBox(item, index);
                })}

              {/* {
                Object.keys(links).length ?
                  <>
                    <Text style={{ fontSize: 16, color: '#272D37', fontFamily: MAIN_FONT.regular, marginVertical: 20 }}>
                      Web Links
                    </Text>
                    <View style={{ flexDirection: 'row' }}>

                      {

                        links['Market Preso URL'] ?
                          <TouchableOpacity style={{ width: '100%', borderRadius: 20 }} onPress={() => this.props.navigation.navigate("WebView", { link: links['Market Preso URL'] })} >
                            <WebView
                              allowsFullscreenVideo

                              scrollEnabled={false}
                              startInLoadingState
                              allowsInlineMediaPlayback
                              androidHardwareAccelerationDisabled
                              containerStyle={{ borderRadius: 20 }}
                              style={{ height: wp(90) > 475 ? 260 : 300, width: '100%', borderRadius: 20 }}
                              source={{
                                uri: links['Market Preso URL']
                              }} />
                          </TouchableOpacity>
                          :

                          Object.keys(links).map((item, index) => {

                            return (
                              <TouchableOpacity onPress={() => this.props.navigation.navigate("WebView", { link: links[item] })} style={[{ flexDirection: 'row', backgroundColor: MAIN_COLOR.PRIMARY_COLOR, height: 40, paddingHorizontal: 10, borderRadius: 20, alignSelf: 'flex-start', justifyContent: 'center', alignItems: 'center', marginRight: 10 }, styles.shadow]}>
                                <Text style={{ fontSize: 14, fontFamily: MAIN_FONT.regular, marginLeft: 5, textAlign: 'center' }}>
                                  {item}
                                </Text>
                              </TouchableOpacity>
                            )
                          })
                      }



                    </View>
                  </>
                  :
                  null

              } */}

              {pngs.length ? (
                <>
                  <Text
                    style={{
                      fontSize: 16,
                      color: "#272D37",
                      fontFamily: MAIN_FONT.regular,
                      marginVertical: 20,
                    }}
                  >
                    Market Data
                  </Text>

                  <ScrollView
                    horizontal
                    style={{
                      paddingHorizontal: "0%",
                      marginBottom: 20,
                      paddingVertical: 10,
                      minWidth: "100%",
                      zIndex: 999,
                    }}
                  >
                    {pngs.map((item, index) => {
                      let ratio = 0.68;
                      Image.getSize(item, (width, height) => {
                        ratio = height / width;
                      });

                      return (
                        <View
                          style={{
                            backgroundColor: "#FFFFFF",
                            padding: 10,
                            borderRadius: 20,
                            width: 320,
                            marginRight: 20,
                          }}
                        >
                          <Image
                            source={{ uri: item }}
                            style={{
                              height: ratio * 300 - 20,
                              width: 300,
                              resizeMode: "contain",
                            }}
                          />
                        </View>
                      );
                    })}
                  </ScrollView>
                </>
              ) : null}

              {pdfs.length ? (
                <>
                  <Text
                    style={{
                      fontSize: 16,
                      color: "#272D37",
                      fontFamily: MAIN_FONT.regular,
                      marginVertical: 20,
                    }}
                  >
                    Documents
                  </Text>

                  <ScrollView
                    horizontal
                    contentContainerStyle={{ minWidth: "100%" }}
                    showsHorizontalScrollIndicator={false}
                    style={{
                      flexDirection: "row",
                      paddingHorizontal: "5%",
                      paddingHorizontal: 0,
                    }}
                  >
                    {pdfs.map((item, index) =>
                      thumbnailResult({ uri: item, cache: true }, index)
                    )}
                  </ScrollView>
                </>
              ) : null}

              {Object.keys(videos).length ? (
                links["Market Preso URL"] ? (
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#272D37",
                      fontFamily: MAIN_FONT.regular,
                      marginVertical: 20,
                      textAlign: "justify",
                    }}
                  >
                    The data you see above reflects the current and past
                    conditions of the suburb where we have found the property.
                    We will explain these data points to you very soon, along
                    with your property presentation.
                  </Text>
                ) : (
                  <>
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#272D37",
                        fontFamily: MAIN_FONT.regular,
                        marginVertical: 20,
                      }}
                    >
                      Videos
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      {videos["Walk Through Videos"] ? (
                        renderVideos(videos["Walk Through Videos"])
                      ) : videos["Pre Settlement Inspection Videos"] ? (
                        renderVideos(videos["Pre Settlement Inspection Videos"])
                      ) : videos["Market Preso Video URL"] ? (
                        <WebView
                          allowsFullscreenVideo
                          startInLoadingState
                          allowsInlineMediaPlayback
                          androidHardwareAccelerationDisabled
                          style={{
                            height: 210,
                            width: "100%",
                            borderRadius: 20,
                          }}
                          source={{
                            // uri: property['PROPERTY PRESO VIDEO URL']

                            html: `<iframe src="${videos[
                              "Market Preso Video URL"
                            ].replace(
                              "share",
                              "embed"
                            )}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>`,

                            // html: `<html><iframe width="100%" height="100%" src=${taskUpdate.video_url.replace("vimeo.com", "player.vimeo.com/video")} webkit-playsinline frameborder="0" webkitallowfullscreen={true} mozallowfullscreen allowfullscreen></iframe></html>`
                          }}
                        />
                      ) : null}
                    </View>
                  </>
                )
              ) : null}
            </View>

            {task["Booking URL"] && (
              <TouchableOpacity
                onPress={() => Linking.openURL(task["Booking URL"])}
                style={{
                  flexDirection: "row",
                  backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
                  borderRadius: 10,
                  padding: 12.5,
                  width: 300,
                  justifyContent: "center",
                  alignSelf: "center",
                  marginVertical: 40,
                }}
              >
                <Text
                  style={{
                    color: "#FFFFFF",
                    fontFamily: MAIN_FONT.semi,
                    fontSize: 14,
                    textAlign: "center",
                  }}
                >
                  BOOK A QUICK CALL
                </Text>
              </TouchableOpacity>
            )}
          </View>

          {/* <View style={{flex:1, backgroundColor: 'red', width: '100%'}}>

            <Pdf
            singlePage={false}
            enablePaging
            spacing={100}
            // enableRTL
            // enableAnnotationRendering
              source={source}
              onLoadComplete={(numberOfPages, filePath) => {
                console.log(`number of pages: ${numberOfPages}`);
              }}
              onPageChanged={(page, numberOfPages) => {
                console.log(`current page: ${page}`);
              }}
              onError={(error) => {
                console.log(error);
              }}
              onPressLink={(uri) => {
                console.log(`Link presse: ${uri}`)
              }}
              activityIndicatorProps={{color: 'red'}}
              style={{ flex: 1, backgroundColor: '#FFF'}} />
          </View>
 */}
        </ScrollView>

        {/* {
          pdfs.length?
          alert(
            (pdfs.length !== filesCount && !fileNull)
            // (pdfs.length + Object.keys(links).length) + "" + (linksCount + filesCount)
            )
          :
          null
        } */}
        {/* {pdfs.length !== filesCount &&
        !fileNull &&
        !pngs.length &&
        !(
          pdfs.length + Object.keys(links).length ===
          linksCount + filesCount
        ) ? (
          <LOADER />
        ) : null} */}

        <Modal
          animationType={"slide"}
          transparent={true}
          visible={selectedLink ? true : false}
          onRequestClose={() => {
            this.setState({ selectedLink: false });
          }}
        >
          <View
            style={{
              flex: 1,
              backgroundColor: "rgba(0, 0, 0, 1)",
              justifyContent: "flex-end",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingVertical: 10,
                marginTop: 25,
                marginHorizontal: "5%",
              }}
            >
              <TouchableOpacity
                onPress={() => this.setState({ selectedLink: false })}
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 17,
                  height: 48,
                  width: 48,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{ height: 12 }}
                  resizeMode="contain"
                  source={require("../../assets/arrow_left.png")}
                />
              </TouchableOpacity>

              <Text
                style={{
                  fontSize: 16,
                  color: "#36414C",
                  fontFamily: MAIN_FONT.semi,
                  textAlign: "center",
                }}
              >
                {/* Task Details */}
              </Text>

              <View
                opacity={0}
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 10,
                  height: 48,
                  width: 48,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{ height: 15 }}
                  resizeMode="contain"
                  source={require("../../assets/arrow_left.png")}
                />
              </View>
            </View>

            {/* 
            <Video
              disableFocus={true}
              source={{ uri: selectedLink }}
              // posterResizeMode="cover"
              fullscreenOrientation="portrait"
              fullscreen={true}
              // ref={(ref)=>this.player[index]=ref}
              // resizeMode={'contain'}
              // controls

              resizeMode={"none"}
              // onPlaybackResume={()=>this.setState({videoUrl: index})}
              controls={true}
              // paused={this.state.videoUrl !== index}
              style={{ flex: 1, borderRadius: 0, backgroundColor: '#000' }}
            /> */}

            <VPlayer
              ref={(ref) => (this.player[index] = ref)}
              source={[
                {
                  url: selectedLink,
                  type: "video/mp4",
                  quality: 720,
                },
                {
                  url: selectedLink,
                  type: "video/mp4",
                  quality: 1080,
                },
                {
                  url: selectedLink,
                  type: "video/mp4",
                  quality: 480,
                },
                {
                  url: selectedLink,
                  type: "video/mp4",
                  quality: 360,
                },
              ]}
              // quality=""
            />

            {/* <TouchableOpacity activeOpacity={1} style={{ height: '85%', width: '100%', alignSelf: 'flex-end', borderTopRightRadius: 50, borderTopLeftRadius: 50 }}>
              <WebView
                androidHardwareAccelerationDisabled
                style={{ flex: 1, borderTopLeftRadius: 20, borderTopRightRadius: 20, zIndex: 20 }}
                javaScriptEnabled={true}
                source={{ uri: selectedLink }}
                startInLoadingState
                containerStyle={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}
              />
            </TouchableOpacity> */}
          </View>
        </Modal>

        <Toast
          config={{
            info: (props) => (
              <InfoToast
                {...props}
                style={{ borderLeftWidth: 0, width: "187px" }}
                contentContainerStyle={{
                  backgroundColor: "#E9E9EB",
                  borderRadius: 6,
                }}
                text1Style={{
                  fontSize: 12,
                  lineHeight: 22,
                  color: MAIN_COLOR.darkGray,
                  fontFamily: MAIN_FONT.regular,
                }}
              />
            ),
          }}
        />
      </SafeAreaView>
    );
  }
}

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserInfo: (state, action) => dispatch(setUser(state, action)),
    setUserToken: (state, action) => dispatch(setUser(state, action)),
    setActiveSupplier: (id, host) => dispatch(setActiveSupplier(id, host)),
    setColorsToRedux: (data) => dispatch(setColors(data)),
    setActiveSupplierInfo: (data) => dispatch(setActiveSupplierInfo(data)),
    setSupplierList: (list) => dispatch(setSupplierList(list)),
    setCartInfo: (id, total) => dispatch(setCartInfo(id, total)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
