import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
  },
  btnBack: {
    borderRadius: 17,
    height: 48,
    width: 48,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10
  },
  contentScrollView: {
    marginTop: 20,
  },
});
