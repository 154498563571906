import React from 'react';
import {
  Image, Linking, Platform, SafeAreaView, Share, Text, TouchableOpacity, View
} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import { NavigationEvents } from 'react-navigation';
import { connect } from 'react-redux';
import { setColors } from "../../Action/color";
import { user as setUser } from '../../Reducer/user';
import { MAIN_COLOR } from "../../Utility/Colors";
import { MAIN_FONT } from "../../Utility/Fonts/font";

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      today: new Date(),
      monthly: false,
      week: [],
      pdfs: [],
      pdf: props.navigation.state.params.pdf,
      link: props.navigation.state.params.link

    };

  }

  fullScreen() {
    setTimeout(() => {
      this.player.presentFullscreenPlayer();
    }, 1000)


  }

  componentWillUnmount() {
    Linking.removeEventListener();
  }

  componentDidUpdate() {
    // console.log(JSON.stringify(this.props));
  }

  componentDidMount() {

    // let path = this.state.link.replace("https://drive.google.com/file/d/", "https://drive.google.com/uc?export=download&id=")

    // path = this.state.link.replace("/view?usp=sharing", "");

    // const { config, fs } = RNFetchBlob;
    // const options = {
    //   fileCache: true
    // };

    // config(options).fetch('GET', path).then(async (res) => {
    //   let base = await res.base64();
    //   if(base) {
    //     this.setState({base});
    //     console.log(base);
    //     Share.share({ url: `data:application/pdf;base64,${base}` })
    //   }
    
    // });


  }



  render() {

    const { pdf, numberOfPages, page, link} = this.state;


    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: MAIN_COLOR.BACKGROUND }} keyboardShouldPersistTaps={true} behavior={Platform.OS === "ios" ? "padding" : "padding"} keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}>
        <NavigationEvents
          onWillFocus={this._onFocus}
          onWillBlur={this._onBlurr}
        />


        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 10, marginTop: 25, marginHorizontal: '5%' }}>

          <TouchableOpacity onPress={() => this.props.navigation.goBack()} style={{ backgroundColor: '#FFFFFF', borderRadius: 17, height: 48, width: 48, justifyContent: 'center', alignItems: 'center' }}>

            <Image
              style={{ height: 12 }}
              resizeMode="contain"
              source={require("../../assets/arrow_left.png")}
            />

          </TouchableOpacity>


          <Text style={{ fontSize: 16, color: '#36414C', fontFamily: MAIN_FONT.semi, textAlign: 'center' }}>
            Pdf
          </Text>

          <View style={{flexDirection: 'row'}}>
          {/* <TouchableOpacity onPress={() => {

            // console.log(pdf)

            // this.downloadFile();

          }

          } style={{ backgroundColor: '#ffffff', borderRadius: 10, height: 48, width: 48, justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>

            <Icon name="clouddownload" style={{ fontSize: 18, color: MAIN_COLOR.G800 }} />

          </TouchableOpacity> */}


          <TouchableOpacity onPress={() => {
            // Share.share({
            //   url: link
            // })

            Share.share({
              url: `${pdf.uri}`
            })
          }

          } style={{ backgroundColor: '#ffffff', borderRadius: 10, height: 48, width: 48, justifyContent: 'center', alignItems: 'center' }}>

            <Icon name="sharealt" style={{ fontSize: 18, color: MAIN_COLOR.G800 }} />

          </TouchableOpacity>


          </View>



         
        </View>


{/* expo web */}

{/* 
        <Pdf
          singlePage={false}
          // enablePaging
          spacing={0}
          fitWidth={true}
          fitPolicy={2}
          // spacing={10}
          // enableRTL
          // enableAnnotationRendering
          source={pdf}
          onLoadComplete={(numberOfPages, filePath) => {
            console.log(`number of pages: ${numberOfPages}`);
          }}
          onPageChanged={(page, numberOfPages) => {
            this.setState({ page, numberOfPages })
          }}
          onError={(error) => {
            console.log(error);
          }}
          onPressLink={(uri) => {
            console.log(`Link presse: ${uri}`)
          }}
          // activityIndicatorProps={{ color: 'red' }}
          style={{ flex: 1, backgroundColor: 'transparent', marginHorizontal: '2.5%' }} /> */}

        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: "100%", }}>
          <Text style={{ fontSize: 14, color: MAIN_COLOR.G800, fontFamily: MAIN_FONT.regular, margin: 20 }}>
            Page
          </Text>

          <Text style={{ fontSize: 16, color: '#8F9CA9', fontFamily: MAIN_FONT.light, margin: 20 }}>
            {page}/{numberOfPages}
          </Text>
        </View>





      </SafeAreaView>
    );
  }
};


function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setUserInfo: (state, action) => dispatch(setUser(state, action)),
    setUserToken: (state, action) => dispatch(setUser(state, action)),
    setActiveSupplier: (id, host) => dispatch(setActiveSupplier(id, host)),
    setColorsToRedux: (data) => dispatch(setColors(data)),
    setActiveSupplierInfo: (data) => dispatch(setActiveSupplierInfo(data)),
    setSupplierList: (list) => dispatch(setSupplierList(list)),
    setCartInfo: (id, total) => dispatch(setCartInfo(id, total))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);