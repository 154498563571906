import { StyleSheet } from "react-native";
import { MAIN_COLOR } from "../../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    paddingHorizontal: 22,
    paddingVertical: 12,
    borderWidth: 1,
    borderColor: "rgba(173, 185, 199, 0.3)",
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    borderRadius: 15,
    minHeight: 300,
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    fontFamily: MAIN_FONT.semi,
    color: MAIN_COLOR.G800,
  },
  currentLabel: {
    fontWeight: 400,
    fontSize: 12,
    color: MAIN_COLOR.G700,
    fontFamily: MAIN_FONT.regular,
  },
  currentValue: {
    fontWeight: 600,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLOR.PRIMARY_COLOR,
  },
  notes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  noteItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  round: {
    width: 18,
    height: 18,
    borderRadius: "50%",
    marginRight: 12,
    backgroundColor: MAIN_COLOR.yellow,
  },
  textLabel: {
    fontWeight: 400,
    fontSize: 12,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.regular,
  },
});
