import { cloneDeep, forEach, omit, sumBy } from "lodash";
import { MARKET_PRICE_REACTIONS } from "../test/marketPriceReactions";
import { PW_REACTIONS } from "../test/pwReactions";
import { RENT_REACTIONS } from "../test/rentReactions";
import { firstMasterLineObject, calcNewEquity } from "../test/test";
import moment from "moment";

export const MAX_RECOMMENDED_BORROWING = 520000;
export const MIN_RECOMMENDED_BORROWING = 300000;

export const INCOME_TYPE = {
  SINGLE: "single",
  COUPLE: "couple",
};

export const LOAN_TYPE = {
  IO: "io",
  PI: "pi",
};

export const OPTIONS_LOAN_STRUCTURE = [
  { label: "Interest Only", value: 1 },
  { label: "Principal & Interest", value: 2 },
];

export const OPTIONS_LOAN_TYPE = {
  [LOAN_TYPE.IO]: { label: "I/O", value: LOAN_TYPE.IO },
  [LOAN_TYPE.PI]: { label: "P&I", value: LOAN_TYPE.PI },
};

export const filterDataDetailProperty = (data) => {
  if (Array.isArray(data) && !data.length) return null;

  if (!Object.keys(data).length) return null;

  if (data.listings && !data.listings.length) return null;

  const _data = Array.isArray(data) ? data : data.listings;

  const itemValid = _data[0];

  for (let i = 1; i < _data.length; i++) {
    if (new Date(_data[i].saleDate) >= new Date(itemValid.saleDate))
      itemValid = _data[i];
  }

  return itemValid;
};
export const calcBuyingStructure = async (
  values,
  wiwo,
  newData,
  dataRedux,
  newDataRedux
) => {
  const { cashSavings, availableEquity, years, customIncomes } = values;
  let widgetProperties = cloneDeep(values.widgetProperties);

  let totalSavingsAndEquity = cashSavings + availableEquity;

  // Get data from widget
  let recommendedBorrowing =
    widgetProperties.dataList[0].input.bpResult.borrowingPowerMaximum;
  let livingExpenses =
    widgetProperties.dataList[0].input.bpResult.expenseResult
      .livingExpenseCustomerMonthly;
  let interestPerMonth =
    widgetProperties.dataList[1].output.repaymentResultModel.repaymentList[0]
      .paymentDisplayRounded;

  const incomeType =
    widgetProperties.dataList[0].input.borrowingRequestModel.applicationType ===
    INCOME_TYPE.SINGLE
      ? "Single"
      : "Couple";

  const wiwoBorrowingPower = widgetProperties?.dataList.find(
    (item) => item.id === "wiwo-borrowing-power"
  );

  const incomes =
    wiwoBorrowingPower.input.borrowingRequestModel.applicantList.map(
      (one) => one.incomeList
    );

  // calc total primary incomes
  const primaryIncomes = incomes.map((incomeList) =>
    incomeList.find((income) => income.id === "primary")
  );

  let totalPrimaryIncome = sumBy(primaryIncomes, "income");

  const listPurchase = [];
  let masterTimeLines = [];
  if (newDataRedux?.length && newData) {
    const newDataFromRedux = newDataRedux.filter(
      (item) => item.indexChange === newData?.indexChange
    );
    if (newDataFromRedux) {
      newData = Object.assign(newDataFromRedux[0], newData);
    }
  }
  while (recommendedBorrowing > MIN_RECOMMENDED_BORROWING) {
    // Check recommended borrowing between two values: MAX = 520000, MIN = 300000
    recommendedBorrowing =
      recommendedBorrowing <= MAX_RECOMMENDED_BORROWING
        ? recommendedBorrowing
        : MAX_RECOMMENDED_BORROWING;

    if (newData?.dependant && listPurchase.length === newData?.indexChange) {
      widgetProperties.dataList[0].input.borrowingRequestModel.dependants =
        newData.dependant;
    }

    if (newData?.interestRate && listPurchase.length === newData?.indexChange) {
      widgetProperties.dataList[1].input.repaymentModel.interestRate =
        newData.interestRate / 100;
    }

    // Step 2: Budget Options
    const values = budgetOptionsStep({
      recommendedBorrowing,
      totalSavingsAndEquity,
      dataChange: newData,
      listPurchase,
    });

    if (!values) break;

    let {
      propertyPrice,
      rateSelected,
      debtOnProperty,
      totalDepositBuyingCosts,
      rentPW,
    } = values;

    let oldNoOfMonth = null;
    let valueResult = {};
    // Step 3: Assumptions at target
    while (true) {
      if (oldNoOfMonth) {
        masterTimeLines.pop();
      }
      let {
        equityTarget,
        noOfMonth,
        valueAtTime,
        salaryAtTime,
        expensesPM,
        rentTracks,
        // totalIncome,
      } = assumptionsAtTargetStep({
        propertyPrice,
        livingExpenses,
        salary: totalPrimaryIncome,
        listPurchase,
        recommendedBorrowing,
        paymentPurchasePerMonth: interestPerMonth,
        masterTimeLines,
        rentPW,
        years,
        dataChange: newData,
        oldNoOfMonth,
      });
      // Step 4: Find Equity Allowance
      let {
        availableEquity,
        yourBorrowingAmount,
        paymentDisplayRounded,
        newWidgetProperty,
        totalIncome,
      } = await findEquityAllowanceStep({
        equityTarget,
        widgetProperties,
        expensesPM,
        salaryAtTime,
        rentTracks,
        recommendedBorrowing,
        wiwo,
        rentPW,
        masterTimeLines,
        propertyPrice,
        paymentPurchasePerMonth: interestPerMonth,
        listPurchase,
        customIncomes,
        years,
        newData,
        debtOnProperty,
        totalDepositBuyingCosts,
        totalSavingsAndEquity,
        rateSelected,
      });

      if (availableEquity) {
        valueResult = {
          equityTarget,
          noOfMonth,
          valueAtTime,
          salaryAtTime,
          expensesPM,
          rentTracks,

          availableEquity,
          yourBorrowingAmount,
          paymentDisplayRounded,
          newWidgetProperty,
          totalIncome,
        };
        break;
      }
      oldNoOfMonth = noOfMonth;
    }
    let {
      equityTarget,
      noOfMonth,
      valueAtTime,
      salaryAtTime,
      expensesPM,
      rentTracks,
      availableEquity,
      yourBorrowingAmount,
      paymentDisplayRounded,
      newWidgetProperty,
      totalIncome,
    } = valueResult;
    if (newData?.loanAmount && listPurchase.length === newData?.indexChange) {
      yourBorrowingAmount = newData.loanAmount || yourBorrowingAmount;
    }
    // TODO
    const cashAndEquityForNextPurchase =
      (newData?.cashContribution &&
      listPurchase.length + 1 === newData?.indexChange
        ? newData.cashContribution
        : totalSavingsAndEquity - totalDepositBuyingCosts) + availableEquity;

    // const totalNoOfMonth =
    //   sumBy(listPurchase, "noOfMonth") + noOfMonth - listPurchase.length - 1;
    const totalNoOfMonth = sumBy(listPurchase, "noOfMonth");
    // const purchaseDate = moment()
    //   .add(totalNoOfMonth, "month")
    //   .format("MM/DD/YYYY");
    const prevPurchase = listPurchase[listPurchase.length - 1];
    const purchaseDate =
      listPurchase.length === 0
        ? moment().format("MM/DD/YYYY")
        : moment(prevPurchase?.purchaseDate)
            .add(prevPurchase?.noOfMonth, "month")
            .format("MM/DD/YYYY");
    if (newData?.purchaseDate && listPurchase.length === newData?.indexChange) {
      purchaseDate = moment(newData?.purchaseDate).format("MM/DD/YYYY");
    }

    // const purchaseDate =
    //   newData?.purchaseDate && listPurchase.length === newData?.indexChange
    //     ? moment(newData?.purchaseDate).format("MM/DD/YYYY")
    //     : moment().add(totalNoOfMonth, "month").format("MM/DD/YYYY");
    const result = {
      noOfMonth,
      propertyPrice,
      equityTarget,
      rentPW,
      valueAtTime,
      salaryAtTime,
      expensesPM,
      rentTracks,
      newWidgetProperty,
      // Add/Edit cashContribution
      cashContribute:
        newData?.cashContribution &&
        listPurchase.length + 1 === newData?.indexChange
          ? newData.cashContribution
          : totalSavingsAndEquity - totalDepositBuyingCosts,
      equityFromPurchase: availableEquity,
      cashAndEquityForNextPurchase,
      totalDepositBuyingCosts,
      rateSelected,
      totalIncome,
      incomeType,
      loanAmount: yourBorrowingAmount,
      primaryIncomes,
      totalSavingsAndEquity,
      targetRentPW: Math.round(rentTracks.slice(-1)[0]),
      paymentDisplayRounded,
      interestPerMonth,
      recommendedBorrowing,
      id: `purchase-${listPurchase.length + 1}`,
      years,
      purchaseDate,
      dependant:
        widgetProperties.dataList[0].input.borrowingRequestModel.dependants,
      interestRate:
        widgetProperties.dataList[1].input.repaymentModel.interestRate * 100,
    };

    // Step 5: Contributions for the next purchase
    totalSavingsAndEquity = cashAndEquityForNextPurchase;
    recommendedBorrowing = yourBorrowingAmount;
    totalPrimaryIncome = salaryAtTime;
    livingExpenses = expensesPM;
    interestPerMonth = paymentDisplayRounded;
    widgetProperties = newWidgetProperty;

    listPurchase.push(result);

    if (totalNoOfMonth >= years * 12) break;
  }
  listPurchase.forEach((item) => {
    const widgetProperty = item?.newWidgetProperty;
    const wiwoBorrowingPower = widgetProperty?.dataList.find(
      (item) => item.id === "wiwo-borrowing-power"
    );
    const incomes =
      wiwoBorrowingPower.input.borrowingRequestModel.applicantList.map(
        (one) => one.incomeList
      );
    const primaryIncomes = incomes.map((incomeList) =>
      incomeList.find((income) => income.id === "primary")
    );
    if (primaryIncomes.length != 0) {
      primaryIncomes.forEach((income, index) => {
        item[`income${index + 1}`] = Math.round(income.income);
      });
    }
  });
  if (newData?.indexChange) {
    const dataPurchaseTargetRedux = dataRedux?.purchaseTargets;
    if (
      listPurchase.length > newData?.indexChange &&
      dataPurchaseTargetRedux.length > newData?.indexChange &&
      newData?.indexChange > 0
    ) {
      for (let i = 0; i < newData?.indexChange; i++) {
        listPurchase[i] = dataPurchaseTargetRedux[i];
      }
    }
  }
  return listPurchase;
};

/**
 * Step 2
 *
 * This function calculates the budget options for the buying structure
 * based on the provided values. The function does not take any parameters
 * and does not return any values.
 */
export const budgetOptionsStep = ({
  recommendedBorrowing,
  totalSavingsAndEquity,
  dataChange,
  listPurchase,
}) => {
  const RATE_12 = 0.12;
  const RATE_20 = 0.2;
  const FEE_RATE_12 = 0.43;
  const FEE_RATE_20 = 0.25;

  // Calculate the budget options for 12% rate
  const value12Percent = calcDepositByPercent({
    recommendedBorrowing,
    rate: RATE_12,
    fee_rate: FEE_RATE_12,
  });

  // Calculate the budget options for 20% rate
  const value20Percent = calcDepositByPercent({
    recommendedBorrowing,
    rate: RATE_20,
    fee_rate: FEE_RATE_20,
  });

  // Get total deposit for 12% and 20%
  const value12PercentTotalDeposit = value12Percent.totalDepositBuyingCosts;
  const value20PercentTotalDeposit = value20Percent.totalDepositBuyingCosts;

  // Add/Edit depositSize
  if (
    dataChange?.depositSize &&
    listPurchase.length === dataChange?.indexChange
  ) {
    return dataChange.depositSize === 20 ? value20Percent : value12Percent;
  }

  /* 
    1. Check if the total savings and equity is less than the total deposit for 12% and 20%
    2. Check if the total savings and equity is less than the total deposit for 12% 
    => recommended borrowing amount reduces by $10,000 and re-run step 2
  */
  if (value20PercentTotalDeposit <= totalSavingsAndEquity) {
    return value20Percent;
  } else if (value12PercentTotalDeposit <= totalSavingsAndEquity) {
    return value12Percent;
  } else {
    if (totalSavingsAndEquity < value12PercentTotalDeposit) {
      recommendedBorrowing = recommendedBorrowing - 10000;
      if (recommendedBorrowing < 300000 || recommendedBorrowing > 520000) {
        return {};
      } else {
        return budgetOptionsStep({
          recommendedBorrowing,
          totalSavingsAndEquity,
        });
      }
    } else {
      return {};
    }
  }
};

export const calcDepositByPercent = ({
  recommendedBorrowing,
  rate,
  fee_rate,
}) => {
  const propertyPrice = recommendedBorrowing * (1 / (1 - rate));
  const deposit = propertyPrice * rate;
  const buyingCost = deposit * fee_rate;
  const depositBuyingCost = deposit + buyingCost;
  const debtOnProperty = Math.round(propertyPrice - deposit);
  // const totalScenarioPercent =
  //   recommendedBorrowing * rate + recommendedBorrowing * rate * fee_rate;
  // const depositAmount =
  //   (recommendedBorrowing + recommendedBorrowing * rate) * rate;
  // const propertyPrice = recommendedBorrowing + totalScenarioPercent;

  // const totalDepositBuyingCosts =
  //   propertyPrice * rate * fee_rate + propertyPrice * rate;
  // const depositPriceOfProperties = propertyPrice * rate;
  // const debtOnProperty = propertyPrice - depositPriceOfProperties;
  const rentPW =
    PW_REACTIONS[
      firstSmallNumberFollowedByLarge(propertyPrice, Object.keys(PW_REACTIONS))
    ];

  return {
    propertyPrice,
    rateSelected: rate * 100,
    totalDepositBuyingCosts: depositBuyingCost,
    debtOnProperty,
    rentPW,
  };
};

/**
 * Step 3
 *
 * This function calculates the budget options for the buying structure
 * based on the provided values. The function does not take any parameters
 * and does not return any values.
 */
export const assumptionsAtTargetStep = ({
  propertyPrice,
  livingExpenses,
  salary,
  listPurchase,
  recommendedBorrowing,
  rentPW,
  masterTimeLines,
  paymentPurchasePerMonth,
  years,
  dataChange,
  oldNoOfMonth,
}) => {
  // The growth rate of the property value over time.
  const GROWTH_RATE = 0.2;
  const SALARY_RATE = 0.05;
  const EXPENSES_RATE = 0.03;

  let noOfMonth = oldNoOfMonth ? oldNoOfMonth + 1 : 0;
  // The target equity at a given time.
  const equityTarget = oldNoOfMonth
    ? calcNewEquity(propertyPrice, noOfMonth)
    : propertyPrice * GROWTH_RATE; // Calculates the target equity based on the property price and growth rate.
  // Calculates the target equity at a given time.
  const valueAtTime =
    dataChange?.targetPrice && listPurchase.length === dataChange?.indexChange
      ? dataChange.targetPrice
      : equityTarget + propertyPrice;

  // An array of keys representing market prices.
  const prices = Object.keys(MARKET_PRICE_REACTIONS);

  /**
   * Finds the first key in the `prices` array that is smaller than
   * `propertyPrice` and followed by a larger number.
   *
   * @returns {string} The key that meets the criteria.
   */
  const key = firstSmallNumberFollowedByLarge(propertyPrice, prices);

  /**
   * An object containing market price reactions.
   * Each key represents a market price and the value is an array
   * of monthly reactions.
   */
  const marketPriceReaction = MARKET_PRICE_REACTIONS[key];

  /**
   * Finds the index of the first element in `marketPriceReaction`
   * that is smaller than `valueAtTime` and followed by a larger number.
   *
   * @returns {number} The index of the element that meets the criteria.
   */

  let salaryAtTime = 0;
  let expensesPM = 0;

  let rentTracks = [];

  if (listPurchase.length > 0) {
    const prevIndex = masterTimeLines.length - 1;

    // Calculate the master time line for the current purchase target
    const currentMasterTimeLine = calcMasterTimeLine({
      propertyPrice,
      rentPW,
      paymentPurchasePerMonth,
      recommendedBorrowing,
      availableEquity: equityTarget,
      months: years * 12,
    });

    // Add/Edit Purchase Date
    if (
      dataChange?.noOfMonth &&
      listPurchase.length === dataChange?.indexChange
    ) {
      noOfMonth = dataChange.noOfMonth + 1;

      forEach(masterTimeLines, (timeLine, idx) => {
        const preTotalNoOfMonth = sumBy(listPurchase.slice(idx), "noOfMonth");
        const newNoOfMonth = preTotalNoOfMonth + noOfMonth;
        rentTracks.push(
          (timeLine[newNoOfMonth - listPurchase.length - 1 + idx]
            ?.rentPerMonth *
            12) /
            52
        );
      });

      // Add/Edit Target Rent
      if (
        dataChange?.targetRent &&
        listPurchase.length === dataChange?.indexChange
      ) {
        rentTracks.push(dataChange.targetRent);
      } else {
        rentTracks.push(
          (currentMasterTimeLine[noOfMonth - 1]?.rentPerMonth * 12) / 52
        );
      }
    } else {
      for (let index = 0; index < currentMasterTimeLine.length - 1; index++) {
        // const previousEquity =
        //   masterTimeLines[prevIndex]?.[
        //     listPurchase[prevIndex].noOfMonth - 1 + index
        //   ]?.newEquity ?? 0;
        const value = currentMasterTimeLine[index].value;
        // const combineEquity =
        //   (previousEquity + currentMasterTimeLine[index].equity) * 0.8;
        /* 
          Check if the combine equity (new equity of previous master timeline + new equity of current master timeline) 
          is greater than the target equity.
         */
        if (value > valueAtTime && !oldNoOfMonth) {
          noOfMonth = oldNoOfMonth ? noOfMonth : index + 1;
          // Align the corresponding column by month for each master timeline
          forEach(masterTimeLines, (timeLine, idx) => {
            const preTotalNoOfMonth = sumBy(
              listPurchase.slice(idx),
              "noOfMonth"
            );
            const newNoOfMonth = preTotalNoOfMonth + noOfMonth;
            rentTracks.push(
              (timeLine[newNoOfMonth - listPurchase.length - 1 + idx]
                ?.rentPerMonth *
                12) /
                52
            );
          });

          // Add/Edit Target Rent
          if (
            dataChange?.targetRent &&
            listPurchase.length === dataChange?.indexChange
          ) {
            rentTracks.push(dataChange.targetRent);
          } else {
            rentTracks.push(
              (currentMasterTimeLine[index]?.rentPerMonth * 12) / 52
            );
          }

          break;
        }
      }
    }

    masterTimeLines.push(currentMasterTimeLine);
  } else {
    const currentMasterTimeLine = calcMasterTimeLine({
      propertyPrice,
      rentPW,
      paymentPurchasePerMonth,
      recommendedBorrowing,
      availableEquity: equityTarget,
      months: years * 12,
    });

    noOfMonth = oldNoOfMonth
      ? noOfMonth
      : findIndexFirstSmallNumberFollowedByLarge(
          valueAtTime,
          marketPriceReaction
        ) + 2;

    masterTimeLines.push(currentMasterTimeLine);

    // Add/Edit Target Rent
    if (
      dataChange?.targetRent &&
      listPurchase.length === dataChange?.indexChange
    ) {
      rentTracks.push(dataChange.targetRent);
    } else {
      rentTracks.push(
        (currentMasterTimeLine[noOfMonth - 1]?.rentPerMonth * 12) / 52
      );
    }
  }
  if (
    dataChange?.income1 &&
    listPurchase.length === dataChange?.indexChange + 1
  ) {
    salary = Math.round(dataChange?.income1);
  }
  salaryAtTime = ((salary * SALARY_RATE) / 12) * noOfMonth + salary;

  if (
    dataChange?.livingExpenses &&
    listPurchase.length === dataChange?.indexChange
  ) {
    expensesPM = dataChange.livingExpenses;
  } else {
    expensesPM =
      ((livingExpenses * EXPENSES_RATE) / 12) * noOfMonth + livingExpenses;
  }

  return {
    equityTarget,
    noOfMonth,
    valueAtTime,
    salaryAtTime,
    expensesPM,
    rentTracks,
  };
};

/**
 * Step 4
 *
 * This function calculates the budget options for the buying structure
 * based on the provided values. The function does not take any parameters
 * and does not return any values.
 */
export const findEquityAllowanceStep = async ({
  equityTarget,
  widgetProperties,
  expensesPM,
  salaryAtTime,
  rentTracks,
  recommendedBorrowing,
  wiwo,
  propertyPrice,
  rentPW,
  paymentPurchasePerMonth,
  masterTimeLines,
  listPurchase,
  customIncomes,
  years,
  newData,
  debtOnProperty,
  totalDepositBuyingCosts,
  totalSavingsAndEquity,
  rateSelected,
}) => {
  const originalData = cloneDeep(widgetProperties);
  // const minCashEquityNextPurchase = rateSelected === 20 ? 93750 : 58500;

  let availableEquity = equityTarget;
  let yourBorrowingAmount = 0;

  const wiwoBorrowingPower = originalData?.dataList.find(
    (item) => item.id === "wiwo-borrowing-power"
  );

  const wiwoRepaymentWidget = originalData?.dataList.find(
    (item) => item.id === "wiwo-repayment-widget"
  );

  wiwoRepaymentWidget.input.repaymentModel.principal = 999999999;
  if (
    wiwoBorrowingPower.input.borrowingRequestModel.loanLiabilityGroups[0]
      .liabilities[0].remainingLimit !== debtOnProperty
  ) {
    wiwoBorrowingPower.input.borrowingRequestModel.loanLiabilityGroups[0].liabilities[0].remainingLimit =
      debtOnProperty;
  }

  const newLiabilities = [
    ...wiwoBorrowingPower.input.borrowingRequestModel.loanLiabilityGroups[0]
      .liabilities,
    {},
  ];

  let incomes =
    wiwoBorrowingPower.input.borrowingRequestModel.applicantList.map(
      (one) => one.incomeList
    );

  const primaryIncomes = incomes.map((incomeList) =>
    incomeList.find((income) => income.id === "primary")
  );

  if (newData?.income1 && listPurchase.length === newData?.indexChange) {
    const { income1, income2 } = newData;
    const listIncome = [
      ...(income1 !== undefined ? [income1] : []),
      ...(income2 !== undefined ? [income2] : []),
    ];

    wiwoBorrowingPower.input.borrowingRequestModel.applicantList = incomes.map(
      (applicantIncomeList, index) => ({
        incomeList: applicantIncomeList.map((income) => {
          if (income.id === "primary" && listIncome[index]) {
            return { ...income, income: Math.round(listIncome[index]) };
          }
          return income;
        }),
      })
    );
  } else {
    const totalPrimaryIncome = sumBy(primaryIncomes, "income");

    const increaseValue =
      (salaryAtTime - totalPrimaryIncome) / primaryIncomes.length;

    // Update primary income to the widget again
    wiwoBorrowingPower.input.borrowingRequestModel.applicantList = incomes.map(
      (applicantIncomeList) => ({
        incomeList: applicantIncomeList.map((income) => {
          if (income.id === "primary") {
            return {
              ...income,
              income: Math.round(income.income + increaseValue),
            };
          }
          return income;
        }),
      })
    );
  }

  incomes = wiwoBorrowingPower.input.borrowingRequestModel.applicantList.map(
    (one) => one.incomeList
  );
  // Update expenses p/m to the widget again
  wiwoBorrowingPower.input.borrowingRequestModel.households[0].livingExpenseModel.livingExpenseMonthly =
    Math.round(expensesPM);

  let newIncomes = incomes.map((incomeList, index) => {
    return [
      ...incomeList.filter((one) => one.id !== "other"),
      ...customIncomes[index],
    ];
  });

  const householdIncomes = newIncomes.map((incomeList) =>
    sumBy(incomeList, "income")
  );
  const incomeObj = {
    income1: householdIncomes[0],
  };
  if (householdIncomes.length > 1) {
    incomeObj.income2 = householdIncomes[1];
  }
  // Edit
  // Determine which income list to update based on the income1 and income2 values.
  rentTracks.forEach((rentTrackIncome) => {
    const incomeIndex = incomeObj.income1 > incomeObj.income2 ? 1 : 0;
    const incomeList = newIncomes[incomeIndex];
    const updatedIncomeList = [
      ...incomeList,
      {
        id: "other",
        income: Math.round(rentTrackIncome),
        incomeType: "gross",
        incomeFrequency: "week",
        incomeCategory: "other",
      },
    ];
    newIncomes[incomeIndex] = updatedIncomeList;
    const updatedIncome = sumBy(incomeList, "income");
    incomeObj[`income${incomeIndex + 1}`] = updatedIncome;
  });

  wiwoBorrowingPower.input.borrowingRequestModel.applicantList = newIncomes.map(
    (one) => ({
      incomeList: one,
    })
  );
  if (newData?.other_income && listPurchase.length === newData?.indexChange) {
    const otherIncome = {
      id: "other",
      income: Math.round(newData?.otherIncome),
      incomeType: "gross",
      incomeFrequency: "annual",
      incomeCategory: "payg",
      adjustmentFactors: [],
    };
    const objectWithMinIncome = primaryIncomes.reduce((minObj, currentObj) => {
      return currentObj.income < minObj.income ? currentObj : minObj;
    });
    incomes.forEach((applicant) => {
      applicant.forEach((incomeList) => {
        const income = incomeList.find((x) => x.id === "primary").income;
        if (income && income.income === objectWithMinIncome.income) {
          incomeList = incomeList.filter((obj) => obj.id !== "other");
          incomeList.push(otherIncome);
        }
      });
    });
    wiwoBorrowingPower.input.borrowingRequestModel.applicantList = incomes;
    // wiwoBorrowingPower.input.borrowingRequestModel.applicantList = incomes.map(
    //   (applicant) => ({
    //     incomeList: applicant.incomeList.map((income) => {
    //       const primaryIncomeObj = applicant.incomeList.find(
    //         (income) => income.id === "primary"
    //       );
    //       if (
    //         primaryIncomeObj &&
    //         primaryIncomeObj.income === objectWithMinIncome.income
    //       ) {
    //         return { ...income, income: newData?.other_income };
    //       }
    //       return income;
    //     }),
    //   })
    // );
  }

  // Update secured loan details to the widget again
  for (let index = 9; index >= 0; index--) {
    if (index === 1)
      return { availableEquity: null, newWidgetProperty: originalData };

    const newInvestLoan = {
      loanType: "investLoan",
      remainingLimit: Math.round(availableEquity + debtOnProperty),
      remainingTermMonths: 360,
      hasIo: true,
      ioTermMonths: 60,
      toBePaidOut: false,
      liabilityTypeLabel: "Investment loan",
    };

    newLiabilities[newLiabilities.length - 1] = newInvestLoan;

    wiwoBorrowingPower.input.borrowingRequestModel.loanLiabilityGroups[0] = {
      id: "homeLoan",
      enabled: true,
      liabilities: newLiabilities,
    };

    wiwoBorrowingPower.input = omit(wiwoBorrowingPower.input, ["bpResult"]);
    // Clear output
    wiwoBorrowingPower.output = null;
    wiwoRepaymentWidget.output = null;
    const result = await updateWiwo(originalData, wiwo);

    yourBorrowingAmount =
      result.dataList[0].input.bpResult.borrowingPowerMaximum;
    const cashEquityNextPurchase =
      availableEquity + totalSavingsAndEquity - totalDepositBuyingCosts;
    if (yourBorrowingAmount >= 300000 && cashEquityNextPurchase > 93750) {
      const paymentPurchasePerMonth =
        result.dataList[1].output.repaymentResultModel.repaymentList[0]
          .paymentDisplayRounded;
      const currentMasterTimeLine = calcMasterTimeLine({
        propertyPrice,
        rentPW,
        paymentPurchasePerMonth,
        recommendedBorrowing,
        availableEquity,
        months: years * 12,
      });
      masterTimeLines[masterTimeLines.length - 1] = currentMasterTimeLine;
      return {
        availableEquity,
        yourBorrowingAmount,
        paymentDisplayRounded: paymentPurchasePerMonth,
        newWidgetProperty: result,
        totalIncome:
          result.dataList[0].input.bpResult.annualIncomeForSensitisedLookup,
      };
    }
    availableEquity = equityTarget * index * 0.1;
  }

  return {
    availableEquity,
    yourBorrowingAmount,
    newWidgetProperty: originalData,
  };
};

/**
 * Step 5
 *
 * This function calculates the budget options for the buying structure
 * based on the provided values. The function does not take any parameters
 * and does not return any values.
 */
const contributionsForNextPurchaseStep = () => {};

export const firstSmallNumberFollowedByLarge = (value, numbers) => {
  return numbers.find(
    (number, index) =>
      number <= value &&
      index + 1 < numbers.length &&
      numbers[index + 1] > value
  );
};

export const findIndexFirstSmallNumberFollowedByLarge = (value, numbers) => {
  return numbers.findIndex(
    (number, index) =>
      number <= value &&
      index + 1 < numbers.length &&
      numbers[index + 1] > value
  );
};

export const calcMasterTimeLine = ({
  propertyPrice,
  rentPW,
  paymentPurchasePerMonth,
  recommendedBorrowing,
  availableEquity,
  months,
  growthPercent,
  rentPercent,
}) => {
  const currentMasterTimeLine = [];

  firstMasterLineObject(
    currentMasterTimeLine,
    propertyPrice,
    rentPW,
    paymentPurchasePerMonth,
    recommendedBorrowing,
    availableEquity,
    0,
    months,
    growthPercent,
    rentPercent
  );
  return currentMasterTimeLine;
};

export const updateWiwo = async (dataChanges, wiwo) => {
  return new Promise((resolve, reject) => {
    wiwo.push([
      "setData",
      "wiwo-begidubi",
      {
        data: dataChanges,
      },
      (e, result) => {
        if (result.success) {
          wiwo.push([
            "getData",
            "wiwo-begidubi",
            (e, result) => {
              if (result.success) {
                resolve(result.data);
              } else {
                reject(e);
              }
            },
          ]);
        } else {
          reject(e);
        }
      },
    ]);
  });
};

const calcTotalOtherIncomes = (incomes) => {
  const newIncomes = incomes.flat(1).map((one) => {
    const value = one.income;
    switch (one.incomeFrequency) {
      case "week":
        return (value * 52) / 12;
      case "fortnight":
        return (value * 26) / 12;
      case "month":
        return value;
      case "annual":
        return value / 12;
    }
  });
  return sumBy(newIncomes);
};
