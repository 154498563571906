import { COLORS } from '../Utility/ReduxType';

const initialState = {
    MAIN: {
        PRIMARY_COLOR: "#C4014B",
        SECONDARY_COLOR: "red",
        BACKGROUND_COLOR: "gray"
    }, 

 
}


export function colors(state = initialState, action) {
    switch (action.type) {

        case COLORS.MAIN_COLORS: {
            //console.log(action.playload)
            return {
                ...state, 
            }
        }

        default:
            return state
    }
}

