import React from 'react';
import {
  StyleSheet,
} from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { MAIN_HEADER, MAIN_COLOR } from "../../Colors";
import FONT_STYLE from '../../Fonts';
import { RFValue } from 'react-native-responsive-fontsize';
import { MAIN_FONT } from '../../Fonts/font';

var styles;

export default styles = StyleSheet.create({

  container: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: MAIN_COLOR.TRANSPARENT_BACKGROUND, 
    position: "absolute",
    //zIndex: 999
  },

  innerContainer: {
    width: wp("85%"),
    // height: "auto",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    //borderWidth: 1,
    borderColor: MAIN_COLOR.BORDER_COLOR,   
    borderRadius: 15, 
  },

  topView: {
    width: "100%",
    height: wp("18%"),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_DARK,  
    borderRadius: 15, 
    borderBottomLeftRadius: 80,
    borderBottomRightRadius: 80,
  },

  contentView: {
    width: "100%",
    padding: wp("5%"),
  },
 

  closeIcon: {
    width: 40,
    height: 40,
  },

  closeButtonView: {
    width: 80,
    height: 80,
    alignItems: "center",
    justifyContent: "center", 
    position: "absolute",
    top: -42
  },

  title: {
    ...FONT_STYLE.popupTitle
  },
 

  descText: {
    fontSize: RFValue(13),
    color: "#171616",  
 },

 buttonView: {
  width: "100%",
  height: wp("10%"),
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: MAIN_COLOR.yellowBackground,  
  borderRadius: 15,
  marginTop: wp("5%")
  // borderBottomLeftRadius: 80,
  // borderBottomRightRadius: 80,
 }

 
});


