import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Dimensions,
  Image,
  Keyboard,
  Platform,
  SafeAreaView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { showMessage } from "react-native-flash-message";
import * as Yup from "yup";

import { MAIN_COLOR } from "../../Utility/Colors";
import stylesCommonComponents from "../../Utility/ComponentStyle/style";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import styles from "./style";
import { confirmPassword } from "../../Clickup/api";
import Toast from "react-native-toast-message";

function SignUp() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const { width, height } = Dimensions.get("window");

  const schemaForm = Yup.object().shape({
    email: Yup.string().required("Email can't be empty"),
    password: Yup.string().required("Email can't be empty"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schemaForm),
  });

  const handleSignUp = async (values) => {
    try {
      await confirmPassword(values);
      showMessage({
        message: "Register successfully",
        type: "success",
      });
      setTimeout(() => {
        window.location.href = window.location.origin;
      }, 1000)
    } catch (error) {
      showMessage({
        message: error.message,
        type: "danger",
      });
    } 
  };

  useEffect(() => { 
    const email = params.get("email");
    if (email) {
      setValue("email", email);
    }
  }, []);

  return (
    <SafeAreaView
      keyboardShouldPersistTaps={true}
      behavior={Platform.OS === "ios" ? "padding" : "padding"}
      keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}
      style={{
        flex: 1,
        backgroundColor: MAIN_COLOR.BACKGROUND,
        flexDirection: width > 1000 ? "row" : "column",
      }}
    >
      {/* Left */}
      <View
        onPress={Keyboard.dismiss}
        accessible={false}
        style={{
          padding: "5%",
          height: width > 1000 ? "100%" : height < 900 ? "100%" : "68%",
          width: width > 1000 ? "50%" : "100%",
          backgroundColor: "#FFF",
        }}
      >
        <View style={[styles.topView]}>
          <Image
            style={styles.logoImage}
            resizeMode="contain"
            source={require("../../assets/header_logo.svg")}
          />
        </View>

        <View
          style={[
            styles.scrollViewContentView,
            { width: width > 1000 ? "100%" : "68%", alignSelf: "center" },
          ]}
        >
          <Text style={styles.textWelcome}>Hey! Welcome back</Text>
          <Text style={styles.textSubTitle}>Please sign-up to continue!</Text>
          <View style={styles.centerView}>
            <Text style={styles.labelForm}>Email</Text>
            <View style={styles.boxInputForm}>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    keyboardType="web-search"
                    autoCorrect={false}
                    outline="none"
                    editable={false}
                    style={styles.inputForm}
                    placeholderTextColor="#ADB9C7"
                    value={value}
                    underlineColorAndroid="transparent"
                    onChangeText={onChange}
                    placeholder="Enter Your Email"
                    returnKeyType={"next"}
                    onSubmitEditing={() => {}}
                    blurOnSubmit={false}
                    disableFullscreenUI
                    autoCapitalize="none"
                  />
                )}
              />
            </View>
            <Text style={styles.labelForm}>Password</Text>
            <View style={[styles.boxInputForm, { marginBottom: 0 }]}>
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    keyboardType="web-search"
                    autoCorrect={false}
                    outline="none"
                    style={styles.inputForm}
                    placeholderTextColor="#ADB9C7"
                    value={value}
                    underlineColorAndroid="transparent"
                    onChangeText={onChange}
                    placeholder="Enter Your Password"
                    returnKeyType={"next"}
                    onSubmitEditing={() => {}}
                    blurOnSubmit={false}
                    autoCapitalize="none"
                  />
                )}
              />
            </View>
            <TouchableOpacity
              style={[stylesCommonComponents.button, styles.btnSubmit]}
              onPress={handleSubmit(handleSignUp)}
              activeOpacity={0.8}
              disabled={!isValid}
            >
              <Text style={styles.textSubmit} numberOfLines={1}>
                Register
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>

      {/* Right */}
      <View
        style={{
          justifyContent: "center",
          flexDirection: width > 1000 ? "column" : "row",
          alignItems: "center",
          overflow: "hidden",
          height: width > 1000 ? "100%" : height < 900 ? 0 : "32%",
          width: width > 1000 ? "50%" : "100%",
        }}
      >
        <Image
          style={{ height: 200, width: "50%" }}
          resizeMode="contain"
          source={require("../../assets/content.png")}
        />
        <View
          style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            width: width > 1000 ? "100%" : "50%",
          }}
        >
          <Text
            style={{
              fontSize: 21,
              color: "#1A3F49",
              fontFamily: MAIN_FONT.bold,
              textAlign: width > 1000 ? "center" : "left",
              marginTop: width > 1000 ? 25 : 0,
            }}
          >
            The Investors Agency
          </Text>

          <Text
            style={{
              fontSize: 16,
              color: "#A2ADB9",
              fontFamily: MAIN_FONT.regular,
              textAlign: width > 1000 ? "center" : "left",
              marginTop: 10,
            }}
          >
            You’re on your way to better property management.
          </Text>
        </View>
      </View>
    </SafeAreaView>
  );
}

export default SignUp;
