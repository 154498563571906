import { sumBy } from "lodash";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { Animated, Image, Text, TouchableOpacity, View } from "react-native";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { MAIN_COLOR } from "../../../../Utility/Colors";
import { formatDollar } from "../../../../Utility/common";
import styles from "./style";
import Tippy from "@tippyjs/react/headless";
import Modal from "../../../../components/Modal";

import { ROLE, ROLE_OPTIONS } from "../../../../Utility/portfolio-roadmap";
import { usePermission } from "../../../../hooks/usePermission";

const PurchaseTarget = ({
  setVisibleModalAddTarget,
  setVisibleModalEditTarget,
  disabledClickOutside,
  properties,
  type,
  index,
  purchaseTargets,
  onSelect,
  purchaseSelected,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const isHightLighted = properties.id === purchaseSelected?.id;

  const animationHeight = useRef(new Animated.Value(0)).current;

  const { isEdit } = usePermission("purchaseTarget");
  const purchaseRef = useRef(null);

  const purchaseTypeData = {
    HOUSE: {
      name: "House",
      icon: require("../../../../assets/portfolio-roadmap/house_option.png"),
    },
    DUAL_OCCUPANCY: {
      name: "Dual Occupancy",
      icon: require("../../../../assets/portfolio-roadmap/house_option2.png"),
    },
    SMSF: {
      name: "SMSF",
      icon: require("../../../../assets/portfolio-roadmap/house_option3.png"),
    },
  };

  const purchase = purchaseTypeData[type];

  const toggleShowDetails = () => {
    onSelect(
      isHightLighted
        ? null
        : {
            type: "purchase-target-details",
            data: properties,
          }
    );
  };

  const openShowDetails = () => {
    Animated.timing(animationHeight, {
      toValue: 260,
      duration: 400,
      useNativeDriver: true,
    }).start();
    setShowDetails(true);
  };

  const closeShowDetails = () => {
    Animated.timing(animationHeight, {
      toValue: 0,
      duration: 600,
      useNativeDriver: true,
    }).start(({ finished }) => {
      if (finished) {
        setShowDetails(false);
      }
    });
  };

  useEffect(() => {
    if (isHightLighted) {
      openShowDetails();
      purchaseRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else {
      closeShowDetails();
    }
  }, [isHightLighted]);

  return (
    <OutsideClickHandler
      onOutsideClick={
        isHightLighted && !disabledClickOutside
          ? () => {
              onSelect(null);
              closeShowDetails();
            }
          : () => {}
      }
    >
      <View style={styles.container} ref={purchaseRef}>
        <View
          style={{
            zIndex: 2,
            borderRadius: 15,
            borderWidth: 1,
            borderColor: showDetails
              ? MAIN_COLOR.yellow
              : "rgba(173, 185, 199, 0.3)",
            backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
          }}
        >
          <Animated.View style={styles.header}>
            <Text style={styles.title}>Purchase Target</Text>
            <View style={styles.type}>
              <Image
                resizeMode="contain"
                style={styles.iconType}
                source={purchase?.icon}
              />
              <Text style={styles.titleType}>{purchase?.name}</Text>
            </View>
          </Animated.View>
          <View style={styles.info}>
            <View style={styles.row}>
              <Text style={styles.rowLabel}>Target Price</Text>
              <Text style={styles.rowValue}>
                {formatDollar(Math.round(properties.propertyPrice))}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.rowLabel}>Target Rent p/w</Text>
              <Text style={styles.rowValue}>
                {formatDollar(Math.round(properties.rentPW))}
              </Text>
            </View>

            {showDetails && (
              <Animated.View style={[{ maxHeight: animationHeight, gap: 8 }]}>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Income Type</Text>
                  <Text style={styles.rowValue}>{properties.incomeType}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Living Expenses p/m</Text>
                  <Text style={styles.rowValue}>
                    {formatDollar(Math.round(properties.expensesPM))}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Total Income</Text>
                  <Text style={styles.rowValue}>
                    {formatDollar(Math.round(properties.totalIncome))}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Deposit Size</Text>
                  <Text style={styles.rowValue}>
                    {properties.rateSelected}%
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Cash Contribution</Text>
                  <Text style={styles.rowValue}>
                    {formatDollar(Math.round(properties.cashContribute))}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Loan Amount</Text>
                  <Text style={styles.rowValue}>
                    {formatDollar(Math.round(properties.loanAmount))}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Interest Rate</Text>
                  <Text style={styles.rowValue}>
                    {properties.interestRate}%
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Loan Type</Text>
                  <Text style={styles.rowValue}>I/O</Text>
                </View>
              </Animated.View>
            )}
            <View style={{ backgroundColor: MAIN_COLOR.BACKGROUND_COLOR }}>
              <View style={{ marginVertical: 16 }}>
                <TouchableOpacity
                  style={[
                    styles.btnView,
                    showDetails && { backgroundColor: MAIN_COLOR.yellow },
                  ]}
                  onPress={toggleShowDetails}
                >
                  <Image
                    resizeMode="cover"
                    style={{ height: 10, width: 10, margin: "auto" }}
                    source={
                      showDetails
                        ? require("../../../../assets/portfolio-roadmap/arrow-up.svg")
                        : require("../../../../assets/portfolio-roadmap/arrow-down.svg")
                    }
                  />
                </TouchableOpacity>
                <View style={styles.divider} />
              </View>
              <View style={styles.footer}>
                <View>
                  <Text style={styles.timeLabel}>Purchase Date</Text>
                  <Text style={styles.time}>
                    {moment(properties.purchaseDate)
                      // .add(
                      //   properties.noOfMonth,
                      //   "month"
                      //   // properties.noOfMonth -
                      //   //   index -
                      //   //   1 +
                      //   //   sumBy(purchaseTargets.slice(0, index), "noOfMonth"),
                      //   // "months"
                      // )
                      .format("MMMM YYYY")}
                  </Text>
                </View>
                {isEdit && (
                  <Tippy
                    trigger="click"
                    interactive
                    placement="left-end"
                    render={(attrs) => (
                      <View style={styles.tippyContainer}>
                        <TouchableOpacity
                          onPress={setVisibleModalEditTarget}
                          style={styles.tippyItem}
                        >
                          <Image
                            style={{ width: 20, height: 20 }}
                            source={require("../../../../assets/portfolio-roadmap/edit.png")}
                          />
                          <Text style={styles.tippyTitle}>
                            Purchase Target Edit
                          </Text>
                        </TouchableOpacity>
                        <View style={styles.tippyDriver} />
                        <TouchableOpacity
                          style={styles.tippyItem}
                          onPress={() => setVisibleModalAddTarget(true)}
                        >
                          <Image
                            style={{ width: 20, height: 20 }}
                            source={require("../../../../assets/portfolio-roadmap/add-circle.png")}
                          />
                          <Text style={styles.tippyTitle}>
                            Add Purchase Target
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )}
                  >
                    <TouchableOpacity style={styles.btnEdit}>
                      <Image
                        resizeMode="contain"
                        style={{ height: 18, width: 18, margin: "auto" }}
                        source={require("../../../../assets/portfolio-roadmap/setting.png")}
                      />
                    </TouchableOpacity>
                  </Tippy>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </OutsideClickHandler>
  );
};

export default PurchaseTarget;
