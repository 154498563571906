import { StyleSheet } from "react-native";
import { MAIN_COLOR } from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    minHeight: 46,
    width: 318,
    borderRadius: 12,
    borderWidth: 1,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    borderColor: "rgba(173, 185, 199, 0.3)",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontFamily: MAIN_FONT.regular,
    fontWeight: 500,
    fontSize: 16,
    color: "#200E32",
  },
  icon: {
    width: 26,
    height: 26,
  },
  rightIc: {
    width: 14,
    height: 14,
  },
  sectionExpand: {
    paddingHorizontal: 20,
    paddingVertical: 8,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "rgba(173, 185, 199, 0.3)",
    marginBottom: 12,
  },
});
