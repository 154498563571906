import {
  Dimensions, StyleSheet
} from 'react-native';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { MAIN_COLOR } from "../../Utility/Colors";
import { MAIN_FONT } from "../../Utility/Fonts/font";


const { width } = Dimensions.get("window");

var styles;

export default styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
  },

  container: {
    flex: 1,
  },

  scrollViewContainer: {
    width,
  },

  textLight: {
    color: MAIN_COLOR.G700,
    fontFamily: MAIN_FONT.regular,
    fontSize: 12,
    textAlign: 'left',
    margin: 5
  },

  textSemi: { 
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.semi,
    textAlign: 'center',
    marginVertical: 5 
  },

  textHeading: {
    fontSize: 16, color: '#272D37', fontFamily: MAIN_FONT.regular, marginLeft: '5%', marginVertical: 20
  },

  scrollViewContentView: {
    flex: 1,
    backgroundColor: 'rgb(243, 244, 249)',
  },

  icon: { height: 16, width: 16, marginRight: 10 },

  iconTitle: { fontSize: 14, color: MAIN_COLOR.G800, fontFamily: MAIN_FONT.semi, textAlign: 'center', marginTop: 5 },

  price: {
    fontSize: 14,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.semi,
    textAlign: 'right'
  },

  priceTitle: {
    fontSize: 13,
    color: MAIN_COLOR.G700,
    fontFamily: MAIN_FONT.regular,
    textAlign: 'left'
  },

  topView: {
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_DARK,
    padding: wp("5%"),
  },

  centerView: {
    justifyContent: "flex-start",
    padding: wp("5%"),
    paddingBottom: wp("5%"),
    paddingTop: 0
  },

  row: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },

  userImage: {
    borderRadius: 25,
    height: 50,
    width: 50,
    resizeMode: 'cover',
    marginRight: 15
  },

  shadow: {
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 1,
    shadowColor: MAIN_COLOR.G700,
  },

  fileIcon: {
    height: 18,
    width: 18,
  },



});


