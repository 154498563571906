import React from "react";
import { Text, View } from "react-native";
import styles from "./style";
import { useMediaQuery } from "react-responsive";

const CardChart = ({
  children,
  title,
  currentLabel,
  currentValue,
  label1,
  color1,
  label2,
  color2,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <View style={styles.container}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: 16,
        }}
      >
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.currentLabel}>
          {currentLabel} <Text style={styles.currentValue}>{currentValue}</Text>
        </Text>
      </View>
      <View style={{ flex: 1, marginVertical: 6 }}>{children}</View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={[styles.round, { backgroundColor: color1 }]} />
          <Text style={styles.textLabel}>{label1}</Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={[styles.round, { backgroundColor: color2 }]} />
          <Text style={styles.textLabel}>{label2}</Text>
        </View>
      </View>
    </View>
  );
};

export default CardChart;
