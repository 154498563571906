export const REPORT_ITEM_BOX_CARD = [
    {
        key: "001",
        name: "Writing",
        image: require("../../Image/card_icon/report/write.png")
    },
    {
        key: "002",
        name: "Progress",
        image: require("../../Image/card_icon/report/graph.png")
    },
    {
        key: "003",
        name: "Timing",
        image: require("../../Image/card_icon/report/calender.png")
    },
    {
        key: "004",
        name: "Friendly",
        image: require("../../Image/card_icon/report/chat.png")
    },
    {
        key: "005",
        name: "Message",
        image: require("../../Image/card_icon/report/email.png")
    },
    {
        key: "006",
        name: "Listening",
        image: require("../../Image/card_icon/report/speak.png")
    },
]


export const PAYMENT_HISTORY = [
    {
        key: "001",
        ref_number: "000025668",
        date: {
            date: "12-10-2020",
            time: "10:12 PM"
        },
        amount: "45000.00",
        state: {
            label: "Processing",
            color: "green"
        },
        pay_method: "VISA"
    },

    {
        key: "001",
        ref_number: "000025668",
        date: {
            date: "01-09-2020",
            time: "12:59 PM"
        },
        amount: "45000.00",
        state: {
            label: "Canceled",
            color: "#e83f63"
        },
        pay_method: "Paypal"
    },
    {
        key: "001",
        ref_number: "000025668",
        date: {
            date: "26-08-2020",
            time: "08:24 AM"
        },
        amount: "45000.00",
        state: {
            label: "Completed",
            color: "#262424"
        },
        pay_method: "VISA"
    }
]


export const PIE_CHART_DATA = [
    {
        name: "Earn",
        percentage: 30,
        color: "#0084ff",
        arrow: {
            state: "UP",
            color: "#00ff37"
        }
    },
    {
        name: "Bonus",
        percentage: 10,
        color: "#07edf5",
        arrow: {
            state: "DOWN",
            color: "#ff2b36"
        }
    },
    {
        name: "Vat",
        percentage: 40,
        color: "#6367eb",
        arrow: {
            state: "DOWN",
            color: "#ff2b36"
        }
    },
    {
        name: "Tax",
        percentage: 45,
        color: "#57597d",
        arrow: {
            state: "UP",
            color: "#00ff37"
        }
    }
]


export const SLIDE_MENU = [
    {
        label: "Profile",
        icon: 'user',
        navigate_screen: "Profile"
    },
    {
        label: "Project",
        icon: 'clipboard',
        navigate_screen: "OrderHistoryScreen"
    },
    // {
    //     label: "Roadmap",
    //     icon: 'dotchart',
    //     navigate_screen: "Roadmap"
    // },
    {
        label: "Portfolio",
        icon: 'ios-briefcase-outline',
        navigate_screen: "Portfolio"
    },
    // {
    //     label: "Share",
    //     icon: require("../../Image/menu_icon/share.png"),
    //     navigate_screen: "QRScanScreen"
    // },
    {
        label: "Chat",
        icon: 'message1',
        navigate_screen: "Chat"
    }
]


export const OVERALL_SATISTICS = [
    {
        key: "001",
        name: "Response Rate",
        description: "Respond to 90% of the inquiries you received in the last 60 days",
        date: "12-10-2020 23.45",
        number: "74",
        arrow: {
            state: "DOWN",
            color: "#ff2b36"
        }
    },
    {
        key: "001",
        name: "Impressions",
        description: "Avoid receiving warnings for TOS violations over the course of 30 days",
        date: "12-10-2020 02.45",
        number: "88",
        arrow: {
            state: "UP",
            color: "#00ff37"
        }
    },
    {
        key: "001",
        name: "Days Without Warnings",
        description: "Avoid receiving warnings for TOS violations over the course of 30 days",
        date: "12-10-2020 22.45",
        number: "98",
        arrow: {
            state: "UP",
            color: "#00ff37"
        }
    },
    // {
    //     key: "001",
    //     name: "Selling Seniority",
    //     description: "Complete at least 60 days as a seller",
    //     date: "12-10-2020 18.33",
    //     number: "23", 
    //     arrow: {
    //         state: "DOWN",
    //         color: "#ff2b36"
    //     }
    // },
    {
        key: "001",
        name: "On-time Delivery",
        description: "Maintain a 4.7 star rating, over the course of 60 days",
        date: "12-10-2020 18.33",
        number: "66",
        arrow: {
            state: "UP",
            color: "#00ff37"
        }
    }
]



export const PEOPLE = [
    {
        key: "001",
        name: "W P Davide",
        passpot_id: "11137589V",
        image_url: require("../../Image/test/people/user_1.png"),
        description: "The entrance of Fantasia Alf Lella Wa Lelia 1001 Nights entertainment mall at Sharm el Sheikh",
        date: "12-10-2020 18.33",
        tele_number: "0710748486",
        arrow: {
            state: "UP",
            color: "#00ff37"
        }
    },

    {
        key: "001",
        name: "Jems Wonder",
        passpot_id: "11137589V",
        image_url: require("../../Image/test/people/user_2.png"),
        description: "The entrance of Fantasia Alf Lella Wa Lelia 1001 Nights entertainment mall at Sharm el Sheikh",
        date: "12-10-2020 18.33",
        tele_number: "0722233386",
        arrow: {
            state: "UP",
            color: "#00ff37"
        }
    },

    {
        key: "001",
        name: "Davin de Xeper",
        passpot_id: "11137589V",
        image_url: require("../../Image/test/people/user_3.png"),
        description: "The entrance of Fantasia Alf Lella Wa Lelia 1001 Nights entertainment mall at Sharm el Sheikh",
        date: "12-10-2020 18.33",
        tele_number: "0710748555",
        arrow: {
            state: "UP",
            color: "#00ff37"
        }
    },

    {
        key: "001",
        name: "Xavinner Dein Masla",
        passpot_id: "11137589V",
        image_url: require("../../Image/test/people/user_4.png"),
        description: "The entrance of Fantasia Alf Lella Wa Lelia 1001 Nights entertainment",
        date: "12-10-2020 18.33",
        tele_number: "0770999486",
        arrow: {
            state: "UP",
            color: "#00ff37"
        }
    },

    {
        key: "001",
        name: "Xavinner Dein Masla",
        passpot_id: "11137589V",
        image_url: require("../../Image/test/people/user_5.png"),
        description: "The entrance of Fantasia Alf Lella Wa Lelia 1001 Nights entertainment mall at Sharm el Sheikh",
        date: "12-10-2020 18.33",
        tele_number: "0710748486",
        arrow: {
            state: "UP",
            color: "#00ff37"
        }
    }
]


export const CONTACT_US_INFO = [
    {
        type: "mail",
        label: "harshakoshila@gmail.com",
        link: "https://harshakoshila@gmail.com",
        icon: require("../../Image/social_platform/gmail.png"),
    },
    {
        type: "web",
        label: "Harsha Koshila",
        link: "https://www.linkedin.com/in/harsha-koshila-1783aa132",
        icon: require("../../Image/social_platform/linkedin.png"),
    },
    {
        type: "web",
        label: "Harsha Koshila",
        link: "https://https://www.facebook.com/Harsha.Koshila",
        icon: require("../../Image/social_platform/fb.png"),
    },
    {
        type: "web",
        label: "harsha_ko",
        link: "https://www.instagram.com/harsha_ko/",
        icon: require("../../Image/social_platform/insta.png"),
    },
    {
        type: "tele",
        label: "+94 719748486",
        link: "+94719748486",
        icon: require("../../Image/social_platform/whatsapp.png"),
    },
]