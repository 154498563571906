import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { ScrollView, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  setLoading,
  setScenarioSelected,
  setNewDataPurchaseTarget,
} from "../../../../Reducer/portfolio-roadmap";
import { MAIN_COLOR } from "../../../../Utility/Colors";
import {
  INCOME_TYPE,
  LOAN_TYPE,
  OPTIONS_LOAN_TYPE,
  calcBuyingStructure,
} from "../../../../Utility/PortfolioRoadmapUtility/portfolio-roadmap";
import DatePicker from "../../../../components/DatePicker";
import InputNumber from "../../../../components/InputNumber";
import ModalBase from "../../../../components/Modal";
import SelectBase from "../../../../components/Select";
import CustomSwitch from "../../../../components/Switch";
import styles from "./style";
import { monthDiff } from "../../../../Utility/common";
import moment from "moment";

const PurchaseTargetModal = ({
  onClose,
  properties,
  indexChange,
  index,
  nextPurchaseTarget,
}) => {
  const { scenarioSelected, newDataRedux } = useSelector(
    (state) => state.PORTFOLIO_ROADMAP
  );

  const dispatch = useDispatch();
  const isAdd = !properties?.id;

  const schemaForm = Yup.object().shape({
    purchaseDate: Yup.string().required().default(new Date()),
    propertyPrice: Yup.number().required().default(0),
    loanAmount: Yup.number().required().default(0),
    cashContribution: Yup.number()
      .required()
      .default(0)
      .min(
        Number(Math.round(properties?.cashContribute) || 0),
        "You must input a value that is higher than previous"
      ),
    targetRent: Yup.number().required().default(0),
    income1: Yup.number().required().default(0),
    livingExpenses: Yup.number().required().default(0),
    depositSize: Yup.string().default("12"),
    incomeType: Yup.string().default(INCOME_TYPE.SINGLE),
    typeOfProperty: Yup.string().default("house"),
    otherIncome: Yup.number().default(0),
    dependant: Yup.number().default(0),
    interestRate: Yup.number().required().default(6.5),
    loanType: Yup.string().default(LOAN_TYPE.IO),
    income1: Yup.number().default(0),
  });

  const initialValues = schemaForm.cast();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { dirtyFields, isValid, errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schemaForm),
    mode: "onChange",
  });

  const handleReRunRoadmap = async (newData) => {
    try {
      dispatch(setLoading(true));
      dispatch(setNewDataPurchaseTarget(newData));
      onClose();
      const dataRedux = scenarioSelected;
      const listPurchase = await calcBuyingStructure(
        {
          cashSavings: scenarioSelected?.cashSavings,
          availableEquity: scenarioSelected?.availableEquity,
          years: scenarioSelected?.investingTimeLine,
          widgetProperties: scenarioSelected?.widgetProperties,
          customIncomes: scenarioSelected?.customIncomes,
        },
        window?._wiwo,
        newData,
        dataRedux,
        newDataRedux
      );
      setTimeout(() => {
        const payload = {
          ...scenarioSelected,
          purchaseTargets: listPurchase,
        };
        dispatch(setScenarioSelected(payload));
      }, 800);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const addNewPurchaseTarget = async (data) => {
    console.log("purchaseTarget", nextPurchaseTarget);
    const selectedScenarioIndex = indexChange;
    const newValues = Object.keys(dirtyFields).map((key) => ({
      indexChange: selectedScenarioIndex,
      [key]: data[key],
    }));
    let newData = {
      ...Object.assign({}, ...newValues),
    };
    if (data.income1 || data.livingExpenses) {
      newData.noOfMonth = nextPurchaseTarget.noOfMonth;
    }
    if (dirtyFields.purchaseDate) {
      newData.noOfMonth = monthDiff(
        new Date(
          scenarioSelected?.purchaseTargets[
            selectedScenarioIndex - 1
          ]?.purchaseDate
        ),
        new Date(watch("purchaseDate"))
      );
    }
    handleReRunRoadmap(newData);
  };

  const editPurchaseTarget = async (data) => {
    const selectedScenarioIndex = scenarioSelected?.purchaseTargets?.findIndex(
      (item) => item.id === properties?.id
    );
    const newValues = Object.keys(dirtyFields).map((key) => ({
      indexChange: selectedScenarioIndex,
      [key]: data[key],
    }));
    let newData = {
      ...Object.assign({}, ...newValues),
    };
    if (data.income1 || data.livingExpenses) {
      newData.noOfMonth = properties.noOfMonth;
    }
    if (dirtyFields.purchaseDate) {
      newData.noOfMonth = monthDiff(
        new Date(
          scenarioSelected?.purchaseTargets[
            selectedScenarioIndex - 1
          ]?.purchaseDate
        ),
        new Date(watch("purchaseDate"))
      );
    }
    handleReRunRoadmap(newData);
  };

  const onSubmit = (data) => {
    if (isAdd) {
      addNewPurchaseTarget(data);
    } else {
      editPurchaseTarget(data);
    }
  };

  useEffect(() => {
    if (!isAdd) {
      reset({
        propertyPrice: Math.round(properties.propertyPrice),
        depositSize: properties.rateSelected,
        incomeType: properties.incomeType.toLowerCase(),
        loanType: LOAN_TYPE.IO,
        loanAmount: Math.round(properties.loanAmount),
        cashContribution: Math.round(properties.cashContribute),
        targetRent: Math.round(properties.rentPW),
        livingExpenses: Math.round(properties.expensesPM),
        purchaseDate: properties.purchaseDate
          ? new Date(properties.purchaseDate)
          : new Date(),
        income1: Math.round(properties.income1),
        dependant: properties.dependant ?? 0,
        interestRate: properties.interestRate,
        income1: properties.income1,
        income2: properties.income2,
      });
    }
  }, []);

  return (
    <ModalBase
      open
      title={properties?.id ? "Purchase Target Edit" : "Add Purchase Target"}
      onClose={onClose}
      btnCancel="Cancel"
      btnSave="Save"
      disableSave={!isValid}
      onSave={handleSubmit(onSubmit)}
    >
      <ScrollView>
        <View style={{ paddingBottom: 12, paddingTop: 12 }}>
          <View style={styles.container}>
            <View style={styles.section}>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    disabled
                    label="Purchase date"
                    onChange={onChange}
                    minDate={new Date()}
                    value={value}
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                    prefixIcon={require("../../../../assets/calendar.png")}
                  />
                )}
                name="purchaseDate"
              />
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputNumber
                    thousandSeparator
                    label="Target Price"
                    value={value}
                    onChange={(value) => {
                      onChange(value);
                      setValue(
                        "loanAmount",
                        (
                          value *
                          ((100 - Number(getValues("depositSize"))) / 100)
                        ).toFixed(1)
                      );
                    }}
                  />
                )}
                name="propertyPrice"
              />
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectBase
                    label="Deposit size"
                    options={[
                      { label: "12%", value: 12 },
                      { label: "20%", value: 20 },
                    ]}
                    selectedValue={value}
                    onChange={(value) => {
                      onChange(value);
                      setValue(
                        "loanAmount",
                        (
                          getValues("propertyPrice") *
                          ((100 - Number(value)) / 100)
                        ).toFixed(1)
                      );
                    }}
                  />
                )}
                name="depositSize"
              />
              <View>
                <Text style={[styles.btnText, { color: "#000000" }]}>
                  Interest only loan
                </Text>
                <View style={[styles.box, { marginTop: 6, gap: 16 }]}>
                  {/* <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <CustomSwitch
                          selectionMode={value}
                          roundCorner={false}
                          option1={OPTIONS_LOAN_TYPE[LOAN_TYPE.IO].value}
                          label1={OPTIONS_LOAN_TYPE[LOAN_TYPE.IO].label}
                          onSelectSwitch={onChange}
                          selectionColor={MAIN_COLOR.yellow}
                        />
                      )}
                      name="loanType"
                    /> */}
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      gap: 12,
                    }}
                  >
                    <Controller
                      control={control}
                      render={({ field: { ...props } }) => (
                        <View style={{ flex: 1.5 }}>
                          <InputNumber
                            label="Loan Amount"
                            thousandSeparator
                            disabled
                            {...props}
                          />
                        </View>
                      )}
                      name="loanAmount"
                    />
                    <Controller
                      control={control}
                      render={({ field: { ...props } }) => (
                        <View style={{ flex: 1 }}>
                          <InputNumber
                            label="Interest Rate"
                            showPrefixIcon={false}
                            hasDelimiter
                            thousandSeparator
                            suffix=" %"
                            {...props}
                          />
                        </View>
                      )}
                      name="interestRate"
                    />
                  </View>
                </View>
              </View>
              {index !== 0 && (
                <View>
                  <Controller
                    control={control}
                    render={({ field: { ...props } }) => (
                      <InputNumber
                        thousandSeparator
                        label="Cash Contribution"
                        {...props}
                      />
                    )}
                    name="cashContribution"
                  />
                  {errors?.cashContribution?.message && (
                    <Text style={{ fontSize: 11, color: "#C4014B" }}>
                      {errors?.cashContribution?.message}
                    </Text>
                  )}
                </View>
              )}
              <Controller
                control={control}
                render={({ field: { ...props } }) => (
                  <InputNumber
                    thousandSeparator
                    label="Target Rent p/w"
                    {...props}
                  />
                )}
                name="targetRent"
              />
            </View>
            <View style={styles.section}>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectBase
                    label="Type of property"
                    options={[
                      { label: "House", value: "house" },
                      { label: "Dual Occupancy", value: "dual_occupancy" },
                      { label: "SMSF", value: "smsf" },
                    ]}
                    disabled
                    selectedValue={value}
                    onChange={onChange}
                  />
                )}
                name="typeOfProperty"
              />
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectBase
                    label="Income Type"
                    options={[
                      { label: "Single", value: INCOME_TYPE.SINGLE },
                      { label: "Couple", value: INCOME_TYPE.COUPLE },
                    ]}
                    selectedValue={value}
                    onChange={onChange}
                  />
                )}
                name="incomeType"
              />
              <View style={[styles.box, { gap: 12 }]}>
                <Controller
                  control={control}
                  render={({ field: { ...props } }) => (
                    <InputNumber
                      styleLabel={{ color: "#000000" }}
                      label="Income 1"
                      thousandSeparator
                      {...props}
                    />
                  )}
                  name="income1"
                />
                {watch("incomeType") === "couple" &&
                  watch(properties.income2) && (
                    <Controller
                      control={control}
                      render={({ field: { ...props } }) => (
                        <InputNumber
                          styleLabel={{ color: "#000000" }}
                          label="Income 2"
                          thousandSeparator
                          {...props}
                        />
                      )}
                      name="income2"
                    />
                  )}
              </View>
              {/* <Controller
                  control={control}
                  render={({ field: { ...props } }) => (
                    <InputNumber
                      thousandSeparator
                      label="Other Income"
                      disabled
                      {...props}
                    />
                  )}
                  name="otherIncome"
                /> */}
              <Controller
                control={control}
                render={({ field: { ...props } }) => (
                  <InputNumber
                    label="Living Expenses per Month"
                    thousandSeparator
                    {...props}
                  />
                )}
                name="livingExpenses"
              />
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectBase
                    label="Dependents"
                    selectedValue={value}
                    onChange={onChange}
                    options={[
                      { label: "No dependents", value: 0 },
                      { label: "1", value: 1 },
                      { label: "2", value: 2 },
                      { label: "3", value: 3 },
                      { label: "4", value: 4 },
                      { label: "5+", value: 5 },
                    ]}
                  />
                )}
                name="dependant"
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </ModalBase>
  );
};

export default PurchaseTargetModal;
