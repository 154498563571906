import React from "react";
import { View, Image, Text } from "react-native";

import styles from "./styles";

import { MAIN_COLOR } from "../../../Utility/Colors";
import Carousel from "react-native-reanimated-carousel";

function OverviewProperty({ property }) {
  if (!property) return null;

  const getLocation = (fullAddress) => {
    const addressArr = fullAddress.split(",");
    const codeState = addressArr[addressArr.length - 1];
    const subBurb = addressArr[addressArr.length - 2];
    const streetAddress = addressArr[0];

    return {
      streetAddress,
      lastAddress: `${subBurb}, ${codeState}`,
    };
  };

  const images = property.images.map((image) => image.sha);

  return (
    <View style={styles.titleModal}>
      <View style={styles.boxThumbnail}>
        <Carousel
          loop
          width={250}
          height={127}
          autoPlay={true}
          style={{ borderRadius: 8 }}
          data={images}
          scrollAnimationDuration={1000}
          pagingEnabled
          renderItem={({ index }) => (
            <Image
              style={{
                height: "100%",
                width: "100%",
              }}
              key={index}
              resizeMode="cover"
              source={`https://insights.proptrack.com/imagery/250x165/${images[index]}/image.jpg`}
            />
          )}
        />

        <Image
          style={styles.thumbnailIcon}
          source={require("../../../assets/portfolio-roadmap/house.png")}
        />
      </View>
      <View style={styles.boxInfoProperty}>
        <Text
          style={{ paddingRight: 33, fontSize: 18, fontWeight: 600, flex: 1 }}
        >
          {getLocation(property.address.fullAddress).streetAddress}
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 8,
          }}
        >
          <Image
            style={{ width: 20, height: 20 }}
            source={require("../../../assets/location.png")}
          />
          <Text
            style={{
              fontSize: 14,
              color: MAIN_COLOR.G700,
              fontWeight: 400,
            }}
          >
            {getLocation(property.address.fullAddress).lastAddress}
          </Text>
        </View>
        <View style={styles.flexRowBetween}>
          {property.attributes.bedrooms && (
            <View style={styles.sectionItemProperty}>
              <Image
                style={{ width: 24, height: 19 }}
                source={require("../../../assets/portfolio-roadmap/bed.png")}
              />
              <Text style={styles.textItemProperty}>
                {property.attributes.bedrooms || 0} Beds
              </Text>
            </View>
          )}
          <View style={styles.sectionItemProperty}>
            <Image
              style={{ width: 21, height: 21 }}
              source={require("../../../assets/portfolio-roadmap/bath.png")}
            />
            <Text style={styles.textItemProperty}>
              {property.attributes.bathrooms || 0} Baths
            </Text>
          </View>
          <View style={styles.sectionItemProperty}>
            <Image
              style={{ width: 24, height: 24 }}
              source={require("../../../assets/portfolio-roadmap/car.png")}
            />
            <Text style={styles.textItemProperty}>
              {property.attributes.carSpaces || 0} Cars
            </Text>
          </View>
          <View style={styles.sectionItemProperty}>
            <Image
              style={{ width: 24, height: 24 }}
              source={require("../../../assets/portfolio-roadmap/area.png")}
            />
            <Text style={styles.textItemProperty}>
              {property.attributes.landArea || 0} m2
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

export default OverviewProperty;
