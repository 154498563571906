import { StyleSheet } from "react-native";
import { MAIN_COLOR } from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    padding: 13,
    borderWidth: 1,
    height: 255,
    width: 280,
    borderRadius: 15,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    borderColor: "rgba(173, 185, 199, 0.3)",
  },
  center: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
  },
  imageOverview: {
    borderRadius: 8,
    height: 116,
    overflow: "hidden",
  },
  image: {
    height: "100%",
    transform: [{ scale: 2.4 }],
  },
  no_image: {
    height: "100%",
    transform: [{ scale: 1.2 }],
    backgroundColor: "#F5F6FA",
  },
  content: {
    marginTop: 14,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "90%",
  },
  icon: {
    width: 20,
    height: 20,
  },
  name: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 18,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.regular,
  },
  address: {
    fontSize: 14,
    fontWeight: 400,
    color: MAIN_COLOR.G700,
    fontFamily: MAIN_FONT.regular,
  },
  btnView: {
    width: 26,
    height: 26,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "rgba(173, 185, 199, 0.3)",
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
  },
  params: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 10,
    marginTop: 12,
  },
  param: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    backgroundColor: "#F5F6FA",
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
  },
  value: {
    fontSize: 12,
    fontWeight: 500,
    color: MAIN_COLOR.G700,
    fontFamily: MAIN_FONT.regular,
  },
  topLeftSection: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    borderTopLeftRadius: 8,
    borderBottomRightRadius: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 4,
    position: "absolute",
    width: 40,
    height: 40,
    zIndex: 1,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  topRightSection: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    borderRadius: 5,
    paddingHorizontal: 8,
    paddingVertical: 4,
    position: "absolute",
    right: 0,
    top: 0,
    margin: 4,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  txtPurchaseBy: {
    fontFamily: MAIN_FONT.regular,
    fontSize: 8,
    fontWeight: 400,
    color: MAIN_COLOR.G800,
    lineHeight: "120%",
  },
  txtAgency: {
    fontFamily: MAIN_FONT.regular,
    fontSize: 10,
    fontWeight: 600,
    color: MAIN_COLOR.G800,
    lineHeight: "120%",
  },
  blur: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    borderTopLeftRadius: 8,
    borderBottomRightRadius: 8,
    zIndex: -1,
  },
});
