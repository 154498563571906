import { StyleSheet } from "react-native";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import { MAIN_COLOR } from "../../Utility/Colors";

var styles;

export default styles = StyleSheet.create({
  container: {
    padding: 25,
    flex: 1,
  },
  header: {
    paddingHorizontal: 30,
    paddingVertical: 28,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    height: 90,
  },
  title: {
    fontWeight: 600,
    fontSize: 22,
    fontFamily: MAIN_FONT.regular,
    color: "#272D37",
  },
  leftSection: {},
  rightSection: {},
});
