import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { MAIN_COLOR } from "../../../../Utility/Colors";
import styles from "./style";

const ButtonExpand = ({
  leftIcon,
  title,
  children,
  showPopup,
  showRightIc,
  disabledClickOutside,
  setShowPopup,
}) => {
  const toggleOpen = () => setShowPopup(!showPopup);

  const handleOutsideClick = () => {
    !disabledClickOutside && setShowPopup(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <View
        style={[
          styles.container,
          showPopup && { borderColor: MAIN_COLOR.yellow },
        ]}
      >
        <TouchableOpacity
          onPress={toggleOpen}
          style={[
            styles.flexRow,
            {
              justifyContent: "space-between",
              paddingHorizontal: 26,
              paddingVertical: 8,
            },
          ]}
        >
          <View style={[styles.flexRow, { gap: 12 }]}>
            <Image resizeMode="contain" style={styles.icon} source={leftIcon} />
            <Text style={styles.title}>{title}</Text>
          </View>
          {showRightIc && (
            <Image
              resizeMode="contain"
              style={styles.rightIc}
              source={
                showPopup
                  ? require("../../../../assets/portfolio-roadmap/arrow-up.svg")
                  : require("../../../../assets/portfolio-roadmap/arrow-down.svg")
              }
            />
          )}
        </TouchableOpacity>
        {showPopup && !!children && (
          <View style={styles.sectionExpand}>
            <View style={styles.divider} />
            {children}
          </View>
        )}
      </View>
    </OutsideClickHandler>
  );
};

export default ButtonExpand;
