import { StyleSheet } from "react-native";
import { MAIN_COLOR, TAB_BAR_COLOR } from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    height: 44,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  borderLeft: {
    borderRadius: 11,
    backgroundColor: "#D9D9D9",
    width: 2,
    height: "100%",
  },
  content: {
    marginLeft: 6,
  },
  label: {
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLOR.G700,
    fontSize: 12,
    fontWeight: 400,
    flexShrink: 1,
    lineHeight: 18,
    marginBottom: 4,
  },
  value: {
    fontFamily: MAIN_FONT.regular,
    color: TAB_BAR_COLOR.INACTIVE_COLOR,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 22,
  },
});
