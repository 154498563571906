// expo cli 6.3.2
import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  StatusBar,
  SafeAreaView,
  Dimensions
} from 'react-native';
import { useFonts } from 'expo-font';
import FlashMessage from "react-native-flash-message";
// import { clearAsyncStorage } from './Action/asyncCtrl';
import 'react-native-gesture-handler';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import '@react-native-firebase/crashlytics';
import firebase from 'firebase';
import Router from "./Router";
import { store, persistor } from "./StoreConfig";
import { FLASH_MESSAGE, MAIN_COLOR } from './Utility/Colors';
import Toast from "react-native-toast-message";
import { toastConfig } from "./configs/toastConfig";

firebase.initializeApp({
  apiKey: "AIzaSyDXqonxP2xZNHPx8YXiG7ayhZzGjVFXlXI",
  authDomain: "stratprops-7d489.firebaseapp.com",
  projectId: "stratprops-7d489",
  storageBucket: "stratprops-7d489.appspot.com",
  messagingSenderId: "768442873006",
  appId: "1:768442873006:web:e108b38fd5de5e002ce93f",
  measurementId: "G-W076XZN9YY"
});

export default function App() {


  const [dimensions, setDimensions] = useState(Dimensions.get('window'));


  useLayoutEffect(() => {
    // const subscription = Dimensions.addEventListener(
    //   "change",
    //   ({ window, screen }) => {
    //     setDimensions({ window, screen });
    //   }
    // );
    // return () => subscription?.remove();
    // alert("L")
  }, [dimensions.width]);


  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        // alert(window)
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  },[dimensions])

  //  clearAsyncStorage();

  const [loaded] = useFonts({
    'Poppins-Light': require('./assets/fonts/Poppins-Light.ttf'),
    'Poppins-Regular': require('./assets/fonts/Poppins-Regular.ttf'),
    'Poppins-SemiBold': require('./assets/fonts/Poppins-SemiBold.ttf'),
    'Poppins-Bold': require('./assets/fonts/Poppins-Bold.ttf'),

  });

  if (!loaded) {
    return null;
  }


  console.disableYellowBox = true;
  return (
    <Provider store={store}>
      <SafeAreaView style={{flex: 1}} >
      <PersistGate loading={null} persistor={persistor}>
        <StatusBar barStyle="dark-content" backgroundColor={MAIN_COLOR.PRIMARY_COLOR} />
        <Router />
        <FlashMessage position="top" titleStyle={{ color: FLASH_MESSAGE.TEXT_COLOR, fontFamily: 'Poppins-Bold' }} />
        </PersistGate>
      </SafeAreaView>
    </Provider>
  );
};
