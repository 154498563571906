import { USER } from '../Utility/ReduxType';
import { setDataToAsyncStorage } from '../Action/asyncCtrl'

const initialState = {

    about: "",
    email: "",
    firstname: "",
    id: "",
    image: "",
    lastname: "",
    phone: "",
    role: "", 
    status: "",
    userid: "",
    username: ""
}

export const setUserToken = content => ({
    type: USER.SET_USER_TOKEN,
    payload: content
});

export const setAccessToken = content => ({
    type: USER.SET_ACCESS_TOKEN,
    payload: content
});

  
export const setUserInfo = content => ({
    type: USER.SET_USER_INFO,
    payload: content
});

export function user(state = initialState, action) {
    
    switch (action.type) {

        case USER.SET_ACCESS_TOKEN: {
            return {
                ...state,
                ACCESS_TOKEN: action.payload.ACCESS_TOKEN,
            }
        }

        case USER.SET_USER_TOKEN: {
            
            setDataToAsyncStorage("user_token", JSON.stringify(action.payload.USER_TOKEN));

            return {
                type: action.type,
                payload: action.payload
            }
        }

        case USER.SET_USER_INFO: {

            // console.log("action is:", action.payload.USER_INFO);

            setDataToAsyncStorage("user_info", JSON.stringify(action.payload.USER_INFO));

            return action
            // {
            //     ...state,
            //     about: action.payload.about,
            //     email: action.payload.email,
            //     firstname: action.payload.firstname,
            //     id: action.payload.id,
            //     image: action.payload.image,
            //     lastname: action.payload.lastname,
            //     phone: action.payload.phone,
            //     role: action.payload.role, 
            //     status:  action.payload.status,
            //     userid: action.payload.userid,
            //     username: action.payload.username
            // }

            
        }

        default:
            return state
    }
}

