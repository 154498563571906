import React from 'react';
import {
  StyleSheet,
  Dimensions,
} from 'react-native';
import { RFValue } from "react-native-responsive-fontsize";

import { MAIN_FONT } from "../../Utility/Fonts/font";
import { MAIN_COLOR, MAIN_HEADER } from "../../Utility/Colors"
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

const { width } = Dimensions.get("window");

var styles;

export default styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
  },

  container: {
    flex: 1,
  },

  scrollViewContainer: {
    width,
  },

  scrollViewContentView: {
    flex: 1,
    backgroundColor: 'rgb(243, 244, 249)'
  },

  topView: {
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_DARK,
    padding: 25,
    paddingTop: 0
  },

  centerView: {
    justifyContent: "flex-start",
    paddingHorizontal: 25
  },

  row: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },

  dateTime: { 
    fontSize: 14, 
    color: MAIN_COLOR.G800, 
    fontFamily: MAIN_FONT.regular, 
    textAlign: 'left' 
  },
  userImage: {
      borderRadius: 25,
      height: 50, 
      width: 50,
      resizeMode: 'cover',
      marginRight: 15
  },

  shadow: {
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 1,
    shadowColor: MAIN_COLOR.G700,
},
taskDescription: {
  fontSize: 13, 
  color: '#66737F', 
  fontFamily: MAIN_FONT.regular, 
},
fileIcon: {
  height: 18, 
  width: 18, 
},



});


