import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Image, ScrollView, Text, TouchableOpacity, View } from "react-native";
import Toast from "react-native-toast-message";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  addPartnerRoadmap,
  deleteRoadmapPartner,
} from "../../../../Clickup/api";
import Modal from "../../../../components/Modal";
import Input from "../../../../components/TextInput";
import styles from "./style";

const AddPartner = ({
  setDisabledClickOutside,
  setShowPopup,
  userId,
  listRoadmapPartner,
  setListRoadmapPartner,
}) => {
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.TIA);

  const [visibleAddPartnerModal, setVisibleAddPartnerModal] = useState(false);
  const [partner, setPartner] = useState(null);
  const [loading, setLoading] = useState(false);

  const schemaForm = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
        ""
      ),
  });

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schemaForm),
  });

  const handleShowModalRemove = (partner) => {
    setVisibleAddPartnerModal(true);
    setPartner(partner);
  };

  const handleHideModalRemove = () => {
    setVisibleAddPartnerModal(false);
    setPartner(null);
  };

  const handleRemovePartner = async () => {
    try {
      await deleteRoadmapPartner(partner.id);
      Toast.show({
        type: "success",
        text1: "Removed Partner Successfully!",
        position: "top",
        visibilityTime: 3000,
      });
      setShowPopup(false);
      setListRoadmapPartner(
        listRoadmapPartner.filter((item) => item.id !== partner.id)
      );
    } catch (error) {
      console.log(error);
    }
    setVisibleAddPartnerModal(false);
    reset();
  };

  const handleAddPartner = async ({ email }) => {
    try {
      setLoading(true);
      const payload = {
        email: email.trim(),
        client_id: client.id,
        client_name: client.name,
      };
      const data = await addPartnerRoadmap(payload);
      if (data.status !== 201) {
        Toast.show({
          type: "error",
          text1: "This partner already added!",
          position: "top",
          visibilityTime: 3000,
        });
      } else {
        Toast.show({
          type: "success",
          text1: "Added Partner Successfully!",
          position: "top",
          visibilityTime: 3000,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowPopup(false);
      setLoading(false);
    }
    reset();
  };

  useEffect(() => {
    setDisabledClickOutside(visibleAddPartnerModal);
  }, [visibleAddPartnerModal]);

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.partnerList}>
        {!!listRoadmapPartner?.length &&
          listRoadmapPartner.map((item, index) => (
            <View style={styles.wrapContent} key={index}>
              <Text style={styles.name}>{item.email}</Text>
              <TouchableOpacity onPress={() => handleShowModalRemove(item)}>
                <Image
                  style={styles.closeIc}
                  resizeMode="contain"
                  source={require("../../../../assets/portfolio-roadmap/close-circle.png")}
                />
              </TouchableOpacity>
            </View>
          ))}
      </ScrollView>
      <View style={styles.footer}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              value={value}
              placeholder="Enter an email"
              onChangeText={onChange}
            />
          )}
          name="email"
        />
        <TouchableOpacity
          style={[styles.btnAdd, (!isValid || loading) && styles.disabledBtn]}
          disabled={loading}
          onPress={handleSubmit(handleAddPartner)}
        >
          <Image
            style={{ width: 24, height: 24 }}
            source={require("../../../../assets/portfolio-roadmap/sms.png")}
          />
          <Text style={styles.btnText}>Add</Text>
        </TouchableOpacity>
      </View>
      {visibleAddPartnerModal && (
        <Modal
          open={visibleAddPartnerModal}
          title="Remove Partner"
          onClose={handleHideModalRemove}
          btnCancel="Cancel"
          btnSave="Remove"
          size="small"
          onSave={handleRemovePartner}
        >
          <Text>
            Are you sure you want to remove partner "{partner.email}"?
          </Text>
        </Modal>
      )}
    </View>
  );
};

export default AddPartner;
