import React from "react";
import {
  Text,
  View,
  Platform,
  SafeAreaView
} from "react-native";
import * as Progress from 'react-native-progress';

import { MAIN_FONT } from "../../Utility/Fonts/font";
import { MAIN_COLOR } from "../../Utility/Colors";
import { CheckListItem } from "./CheckListItem";

// redux
import { connect } from "react-redux";

// style
import styles from "./style";

class CheckListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checklists: props.checklists
    }
  }

  render() {
    const { checklists } = this.state;
    const items = checklists[0]?.items ?? [];
    const lengthChecked = items.filter(item => item.resolved === true).length;

    const progress = (lengthChecked / items.length) * 100;

    return (
      <View style={{
        backgroundColor: "#FFFFFF",
        borderRadius: 20,
        paddingHorizontal: "5%",
        paddingVertical: 30,
        marginTop: 5,
        marginBottom: 5
      }}>

        <Text
          style={{
            fontSize: 17,
            color: "#36414C",
            fontFamily: MAIN_FONT.semi,
            width: "60%",
          }}
        >
          {checklists[0]?.name ?? ''}
        </Text>
        <View style={{ justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center', gap: 8, marginVertical: 12 }}>
          <Progress.Bar progress={progress.toFixed(0) / 100} animationType="spring" width={130} height={12} borderRadius={10} borderColor={"transparent"} color={MAIN_COLOR.PRIMARY_COLOR} unfilledColor={"#E7ECF2"} />
          <Text style={{ fontSize: 14, color: '#000', fontFamily: MAIN_FONT.regular, marginVertical: 5 }}>
            {lengthChecked}/{items.length}
          </Text>
        </View>
        <View>
          {items?.map((item, index) => <CheckListItem key={index} item={item} />)}
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckListComponent);
