import { StyleSheet } from "react-native";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";
import { MAIN_COLOR } from "../../../../Utility/Colors";

var styles;

export default styles = StyleSheet.create({
  container: {
    height: 96,
    width: 280,
    paddingHorizontal: 16,
    paddingVertical: 32,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: "rgba(173, 185, 199, 0.3)",
  },
  icon: {
    width: 32,
    height: 32,
    marginRight: 12,
  },
  text: {
    fontWeight: 600,
    fontSize: 16,
    fontFamily: MAIN_FONT.semi,
    color: MAIN_COLOR.G800,
  },
  arrowDown: {
    position: "absolute",
    right: 16,
    width: 14,
    height: 12,
  },
  blurView: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
  },
});
