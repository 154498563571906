import React from 'react';
import {
  StyleSheet,
  Dimensions,
} from 'react-native';
import { RFValue } from "react-native-responsive-fontsize";

import { MAIN_FONT } from "../../Utility/Fonts/font";
import { MAIN_COLOR, MAIN_HEADER } from "../../Utility/Colors"
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

const { width } = Dimensions.get("window");

var styles;

export default styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
  },

  container: {
    flex: 1,
  },

  scrollViewContainer: {
    width,
  },

  scrollViewContentView: {
    flex: 1,
    backgroundColor: 'rgb(243, 244, 249)'
  },

  topView: {
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_DARK,
    padding: wp("5%"),
  },

  centerView: {
    justifyContent: "flex-start",
    padding: 25,
    paddingBottom: 25, 
    paddingTop: 0
  },

  row: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },

  userImage: {
      borderRadius: 25,
      height: 50, 
      width: 50,
      resizeMode: 'cover',
      marginRight: 15
  },



});


