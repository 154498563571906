import React from 'react';
import {
  StyleSheet,
  Dimensions,
} from 'react-native';
import { RFValue } from "react-native-responsive-fontsize";

import { MAIN_COLOR, MAIN_HEADER } from "../Colors"
import { MAIN_FONT } from "../Fonts/font";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

const { width } = Dimensions.get("window");

var styles;

export default styles = StyleSheet.create({

  button: {
    marginTop: wp("3%"),
    padding: hp("1%"),
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    backgroundColor: MAIN_COLOR.yellowBackground,
  },

  buttonText: { 
    fontFamily: MAIN_FONT.titillium_web_regular,
    fontSize: RFValue(14),
    color: "#FFF", 
    width: wp("15.5%"),
    textAlign: "center"
  },

  textInputView: {
    flexDirection: "row",
    marginTop: wp("3%"),
    borderWidth: 1,
    borderRadius: 10,
    borderColor: "#D3D3D3",
    backgroundColor: "#FFFFFF",
    paddingLeft: wp("4%"),
    padding: hp("1%"),
  },

  textInputViewError: {
    flexDirection: "row",
    marginTop: wp("3%"),
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: "#FFFFFF",
    paddingLeft: wp("4%"),
    padding: hp("1%"),
    borderColor: "#FF0061",
  },

  inputViewText: {
    flex: 1,
    fontFamily: MAIN_FONT.titillium_web_regular,
    fontSize: RFValue(14),
    color: "#000000",
    padding: 0,
    
    //textAlign: "jutify"
  },

  dropDownView: {
    flexDirection: "row",
    height: wp("12%"),
    marginTop: wp("3%"),
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 5,
    borderRadius: 4,
    borderColor: "#D3D3D3",
    backgroundColor: "#FFFFFF",
    paddingLeft: wp("3.5%"),
    paddingRight: wp("3.5%"),
    alignItems: "center",
  },

  dropDownViewError: {
    flexDirection: "row",
    height: wp("12%"),
    marginTop: wp("3%"),
    borderBottomWidth: 1.5,
    borderLeftWidth: 1.5,
    borderTopWidth: 1.5,
    borderRightWidth: 7,
    borderRadius: 4,
    borderColor: "#FF0061",
    backgroundColor: "#FFFFFF",
    paddingLeft: wp("3.5%"),
    paddingRight: wp("3.5%"),
    alignItems: "center",
  },

  dropDownText: {
    flex: 1,
    fontFamily: MAIN_FONT.titillium_web_regular,
    fontSize: RFValue(14),
    color: "#000000",
    padding: 0,
  },

});


